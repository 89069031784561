import React from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { Navigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { login } from '../../store/users/actions'
import { useAppDispatch } from '../../store'
import { AlertMessage } from '../AlertMessage'
import { socket } from '../../utils/socket'

export const Login: React.FC = () => {
  const [error, setError] = React.useState('')
  const { users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const location = useLocation()

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    const data = new FormData(event.currentTarget)
    const email = data.get('email') as string
    const password = data.get('password') as string
    if (!email || !password) return null

    dispatch(login({ email, password })).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const error = res.error as { message?: string } | undefined
        if (error?.message) {
          setError(error?.message)
        }
      }
      if (res.meta.requestStatus === 'fulfilled' && !socket.active) {
        socket.connect()
      }
    })
  }

  if (users.isAuth) return <Navigate to={ROUTES.onlineHelp} state={{ from: location }} replace />

  return (
    <Container component='main' maxWidth='xs' sx={{ height: '100vh' }}>
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: 'error.main' }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Увійти
        </Typography>
        <Box component='form' onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            required
            fullWidth
            id='email'
            label='Email'
            name='email'
            autoComplete='email'
            autoFocus
          />
          <TextField
            margin='normal'
            required
            fullWidth
            name='password'
            label='Пароль'
            type='password'
            id='password'
            autoComplete='current-password'
          />
          <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2 }}>
            Увійти
          </Button>
        </Box>
        {error ? <AlertMessage type='error' message={error} /> : null}
      </Box>
    </Container>
  )
}
