import React from 'react'
import Box from '@mui/material/Box'
import { Chat } from '../../components/Chat'
import { UnauthorizedChatStatus, InnerHeight, RightSidebarType } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { useAppDispatch } from '../../store'
import {
  closeChat,
  createMessage,
  getCurrentChat,
  getMessages,
  readMessage,
  uploadFile,
} from '../../store/chatsUnauthorized/actions'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { UnauthorizedChatHeader } from '../../components/ChatHeaderInfo/UnauthorizedChatHeader'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery'
import { ChatsUnauthorizedActions } from '../../store/chatsUnauthorized'
import { ROUTES } from '../../utils/routes'

export const ChatWorkspaceUnauthorized: React.FC = () => {
  const { chatsUnauthorized, templatesUnauthorized, users, configurations } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const status = useQuery().get('status')
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!Number.isNaN(Number(id))) {
      dispatch(getCurrentChat(Number(id))).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          navigate(ROUTES.routeChange(''))
        }
      })
    } else {
      dispatch(ChatsUnauthorizedActions.setCurrentChat(null))
    }
  }, [chatsUnauthorized.clikedOnChat, id, status, users.socket])

  React.useEffect(() => {
    if (status !== null) {
      dispatch(ChatsUnauthorizedActions.changeStatus(Number(status)))
    }
  }, [status])

  const handleReadMessage = (id: number) => {
    dispatch(readMessage(id))
  }

  const handleCloseChat = (data: { unauthorized_id: number; appeal_id: number }, callback?: () => void) => {
    dispatch(closeChat(data)).finally(() => callback && callback())
  }

  const handleChangePage = (page: number, callback?: () => void) => {
    if (chatsUnauthorized.currentChat) {
      dispatch(getMessages({ id: chatsUnauthorized.currentChat.absnum, page })).finally(() => {
        callback && callback()
      })
    }
  }

  const handleOpenSidebar = () => {
    dispatch(setRightSidebar(RightSidebarType.UNAUTHORIZED))
  }

  const handleCreateMessage = (value: string, callback?: () => void) => {
    if (chatsUnauthorized.currentChat) {
      dispatch(
        createMessage({
          unauthorized_id: chatsUnauthorized.currentChat.absnum,
          message: value,
        })
      ).then(callback)
    }
  }

  const handleSaveFile = (file: File, callback?: (succes: boolean, data?: any) => void) => {
    if (chatsUnauthorized.currentChat) {
      const fd = new FormData()
      fd.append('file', file)
      fd.append('chat_id', String(chatsUnauthorized.currentChat.absnum))
      fd.append('file_name', file.name)
      dispatch(uploadFile(fd)).then((res) => {
        const success = res.meta.requestStatus === 'fulfilled'
        if (callback) callback(success, res)
      })
    }
  }

  if (!chatsUnauthorized.currentChat || chatsUnauthorized.loadingChat) return null

  return (
    <Box px={3} height={InnerHeight} overflow={'auto'} display={'flex'} flexDirection={'column'}>
      {chatsUnauthorized.currentChat ? (
        <UnauthorizedChatHeader
          isBtn={
            UnauthorizedChatStatus.CLOSE !== chatsUnauthorized.currentChat.status &&
            chatsUnauthorized.currentChat.expert_id === users.user?.id
          }
          currentChat={chatsUnauthorized.currentChat}
          openSidebar={handleOpenSidebar}
          closeChat={handleCloseChat}
          siteUrl={configurations.all?.find((item) => item.absnum === chatsUnauthorized.currentChat?.site_id)?.url}
        />
      ) : null}
      {chatsUnauthorized.currentChat.chat ? (
        <Chat
          chat={chatsUnauthorized.currentChat.chat}
          currentChat={chatsUnauthorized.currentChat}
          groups={templatesUnauthorized.groups}
          readMessage={handleReadMessage}
          changePage={handleChangePage}
          createMessage={handleCreateMessage}
          saveFile={handleSaveFile}
        />
      ) : null}
    </Box>
  )
}
