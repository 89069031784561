import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IMailing, IMailingState } from './types'

export const getMailingList = createAsyncThunk<IMailingState['data'], IMailing>(
  'mailing/getMailingList',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)
      return callApi({
        method: 'get',
        path: query ? `/mailing?${query}` : '/mailing',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getMailing = createAsyncThunk<IMailingState['data'], number>(
  'mailing/getMailing',
  async (id, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/mailing/one/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const createMailing = createAsyncThunk<IMailing, IMailing>(
  'mailing/createMailing',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/mailing',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateMailing = createAsyncThunk<IMailing, { id: number; data: IMailing }>(
  'mailing/updateMailing',
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/mailing/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateMailingStatus = createAsyncThunk<IMailing, { id: number; data: { status: number } }>(
  'mailing/updateMailingStatus',
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload
      return callApi({
        method: 'put',
        data,
        path: `/mailing/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const deleteMailing = createAsyncThunk<IMailing, number>('mailing/deleteMailing', async (payload, thunkAPI) => {
  try {
    const id = payload
    return callApi({
      method: 'delete',
      data: {},
      path: `/mailing/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const uploadFile = createAsyncThunk<{ link: string }, FormData>(
  'mailing/uploadFile',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        isFormData: true,
        path: `/mailing/upload`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
