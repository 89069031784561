import React from 'react'
import Paper from '@mui/material/Paper'
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { Form, Formik } from 'formik'
import { InputFormik } from '../../ui/InputFormik'
import { AlertMessage } from '../AlertMessage'
import { Title } from '../Title'
import { IEmployee, TCreateEmployeeR } from '../../store/employees/types'
import { FieldNewPasswd } from '../../ui/FieldNewPasswd'
import { useAppDispatch } from '../../store'
import { changePasswordEmployee } from '../../store/employees/actions'
import DeleteIcon from '@mui/icons-material/Delete'
import { CHANNELS_ID, Positions, Roles } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { UploadFileFormik } from '../../ui/UploadFileFormik'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

interface IData {
  [x: string]: { id: number; name: string }
}

interface Props {
  title: string
  data?: IEmployee
  handleSubmit: (values: TCreateEmployeeR, callback: (success: boolean, message?: string) => void) => void
}

export const EmployeeForm: React.FC<Props> = ({ title, data, handleSubmit }) => {
  const [errors, setErrors] = React.useState('')
  const [error, setError] = React.useState(false)
  const [role, setRole] = React.useState('1')
  const [loading, setLoading] = React.useState(false)
  const [isNewPassw, setIsNewPassw] = React.useState(false)
  const [sites, setSites] = React.useState<string[]>([])
  const [sitesProducts, setSitesProducts] = React.useState<IData>({})
  const [directions, setDirections] = React.useState<{ [x: string]: { value: string; label: string } }>({})
  const [objSites, setObjSites] = React.useState<IData>({})

  const { configurations } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (configurations.all) {
      const obj = configurations.all.reduce((acum, item) => {
        item.direction_list?.forEach((direction) => {
          acum[direction.absnum] = { value: String(direction.absnum), label: direction.name }
        })
        return acum
      }, {} as { [x: string]: { value: string; label: string } })

      setDirections(obj)
    }
  }, [configurations.all])

  React.useEffect(() => {
    if (data?.products && data.products.length > 0) {
      const result = data.products.reduce((acum, item) => {
        if (item.children) {
          item.children.forEach((subProduct) => {
            if (!acum[subProduct.absnum]) {
              acum[subProduct.absnum] = { id: subProduct.absnum, name: subProduct.name }
            }
          })
        }
        return acum
      }, {} as IData)
      setSitesProducts(result)
    }
    if (data) {
      setRole(String(data.role || ''))
    }
    if (data?.sites) {
      const names = {} as { [x: string]: string }
      const sites = data.sites.reduce((acum, item) => {
        acum[item.absnum] = { id: item.absnum, name: item.name }
        item.directions.split(',').forEach((id) => {
          if (directions[id]) {
            names[id] = directions[id].label
          }
        })
        return acum
      }, {} as IData)

      setObjSites(sites)
      setSites(Object.values(names))
    }
  }, [data, directions])

  const handleChange = (event: SelectChangeEvent<typeof sites>) => {
    const {
      target: { value },
    } = event
    const names = typeof value === 'string' ? value.split(',') : value
    setSites(names)
    if (names.length > 0 && configurations.all) {
      const products = {} as IData
      const objSites = {} as IData
      configurations.all.forEach((item) => {
        const checked = item.direction_list?.filter((item) => names.includes(item.name)) || []
        if (checked.length > 0) {
          item.products?.forEach((product) => {
            if (!products[product.absnum]) {
              products[product.absnum] = { id: product.absnum, name: product.name }
            }
          })
          objSites[item.absnum] = { id: item.absnum, name: item.name }
        }
      })

      setSitesProducts(products)
      setObjSites(objSites)
    } else {
      setSitesProducts({})
      setObjSites({})
    }
  }

  const removeSite = (id: number) => {
    const obj = { ...objSites }
    delete obj[id]
    setObjSites(obj)
  }

  const removeProduct = (id: number) => {
    const obj = { ...sitesProducts }
    delete obj[id]
    setSitesProducts(obj)
  }

  const renderFiled = () => {
    if (!data) return <InputFormik label='Пароль' name='password' required />
    if (isNewPassw)
      return (
        <FieldNewPasswd
          handleClose={() => setIsNewPassw(false)}
          handleSubmit={(value, callback) => {
            dispatch(
              changePasswordEmployee({
                userId: data.id,
                data: {
                  password: value,
                },
              })
            )
              .then(() => callback(true))
              .catch(() => callback(false))
          }}
        />
      )
    return (
      <Stack direction={'row'} spacing={2}>
        <Button variant='text' onClick={() => setIsNewPassw(true)}>
          Змінити пароль
        </Button>
      </Stack>
    )
  }

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          username: data?.username || '',
          password: '',
          fio: data?.fio || '',
          photo: data?.photo || '',
          chanel_news: data?.channels?.includes(String(CHANNELS_ID.NEWS)) ? true : false,
          chanel_sales: data?.channels?.includes(String(CHANNELS_ID.SALES)) ? true : false,
          chanel_online: data?.channels?.includes(String(CHANNELS_ID.ONLINE)) ? true : false,
          chanel_written: data?.channels?.includes(String(CHANNELS_ID.WRITTEN)) ? true : false,
          chanel_tech_support: data?.channels?.includes(String(CHANNELS_ID.TECH_SUPPORT)) ? true : false,
          chanel_unauthorized: data?.channels?.includes(String(CHANNELS_ID.UNAUTHORIZED)) ? true : false,
        }}
        onSubmit={(values) => {
          const channels = [] as number[]
          if (values.chanel_news) channels.push(CHANNELS_ID.NEWS)
          if (values.chanel_sales) channels.push(CHANNELS_ID.SALES)
          if (values.chanel_online) channels.push(CHANNELS_ID.ONLINE)
          if (values.chanel_written) channels.push(CHANNELS_ID.WRITTEN)
          if (values.chanel_tech_support) channels.push(CHANNELS_ID.TECH_SUPPORT)
          if (values.chanel_unauthorized) channels.push(CHANNELS_ID.UNAUTHORIZED)

          const products = Object.values(sitesProducts).map((item) => ({ product_id: item.id }))

          const respSites = Object.values(objSites)
            .filter((item) => !!Number(item.id))
            .map((item) => ({ site_id: Number(item.id) }))

          if (respSites.length === 0) return setError(true)

          if (products.length === 0) return setError(true)
          if (!Number(role)) return setError(true)

          setLoading(true)
          handleSubmit(
            {
              sites: respSites,
              products,
              fio: values.fio,
              password: values.password,
              role: Number(role),
              username: values.username,
              channels,
              photo: values.photo,
            },
            (success, message) => {
              if (!success) {
                setErrors(message || 'Сталася помилка')
              }
              setLoading(false)
            }
          )
        }}
      >
        {(formik) => (
          <Form>
            <Paper sx={{ m: 2, p: 2, overflow: 'hidden', minHeight: 'calc(100vh - 102px)' }}>
              <Stack spacing={2} direction={'column'} maxWidth={'900px'}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                  <Title title={title} color={'default'} mb={2} />
                  <Stack direction={'row'} spacing={2}>
                    <Button variant={'contained'} type='submit'>
                      {loading ? <CircularProgress size={15} /> : 'Зберегти'}
                    </Button>
                    <Button variant={'outlined'} color={'inherit'} onClick={() => navigate(ROUTES.employees)}>
                      Назад
                    </Button>
                  </Stack>
                </Stack>
                {errors ? <AlertMessage type='error' message={errors} /> : null}
                <InputFormik label='ПІБ' name='fio' required />
                <InputFormik label='Email' name='username' required />
                {renderFiled()}
                <div style={{ position: 'relative' }}>
                  <UploadFileFormik label='Фото 107 x 132' name='photo' disabled apiPath='/employees/upload' />
                  <div
                    style={{
                      position: 'absolute',
                      right: '-140px',
                      top: '-40px',
                    }}
                  >
                    {formik.values.photo ? (
                      <img style={{ width: '107px', height: '137px' }} src={formik.values.photo} alt='Фото' />
                    ) : null}
                  </div>
                </div>
                <FormControl>
                  <InputLabel size={'small'} id='simple-select'>
                    Посада
                  </InputLabel>
                  <Select
                    size={'small'}
                    value={role}
                    labelId='simple-select'
                    id='simple-select'
                    label='Посада'
                    name='role'
                    error={error && !Number(role)}
                    onChange={(event) => setRole(event.target.value)}
                  >
                    {Object.entries(Positions).map((items, index) => {
                      return (
                        <MenuItem
                          disabled={Number(items[0]) === Roles.ADMIN}
                          key={`role_${index}`}
                          value={Number(items[0])}
                        >
                          {items[1].name}
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography component='h6' variant='h6' marginRight={'20px'}>
                    Канали
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.chanel_online}
                          name='chanel_online'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Онлайн'
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.chanel_written}
                          name='chanel_written'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Письмово'
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.chanel_tech_support}
                          name='chanel_tech_support'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Техпідтримка'
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.chanel_sales}
                          name='chanel_sales'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Продажі'
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.chanel_news}
                          name='chanel_news'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Новини'
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.chanel_unauthorized}
                          name='chanel_unauthorized'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Неавторизовані'
                    />
                  </FormGroup>
                </Stack>
                <FormControl sx={{ mt: 1 }} fullWidth>
                  <InputLabel size={'small'} id='demo-multiple-checkbox-label'>
                    Напрямки
                  </InputLabel>
                  <Select
                    size={'small'}
                    labelId='demo-multiple-checkbox-label'
                    id='demo-multiple-checkbox-label'
                    label='Напрямки'
                    name='demo-multiple-checkbox-label'
                    multiple
                    value={sites}
                    onChange={handleChange}
                    input={<OutlinedInput sx={{ color: '#000' }} label='Сайт' />}
                    renderValue={(selected) => selected.join(', ')}
                    MenuProps={MenuProps}
                    error={error && sites.length === 0}
                  >
                    {Object.values(directions).map((item) => (
                      <MenuItem key={item.value} value={item.label}>
                        <Checkbox checked={!!sites.find((it) => it === item.label)} />
                        <ListItemText primary={item.label} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Stack spacing={1} direction={'row'} flexWrap={'wrap'}>
                  <Typography component='h6' variant='h6' marginRight={'20px'}>
                    Сайти
                  </Typography>
                  {Object.values(objSites)?.map((item) => (
                    <Chip
                      key={`sub_${item.id}`}
                      label={item.name}
                      style={{ margin: '0 10px 10px 0' }}
                      onDelete={() => removeSite(item.id)}
                      deleteIcon={<DeleteIcon />}
                      variant='outlined'
                    />
                  ))}
                </Stack>
                <Stack spacing={1} direction={'row'} flexWrap={'wrap'}>
                  <Typography component='h6' variant='h6' marginRight={'20px'}>
                    Продукти
                  </Typography>
                  {Object.values(sitesProducts)?.map((item) => (
                    <Chip
                      key={`sub_${item.id}`}
                      label={item.name}
                      style={{ margin: '0 10px 10px 0' }}
                      onDelete={() => removeProduct(item.id)}
                      deleteIcon={<DeleteIcon />}
                      variant='outlined'
                    />
                  ))}
                </Stack>
              </Stack>
            </Paper>
          </Form>
        )}
      </Formik>
    </>
  )
}
