import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import { makeStyles } from '@mui/styles'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { useAppDispatch } from '../../store'
import { createMailing, deleteMailing, getMailingList, updateMailingStatus } from '../../store/mailing/actions'
import { CHANNELS_ID, Roles, STATUSES_MAILING, TypeMailing } from '../../utils/consts'
import { useSearch } from '../../hooks/useSearch'
import { BasicDatePicker } from '../../ui/BasicDatePicker'
import moment from 'moment'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { IMailing } from '../../store/mailing/types'
import { getDownloadStatatistics, getStatViewDownload } from '../../store/statMailing/actions'
import { searchEmployees } from '../../store/employees/actions'
import { ConfirmModal } from '../../components/modals/ConfirmModal'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'

interface Column {
  id: 'absnum' | 'name' | 'status' | 'adate' | 'date_start' | 'direction_name'
  label: string
  width?: number | string
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'absnum', label: 'ID', width: '70px' },
  { id: 'name', label: 'Назва повідомлення', width: '17%' },
  { id: 'status', label: 'Статус', width: 112 },
  { id: 'adate', label: 'Дата створення' },
  { id: 'date_start', label: 'Дата старту' },
  { id: 'direction_name', label: 'Напрямок' },
]

const Styles = makeStyles(() => ({
  searchField: {
    width: '210px !important',
    marginRight: '10px !important',
    marginBottom: '10px !important',
  },
}))

export const MailingList: React.FC = () => {
  const [data, setData] = React.useState<null | { id: number; name: string }>(null)
  const [name, setName] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [directionId, setDirectionId] = React.useState('')
  const [from, setFrom] = React.useState<Date | null>(null)
  const [to, setTo] = React.useState<Date | null>(null)
  const [authorId, setAuthorId] = React.useState('')
  const [loadingId, setLoadingId] = React.useState(0)
  const [loadingStat, setLoadingStat] = React.useState(false)
  const [directions, setDirections] = React.useState<{ value: string; label: string }[]>([])
  const [errors, setErrors] = React.useState<{ id: number; message: string } | null>(null)

  const { mailing, configurations, employees, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { loading, handleSearch } = useSearch({ actionThunk: getMailingList })

  const classes = Styles()

  const obj = {
    name,
    status,
    direction_id: directionId,
    from: from ? moment(from).format('YYYY-MM-DD HH:mm') : '',
    to: to ? moment(to).format('YYYY-MM-DD HH:mm') : '',
    author_id: authorId,
  }

  React.useEffect(() => {
    dispatch(
      searchEmployees({
        channels: String(CHANNELS_ID.NEWS),
        roles: [Roles.CHIEF, Roles.MARKETER].toString(),
      })
    )
  }, [])

  React.useEffect(() => {
    if (configurations.all) {
      const obj = configurations.all.reduce((acum, item) => {
        item.direction_list?.forEach((direction) => {
          acum[direction.absnum] = { value: String(direction.absnum), label: direction.name }
        })

        return acum
      }, {} as { [x: string]: { value: string; label: string } })
      setDirections(Object.values(obj))
    }
  }, [configurations.all])

  const clearFilters = () => {
    setAuthorId('')
    setName('')
    setDirectionId('')
    setFrom(null)
    setTo(null)
    setStatus('')
    dispatch(getMailingList({}))
  }

  const handleChangePage = (event: unknown, page: number) => {
    if (!mailing.data) return null
    if (mailing.data.pages >= page) {
      dispatch(getMailingList({ page, ...obj }))
    }
  }

  const handleChangeStatus = (data: IMailing) => {
    if ([TypeMailing.COMPLETED].includes(Number(data.status)) || !data.date_start || !data.date_end) return null
    dispatch(
      updateMailingStatus({
        id: data.absnum as number,
        data: {
          ...data,
          status: [TypeMailing.PLANNED, TypeMailing.SENDING].includes(Number(data.status))
            ? TypeMailing.DEACTIVATED
            : TypeMailing.PLANNED,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus !== 'fulfilled') {
        const { error } = res as { error: { message: string } }
        setErrors({ id: data.absnum as number, message: error.message })
      }
    })
  }

  const handleCoppy = (data: IMailing) => {
    setLoadingId(data.absnum || 0)
    dispatch(
      createMailing({
        site_id: data.site_id,
        direction_id: data.direction_id,
        status: TypeMailing.DRAFT,
        name: data.name,
        body: data.body,
        btn_name: data.btn_name,
        btn_color: data.btn_color,
        link: data.link,
        photo: data.photo,
        sender: data.sender,
        img: data.img,
      })
    ).then(() => setLoadingId(0))
  }

  const handleDelete = () => {
    if (!data) return null
    dispatch(deleteMailing(data.id))
    setData(null)
  }

  const downloadStat = () => {
    setLoadingStat(true)
    dispatch(getStatViewDownload(obj)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const { data } = res.payload as { data: Buffer }
        const buff = new Uint8Array(data).buffer
        const url = window.URL.createObjectURL(
          new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Звіт.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.parentNode && link.parentNode.removeChild(link)
      }
      setLoadingStat(false)
    })
  }

  const downloadStatatistics = () => {
    setLoadingStat(true)
    dispatch(getDownloadStatatistics(obj)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const { data } = res.payload as { data: Buffer }
        const buff = new Uint8Array(data).buffer
        const url = window.URL.createObjectURL(
          new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `Статистика.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.parentNode && link.parentNode.removeChild(link)
      }
      setLoadingStat(false)
    })
  }

  const size = 'small'
  return (
    <>
      <Paper sx={{ m: 2, overflow: 'hidden', minHeight: 'calc(100% - 100px)' }}>
        <Box pt={2} px={2} display={'flex'} justifyContent={'space-between'}>
          <Button onClick={() => navigate(ROUTES.mailingChange('new'))} color={'success'} variant={'contained'}>
            Створити
          </Button>
          <Box display={'flex'} gap={2}>
            <Button disabled={loadingStat} onClick={downloadStat} color={'success'} variant={'outlined'}>
              Завантажити звіт
            </Button>
            {['yevhenii.davydenko@lasoft.org', 'okukharuk@expertus.com.ua', 'oshchankina@expertus.com.ua'].includes(
              users.user?.username || ''
            ) && (
              <Button disabled={loadingStat} onClick={downloadStatatistics} color={'success'} variant={'outlined'}>
                Завантажити статистику
              </Button>
            )}
          </Box>
        </Box>
        <Box my={2} px={2} display={'flex'} flexWrap={'wrap'}>
          <TextField
            className={classes.searchField}
            size={size}
            id='outlined-basic-id'
            label='Пошук за назвою'
            variant='outlined'
            value={name}
            onChange={(event) => {
              setName(event.target.value)
              handleSearch(event.target.value, 'name', obj)
            }}
          />
          <FormControl>
            <InputLabel size={size} id='simple-select'>
              Статус
            </InputLabel>
            <Select
              className={classes.searchField}
              size={size}
              labelId='simple-select'
              id='simple-select'
              label='Статус'
              value={status}
              onChange={(event) => {
                setStatus(event.target.value)
                handleSearch(event.target.value, 'status', obj)
              }}
            >
              <MenuItem value={''}>Всі</MenuItem>
              {Object.entries(STATUSES_MAILING)?.map((item, index) => {
                return (
                  <MenuItem key={index} value={item[0]}>
                    {item[1].title}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <BasicDatePicker
            className={classes.searchField}
            id={'from'}
            label='Дата створення: Від'
            value={from}
            onChange={(date) => {
              setFrom(date)
              handleSearch(date ? moment(date).format('YYYY-MM-DD HH:mm') : '', 'from', obj)
            }}
          />
          <BasicDatePicker
            className={classes.searchField}
            id={'to'}
            label='До'
            value={to}
            onChange={(date) => {
              setTo(date)
              handleSearch(date ? moment(date).format('YYYY-MM-DD HH:mm') : '', 'to', obj)
            }}
          />
          <FormControl>
            <InputLabel size={size} id='simple-select'>
              Напрямок
            </InputLabel>
            <Select
              className={classes.searchField}
              size={size}
              labelId='simple-select'
              id='simple-select'
              label='Напрямок'
              value={directionId}
              onChange={(event) => {
                setDirectionId(event.target.value)
                handleSearch(event.target.value, 'direction_id', obj)
              }}
            >
              <MenuItem value={''}>Всі</MenuItem>
              {directions.map((item) => {
                return (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <FormControl>
            <InputLabel size={size} id='simple-select'>
              Хто створив
            </InputLabel>
            <Select
              className={classes.searchField}
              size={size}
              labelId='simple-select'
              id='simple-select'
              label='Хто створив'
              value={authorId}
              onChange={(event) => {
                setAuthorId(event.target.value)
                handleSearch(event.target.value, 'author_id', obj)
              }}
            >
              <MenuItem value={0}>Всі</MenuItem>
              {employees.searchData?.employees?.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.fio}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          <Button style={{ height: '37px' }} variant='contained' color='primary' onClick={clearFilters}>
            {loading ? <CircularProgress size={25} /> : 'Очистити фільтр'}
          </Button>
        </Box>
        <TableContainer>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    key={column.id}
                    align={column.align}
                    style={{ width: column.width }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell align={'center'} sx={{ fontWeight: 'bold' }}>
                  Активація
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {mailing.data?.mailing.map((row) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.absnum}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      if (column.id === 'status')
                        return (
                          <TableCell key={column.id} align={column.align}>
                            <Typography fontSize={12} color={STATUSES_MAILING[row.status || 0]?.color}>
                              {STATUSES_MAILING[row.status || 0]?.title}
                            </Typography>
                          </TableCell>
                        )
                      if (column.id === 'adate' || column.id === 'date_start')
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value ? moment(value).format('DD.MM.YYYY HH:mm') : null}
                          </TableCell>
                        )
                      if (column.id === 'direction_name')
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {(value as string) || 'Інше'}
                          </TableCell>
                        )
                      return (
                        <TableCell key={column.id} align={column.align} sx={{ wordBreak: 'break-word' }}>
                          {value as string}
                        </TableCell>
                      )
                    })}
                    <TableCell width={'160px'} style={{ position: 'relative' }}>
                      {errors?.id === row.absnum ? (
                        <Alert
                          style={{
                            position: 'absolute',
                            right: '150px',
                            top: '-25px',
                            zIndex: 1000,
                            width: '500px',
                          }}
                          severity='error'
                          onClose={() => setErrors(null)}
                        >
                          {errors?.message}
                        </Alert>
                      ) : null}
                      <Button
                        onClick={() => handleChangeStatus(row)}
                        disabled={
                          [TypeMailing.COMPLETED].includes(Number(row.status)) || !row.date_start || !row.date_end
                        }
                        color={
                          [TypeMailing.PLANNED, TypeMailing.SENDING].includes(Number(row.status))
                            ? 'inherit'
                            : 'primary'
                        }
                        variant={
                          [TypeMailing.PLANNED, TypeMailing.SENDING].includes(Number(row.status))
                            ? 'outlined'
                            : 'contained'
                        }
                      >
                        {[TypeMailing.PLANNED, TypeMailing.SENDING].includes(Number(row.status))
                          ? 'Деактивувати'
                          : 'Активувати'}
                      </Button>
                    </TableCell>
                    <TableCell>
                      <Box>
                        <IconButton
                          onClick={() => {
                            navigate(ROUTES.mailingChange(`statistics/${row.absnum}`))
                          }}
                          disabled={
                            ![TypeMailing.COMPLETED, TypeMailing.SENDING, TypeMailing.DEACTIVATED].includes(
                              Number(row.status)
                            )
                          }
                          color={'primary'}
                        >
                          <TrendingUpIcon />
                        </IconButton>

                        <IconButton
                          disabled={loadingId === row.absnum}
                          color={'primary'}
                          onClick={() => handleCoppy(row)}
                        >
                          {loadingId === row.absnum ? <CircularProgress size={23} /> : <ContentCopyIcon />}
                        </IconButton>
                        <IconButton
                          color={'success'}
                          onClick={() => {
                            navigate(ROUTES.mailingChange(`edit/${row.absnum}`))
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          color={'error'}
                          onClick={() => setData({ id: row.absnum as number, name: row.name as string })}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ display: 'flex', justifyContent: 'flex-end', padding: '10px 0' }}
          count={mailing.data ? mailing.data.pages : 0}
          page={mailing.data ? mailing.data.page : 1}
          onChange={handleChangePage}
        />
      </Paper>
      <ConfirmModal
        title={'Видалити ' + data?.name || ''}
        open={!!data}
        delHandler={handleDelete}
        closeHandler={() => setData(null)}
      />
    </>
  )
}
