import React from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { passChatToTechSupoort } from '../../store/chatsWritten/actions'
import { MessageSender } from './components/MessageSender'
import { uploadFile } from '../../store/techSupport/actions'
import { parseLink } from '../../utils/parseLink'

export const RightWritten: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | boolean>(false)
  const [is_approved, setApproved] = React.useState<{ is_tech_support: number }>({
    is_tech_support: 0,
  })

  const { chatsWritten, configurations } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (configurations.all && chatsWritten.message) {
      const config = configurations.all.find((item) => item.absnum === chatsWritten.message?.site_id)
      if (config) {
        setApproved({
          is_tech_support: config.approved_tech,
        })
      }
    }
  }, [configurations.all, chatsWritten.message])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleSaveFile = (file: File, callback?: (succes: boolean, data?: any) => void) => {
    if (chatsWritten.message) {
      const fd = new FormData()
      fd.append('file', file)
      fd.append('chat_id', String(chatsWritten.message.chat_id))
      fd.append('file_name', file.name)
      dispatch(uploadFile(fd)).then((res) => {
        const success = res.meta.requestStatus === 'fulfilled'
        if (callback) callback(success, res)
      })
    }
  }

  const handlePassChatToTechSupport = (value: string, callback: () => void) => {
    if (!chatsWritten.message) return null
    dispatch(
      passChatToTechSupoort({
        message_id: chatsWritten.message.absnum,
        part_id: chatsWritten.message.part_id,
        data: {
          message: value,
        },
      })
    ).finally(() => {
      setExpanded(false)
      dispatch(setRightSidebar(false))
      callback()
    })
  }

  if (!chatsWritten.message) return null

  return (
    <Box sx={{ width: 350, paddingTop: '70px' }} role='presentation'>
      {is_approved.is_tech_support ? (
        <MessageSender
          initValue={parseLink(chatsWritten.message?.question)}
          title='Техпідтримка'
          expanded={expanded === '1'}
          onChange={handleChange('1')}
          onSend={handlePassChatToTechSupport}
          saveFile={handleSaveFile}
        />
      ) : null}
    </Box>
  )
}
