import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  Stack,
  TextField,
} from '@mui/material'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { DialogTypeName, LogChatName, Roles } from '../../utils/consts'
import { useSearch } from '../../hooks/useSearch'
import { downloadLogChats, getLogChats } from '../../store/logChats/actions'
import moment from 'moment'
import { SelectSearch } from '../../ui/SelectSearch'
import { BasicDatePicker } from '../../ui/BasicDatePicker'
import DeleteIcon from '@mui/icons-material/Delete'
import { checkFio } from '../../utils/checkFio'
import { ButtonDatePicker } from '../../ui/ButtonDatePicker'
import { useAppDispatch } from '../../store'

interface Column {
  id:
    | 'absnum'
    | 'channel_name'
    | 'chat_id'
    | 'site_name'
    | 'dialog_id'
    | 'client_id'
    | 'user_fio'
    | 'current_user_fio'
    | 'log_chat_type_name'
    | 'chat_type_name'
    | 'product_id'
    | 'to_channel_name'
    | 'from_channel_name'
    | 'chat_status_name'
    | 'rating'
    | 'is_demo'
    | 'time_answer'
    | 'time_chat'
    | 'deadline'
    | 'created_at'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'absnum', label: 'ID' },
  { id: 'channel_name', label: 'Канал', align: 'center' },
  { id: 'site_name', label: 'Система', align: 'center' },
  { id: 'dialog_id', label: 'ID діалогу', align: 'center' },
  //   { id: 'client_id', label: 'ID клієнта' },
  { id: 'user_fio', label: 'ПІБ консультанта', align: 'center' },
  //   { id: 'current_user_fio', label: 'Поточний консультант' },
  { id: 'log_chat_type_name', label: 'Тип логу', align: 'center' },
  { id: 'chat_type_name', label: 'Тип чату', align: 'center' },
  //   { id: 'product_id', label: 'ID продукту' },
  //   { id: 'to_channel_name', label: 'Канал звідки передано чат' },
  //   { id: 'from_channel_name', label: 'Канал куди передано чат' },
  { id: 'chat_status_name', label: 'Статус чату' },
  { id: 'rating', label: 'Оцінка', align: 'center' },
  //   { id: 'is_demo', label: 'Демо' },
  { id: 'time_answer', label: 'Час відповіді сек', align: 'center' },
  { id: 'time_chat', label: 'Час чату сек', align: 'center' },
  //   { id: 'deadline', label: 'Дедлайн' },
  { id: 'created_at', label: 'Дата створення', align: 'center' },
]

const formatDate = 'YYYY-MM-DD HH:mm:ss'

export const ListLogChats: React.FC = () => {
  const [loadingDownload, setLoadingDownload] = React.useState(false)
  const [period, setPeriod] = React.useState<Date | null>(null)
  const [dialogId, setDialogId] = React.useState('')
  const [userId, setUserId] = React.useState('')
  const [channelId, setChannelId] = React.useState('')
  const [logChatType, setLogChatType] = React.useState('')
  const [chatType, setChatType] = React.useState('')
  const [from, setFrom] = React.useState<Date | null>(null)
  const [to, setTo] = React.useState<Date | null>(null)
  const [page, setPage] = React.useState(1)

  const { logChats, employees } = useSelector((store: IStore) => store)
  const { loading, handleSearch } = useSearch({ actionThunk: getLogChats })
  const dispatch = useAppDispatch()

  const obj = {
    dialog_id: dialogId,
    channel_id: channelId,
    user_id: userId,
    log_chat_type_id: logChatType,
    chat_type_id: chatType,
    date_from: from ? moment(from).format(formatDate) : '',
    date_to: to ? moment(to).format(formatDate) : '',
    page: String(1),
  }

  React.useEffect(() => {
    handleSearch('', '')
  }, [])

  const handleChangeFilter = (value: string, key: string) => {
    setPage(1)
    handleSearch(value, key, { ...obj, [key]: value })
  }

  const handleClearFilter = () => {
    setPage(1)
    setChannelId('')
    setDialogId('')
    setUserId('')
    setLogChatType('')
    setChatType('')
    setFrom(null)
    setTo(null)
    handleSearch('1', 'page')
  }

  const handleChangePage = (event: unknown, page: number) => {
    if (!logChats.data) return null
    if (logChats.data.pages >= page) {
      setPage(page)
      handleSearch(String(page), 'page', { ...obj, page: String(page) })
    }
  }

  const handleChangePeriod = (value: Date | null) => {
    setPeriod(value)
    if (!loadingDownload && value) {
      const year = new Date(value).getFullYear()
      const month = String(new Date(value).getMonth())
      if (!year || !month) return null

      setLoadingDownload(true)
      dispatch(downloadLogChats({ year, month }))
        .then((res) => {
          const { data } = (res.payload as { data?: Buffer }) || ({} as { data?: Buffer })
          if (data) {
            const names = ['Логи_чатів']
            names.push(`_${month + 1}_${year}`)

            const buff = new Uint8Array(data).buffer
            const url = window.URL.createObjectURL(
              new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            )
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `${names.join('')}.xlsx`)
            document.body.appendChild(link)
            link.click()
            link.parentNode && link.parentNode.removeChild(link)
          }
        })
        .finally(() => {
          setLoadingDownload(false)
          setPeriod(null)
        })
    }
  }

  return (
    <>
      <Paper sx={{ m: 2, overflow: 'hidden', height: 'calc(100% - 95px)' }}>
        <Stack m={2} spacing={2} direction={'row'}>
          <ButtonDatePicker
            disabled={loadingDownload}
            label='Скачати логи'
            value={period}
            onChange={handleChangePeriod}
          />
          <FormControl sx={{ width: '150px' }}>
            <InputLabel id='simple-select-label1' size={'small'}>
              Канал
            </InputLabel>
            <Select
              size={'small'}
              labelId='simple-select-label1'
              id='simple-select3'
              value={channelId}
              label='Тип логу'
              onChange={(event) => {
                setChannelId(event.target.value)
                handleChangeFilter(event.target.value, 'channel_id')
              }}
            >
              <MenuItem value={''}>Усі</MenuItem>
              <MenuItem value={'1'}>Онлайн</MenuItem>
              <MenuItem value={'2'}>Письмово</MenuItem>
              <MenuItem value={'3'}>Техпідтримка</MenuItem>
              <MenuItem value={'4'}>Продажі</MenuItem>
              <MenuItem value={'5'}>Новини</MenuItem>
            </Select>
          </FormControl>

          <TextField
            style={{ width: '150px' }}
            size={'small'}
            id='outlined-basic-id'
            label='ID діалогу'
            variant='outlined'
            value={dialogId}
            onChange={(event) => {
              setDialogId(event.target.value)
              handleChangeFilter(event.target.value, 'dialog_id')
            }}
          />
          <SelectSearch
            style={{ width: '150px' }}
            label='Консультант'
            value={userId}
            options={employees.data?.employees.map((item) => ({ value: String(item.id), label: checkFio(item) })) || []}
            onChange={(value) => {
              setUserId(value)
              handleChangeFilter(value, 'user_id')
            }}
          />

          <FormControl style={{ width: '150px' }}>
            <InputLabel id='simple-select-label3' size={'small'}>
              Тип логу
            </InputLabel>
            <Select
              size={'small'}
              labelId='simple-select-label3'
              id='simple-select3'
              value={logChatType}
              label='Тип логу'
              onChange={(event) => {
                setLogChatType(event.target.value)
                handleChangeFilter(event.target.value, 'log_chat_type_id')
              }}
            >
              <MenuItem value={''}>Усі</MenuItem>
              {Object.keys(LogChatName).map((item, index) => (
                <MenuItem key={`${index}_${item}`} value={Number(item)}>
                  {LogChatName[item].title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl style={{ width: '150px' }}>
            <InputLabel size={'small'} id='simple-select'>
              Тип чату
            </InputLabel>
            <Select
              size={'small'}
              labelId='simple-select'
              id='simple-select'
              label='Тип чату'
              value={chatType}
              onChange={(e) => {
                setChatType(e.target.value)
                handleChangeFilter(e.target.value, 'chat_type_id')
              }}
            >
              <MenuItem value={''}>Усі</MenuItem>
              {Object.keys(DialogTypeName).map((item, index) => (
                <MenuItem key={`${index}_${item}`} value={Number(item)}>
                  {DialogTypeName[item].title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <BasicDatePicker
            style={{ width: '150px' }}
            id={'from'}
            label='Від'
            value={from}
            onChange={(date) => {
              setFrom(date)
              handleChangeFilter(date ? moment(date).format(formatDate) : '', 'date_from')
            }}
          />
          <BasicDatePicker
            style={{ width: '150px' }}
            id={'to'}
            label='До'
            value={to}
            onChange={(date) => {
              setTo(date)
              handleChangeFilter(date ? moment(date).format(formatDate) : '', 'date_to')
            }}
          />

          <IconButton color={'error'} onClick={handleClearFilter}>
            {loading || loadingDownload ? <CircularProgress size={24} /> : <DeleteIcon />}
          </IconButton>
        </Stack>
        <TableContainer sx={{ maxHeight: 'calc(100vh - 220px)' }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {logChats.data?.logs.map((row, index) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.absnum}>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align}>
                        {row[column.id]}
                      </TableCell>
                    ))}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          sx={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}
          count={logChats.data ? logChats.data.pages : 1}
          page={page}
          onChange={handleChangePage}
        />
      </Paper>
    </>
  )
}
