import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { getDialog, getDialogMessages, searchChats } from './actions'

const initialState: IStore['searchChatsOnline'] = {
  data: null,
  dialog: null,
  searchFields: {},
}

export const slice = createSlice({
  name: 'searchChatsOnline',
  initialState,
  reducers: {
    setDialog: (state, action) => {
      state.dialog = action.payload
    },
    setSearchFields: (state, action) => {
      state.searchFields = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchChats.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(getDialog.fulfilled, (state, action) => {
      if (action.payload?.chat) {
        state.dialog = { ...action.payload, pages: { [action.payload.chat.page]: action.payload.chat } }
      }
    })
    builder.addCase(getDialogMessages.fulfilled, (state, action) => {
      if (state.dialog?.chat && action.payload) {
        state.dialog.pages[action.payload.page] = action.payload
        state.dialog.chat = action.payload
      }
    })
  },
})

export const SearchChatsOnlineActions = slice.actions

export default slice.reducer
