import React from 'react'
import BarChartIcon from '@mui/icons-material/BarChart'
import ChatBubbleOutlineRoundedIcon from '@mui/icons-material/ChatBubbleOutlineRounded'
import { ROUTES } from './routes'
import MailIcon from '@mui/icons-material/Mail'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import SettingsSuggestOutlinedIcon from '@mui/icons-material/SettingsSuggestOutlined'
import { Roles } from './consts'
import OutboxIcon from '@mui/icons-material/Outbox'
import ListAltIcon from '@mui/icons-material/ListAlt'

export const MENU = {
  onlineHelp: {
    title: 'Онлайн помічник',
    link: ROUTES.onlineHelp,
    icon: <ChatBubbleOutlineRoundedIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.CONSULTANT, Roles.TRAINEE, Roles.ADMIN],
  },
  techSupport: {
    title: 'Техпідтримка',
    link: ROUTES.chatsTechSupport,
    icon: <ChatBubbleOutlineRoundedIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.CONSULTANT, Roles.TRAINEE, Roles.ADMIN],
  },
  chatsSales: {
    title: 'Продажі',
    link: ROUTES.chatsSales,
    icon: <ChatBubbleOutlineRoundedIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.CONSULTANT, Roles.TRAINEE, Roles.ADMIN],
  },
  chatsNews: {
    title: 'Новини',
    link: ROUTES.chatsNews,
    icon: <ChatBubbleOutlineRoundedIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.CONSULTANT, Roles.TRAINEE, Roles.ADMIN],
  },
  chatsUnauthorized: {
    title: 'Неавторизовані',
    link: ROUTES.chatsUnauthorized,
    icon: <ChatBubbleOutlineRoundedIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.CONSULTANT, Roles.TRAINEE, Roles.ADMIN],
  },
  letter: {
    title: 'Письмово',
    link: ROUTES.letter,
    icon: <MailIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.ADMIN, Roles.CONSULTANT, Roles.TRAINEE],
  },
  onlineStat: {
    title: 'Статистика онлайн',
    link: ROUTES.statOnline,
    icon: <BarChartIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.ADMIN],
  },
  writtenStat: {
    title: 'Статистика письмово',
    link: ROUTES.statWritten,
    icon: <BarChartIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.ADMIN],
  },
  techStat: {
    title: 'Статистика техпідтримки',
    link: ROUTES.statTechSupport,
    icon: <BarChartIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.ADMIN],
  },
  salesStat: {
    title: 'Статистика продажі',
    link: ROUTES.statSales,
    icon: <BarChartIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.ADMIN],
  },
  newsStat: {
    title: 'Статистика новини',
    link: ROUTES.statNews,
    icon: <BarChartIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.ADMIN],
  },
  unauthorizedStat: {
    title: 'Статистика неавторизовані',
    link: ROUTES.statUnauthorized,
    icon: <BarChartIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.ADMIN],
  },
  mailing: {
    title: 'Розсилка',
    link: ROUTES.mailing,
    icon: <OutboxIcon sx={{ color: 'white' }} />,
    roles: [Roles.MARKETER, Roles.CHIEF, Roles.ADMIN],
  },
  logChats: {
    title: 'Логи чатів',
    link: ROUTES.logChats,
    icon: <ListAltIcon sx={{ color: 'white' }} />,
    roles: [Roles.ADMIN],
  },
  participants: {
    title: 'Співробітники',
    link: ROUTES.employees,
    icon: <PeopleAltRoundedIcon sx={{ color: 'white' }} />,
    roles: [Roles.CHIEF, Roles.ADMIN],
  },
  config: {
    title: 'Конфігуратор',
    link: ROUTES.configurators,
    icon: <SettingsSuggestOutlinedIcon sx={{ color: 'white' }} />,
    roles: [Roles.ADMIN],
  },
}
