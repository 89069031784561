import React from 'react'
import { ChatHeaderInfo } from '../../components/ChatHeaderInfo'
import Box from '@mui/material/Box'
import { Chat } from '../../components/Chat'
import { TechChatStatus, InnerHeight, RightSidebarType } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { useAppDispatch } from '../../store'
import {
  createDialog,
  createMessage,
  getCurrentChat,
  getMessages,
  readMessage,
  updateDraftMessage,
  updateMessage,
  uploadFile,
} from '../../store/techSupport/actions'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery'
import { ChatsTechSupportActions } from '../../store/techSupport'
import { ROUTES } from '../../utils/routes'

export const ChatWorkspaceTechSupport: React.FC = () => {
  const [search, setSearch] = React.useState('')
  const { techSupport, templatesTechSupport, users, configurations } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const status = useQuery().get('status')
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!Number.isNaN(Number(id))) {
      dispatch(getCurrentChat(Number(id))).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          navigate(ROUTES.routeChange(''))
        }
      })
    } else {
      dispatch(ChatsTechSupportActions.setCurrentChat(null))
    }
  }, [techSupport.clikedOnChat, id, status, users.socket])

  React.useEffect(() => {
    if (status !== null) {
      dispatch(ChatsTechSupportActions.changeStatus(Number(status)))
    }
  }, [status])

  const handleReadMessage = (id: number) => {
    dispatch(readMessage(id))
  }

  const handleChangePage = (page: number, callback?: () => void) => {
    if (techSupport.currentChat) {
      dispatch(getMessages({ id: techSupport.currentChat.absnum, page })).then(callback)
    }
  }

  const handleOpenSidebar = () => {
    dispatch(setRightSidebar(RightSidebarType.TECH_SUPPORT))
  }

  const handleCreateMessage = React.useCallback(
    (value: string, callback?: () => void) => {
      if (techSupport.currentChat) {
        dispatch(
          createMessage({
            chat_id: techSupport.currentChat.absnum,
            message: value,
          })
        ).then(callback)
      }
    },
    [techSupport.currentChat?.absnum]
  )

  const handleSaveFile = React.useCallback(
    (file: File, callback?: (succes: boolean, data?: any) => void) => {
      if (techSupport.currentChat) {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('chat_id', String(techSupport.currentChat.absnum))
        fd.append('file_name', file.name)
        dispatch(uploadFile(fd)).then((res) => {
          const success = res.meta.requestStatus === 'fulfilled'
          if (callback) callback(success, res)
        })
      }
    },
    [techSupport.currentChat?.absnum]
  )

  const handleEditMessage = React.useCallback(
    (id: number, value: string, callback?: () => void) => {
      dispatch(
        updateMessage({
          id,
          data: { message: value },
        })
      ).then(callback)
    },
    [techSupport.currentChat?.absnum]
  )

  const handleCreateDialog = React.useCallback(
    (callback: () => void) => {
      if (!techSupport.currentChat) return null
      dispatch(createDialog(techSupport.currentChat.chat_id)).then((res) => {
        callback()
        if (res.meta.requestStatus === 'fulfilled') {
          navigate(`${ROUTES.chatsTechSupport}/${techSupport.currentChat?.chat_id}?status=${TechChatStatus.INITIATED}`)
        }
      })
    },
    [techSupport.currentChat?.absnum]
  )

  const handleAutoSave = React.useCallback(
    (value: string, callback: () => void) => {
      if (!techSupport.currentChat) return null
      dispatch(
        updateDraftMessage({
          id: techSupport.currentChat.chat_id,
          data: { message: value },
        })
      ).finally(callback)
    },
    [techSupport.currentChat?.absnum]
  )

  if (!techSupport.currentChat || techSupport.loadingChat) return null

  return (
    <Box px={3} height={InnerHeight} overflow={'auto'} display={'flex'} flexDirection={'column'}>
      {techSupport.currentChat ? (
        <ChatHeaderInfo
          isBtn={
            TechChatStatus.CLOSE_CHAT !== techSupport.currentChat.status &&
            TechChatStatus.TRANSFERED !== techSupport.currentChat.status &&
            techSupport.currentChat.expert_id === users.user?.id
          }
          currentChat={techSupport.currentChat}
          siteUrl={configurations.all?.find((item) => item.absnum === techSupport.currentChat?.site_id)?.url}
          openSidebar={handleOpenSidebar}
          country={techSupport.currentChat.profile?.country || ''}
          createDialog={
            [TechChatStatus.PROCESSED_OFFLINE_CHAT, TechChatStatus.CLOSE_CHAT].includes(techSupport.statusChat) &&
            [TechChatStatus.PROCESSED_OFFLINE_CHAT, TechChatStatus.CLOSE_CHAT].includes(techSupport.currentChat.status)
              ? handleCreateDialog
              : undefined
          }
          handleSearch={setSearch}
        />
      ) : null}
      {techSupport.currentChat.chat ? (
        <Chat
          searchText={search}
          chat={techSupport.currentChat.chat}
          groups={templatesTechSupport.groups}
          currentChat={techSupport.currentChat}
          isEdit={[TechChatStatus.ACTIVE, TechChatStatus.RAW_OFFLINE_CHAT, TechChatStatus.INITIATED].includes(
            techSupport.currentChat.status
          )}
          readMessage={handleReadMessage}
          changePage={handleChangePage}
          createMessage={handleCreateMessage}
          saveFile={handleSaveFile}
          handleEditMessage={handleEditMessage}
          handleAutoSave={
            ![TechChatStatus.TRANSFERED, TechChatStatus.PROCESSED_OFFLINE_CHAT, TechChatStatus.CLOSE_CHAT].includes(
              techSupport.statusChat
            ) ||
            ![TechChatStatus.TRANSFERED, TechChatStatus.PROCESSED_OFFLINE_CHAT, TechChatStatus.CLOSE_CHAT].includes(
              techSupport.currentChat.status
            )
              ? handleAutoSave
              : undefined
          }
        />
      ) : null}
    </Box>
  )
}
