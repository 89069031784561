import React from 'react'
import { FormikValues, useField } from 'formik'
import { onValidate } from './InputFormik'
import { FormControl, InputLabel, Select, MenuItem, ListSubheader } from '@mui/material'

interface Data {
  label: string
  value: string | number
}

interface IField {
  label?: string
  required?: boolean
  disabled?: boolean
  name: string
  children?: JSX.Element
  style?: React.CSSProperties | undefined
  data?: Data[] | Record<string, Data[]>
}

export const SelectFormik = React.memo(({ disabled, label, name, required, style, data }: IField) => {
  const [field, meta] = useField({
    name,
    validate: (value: FormikValues) => onValidate(name, value, { required }),
  })

  const renderMenu = () => {
    if (!data) return null
    if (Array.isArray(data)) {
      return data.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      ))
    }
    return Object.keys(data).map((key) => [
      <ListSubheader>{key}</ListSubheader>,
      data[key]?.map((item) => (
        <MenuItem key={item.value} value={item.value}>
          {item.label}
        </MenuItem>
      )),
    ])
  }

  return (
    <FormControl>
      <InputLabel size={'small'} id={field.name}>
        {label || ''}
      </InputLabel>
      <Select
        id={field.name}
        style={style}
        disabled={disabled}
        label={label || ''}
        size='small'
        variant='outlined'
        error={meta.touched && !!meta.error}
        {...field}
      >
        {renderMenu()}
      </Select>
    </FormControl>
  )
})
