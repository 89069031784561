import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { getMailingList, getMailing, createMailing, updateMailing, deleteMailing, updateMailingStatus } from './actions'

const initialState: IStore['mailing'] = {
  data: { mailing: [], page: 1, pages: 1 },
  current: null,
}

const slice = createSlice({
  name: 'mailing',
  initialState,
  reducers: {
    updateMailing: (state, action) => {
      if (state.data) {
        const mailing = state.data.mailing.map((item) =>
          item.absnum === action.payload.absnum ? action.payload : item
        )
        state.data = { ...state.data, mailing }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getMailingList.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(getMailing.fulfilled, (state, action) => {
      state.current = action.payload
    })
    builder.addCase(createMailing.fulfilled, (state, action) => {
      if (state.data && state.data.mailing.length > 0) {
        state.data = { ...state.data, mailing: [action.payload, ...state.data.mailing] }
      } else {
        state.data = { mailing: [action.payload], page: 1, pages: 1 }
      }
    })
    builder.addCase(updateMailing.fulfilled, (state, action) => {
      if (state.data) {
        const mailing = state.data.mailing.map((item) =>
          item.absnum === action.payload.absnum ? action.payload : item
        )
        state.data = { ...state.data, mailing }
      }
    })
    builder.addCase(updateMailingStatus.fulfilled, (state, action) => {
      if (state.data) {
        const mailing = state.data.mailing.map((item) =>
          item.absnum === action.payload.absnum ? action.payload : item
        )
        state.data = { ...state.data, mailing }
      }
    })
    builder.addCase(deleteMailing.fulfilled, (state, action) => {
      if (state.data) {
        const mailing = state.data.mailing.filter((item) => item.absnum !== action.payload.absnum)
        state.data = { ...state.data, mailing }
      }
    })
  },
})

export const MailingActions = slice.actions

export default slice.reducer
