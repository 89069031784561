import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../store'
import { GroupForm } from '../../components/Templates/GroupForm'
import { ROUTES } from '../../utils/routes'
import { createAppeal } from '../../store/appeal/actions'

export const CreateAppeal: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <GroupForm
      title={'Створити тип звернення'}
      label={'Назва типу'}
      handleSubmit={(values, callback) => {
        dispatch(createAppeal(values)).then((res) => {
          if (res.meta.requestStatus !== 'fulfilled') {
            callback(false)
          } else {
            navigate(ROUTES.settingsUnauthorized)
          }
        })
      }}
    />
  )
}
