export const changeMessge = (message: string, searchText = '') => {
  if (searchText) {
    const parser = new DOMParser()
    const element = parser.parseFromString(message, 'text/html')
    const currentElement = element.body
    if (!currentElement.textContent) return message

    searchText.split(' ').forEach((item) => {
      if (!item) return null

      currentElement.querySelectorAll('*').forEach((el) => {
        el.innerHTML = el.innerHTML.replace(new RegExp(item, 'gi'), (value) => {
          return `<mark>${value}</mark>`
        })
      })
    })

    return currentElement.innerHTML
  }
  return message
}
