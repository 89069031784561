import React from 'react'
import Box from '@mui/material/Box'
import { FormControl, InputLabel, MenuItem } from '@mui/material'
import Select from '@mui/material/Select'
import { ThemeProvider } from '@mui/material'
import { darkTheme } from '../../utils/darkTheme'
import { UserStatus } from '../../utils/consts'

interface Props {
  statusUser: number
  handleChangeStatus: (value: number) => void
}

export const SettingsHeader: React.FC<Props> = ({ statusUser, handleChangeStatus }) => {
  return (
    <Box mt={-1} ml={3} py={1} display={'flex'} alignItems={'center'}>
      <ThemeProvider theme={darkTheme}>
        <FormControl variant='standard' sx={{ m: 1, minWidth: 120 }}>
          <InputLabel id='select-standard-label'>Статус</InputLabel>
          <Select
            size={'small'}
            labelId='select-standard-label'
            id='select-standard'
            value={statusUser}
            onChange={(e) => handleChangeStatus(Number(e.target.value))}
            label='Статус'
            variant={'standard'}
          >
            {Object.keys(UserStatus).map((k) => (
              <MenuItem key={k} value={k}>
                {UserStatus[k].title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </ThemeProvider>
    </Box>
  )
}
