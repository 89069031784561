import React from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAppDispatch } from '../../../store'
import { updateTemplate } from '../../../store/templatesSales/actions'
import { IStore } from '../../../store/types'
import { TamplateForm } from '../../../components/Templates/TamplateForm'
import { ITemplate } from '../../../store/templates/types'
import { TypeTemplates } from '../../../utils/consts'

export const SalesEditTamplate: React.FC = () => {
  const [template, setTemplate] = React.useState<ITemplate | null>(null)

  const { id } = useParams()

  const { templatesSales } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (id && templatesSales.currentGroup) {
      const template = templatesSales.currentGroup.templates.find((template) => template.absnum === Number(id))
      if (template) {
        setTemplate(template)
      }
    }
  }, [id, templatesSales.currentGroup])

  const handleCreate = (
    values: { group_id?: number | undefined; product_id: number; message: string },
    callback: (success: boolean) => void
  ) => {
    dispatch(
      updateTemplate({
        id: Number(id),
        data: { ...values, is_type: TypeTemplates.SALES },
      })
    ).then((res) => {
      if (res.meta.requestStatus !== 'fulfilled') {
        callback(false)
      } else {
        callback(true)
      }
    })
  }

  if (!templatesSales.currentGroup || !template) return null

  return (
    <TamplateForm
      title={`Група ${templatesSales.currentGroup.name}. Шаблон`}
      data={template}
      groupId={templatesSales.currentGroup.absnum}
      createTemplate={handleCreate}
    />
  )
}
