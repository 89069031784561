import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Checkbox, FormControlLabel, FormGroup, Stack } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { createQuestion, editQuestion } from '../../../store/chatsWritten/actions'
import { IMessageWritten } from '../../../store/chatsWritten/types'
import { EditorDefault } from '../../Editors/EditorDefault'
import { parseLink } from '../../../utils/parseLink'
import { AlertMessage } from '../../AlertMessage'
import { styles } from './Styles'
import { RejectedWithValueAction } from '../../../store/types'
import { WrittenStatus } from '../../../utils/consts'

interface Props {
  closeHandler: () => void
  open: boolean
  chatId?: number
  message?: IMessageWritten
  type: 'create' | 'edit'
}

export const EditQuestionModal: React.FC<Props> = ({ type, open, chatId, message, closeHandler }) => {
  const [isAdded, setIsAdded] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState('')
  const editorRef = React.useRef<any | null>(null)

  const dispatch = useAppDispatch()

  const handleSend = () => {
    const body = editorRef.current.getContent() as string
    if (!body || loading) return null
    setLoading(true)
    if (type === 'create' && chatId) {
      dispatch(
        createQuestion({
          chat_id: chatId,
          question: body,
        })
      ).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          setErrors('Сталася помилка')
        } else {
          setLoading(false)
          closeHandler()
        }
      })
    } else if (type === 'edit' && message) {
      dispatch(
        editQuestion({
          message_id: message.absnum,
          part_id: message.part_id,
          question: parseLink(body, true),
          is_added: isAdded ? 1 : 0,
        })
      ).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          const { error } = res as RejectedWithValueAction<any, any>
          if (error.message) {
            setErrors(error.message)
          } else {
            setErrors('Сталася помилка')
          }
          setLoading(false)
        } else {
          setLoading(false)
          closeHandler()
        }
      })
    }
  }

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableEnforceFocus
    >
      <Box sx={{ ...styles, width: 800 }}>
        <Stack direction={'column'}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            {type === 'create' ? 'Створити питання' : 'Редагувати питання'}
          </Typography>
          <EditorDefault editorRef={editorRef} initialValue={message ? parseLink(message.question) : ''} />
        </Stack>
        <Stack pt={4} direction={'row'} justifyContent={'space-around'}>
          {message && message.status < WrittenStatus.SEND_TO_CLIENT && type === 'edit' && (
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={isAdded} name='isAdded' onChange={() => setIsAdded(!isAdded)} />}
                label='Уточнення'
              />
            </FormGroup>
          )}
          <Button variant={'contained'} color={'error'} onClick={handleSend}>
            Підтвердити
          </Button>
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            Скасувати
          </Button>
        </Stack>
        {errors ? <AlertMessage type='error' message={errors} /> : null}
      </Box>
    </Modal>
  )
}
