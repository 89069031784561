import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '../../utils/callApi'
import { IConfigurationsState, TCreateConfig, TUpdateConfig } from './types'

export const getConfigProducts = createAsyncThunk<IConfigurationsState['products'], string>(
  'configurations/getProducts',
  async (payload, thunkAPI) => {
    try {
      const name = payload
      let path = 'products'
      if (name) path += `?name=${name}`

      return callApi({
        method: 'get',
        data: {},
        path: `/configurations/${path}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getConfigurations = createAsyncThunk<IConfigurationsState['all']>(
  'configurations/getConfigurations',
  async (_, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: '/configurations',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getConfiguration = createAsyncThunk<IConfigurationsState['current'], number>(
  'configurations/getConfiguration',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/configurations/configuration/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const createConfiguration = createAsyncThunk<IConfigurationsState['current'], TCreateConfig>(
  'configurations/createConfiguration',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/configurations/configuration',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateConfiguration = createAsyncThunk<IConfigurationsState['current'], TUpdateConfig>(
  'configurations/updateConfiguration',
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/configurations/configuration/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const deleteConfiguration = createAsyncThunk<IConfigurationsState['current'], number>(
  'configurations/deleteConfiguration',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'delete',
        data: {},
        path: `/configurations/configuration/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
