import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IChatOline } from '../chatsOnline/types'
import { ISearchChat } from '../searchChatsTechSupport/types'
import { ISearchChatsNewsState } from './types'

export const searchChats = createAsyncThunk<ISearchChatsNewsState['data'], ISearchChat>(
  'searchChatsNews/searchChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news/search?${query}` : '/admin-chat-news/search',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getDialog = createAsyncThunk<ISearchChatsNewsState['dialog'], number>(
  'searchChatsNews/getDialog',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-news/dialog/chat/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getDialogMessages = createAsyncThunk<IChatOline, { id: number; page: number }>(
  'searchChatsNews/getDialogMessages',
  async (payload, thunkAPI) => {
    try {
      const { id, page } = payload
      return callApi({
        method: 'get',
        path: page ? `/admin-chat-news/dialog/messages/${id}?page=${page}` : 'admin-chat-news/dialog/messages',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
