import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { changeExpert } from '../../../store/chatsWritten/actions'
import { IMessageWritten } from '../../../store/chatsWritten/types'
import { styles } from './Styles'
import { AlertMessage } from '../../AlertMessage'
import { IStore, RejectedWithValueAction } from '../../../store/types'
import { useSelector } from 'react-redux'
import { Roles } from '../../../utils/consts'
import { getEmployees } from '../../../store/employees/actions'
import { IEmployee } from '../../../store/employees/types'

interface Props {
  closeHandler: () => void
  open: boolean
  message: IMessageWritten
}

export const ChooseExpertModal: React.FC<Props> = ({ open, message, closeHandler }) => {
  const [expertId, setExpertId] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState('')

  const { employees, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (users.user) {
      dispatch(
        getEmployees({
          channels: users.user.channels,
        })
      )
    }
  }, [users.user])

  const handleSend = () => {
    if (!expertId || !message || loading) return null
    setLoading(true)
    dispatch(
      changeExpert({
        message_id: message.absnum,
        part_id: message.part_id,
        expert_id: Number(expertId),
      })
    ).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        const { error } = res as RejectedWithValueAction<any, any>
        if (error.message) {
          setErrors(error.message)
        } else {
          setErrors('Сталася помилка')
        }
        setLoading(false)
      } else {
        setLoading(false)
        closeHandler()
      }
    })
  }

  const checkFio = (user: IEmployee) => {
    const count = user.total_messages ? ` (${user.total_messages})` : '(-)'
    const fio = user.role === Roles.CHIEF ? `${user.fio}, керівник` : user.fio
    return fio + count
  }

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{ ...styles, width: 500 }}>
        <Stack direction={'column'}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Вибір експерта
          </Typography>
          <FormControl fullWidth sx={{ marginTop: '20px' }}>
            <InputLabel size={'small'} id='expert-select'>
              Експерт
            </InputLabel>
            <Select
              size={'small'}
              labelId='expert-select'
              id='expert-select'
              label='Експерт'
              value={expertId}
              onChange={(e) => {
                setExpertId(e.target.value)
              }}
            >
              {employees.data?.employees
                .filter((item) => !!item.approved)
                .map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {checkFio(item)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack pt={6} direction={'row'} justifyContent={'space-around'}>
          <Button variant={'contained'} color={'error'} onClick={handleSend}>
            Підтвердити
          </Button>
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            Скасувати
          </Button>
        </Stack>
        {errors ? <AlertMessage type='error' message={errors} /> : null}
      </Box>
    </Modal>
  )
}
