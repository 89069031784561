import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import ukLocale from 'date-fns/locale/uk'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { onValidate } from './InputFormik'
import { FormikValues, useField } from 'formik'

interface IField {
  label?: string
  required?: boolean
  disabled?: boolean
  name: string
  children?: JSX.Element
  style?: React.CSSProperties | undefined
  className?: string
}

export const BasicDatePickerFormik: React.FC<IField> = ({ disabled, label, name, required, style, className }) => {
  const [field, meta, { setValue }] = useField({
    name,
    validate: (value: FormikValues) => onValidate(name, value, { required }),
  })

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
      <DateTimePicker
        mask='__/__/____ __:__'
        inputFormat='dd/MM/yyyy HH:mm'
        label={label}
        views={['year', 'month', 'day', 'hours', 'minutes']}
        className={className}
        {...field}
        onChange={(date) => setValue(date)}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            style={style}
            disabled={disabled}
            label={label || ''}
            size='small'
            variant='outlined'
            {...params}
            error={meta.touched && !!meta.error}
          />
        )}
      />
    </LocalizationProvider>
  )
}
