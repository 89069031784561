import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { urlRegex } from '../../utils/consts'
import { URI } from '../../utils/config'

interface EditorProps {
  editorRef?: React.MutableRefObject<any | null>
  initialValue?: string
  placeholder?: string
  disabled?: boolean
}

export const EditorDefault: React.FC<EditorProps> = ({ disabled, editorRef, placeholder, initialValue }) => {
  return (
    <Editor
      onInit={(evt, editor) => editorRef && (editorRef.current = editor)}
      tinymceScriptSrc={`${URI}/tinymce/tinymce.min.js`}
      initialValue={initialValue}
      init={{
        readonly: disabled,
        height: 200,
        plugins: 'preview code link lists',
        menubar: false,
        toolbar:
          'undo redo | code | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | bullist | link',
        paste_as_text: true,
        contextmenu: false,
        quickbars_selection_toolbar: false,
        placeholder,
        paste_preprocess: (editor, args) => {
          const matches = args.content.match(urlRegex)
          if (matches) {
            let output = args.content
            const matchesFiltered = matches
              .map((item) => item.replace(/&nbsp/g, ''))
              .reduce((prev, item) => (prev.includes(item) ? prev : prev.push(item), prev), [] as string[])
            for (const match of matchesFiltered) {
              output = output.split(match).join(`<a href="${match}" target="_blank" rel="noreferrer">${match}</a>`)
            }
            args.content = output
          }
        },
      }}
    />
  )
}
