import { createAsyncThunk } from '@reduxjs/toolkit'
import { ChatsSalesActions } from '.'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IEmployeesState } from '../employees/types'
import { IChatsSalesState } from '../chatsSales/types'
import { IChatOline } from '../chatsOnline/types'

export const getChats = createAsyncThunk<IChatsSalesState['chats'], { status: number; page?: number }>(
  'chatsSales/getChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-sales?${query}` : '/admin-chat-sales',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getChatsByPage = createAsyncThunk<IChatsSalesState['chats'], { status: number; page?: number }>(
  'chatsSales/getChatsByPage',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-sales?${query}` : '/admin-chat-sales',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getMessages = createAsyncThunk<IChatOline, { id: number; page: number }>(
  'chatsSales/getMessages',
  async (payload, thunkAPI) => {
    try {
      const { id, page } = payload
      return callApi({
        method: 'get',
        path: page ? `/admin-chat-sales/messages/${id}?page=${page}` : '/admin-chat-sales',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getCurrentChat = createAsyncThunk<IChatsSalesState['currentChat'], number>(
  'chatsSales/getCurrentChat',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-chat-sales/chat/${payload}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const closeChat = createAsyncThunk<IChatsSalesState['currentChat'], number>(
  'chatsSales/closeChat',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      const resp = (await callApi({
        method: 'get',
        path: `/admin-chat-sales/close/${id}`,
      })) as IChatsSalesState['currentChat']
      thunkAPI.dispatch(ChatsSalesActions.updateChats(resp))
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const captureChat = createAsyncThunk<IChatsSalesState['currentChat'], number>(
  'chatsSales/captureChat',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-sales/capture/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatR {
  chat_id: number
  user_id: number
}
export const passChat = createAsyncThunk<IChatsSalesState['currentChat'], TPassChatR>(
  'chatsSales/passChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-sales/pass`,
      })) as IChatsSalesState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatToExpertR {
  chat_id: number
  product_id: number
  message: string
}
export const passChatToWritten = createAsyncThunk<IChatsSalesState['currentChat'], TPassChatToExpertR>(
  'chatsSales/passChatToWritten',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-sales/pass/written`,
      })) as IChatsSalesState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TChangeStatusChatR {
  chat_id: number
  status: number
}
export const changeStatusChat = createAsyncThunk<IChatsSalesState['currentChat'], TChangeStatusChatR>(
  'chatsSales/changeStatusChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'patch',
        data,
        path: `/admin-chat-sales/status`,
      })) as IChatsSalesState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCreateMessageR {
  chat_id: number
  message: string
}
export const createMessage = createAsyncThunk<void, TCreateMessageR>(
  'chatsSales/createMessage',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: `/admin-chat-sales/message`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateMessage = createAsyncThunk<void, { id: number; data: { message: string } }>(
  'chatsSales/updateMessage',
  async (payload, thunkAPI) => {
    try {
      const { data, id } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/admin-chat-sales/message/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateDraftMessage = createAsyncThunk<
  IChatsSalesState['currentChat'],
  { id: number; data: { message: string } }
>('chatsSales/updateDraftMessage', async (payload, thunkAPI) => {
  try {
    const { data, id } = payload
    return callApi({
      method: 'patch',
      data,
      path: `/admin-chat-sales/draft-message/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const readMessage = createAsyncThunk<void, number>('chatsSales/readMessage', async (payload, thunkAPI) => {
  try {
    const id = payload
    return callApi({
      method: 'get',
      path: `/admin-chat-sales/read-message/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const getOnlineEmoloyees = createAsyncThunk<
  IEmployeesState['data'],
  { product_id: number; status: number; fio?: string }
>('chatsSales/getOnlineEmoloyees', async (payload, thunkAPI) => {
  try {
    const query = buildQuery(payload)

    return callApi({
      method: 'get',
      path: query ? `/employees?${query}` : '/employees',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const uploadFile = createAsyncThunk<{ link: string }, FormData>(
  'chatsSales/uploadFile',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        isFormData: true,
        path: `/admin-chat-sales/upload`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateNotifications = createAsyncThunk<
  IChatsSalesState['currentChat'],
  { chat_id: number; is_notifications: number }
>('chatsSales/updateNotifications', async (payload, thunkAPI) => {
  try {
    const data = payload
    return callApi({
      method: 'post',
      data,
      path: '/admin-chat-sales/notifications',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})
