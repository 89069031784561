import { createAsyncThunk } from '@reduxjs/toolkit'
import { ChatsNewsActions } from '.'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IChatOline } from '../chatsOnline/types'
import { IChatsNewsState } from './types'

export const getChats = createAsyncThunk<IChatsNewsState['chats'], { status: number; page?: number }>(
  'chatsNews/getChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news?${query}` : '/admin-chat-news',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getChatsByPage = createAsyncThunk<IChatsNewsState['chats'], { status: number; page?: number }>(
  'chatsNews/getChatsByPage',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news?${query}` : '/admin-chat-news',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getMessages = createAsyncThunk<IChatOline, { id: number; page: number }>(
  'chatsNews/getMessages',
  async (payload, thunkAPI) => {
    try {
      const { id, page } = payload
      return callApi({
        method: 'get',
        path: page ? `/admin-chat-news/messages/${id}?page=${page}` : '/admin-chat-news',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getCurrentChat = createAsyncThunk<IChatsNewsState['currentChat'], number>(
  'chatsNews/getCurrentChat',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-chat-news/chat/${payload}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const closeChat = createAsyncThunk<IChatsNewsState['currentChat'], number>(
  'chatsNews/closeChat',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      const resp = (await callApi({
        method: 'get',
        path: `/admin-chat-news/close/${id}`,
      })) as IChatsNewsState['currentChat']
      thunkAPI.dispatch(ChatsNewsActions.updateChats(resp))
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const captureChat = createAsyncThunk<IChatsNewsState['currentChat'], number>(
  'chatsNews/captureChat',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-news/capture/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatR {
  chat_id: number
  user_id: number
}
export const passChat = createAsyncThunk<IChatsNewsState['currentChat'], TPassChatR>(
  'chatsNews/passChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-news/pass`,
      })) as IChatsNewsState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatToTechExpertR {
  chat_id: number
  message: string
}
export const passChatToTechSupoort = createAsyncThunk<IChatsNewsState['currentChat'], TPassChatToTechExpertR>(
  'chatsNews/passChatToTechSupoort',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-news/pass/tech-support`,
      })) as IChatsNewsState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TChangeStatusChatR {
  chat_id: number
  status: number
}
export const changeStatusChat = createAsyncThunk<IChatsNewsState['currentChat'], TChangeStatusChatR>(
  'chatsNews/changeStatusChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'patch',
        data,
        path: `/admin-chat-news/status`,
      })) as IChatsNewsState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCreateMessageR {
  chat_id: number
  message: string
}
export const createMessage = createAsyncThunk<void, TCreateMessageR>(
  'chatsNews/createMessage',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: `/admin-chat-news/message`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateMessage = createAsyncThunk<void, { id: number; data: { message: string } }>(
  'chatsNews/updateMessage',
  async (payload, thunkAPI) => {
    try {
      const { data, id } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/admin-chat-news/message/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateDraftMessage = createAsyncThunk<
  IChatsNewsState['currentChat'],
  { id: number; data: { message: string } }
>('chatsNews/updateDraftMessage', async (payload, thunkAPI) => {
  try {
    const { data, id } = payload
    return callApi({
      method: 'patch',
      data,
      path: `/admin-chat-news/draft-message/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const readMessage = createAsyncThunk<void, number>('chatsNews/readMessage', async (payload, thunkAPI) => {
  try {
    const id = payload
    return callApi({
      method: 'get',
      path: `/admin-chat-news/read-message/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const uploadFile = createAsyncThunk<{ link: string }, FormData>(
  'chatsNews/uploadFile',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        isFormData: true,
        path: `/admin-chat-news/upload`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateNotifications = createAsyncThunk<
  IChatsNewsState['currentChat'],
  { chat_id: number; is_notifications: number }
>('chatsNews/updateNotifications', async (payload, thunkAPI) => {
  try {
    const data = payload
    return callApi({
      method: 'post',
      data,
      path: '/admin-chat-news/notifications',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})
