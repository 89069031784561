import React from 'react'
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Stack } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { ConfirmModal } from '../modals/ConfirmModal'
import { IGroupTemplate } from '../../store/templates/types'

interface Column {
  id: 'absnum' | 'message'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'absnum', label: 'ID', minWidth: 10 },
  { id: 'message', label: 'Повідомлення', minWidth: 100 },
]

interface Props {
  groups: IGroupTemplate[] | null
  deleteTemplate: (id: number, calback?: () => void) => void
}

export const ListTamplates: React.FC<Props> = ({ groups, deleteTemplate }) => {
  const [deleteId, setDeleteId] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const navigate = useNavigate()
  const { id } = useParams()

  const editHandler = (templateId: number) => {
    navigate(ROUTES.onlineHelpTemplate(templateId))
  }

  const deleteHandler = () => {
    if (!deleteId || loading) return null
    setLoading(true)
    deleteTemplate(deleteId, () => setLoading(false))
    setDeleteId(0)
  }

  if (!groups) return null

  return (
    <>
      <Paper sx={{ m: 2, overflow: 'hidden', height: '100vh' }}>
        <Stack my={2} px={2} spacing={2} direction={'row'}>
          <Button
            onClick={() => navigate(ROUTES.onlineHelpTemplate(`create/${id}`))}
            color={'primary'}
            variant={'outlined'}
          >
            Створити
          </Button>
        </Stack>
        <TableContainer sx={{ height: `calc(100vh - 190px)` }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {groups
                ?.find((group) => group.absnum === Number(id))
                ?.templates.map((row) => (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.absnum}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      )
                    })}
                    <TableCell width={200} align={'right'}>
                      <Button onClick={() => editHandler(row.absnum)} color={'primary'} variant={'outlined'}>
                        Редагувати
                      </Button>
                    </TableCell>
                    <TableCell width={100} align={'right'}>
                      <Button
                        disabled={loading}
                        onClick={() => setDeleteId(row.absnum)}
                        color={'error'}
                        variant={'outlined'}
                      >
                        Видалити
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ConfirmModal
        title={`Видалити шаблон № ${deleteId}`}
        open={!!deleteId}
        delHandler={deleteHandler}
        closeHandler={() => setDeleteId(0)}
      />
    </>
  )
}
