import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IDtoSearch } from '../statSales/types'
import { IStatWrittenState } from './types'

export const getStatQuantity = createAsyncThunk<IStatWrittenState['statQuantity'], IDtoSearch>(
  'statWritten/getStatQuantity',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-written/stat?${query}` : '/admin-chat-written/stat',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatQuantityAndExpert = createAsyncThunk<IStatWrittenState['statQuantityAndExpert'], IDtoSearch>(
  'statWritten/getStatQuantityAndExpert',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-written/stat/block2?${query}` : '/admin-chat-written/stat/block2',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatRaiting = createAsyncThunk<IStatWrittenState['statDataRating'], IDtoSearch>(
  'statWritten/getStatRaiting',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-written/stat/block3?${query}` : '/admin-chat-written/stat/block3',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStat = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statWritten/downlaodStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-written/stat/download?${query}` : '/admin-chat-written/stat/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatCountSession = createAsyncThunk<IStatWrittenState['session'], IDtoSearch>(
  'statWritten/getStatCountSession',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-written/stat/session?${query}` : '/admin-chat-written/stat/session',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
