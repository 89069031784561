import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { getLogChats } from './actions'

const initialState: IStore['logChats'] = {
  data: null,
}

export const slice = createSlice({
  name: 'logChats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLogChats.fulfilled, (state, action) => {
      state.data = action.payload
    })
  },
})

export const LogChatsActions = slice.actions

export default slice.reducer
