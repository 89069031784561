import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '../../../store/types'
import { useAppDispatch } from '../../../store'
import { deleteTemplate } from '../../../store/templatesUnauthorized/actions'
import { ListTamplates } from '../../../components/Templates/ListTamplates'

export const UnauthorizedListTamplates: React.FC = () => {
  const { templatesUnauthorized } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  const handleDelete = (id: number, calback?: () => void) => {
    dispatch(deleteTemplate(id)).then(calback)
  }

  return <ListTamplates groups={templatesUnauthorized.groups} deleteTemplate={handleDelete} />
}
