import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { useAppDispatch } from '../../store'
import { deleteEmployee, searchEmployees } from '../../store/employees/actions'
import { Positions, Roles } from '../../utils/consts'
import { useSearch } from '../../hooks/useSearch'
import { EmployeesActions } from '../../store/employees'
import DoneIcon from '@mui/icons-material/Done'
import { ConfirmModal } from '../../components/modals/ConfirmModal'

interface Column {
  id: 'fio' | 'role' | 'products' | 'photo'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'fio', label: 'ПІБ', minWidth: 170 },
  { id: 'role', label: 'Посада', minWidth: 100 },
  {
    id: 'products',
    label: 'Продукти',
    minWidth: 200,
  },
  { id: 'photo', label: 'Фото' },
]

export const ListEmpoyees: React.FC = () => {
  const [fio, setFio] = React.useState('')
  const [position, setPosition] = React.useState('')
  const [deleteEmpl, setDeleteEmpl] = React.useState<null | { id: number; fio: string }>(null)

  const { employees, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { loading, handleSearch } = useSearch({ actionThunk: searchEmployees })

  React.useEffect(() => {
    dispatch(EmployeesActions.setSearchData(employees.data))
  }, [employees.data])

  const handleDelete = () => {
    if (!deleteEmpl) return null
    dispatch(deleteEmployee(deleteEmpl.id))
    setDeleteEmpl(null)
  }

  const isRights = [Roles.CHIEF, Roles.ADMIN].includes(users.user?.role || 0)

  const size = 'small'
  return (
    <>
      <Paper sx={{ m: 2, overflow: 'hidden', height: 'calc(100% - 95px)' }}>
        <Stack my={2} px={2} spacing={2} direction={'row'}>
          {isRights ? (
            <Button onClick={() => navigate(ROUTES.employeeChange('new'))} color={'primary'} variant={'outlined'}>
              Добавити
            </Button>
          ) : null}
          <TextField
            size={size}
            id='outlined-basic-id'
            label='Пошук по ПІБ'
            variant='outlined'
            value={fio}
            onChange={(event) => {
              setFio(event.target.value)
              handleSearch(event.target.value, 'fio')
            }}
          />
          <FormControl style={{ width: '223px' }}>
            <InputLabel size={size} id='simple-select'>
              Посада
            </InputLabel>
            <Select
              size={size}
              labelId='simple-select'
              id='simple-select'
              label='Посада'
              value={position}
              onChange={(event) => {
                setPosition(event.target.value)
                handleSearch(event.target.value, 'role')
              }}
            >
              <MenuItem value={0}>Всі</MenuItem>
              {Object.entries(Positions).map((items, index) => {
                if (!users.user || !items[1].role.includes(users.user.role)) return null
                return (
                  <MenuItem key={`role_${index}`} value={Number(items[0])}>
                    {items[1].name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          {loading ? <CircularProgress size={25} /> : null}
        </Stack>
        <TableContainer sx={{ maxHeight: 'calc(100vh - 180px)' }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
                {users.user?.role === Roles.ADMIN ? <TableCell /> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {employees.searchData?.employees
                .filter((item) => !!item.approved)
                .map((row, index) => {
                  return (
                    <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.id]
                        if (column.id === 'photo')
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value ? <DoneIcon /> : null}
                            </TableCell>
                          )
                        if (typeof value === 'string')
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          )
                        if (typeof value === 'number')
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {Positions[value]?.name || ''}
                            </TableCell>
                          )
                        if (Array.isArray(value))
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value.length > 3 ? (
                                <>
                                  {value.slice(0, 3).map((item) => (
                                    <div key={`${item.absnum}_${index}`}>{item.name}</div>
                                  ))}{' '}
                                  і т. д.
                                </>
                              ) : (
                                value.map((item) => <div key={`${item.absnum}_${index}`}>{item.name}</div>)
                              )}
                            </TableCell>
                          )
                      })}
                      <TableCell width={200} align={'right'}>
                        <Button
                          onClick={() => {
                            navigate(ROUTES.employeeChange(row.id))
                          }}
                          color={'primary'}
                          variant={'outlined'}
                        >
                          Редагувати
                        </Button>
                      </TableCell>
                      {users.user?.role === Roles.ADMIN ? (
                        <TableCell width={100} align={'right'}>
                          <Button
                            onClick={() => setDeleteEmpl({ id: row.id, fio: row.fio })}
                            color={'error'}
                            variant={'outlined'}
                          >
                            Видалити
                          </Button>
                        </TableCell>
                      ) : null}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ConfirmModal
        title={'Видалити ' + deleteEmpl?.fio || ''}
        open={!!deleteEmpl}
        delHandler={handleDelete}
        closeHandler={() => setDeleteEmpl(null)}
      />
    </>
  )
}
