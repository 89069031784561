import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { getProducts } from './actions'

const initialState: IStore['products'] = {
  products: null,
}

const slice = createSlice({
  name: 'products',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProducts.fulfilled, (state, action) => {
      state.products = action.payload
    })
  },
})

export const ProductsActions = slice.actions

export default slice.reducer
