import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { createGroup, createTemplate, deleteTemplate, getTechTemplates, updateGroup, updateTemplate } from './actions'
import { ITemplatesState } from './types'

const initialState: IStore['templatesTechSupport'] = {
  groups: null,
  currentGroup: null,
}

const slice = createSlice({
  name: 'templatesTechSupport',
  initialState,
  reducers: {
    setCurrentGroup: (state, action: PayloadAction<ITemplatesState['currentGroup']>) => {
      state.currentGroup = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createGroup.fulfilled, (state, action) => {
      if (state.groups) {
        state.groups = [...state.groups, { ...action.payload, templates: [] }]
      } else {
        state.groups = [{ ...action.payload, templates: [] }]
      }
    })
    builder.addCase(updateGroup.fulfilled, (state, action) => {
      if (state.groups) {
        state.groups = state.groups.map((it) =>
          it.absnum === action.payload.absnum ? { ...it, name: action.payload.name } : it
        )
      }
    })
    builder.addCase(getTechTemplates.fulfilled, (state, action) => {
      state.groups = action.payload
    })
    builder.addCase(createTemplate.fulfilled, (state, action) => {
      if (state.groups && action.payload) {
        state.groups = state.groups.map((group) => {
          if (group.absnum === action.payload.group_id) {
            const templates = [...group.templates, action.payload]
            return { ...group, templates }
          }
          return group
        })
      }
    })
    builder.addCase(updateTemplate.fulfilled, (state, action) => {
      if (state.groups && action.payload) {
        state.groups = state.groups.map((group) => {
          if (group.absnum === action.payload.group_id) {
            const templates = group.templates.map((template) =>
              template.absnum === action.payload.absnum ? action.payload : template
            )
            return { ...group, templates }
          }
          return group
        })
      }
    })
    builder.addCase(deleteTemplate.fulfilled, (state, action) => {
      if (state.groups && action.payload) {
        state.groups = state.groups.map((group) => {
          if (group.absnum === action.payload.group_id) {
            const templates = group.templates.filter((template) => template.absnum !== action.payload.absnum)
            return { ...group, templates }
          }
          return group
        })
      }
    })
  },
})

export const TemplatesTechSupportActions = slice.actions

export default slice.reducer
