import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, CircularProgress } from '@mui/material'
import { IStore } from '../../store/types'
import { CurrentDialog } from '../../components/SearchWorkspace/CurrentDialog'
import { useAppDispatch } from '../../store'
import { getDialog, getDialogMessages } from '../../store/searchChatsOnline/actions'
import { SearchChatsOnlineActions } from '../../store/searchChatsOnline'
import { ChatStatus, InnerHeight, SIZE_PROG } from '../../utils/consts'
import { ChatHeaderInfo } from '../../components/ChatHeaderInfo'
import { ROUTES } from '../../utils/routes'
import { createDialog } from '../../store/chatsOnline/actions'

export const CurrentDialogOnline: React.FC = () => {
  const [mainProduct, setMainProduct] = React.useState('')
  const { searchChatsOnline, products } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  React.useEffect(() => {
    if (id) {
      dispatch(getDialog(Number(id)))
    }
  }, [])

  React.useEffect(() => {
    if (searchChatsOnline.dialog?.product_id && products.products) {
      const allProducts = products.products.reduce((prev, item) => {
        if (item.children) {
          item.children.map((product) => {
            prev[product.absnum] = item.name
          })
        }
        return prev
      }, {} as { [x: string]: string })
      setMainProduct(allProducts[searchChatsOnline.dialog.product_id])
    }
  }, [searchChatsOnline.dialog?.product_id, products])

  const handleGetDialogMessages = (data: { id: number; page: number }, callback?: () => void) => {
    dispatch(getDialogMessages(data)).then(callback)
  }

  const handleCloseDialog = () => {
    dispatch(SearchChatsOnlineActions.setDialog(null))
    navigate(ROUTES.changeSearch(''))
  }

  const handleCreateDialog = (callback: () => void) => {
    if (!searchChatsOnline.dialog) return null
    dispatch(createDialog(searchChatsOnline.dialog.chat_id)).then((res) => {
      callback()
      if (res.meta.requestStatus === 'fulfilled') {
        navigate(`${ROUTES.chats}/${searchChatsOnline.dialog?.chat_id}?status=${ChatStatus.INITIATED}`)
      }
    })
  }

  if (!searchChatsOnline.dialog) {
    return (
      <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress size={SIZE_PROG} />
      </Box>
    )
  }

  return (
    <Box px={3} height={InnerHeight} overflow={'auto'} display={'flex'} flexDirection={'column'}>
      {searchChatsOnline.dialog ? (
        <ChatHeaderInfo
          mainProduct={mainProduct}
          currentChat={searchChatsOnline.dialog}
          comeBack={handleCloseDialog}
          createDialog={handleCreateDialog}
        />
      ) : null}
      <CurrentDialog dialog={searchChatsOnline.dialog} getDialogMessages={handleGetDialogMessages} />
    </Box>
  )
}
