import { blue, blueGrey, brown, cyan, green, grey, lightBlue, orange, red, teal } from '@mui/material/colors'

export const InnerHeight = 'calc(100vh - 69px);'
export const ERROR_MESSAGE = 'Виникла помилка'
export const TIMEOUT_REQ = 1000
export const SIZE_PROG = 24

export const urlRegex =
  /(https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*))/g

export const ACCEPT =
  '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,' +
  '.pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel,image/*'

export enum StatusWork {
  OFFLINE,
  CHANGE,
  ONLINE,
}

export const UserStatus: Record<string, { title: string; color: string; color2: string }> = {
  [StatusWork.OFFLINE]: { title: 'Oфлайн', color: 'red', color2: red[900] },
  [StatusWork.CHANGE]: { title: 'Перезмінка', color: '#f8ec04', color2: orange[900] },
  [StatusWork.ONLINE]: { title: 'Онлaйн', color: '#44b700', color2: green[500] },
}

export enum Roles {
  MARKETER = 1,
  TRAINEE,
  CONSULTANT,
  CHIEF,
  ADMIN,
}

export enum TypeTemplates {
  ONLINE,
  SUPPORT,
  SALES,
  NEWS,
  UNAUTHORIZED,
}

export enum CHANNELS_ID {
  ONLINE = 1,
  WRITTEN,
  TECH_SUPPORT,
  SALES,
  NEWS,
  UNAUTHORIZED,
}

export enum TypeMessage {
  DEFAULT,
  AUTO,
  RATING,
  CONSULTANT,
  TRANSFERED,
  RECIVED,
  MAILING,
}

export const NameTransfer: { [x: number]: string } = {
  4: 'Переданий',
  5: 'Отриманий',
}

export const Positions: { [x: number]: { role: number[]; name: string } } = {
  1: { role: [Roles.CHIEF, Roles.ADMIN], name: 'Маркетолог' },
  2: { role: [Roles.CHIEF, Roles.ADMIN], name: 'Стажер' },
  3: { role: [Roles.CHIEF, Roles.ADMIN], name: 'Консультант' },
  4: { role: [Roles.CHIEF, Roles.ADMIN], name: 'Керівник' },
  5: { role: [Roles.CHIEF, Roles.ADMIN], name: 'Адмін' },
}

export enum ChatStatus {
  ACTIVE = 1,
  COLLAPSED,
  RAW_OFFLINE_CHAT,
  PROCESSED_OFFLINE_CHAT,
  INITIATED,
  CLOSE_CHAT,
}

export enum TechChatStatus {
  ACTIVE = 1,
  TRANSFERED,
  RAW_OFFLINE_CHAT,
  PROCESSED_OFFLINE_CHAT,
  INITIATED,
  CLOSE_CHAT,
}

export enum SalesChatStatus {
  ONLINE = 1,
  TRANSFERED,
  MISSED,
  OFFLINE,
  CLOSE,
}

export enum UnauthorizedChatStatus {
  NEWS = 1,
  IN_WORK,
  CLOSE,
}

export const WrittenType: { [x: number]: string } = {
  1: 'Передано з Онлайн',
  3: 'Передано з Техпідтримки',
  4: 'Передано з каналу Продажі',
  5: 'Передано з каналу Новини',
}

export enum RightSidebarType {
  DEFAULT,
  ONLINE,
  TECH_SUPPORT,
  SALES,
  NEWS,
  WRITTEN,
  UNAUTHORIZED,
}

export const STATUSES_CHAT: { [x: string]: { title: string; color: string } } = {
  [ChatStatus.ACTIVE]: {
    title: 'Активний',
    color: green[500],
  },
  [ChatStatus.COLLAPSED]: {
    title: 'Згорнутий',
    color: blueGrey[500],
  },
  [ChatStatus.RAW_OFFLINE_CHAT]: {
    title: 'Неопрацьований офлайн',
    color: orange[500],
  },
  [ChatStatus.PROCESSED_OFFLINE_CHAT]: {
    title: 'Опрацьований офлайн',
    color: lightBlue[500],
  },
  [ChatStatus.CLOSE_CHAT]: {
    title: 'Закритий',
    color: red[900],
  },
  [ChatStatus.INITIATED]: {
    title: 'Ініційований',
    color: red[300],
  },
}

export const STATUSES_CHAT_TECH: { [x: string]: { title: string; color: string } } = {
  [TechChatStatus.ACTIVE]: {
    title: 'Активний',
    color: green[500],
  },
  [TechChatStatus.TRANSFERED]: {
    title: 'Переданий',
    color: blueGrey[500],
  },
  [TechChatStatus.RAW_OFFLINE_CHAT]: {
    title: 'Неопрацьований офлайн',
    color: orange[500],
  },
  [TechChatStatus.PROCESSED_OFFLINE_CHAT]: {
    title: 'Опрацьований офлайн',
    color: lightBlue[500],
  },
  [TechChatStatus.CLOSE_CHAT]: {
    title: 'Закритий',
    color: red[900],
  },
  [TechChatStatus.INITIATED]: {
    title: 'Ініційований',
    color: red[300],
  },
}

export const STATUSES_CHAT_SALES: { [x: string]: { title: string; color: string } } = {
  1: {
    title: 'Онлайн',
    color: green[500],
  },
  2: {
    title: 'Переданий',
    color: blueGrey[500],
  },
  3: {
    title: 'Пропущений',
    color: orange[500],
  },
  4: {
    title: 'Офлайн',
    color: lightBlue[500],
  },
  5: {
    title: 'Закритий',
    color: red[900],
  },
}

export const STATUSES_CHAT_UNAUTHORIZED: { [x: string]: { title: string; color: string } } = {
  1: {
    title: 'Новий',
    color: blue[500],
  },
  2: {
    title: 'В роботі',
    color: green[500],
  },
  3: {
    title: 'Закритий',
    color: red[900],
  },
}

export enum WrittenStatus {
  NEW,
  NEW_CLARIFIED,
  TRANSFER_TO_EXPERT,
  CLARIFIED,
  RECIVED_CLARIFIED,
  REPID,
  ON_APPROVAL,
  SEND_TO_CLIENT,
  RATED_BY_CLIENT,
  CLOSING_IS_POSTPONED,
  CLOSED,
  REFUSAL,
  DELETED,
  TECH_SUPPORT,
  EXPIRED,
}

export enum WrittenPriorities {
  NEW,
  MINICHAT,
  RECIVED_CLARIFIED,
  NEW_CLARIFIED,
  UNPROCESSED,
  CLARIFIED,
  PROCESSED,
  CLOSED,
}

export const STATUSES_WRITTEN: { [x: string]: { title: string; color: string; background?: string } } = {
  [WrittenStatus.NEW]: {
    title: 'Нове',
    color: red[900],
  },
  [WrittenStatus.NEW_CLARIFIED]: {
    title: 'Нове (уточнене)',
    color: red[400],
  },
  [WrittenStatus.TRANSFER_TO_EXPERT]: {
    title: 'Передане експерту',
    color: blue[900],
  },
  [WrittenStatus.CLARIFIED]: {
    title: 'Уточнюється',
    color: cyan[700],
  },
  [WrittenStatus.RECIVED_CLARIFIED]: {
    title: 'Отримані уточнення',
    color: teal[500],
  },
  [WrittenStatus.REPID]: {
    title: 'Повторно',
    color: orange[500],
  },
  [WrittenStatus.ON_APPROVAL]: {
    title: 'На затвердженні',
    color: orange[900],
  },
  [WrittenStatus.SEND_TO_CLIENT]: {
    title: 'Надіслане користувачу',
    color: green[500],
  },
  [WrittenStatus.RATED_BY_CLIENT]: {
    title: 'Оцінене користувачем',
    color: lightBlue[500],
  },
  [WrittenStatus.CLOSING_IS_POSTPONED]: {
    title: 'Закриття відкладено',
    color: brown[500],
  },
  [WrittenStatus.CLOSED]: {
    title: 'Закрите',
    color: grey[500],
  },
  [WrittenStatus.REFUSAL]: {
    title: 'Відмова',
    color: blueGrey[500],
  },
  [WrittenStatus.TECH_SUPPORT]: {
    title: 'Передано в техпідтримку',
    color: blueGrey[500],
  },
  [WrittenStatus.EXPIRED]: {
    title: 'Прострочений дедлайн',
    color: blueGrey[500],
  },
}

export const STATUSES_TRANSFER: { [x: string]: { title: string } } = {
  '0.1': {
    title: 'Чат в процесі передачі',
  },
  '1.2': {
    title: 'Чат переданий у "Письмово"',
  },
  '1.3': {
    title: 'Чат переданий у "Техпідтримку"',
  },
  '1.1': {
    title: 'Чат переданий',
  },
  '2.1': {
    title: 'Чат не переданий',
  },
}

export const STATUSES_TRANSFER_TECH: { [x: string]: { title: string } } = {
  '1.1': {
    title: 'Чат переданий у "Онлайн"',
  },
  '1.2': {
    title: 'Чат переданий у "Письмово"',
  },
  '1.3': {
    title: 'Чат переданий',
  },
}

export const STATUSES_MAILING: { [x: string]: { title: string; color: string } } = {
  0: {
    title: 'Чорновик',
    color: grey[500],
  },
  1: {
    title: 'Заплановано',
    color: green[500],
  },
  2: {
    title: 'Відправка',
    color: red[500],
  },
  3: {
    title: 'Деактивовано',
    color: grey[500],
  },
  4: {
    title: 'Завершено',
    color: lightBlue[500],
  },
}

export enum TypeMailing {
  DRAFT,
  PLANNED,
  SENDING,
  DEACTIVATED,
  COMPLETED,
}

export enum DialogType {
  DEFAULT,
  ONLINE,
  OFFLINE,
  MISSED,
  INITIATED,
}

export const DialogTypeName: Record<string, { title: string; color: string }> = {
  [DialogType.ONLINE]: { title: 'Онлайн', color: lightBlue[500] },
  [DialogType.OFFLINE]: { title: 'Офлайн', color: lightBlue[500] },
  [DialogType.MISSED]: { title: 'Пропущений', color: lightBlue[500] },
  [DialogType.INITIATED]: { title: 'Ініційований', color: lightBlue[500] },
}

export enum TypeUserLog {
  ENTRANCE = 1,
  EXIT,
  CONNECTION,
  LOST_CONNECTION,
  CHANGED_STATUS,
}

export const UserLogName: Record<string, { title: string; color: string }> = {
  [TypeUserLog.ENTRANCE]: { title: 'Вхід', color: lightBlue[500] },
  [TypeUserLog.EXIT]: { title: 'Вихід', color: blueGrey[500] },
  [TypeUserLog.CONNECTION]: { title: "Встановлений зв'язок", color: green[500] },
  [TypeUserLog.LOST_CONNECTION]: { title: "Втрачений зв'язок", color: red[900] },
  [TypeUserLog.CHANGED_STATUS]: { title: 'Зміна статусу', color: orange[900] },
}

export enum TypeBtn {
  RECTANGULAR = 1,
  CIRCLE,
  CIRCLE_NEW_YEARS,
  RECTANGULAR_NEW_YEARS,
  RECTANGULAR_TECH_SUPPORT,
}

export enum LogChatTypes {
  DEFAULT,
  CREATED,
  DISTRIBUTION,
  ANSWERED,
  CHANGED_STATUS,
  TRANSFERED,
  RECEIVED,
  EVALUATED,
  MISSED_DEADLINE,
  ADDITION,
  PROCESSED,
}

export const LogChatName: Record<string, { title: string; color: string }> = {
  [LogChatTypes.CREATED]: { title: 'Створено', color: lightBlue[500] },
  [LogChatTypes.DISTRIBUTION]: { title: 'Розподіл чату', color: blueGrey[500] },
  [LogChatTypes.ANSWERED]: { title: 'Відповідь', color: green[500] },
  [LogChatTypes.CHANGED_STATUS]: { title: 'Зміна статусу', color: red[900] },
  [LogChatTypes.TRANSFERED]: { title: 'Передано', color: orange[900] },
  [LogChatTypes.RECEIVED]: { title: 'Отримано', color: orange[900] },
  [LogChatTypes.EVALUATED]: { title: 'Оцінено', color: orange[900] },
  [LogChatTypes.MISSED_DEADLINE]: { title: 'Прострочений дедлайн', color: orange[900] },
  [LogChatTypes.ADDITION]: { title: 'Доповнення до запитання', color: orange[900] },
  [LogChatTypes.PROCESSED]: { title: 'Опрацьовано', color: orange[900] },
}
