import React from 'react'
import { Stack, useTheme } from '@mui/material'

interface Props {
  children?: React.ReactElement
}

export const SectionTitleWrapper: React.FC<Props> = ({ children }) => {
  const theme = useTheme()

  return (
    <Stack
      direction={'row'}
      justifyContent={'space-between'}
      alignItems={'center'}
      bgcolor={theme.palette.primary.main}
      p={2}
    >
      {children}
    </Stack>
  )
}
