import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IChatOline } from '../chatsOnline/types'
import { ISearchChat, ISearchChatsOnlineState } from './types'

export const searchChats = createAsyncThunk<ISearchChatsOnlineState['data'], ISearchChat>(
  'searchChatsOnline/searchChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-online/search?${query}` : '/admin-chat-online/search',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getDialog = createAsyncThunk<ISearchChatsOnlineState['dialog'], number>(
  'chatsOnline/getDialog',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-online/dialog/chat/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getDialogMessages = createAsyncThunk<IChatOline, { id: number; page: number }>(
  'searchChatsOnline/getDialogMessages',
  async (payload, thunkAPI) => {
    try {
      const { id, page } = payload
      return callApi({
        method: 'get',
        path: page ? `/admin-chat-online/dialog/messages/${id}?page=${page}` : 'admin-chat-online/dialog/messages',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
