import React from 'react'
import reactCSS from 'reactcss'
import { Color, ColorResult, RGBColor, SketchPicker } from 'react-color'
import { Box } from '@mui/system'
import TextField from '@mui/material/TextField'
import { useField } from 'formik'

interface IField {
  label?: string
  required?: boolean
  disabled?: boolean
  name: string
  children?: JSX.Element
  style?: React.CSSProperties | undefined
}

export const InputPalette: React.FC<IField> = ({ disabled, label, name, required, style }) => {
  const [state, setState] = React.useState<{ displayColorPicker: boolean; color: RGBColor; hex: string }>({
    displayColorPicker: false,
    color: {
      r: 65,
      g: 117,
      b: 5,
      a: 100,
    },
    hex: '#417505',
  })

  const [field, meta, { setValue }] = useField({
    value: state.hex,
    name,
  })

  React.useEffect(() => {
    setState((prev) => ({ ...prev, hex: field.value }))
  }, [field.value])

  const handleClick = () => {
    setState({ ...state, displayColorPicker: !state.displayColorPicker })
  }

  const handleClose = () => {
    setState({ ...state, displayColorPicker: false })
  }

  const handleChange = (color: ColorResult) => {
    setState({ ...state, color: color.rgb, hex: color.hex })
    setValue(color.hex)
  }

  const styles = reactCSS({
    default: {
      container: {
        position: 'absolute',
        right: '5px',
        bottom: '-3px',
      },
      color: {
        width: '120px',
        height: '22px',
        borderRadius: '2px',
        background: `${state.hex}`,
      },
      swatch: {
        padding: '5px',
        background: '#fff',
        display: 'inline-block',
        cursor: 'pointer',
      },
      popover: {
        position: 'absolute',
        zIndex: '2',
        right: '0px',
      },
      cover: {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
      },
    },
  })

  return (
    <Box display={'flex'} position={'relative'}>
      <TextField
        fullWidth
        style={style}
        disabled
        label={label || ''}
        size='small'
        variant='outlined'
        error={meta.touched && !!meta.error}
        {...field}
      />
      <div style={styles.container as any}>
        <div style={styles.swatch} onClick={handleClick}>
          <div style={styles.color} />
        </div>
        {state.displayColorPicker ? (
          <div style={styles.popover as any}>
            <div style={styles.cover as any} onClick={handleClose} />
            <SketchPicker color={state.color as unknown as Color} onChange={handleChange} />
          </div>
        ) : null}
      </div>
    </Box>
  )
}
