import React from 'react'
import { Editor } from '@tinymce/tinymce-react'
import { URI } from '../../utils/config'

interface EditorProps {
  initialValue?: string
  value?: string
  placeholder?: string
  disabled?: boolean
  onChange: (value: string) => void
}

export const EditorInline: React.FC<EditorProps> = ({ disabled, value, placeholder, onChange, initialValue }) => {
  return (
    <Editor
      value={value}
      tinymceScriptSrc={`${URI}/tinymce/tinymce.min.js`}
      initialValue={initialValue}
      onEditorChange={(newValue) => {
        onChange(newValue)
      }}
      init={{
        readonly: disabled,
        language: 'uk',
        height: 200,
        inline: true,
        plugins: 'preview advcode link charmap',
        menubar: false,
        quickbars_selection_toolbar: false,
        placeholder,
        content_style: `
        .mce-content-body {
          border: 1px solid #000;
          border-radius: 3px;
          padding: 0 10px;
        }
        `,
      }}
    />
  )
}
