import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStore } from '../types'
import {
  getWittenMessages,
  getMiniChat,
  getWittenMessage,
  deleteWittenMessage,
  deleteFile,
  getMiniChatMessages,
} from './actions'
import { IMessageWritten, IMiniChat, IChatsWrittenState } from './types'
import { WrittenPriorities } from '../../utils/consts'

const filterMessages = (messages: IMessageWritten[], message: IMessageWritten) => {
  return messages?.filter((item) => {
    if (item.absnum !== message.absnum) return true
    if (item.absnum === message.absnum && item.part_id !== message.part_id) return true
    return false
  })
}

const sortMessages = (a: IMessageWritten, b: IMessageWritten) => {
  if (a.priority < b.priority) {
    return -1
  }

  if (a.priority > b.priority) {
    return 1
  }

  if (a.priority === WrittenPriorities.NEW && b.priority === WrittenPriorities.NEW) {
    return new Date(b.adate).getTime() - new Date(a.adate).getTime()
  }

  if (
    (a.priority === WrittenPriorities.MINICHAT && b.priority === WrittenPriorities.MINICHAT) ||
    (a.priority === WrittenPriorities.RECIVED_CLARIFIED && b.priority === WrittenPriorities.RECIVED_CLARIFIED) ||
    (a.priority === WrittenPriorities.NEW_CLARIFIED && b.priority === WrittenPriorities.NEW_CLARIFIED)
  ) {
    return new Date(b.mini_chat_at).getTime() - new Date(a.mini_chat_at).getTime()
  }

  if (a.priority === WrittenPriorities.UNPROCESSED && b.priority === WrittenPriorities.UNPROCESSED) {
    return new Date(a.deadline).getTime() - new Date(b.deadline).getTime()
  }

  if (a.priority === WrittenPriorities.CLARIFIED && b.priority === WrittenPriorities.CLARIFIED) {
    return new Date(b.clarified_at).getTime() - new Date(a.clarified_at).getTime()
  }

  if (a.priority === WrittenPriorities.PROCESSED && b.priority === WrittenPriorities.PROCESSED) {
    return new Date(b.publication_at).getTime() - new Date(a.publication_at).getTime()
  }

  return b.absnum - a.absnum
}

const filterMiniChatMessages = (messages: IMiniChat[], message: IMiniChat) => {
  return messages?.filter((item) => {
    if (item.absnum !== message.absnum) return true
    if (item.absnum === message.absnum && item.part_id !== message.part_id) return true
    return false
  })
}

const updateMessages = (messages: IMessageWritten[], message: IMessageWritten) => {
  return messages.map((item) => {
    if (item.absnum === message.absnum && item.part_id === message?.part_id) {
      return message
    }
    return item
  })
}

const initialState: IStore['chatsWritten'] = {
  data: null,
  message: null,
  miniChat: null,
  page: 1,
  loading: false,
}

export const slice = createSlice({
  name: 'chatsWritten',
  initialState,
  reducers: {
    changePage: (state, action) => {
      if (state.page !== action.payload) {
        state.loading = true
      }
      state.page = action.payload
    },
    updateMessage: (state, action: PayloadAction<IMessageWritten>) => {
      if (state.data && action.payload) {
        const messages = updateMessages(state.data.messages, action.payload).sort(sortMessages)

        state.data = { ...state.data, messages }
      }
      if (
        state.message &&
        action.payload &&
        state.message.absnum === action.payload.absnum &&
        state.message.part_id === action.payload?.part_id
      ) {
        state.message = action.payload
      }
    },
    addMessage: (state, action: PayloadAction<IMessageWritten>) => {
      if (state.data && action.payload) {
        const messages = filterMessages(state.data.messages, action.payload)
        state.data = { ...state.data, messages: [action.payload, ...messages].sort(sortMessages) }
      }
    },
    deleteMessage: (state, action: PayloadAction<IMessageWritten>) => {
      if (state.data && action.payload) {
        const messages = filterMessages(state.data.messages, action.payload)
        state.data = { ...state.data, messages }
      }
    },
    addeMessageMiniChat: (state, action: PayloadAction<IMiniChat>) => {
      if (
        state.miniChat &&
        state.message?.absnum === action.payload.message_id &&
        state.message?.part_id === action.payload.part_id
      ) {
        const messages = filterMiniChatMessages(state.miniChat.messages, action.payload)
        messages.push(action.payload)
        state.miniChat = { ...state.miniChat, messages }
      }
    },
    setMiniChat: (state, action: PayloadAction<IChatsWrittenState['miniChat']>) => {
      state.miniChat = action.payload
    },
    setMessage: (state, action: PayloadAction<IChatsWrittenState['message']>) => {
      state.message = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getWittenMessages.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getWittenMessages.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(getWittenMessages.fulfilled, (state, action) => {
      state.data = action.payload
      state.page = action.payload?.page || state.page
      state.loading = false
    })
    builder.addCase(getMiniChat.fulfilled, (state, action) => {
      state.miniChat = action.payload
    })
    builder.addCase(getMiniChatMessages.fulfilled, (state, action) => {
      if (state.miniChat && action.payload) {
        state.miniChat = {
          ...action.payload,
          messages: [...action.payload.messages, ...state.miniChat.messages],
        }
      }
    })
    builder.addCase(getWittenMessage.fulfilled, (state, action) => {
      state.message = action.payload
    })
    builder.addCase(deleteWittenMessage.fulfilled, (state, action) => {
      if (state.data && action.payload) {
        const messages = filterMessages(state.data.messages, action.payload)
        state.data = { ...state.data, messages }
      }
    })
    builder.addCase(deleteFile.fulfilled, (state, action) => {
      if (state.data && action.payload) {
        const messages = updateMessages(state.data.messages, action.payload)
        state.data = { ...state.data, messages }
      }
      if (state.message && action.payload) {
        state.message = { ...state.message, attachment: action.payload.attachment }
      }
    })
  },
})

export const ChatsWrittenActions = slice.actions

export default slice.reducer
