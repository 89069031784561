import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '../../utils/callApi'
import { IAppealState, IAppeal } from './types'

export const getAppeal = createAsyncThunk<IAppealState['list']>('appeal/getAppeal', async (_, thunkAPI) => {
  try {
    return callApi({
      method: 'get',
      path: '/admin-office/appeal',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

interface TCreateR {
  name: string
}
export const createAppeal = createAsyncThunk<IAppeal, TCreateR>('appeal/createAppeal', async (payload, thunkAPI) => {
  try {
    const data = payload
    return callApi({
      method: 'post',
      data,
      path: '/admin-office/appeal',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

interface TUpdateR {
  id: number
  data: {
    name: string
  }
}
export const updateAppeal = createAsyncThunk<IAppeal, TUpdateR>('appeal/updateAppeal', async (payload, thunkAPI) => {
  try {
    const { id, data } = payload
    return callApi({
      method: 'patch',
      data,
      path: `/admin-office/appeal/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const deleteAppeal = createAsyncThunk<IAppeal, number>('appeal/deleteAppeal', async (payload, thunkAPI) => {
  try {
    const id = payload
    return callApi({
      method: 'delete',
      data: {},
      path: `/admin-office/appeal/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})
