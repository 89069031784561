import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Alert, AlertTitle, Button, FormControl, InputLabel, Link, MenuItem, Select } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { ChatStatus, Roles, UnauthorizedChatStatus } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { IChatUnauthorized } from '../../store/chatsUnauthorized/types'

interface Props {
  isBtn?: boolean
  currentChat: IChatUnauthorized
  siteUrl?: string
  comeBack?: () => void
  closeChat?: (data: { unauthorized_id: number; appeal_id: number }, callback: (success?: boolean) => void) => void
  openSidebar?: () => void
}

export const UnauthorizedChatHeader: React.FC<Props> = ({
  isBtn,
  currentChat,
  siteUrl,
  comeBack,
  closeChat,
  openSidebar,
}) => {
  const [loading, setLoading] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [appealId, setAppealId] = React.useState('')
  const [error, setError] = React.useState('')
  const { appeal, users } = useSelector((store: IStore) => store)

  const renderCloseBtn = () => {
    if (currentChat.status === UnauthorizedChatStatus.IN_WORK && closeChat && isBtn) {
      return (
        <Button
          color={'error'}
          variant={'contained'}
          disabled={loading}
          onClick={() => {
            if (!appealId) return setError("Поле “Тип звернення” є обов'язковим до заповнення")
            if (currentChat) {
              setLoading(true)
              closeChat({ unauthorized_id: currentChat.absnum, appeal_id: Number(appealId) }, () => setLoading(false))
            }
          }}
        >
          Закрити чат
        </Button>
      )
    }
  }

  const renderTransferBtn = () => {
    if (
      (currentChat.status < UnauthorizedChatStatus.CLOSE && openSidebar && isBtn) ||
      (users.user && users.user.role >= Roles.CHIEF)
    ) {
      return (
        <Button color={'info'} variant={'contained'} onClick={openSidebar}>
          Передати чат
        </Button>
      )
    }
  }

  const renderAppealSelect = () => {
    if (currentChat.status === UnauthorizedChatStatus.IN_WORK && isBtn) {
      return (
        <FormControl variant='outlined' sx={{ width: '200px' }}>
          <InputLabel size={'small'} id='select-appeal'>
            Тип звернення
          </InputLabel>
          <Select
            size={'small'}
            labelId='select-appeal'
            id='select-appeal'
            value={appealId}
            onChange={(e) => {
              setError('')
              setAppealId(e.target.value)
            }}
            label='Тип звернення'
            variant={'outlined'}
          >
            {appeal.list?.map((item) => (
              <MenuItem key={item.absnum} value={item.absnum}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
  }

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <Box borderBottom={'1px solid #cccccc'} py={1}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          color={'primary'}
          variant={'text'}
          sx={{ ml: -1, fontWeight: '600', fontSize: 18 }}
        >
          {currentChat.client_fio}
        </Button>
        {comeBack ? (
          <Button color={'info'} variant={'contained'} disabled={loading} onClick={comeBack}>
            Повернутися
          </Button>
        ) : null}
        <>
          {renderAppealSelect()}
          {renderCloseBtn()}
          {renderTransferBtn()}
        </>
      </Box>
      {error ? (
        <Box>
          <Alert severity='error'>
            <AlertTitle>{error}</AlertTitle>
          </Alert>
        </Box>
      ) : null}
      {isOpen && (
        <>
          <Box mt={1} display={'flex'} alignItems={'center'}>
            <Typography minWidth={100} color={'gray'} pr={2}>
              Пошта
            </Typography>
            <Typography>
              <Link href={currentChat.email}>{currentChat.email}</Link>
            </Typography>
            <Button onClick={() => handleCopy(currentChat.email || '')} startIcon={<ContentCopyOutlinedIcon />} />
            {currentChat.phone ? (
              <>
                {' '}
                <Typography color={'gray'} pl={2}>
                  Телефон
                  <Link ml={1} href={`tel:${currentChat.phone}`}>
                    {currentChat.phone}
                  </Link>
                </Typography>
                <Button onClick={() => handleCopy(currentChat.phone || '')} startIcon={<ContentCopyOutlinedIcon />} />
              </>
            ) : null}
          </Box>
          {siteUrl ? (
            <Box mt={1} display={'flex'} alignItems={'center'}>
              <Typography minWidth={100} color={'gray'} pr={2}>
                Сайт
              </Typography>

              <Link href={siteUrl} target='_blank'>
                {siteUrl}
              </Link>
              <Button onClick={() => handleCopy(siteUrl)} startIcon={<ContentCopyOutlinedIcon />} />
            </Box>
          ) : null}
        </>
      )}
    </Box>
  )
}
