import React from 'react'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { InnerHeight, Roles, STATUSES_CHAT_UNAUTHORIZED, UnauthorizedChatStatus } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { EMainView, IStore } from '../../store/types'
import { setMainView } from '../../store/mainViewSlice'
import { AllChats } from '../AllChats'
import { Settings } from '../Settings'
import { Search } from '../Search'
import { ListGroupTemplates } from '../Templates/ListGroupTemplates'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { ChatsUnauthorizedActions } from '../../store/chatsUnauthorized'
import { searchChats } from '../../store/searchChatsUnauthorized/actions'
import { ISearchChat } from '../../store/searchChatsOnline/types'
import { useAppDispatch } from '../../store'
import { TemplatesSalesActions } from '../../store/templatesUnauthorized'
import { IGroupTemplate } from '../../store/templates/types'
import { SearchChatsUnauthorizedActions } from '../../store/searchChatsUnauthorized'
import { useScrollChats } from '../../hooks/useScrollChats'
import { getChats, getChatsByPage } from '../../store/chatsUnauthorized/actions'
import { ChatItemUnauthorized } from '../ChatItem/ChatItemUnauthorized'

export const MainWorkspaceUnauthorized: React.FC<{ children: any }> = ({ children }) => {
  const [statuses, setStatuses] = React.useState<{ value: string | number; label: string }[]>([])
  const [seconds, setSeconds] = React.useState(0)
  const { chatsUnauthorized, mainView, templatesUnauthorized, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { handleScroll } = useScrollChats({
    data: chatsUnauthorized,
    getChats: getChatsByPage,
  })

  const checkActiveBtn = () => {
    if (location.pathname.includes(ROUTES.templatesUnauthorized)) return dispatch(setMainView(EMainView.textSettings))
    if (location.pathname.includes(ROUTES.settingsUnauthorized)) return dispatch(setMainView(EMainView.settings))
    if (location.pathname.includes(ROUTES.searchUnauthorized)) return dispatch(setMainView(EMainView.search))
    dispatch(setMainView(EMainView.chat))
  }

  React.useEffect(() => {
    if (chatsUnauthorized.statusChat === UnauthorizedChatStatus.NEWS && mainView === EMainView.chat) {
      const timeout = setTimeout(() => {
        setSeconds(seconds + 1)
      }, 5000)

      return () => {
        clearTimeout(timeout)
      }
    }
  }, [seconds, chatsUnauthorized, mainView])

  React.useEffect(() => {
    checkActiveBtn()
    const data = Object.entries(STATUSES_CHAT_UNAUTHORIZED).map((item) => ({
      value: Number(item[0]),
      label: item[1].title,
    }))
    setStatuses([{ value: 0, label: 'Усі' }, ...data])
  }, [])

  React.useEffect(() => {
    if (mainView === EMainView.chat && users.socket) {
      dispatch(getChats({ status: chatsUnauthorized.statusChat }))
    }
  }, [chatsUnauthorized.statusChat, mainView, users.socket])

  const handleSearch = (values: ISearchChat, callback?: (success?: boolean) => void) => {
    dispatch(SearchChatsUnauthorizedActions.setSearchFields(values))
    dispatch(searchChats(values)).then(() => callback && callback())
  }

  const handleSetGroup = (group: IGroupTemplate) => {
    dispatch(TemplatesSalesActions.setCurrentGroup(group))
  }

  const handleChangeStatus = (status: number) => {
    dispatch(ChatsUnauthorizedActions.changeStatus(status))
    navigate(ROUTES.routeChange(''))
  }

  const renderWorkspaceLeft = () => {
    switch (mainView) {
      case EMainView.chat:
        return (
          <AllChats
            statuses={statuses}
            total={chatsUnauthorized.chats?.total || 0}
            status={chatsUnauthorized.statusChat}
            handleChange={handleChangeStatus}
            handleScroll={handleScroll}
          >
            <>
              {chatsUnauthorized.chats?.chats.map((item, idx) => (
                <ChatItemUnauthorized key={`1_${idx}`} chat={item} />
              ))}
            </>
          </AllChats>
        )
      case EMainView.textSettings:
        if (users.user?.role === Roles.ADMIN || users.user?.role === Roles.CHIEF) {
          return <ListGroupTemplates groups={templatesUnauthorized.groups} setCurrentGroup={handleSetGroup} />
        }
        return <Box />
      case EMainView.settings:
        return <Settings titleFirstPage='Типи звернень' />
      case EMainView.search:
        return (
          <Search
            statuses={Object.keys(STATUSES_CHAT_UNAUTHORIZED).map((key) => ({
              value: key,
              label: STATUSES_CHAT_UNAUTHORIZED[key].title,
            }))}
            searchChats={handleSearch}
            products={null}
          />
        )
      default:
        return <Box />
    }
  }

  const handlerChange = (num: number) => {
    if (num === 0) {
      navigate(ROUTES.routeChange(''))
    }
    dispatch(setMainView(num))
    switch (num) {
      case EMainView.chat:
        return navigate(ROUTES.chatsUnauthorized)
      case EMainView.textSettings:
        return navigate(ROUTES.templatesUnauthorized)
      case EMainView.settings:
        return navigate(ROUTES.settingsUnauthorized)
      case EMainView.search:
        return navigate(ROUTES.searchUnauthorized)
      default:
        return <Box />
    }
  }

  if (!users.user?.approved_online) return null

  return (
    <React.Fragment>
      <Box display={'flex'}>
        <Box>
          <Box
            sx={{ width: '25vw', maxWidth: 500, minWidth: 300, height: InnerHeight, background: 'white' }}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            boxShadow={'4px 2px 5px rgba(0,0,0,0.1)'}
          >
            {renderWorkspaceLeft()}
            <BottomNavigation
              showLabels
              value={mainView}
              sx={{ borderTop: '1px solid #dcdcdc', background: '#f5f5f5' }}
              onChange={(e, num) => handlerChange(num)}
            >
              <BottomNavigationAction label='' icon={<ChatBubbleIcon />} />
              {users.user?.role === Roles.ADMIN || users.user?.role === Roles.CHIEF ? (
                <BottomNavigationAction label='' icon={<DescriptionOutlinedIcon />} />
              ) : null}
              <BottomNavigationAction label='' icon={<SettingsOutlinedIcon />} />
              <BottomNavigationAction label='' icon={<SearchOutlinedIcon />} />
            </BottomNavigation>
          </Box>
        </Box>
        <Box
          flex={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={chatsUnauthorized.currentChat ? 'start' : 'center'}
          maxHeight={InnerHeight}
        >
          <Box display={'flex'} flexDirection={'column'} flex={1} height={InnerHeight} position={'relative'}>
            {children}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
