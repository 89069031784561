import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IDtoSearch, IStatUnauthorizedState } from './types'

export const getStat = createAsyncThunk<IStatUnauthorizedState['data'], IDtoSearch>(
  'statUnauthorized/getStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-unauthorized/stat?${query}` : '/admin-chat-unauthorized/stat',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const searchStat = createAsyncThunk<IStatUnauthorizedState['data'], IDtoSearch>(
  'statUnauthorized/searchStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-unauthorized/stat?${query}` : '/admin-chat-unauthorized/stat',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStat = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statUnauthorized/downlaodStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-unauthorized/stat/download?${query}` : '/admin-chat-unauthorized/stat/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatBlock2 = createAsyncThunk<IStatUnauthorizedState['dataRating'], IDtoSearch>(
  'statUnauthorized/getStatBlock2',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-unauthorized/stat/block2?${query}` : '/admin-chat-unauthorized/stat/block2',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const searchStatBlock2 = createAsyncThunk<IStatUnauthorizedState['dataRating'], IDtoSearch>(
  'statUnauthorized/searchStatBlock2',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-unauthorized/stat/block2?${query}` : '/admin-chat-unauthorized/stat/block2',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatBlock4 = createAsyncThunk<IStatUnauthorizedState['statChats'], IDtoSearch>(
  'statUnauthorized/getStatBlock4',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-unauthorized/stat/block4?${query}` : '/admin-chat-unauthorized/stat/block4',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const searchStatBlock4 = createAsyncThunk<IStatUnauthorizedState['searchStatChats'], IDtoSearch>(
  'statUnauthorized/searchStatBlock4',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-unauthorized/stat/block4?${query}` : '/admin-chat-unauthorized/stat/block4',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStatBlock2 = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statUnauthorized/downlaodStatBlock2',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query
          ? `/admin-chat-unauthorized/stat/block2/download?${query}`
          : '/admin-chat-unauthorized/stat/block2/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStatBlock3 = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statUnauthorized/downlaodStatBlock3',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query
          ? `/admin-chat-unauthorized/stat/block3/download?${query}`
          : '/admin-chat-unauthorized/stat/block3/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStatBlock4 = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statUnauthorized/downlaodStatBlock4',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query
          ? `/admin-chat-unauthorized/stat/block4/download?${query}`
          : '/admin-chat-unauthorized/stat/block4/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
