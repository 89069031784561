import React from 'react'
import Paper from '@mui/material/Paper'
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { Form, Formik } from 'formik'
import { InputFormik, TextareaFormik } from '../../ui/InputFormik'
import { AlertMessage } from '../AlertMessage'
import { Title } from '../Title'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { IMailing } from '../../store/mailing/types'
import { InputPalette } from '../../ui/InputPalette'
import { BasicDatePickerFormik } from '../../ui/BasicDatePickerFormik'
import { UploadFileFormik } from '../../ui/UploadFileFormik'
import { addParagraph } from '../../utils/addParagraph'

export const Styles = makeStyles(() => ({
  alertNews: {
    minWidth: '300px',
    maxWidth: '380px',
    marginBottom: '20px',
    background: 'white !important',
    padding: '6px 6px 6px 0',
  },
  alertBadge: {
    width: '65px',
    height: '65px',
  },
  businessPerson: {
    width: '40px',
    height: '40px',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
  },
}))

const ERROR_MESSAGE = 'Максимальна ширина зображення 900 пікселів, висота — 450'

interface Props {
  title: string
  data?: IMailing
  handleSubmit: (values: IMailing, callback: (error: string) => void) => void
}

export const MailingForm: React.FC<Props> = ({ title, data, handleSubmit }) => {
  const [errors, setErrors] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [groupsSites, setGroupsSites] = React.useState<{
    [x: string]: { value: string; label: string; direction_id: number }[]
  }>({})

  const { configurations } = useSelector((store: IStore) => store)
  const navigate = useNavigate()
  const classes = Styles()

  React.useEffect(() => {
    if (configurations.all) {
      const obj = configurations.all.reduce((acum, item) => {
        const data = { value: String(item.absnum), label: item.name }
        item.direction_list?.forEach((direction) => {
          if (acum[direction.name]) acum[direction.name].push({ ...data, direction_id: direction.absnum })
          else acum[direction.name] = [{ ...data, direction_id: direction.absnum }]
        })
        return acum
      }, {} as { [x: string]: { value: string; label: string; direction_id: number }[] })
      setGroupsSites(obj)
    }
  }, [configurations.all])

  const checkImg = async (url: string): Promise<boolean> =>
    new Promise((resolve) => {
      let result = true
      const img = new Image()
      img.src = url

      img.onload = () => {
        if (img.width > 900 || img.height > 450) {
          result = false
        }
        resolve(result)
      }
    })

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          status: data?.status || 0,
          name: data?.name || '',
          body: data?.body || '',
          btn_name: data?.btn_name || '',
          btn_color: data?.btn_color || '#417505',
          link: data?.link || '',
          site_id: data ? String(data.site_id) : '',
          date_start: data?.date_start || null,
          date_end: data?.date_end || null,
          photo: data?.photo || '',
          direction_id: data?.direction_id || '0',
          sender: data?.sender || '',
          img: data?.img || '',
        }}
        onSubmit={async (values, formikHelpers) => {
          setErrors('')
          const { date_start, date_end } = values
          if (date_start && new Date(date_start) < new Date())
            return setErrors('Дата початку має бути більша за теперешній час')
          if (date_start && date_end && new Date(date_start) > new Date(date_end))
            return setErrors('Дата початку більша за дату закінчення')

          if (values.img) {
            const result = await checkImg(values.img)
            if (!result) {
              formikHelpers.setErrors({ img: ERROR_MESSAGE })
              return null
            }
          }

          setLoading(true)
          handleSubmit(
            { ...values, site_id: Number(values.site_id), direction_id: Number(values.direction_id) },
            (error) => {
              if (error) {
                setErrors(error)
              }
              setLoading(false)
            }
          )
        }}
      >
        {(formik) => (
          <Form>
            <Paper
              sx={{
                m: 2,
                p: 2,
                overflow: 'hidden',
                minHeight: 'calc(100vh - 102px)',
                overflowX: 'auto',
                display: 'flex',
                flexWrap: 'wrap',
                alignItems: 'flex-start',
                gap: '50px',
              }}
            >
              <Stack spacing={2} direction={'column'} minWidth={'600px'} width={'50%'}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                  <Title title={title} color={'default'} mb={2} />
                  <Stack direction={'row'} spacing={2}>
                    <Button variant={'contained'} type='submit'>
                      {loading ? <CircularProgress size={15} /> : 'Зберегти'}
                    </Button>
                    <Button variant={'outlined'} color={'inherit'} onClick={() => navigate(ROUTES.mailing)}>
                      Назад
                    </Button>
                  </Stack>
                </Stack>
                {errors ? <AlertMessage type='error' message={errors} /> : null}
                <InputFormik label='Назва повідомлення' name='name' required />
                <TextareaFormik
                  maxLength={255}
                  style={{ minHeight: 60, padding: '8.5px 14px' }}
                  label='Текст повідомлення до 255 символів з пробілами'
                  name='body'
                  required
                />
                <UploadFileFormik
                  label='Завантажте або додайте посилання на зображення'
                  name='img'
                  apiPath='/mailing/upload'
                  checkFile={(file) =>
                    new Promise((resolve) => {
                      const reader = new FileReader()
                      reader.onload = async () => {
                        if (typeof reader.result === 'string') {
                          const result = await checkImg(reader.result)
                          if (!result) {
                            formik.setErrors({ img: ERROR_MESSAGE })
                          }
                          resolve(result)
                        }
                      }
                      reader.readAsDataURL(file)
                    })
                  }
                />
                <InputFormik maxLength={26} label='Назва кнопки до 26 символів з пробілами' name='btn_name' required />
                <InputPalette label='Колір кнопки' name='btn_color' required />
                <InputFormik label='Посилання на кнопці' name='link' required />
                <BasicDatePickerFormik label='Дата початку' name='date_start' required />
                <BasicDatePickerFormik label='Дата закінчення' name='date_end' required />
                <FormControl>
                  <InputLabel size={'small'} id='site_id'>
                    Сайт розміщення повідомлення
                  </InputLabel>
                  <Select
                    id='site_id'
                    name='site_id'
                    label={'Сайт розміщення повідомлення'}
                    size='small'
                    variant='outlined'
                    error={!!formik.errors.site_id && formik.touched.site_id}
                    value={formik.values.site_id}
                    onChange={formik.handleChange}
                  >
                    {Object.keys(groupsSites).map((key) => [
                      <ListSubheader>{key}</ListSubheader>,
                      groupsSites[key]?.map((item) => (
                        <MenuItem
                          key={item.value}
                          value={item.value}
                          onClick={() => {
                            formik.values.direction_id = item.direction_id
                          }}
                        >
                          {item.label}
                        </MenuItem>
                      )),
                    ])}
                  </Select>
                </FormControl>
                <UploadFileFormik
                  label='Завантажте або додайте посилання на іконку'
                  name='photo'
                  required
                  apiPath='/mailing/upload'
                />
                <InputFormik label='Відправник' name='sender' maxLength={90} />
              </Stack>
              <Alert
                icon={<img className={classes.alertBadge} src={formik.values.photo} alt={'іконка'} />}
                className={classes.alertNews}
                variant='outlined'
                severity='info'
                sx={{
                  '.MuiAlert-icon': {
                    margin: '0px 5px',
                  },
                }}
              >
                {formik.values.sender ? (
                  <Typography
                    mb={1}
                    style={{ wordBreak: 'break-word', fontStyle: 'italic', color: 'rgb(1, 67, 97, 0.7)' }}
                  >
                    {formik.values.sender}
                  </Typography>
                ) : null}
                <Typography
                  mb={1}
                  style={{ wordBreak: 'break-word' }}
                  dangerouslySetInnerHTML={{ __html: addParagraph(formik.values.body) || 'Повідомлення' }}
                />
                {formik.values.img ? (
                  <img style={{ maxHeight: '450px', maxWidth: '290px' }} src={formik.values.img} alt='Зображення' />
                ) : null}
                <Button
                  sx={{ mt: 2 }}
                  fullWidth
                  variant='contained'
                  size='small'
                  href={formik.values.link}
                  style={{ backgroundColor: formik.values.btn_color, whiteSpace: 'nowrap' }}
                >
                  {formik.values.btn_name || 'Назва кнопки'}
                </Button>
                <Button sx={{ mt: 2 }} fullWidth variant={'contained'}>
                  Написати в чат
                </Button>
              </Alert>
            </Paper>
          </Form>
        )}
      </Formik>
    </>
  )
}
