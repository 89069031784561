import React from 'react'
import { useSelector } from 'react-redux'
import { IProduct } from '../store/products/types'
import { IStore } from '../store/types'

const initProduct: IProduct = {
  absnum: 0,
  approved: 0,
  name: '',
  parent_id: 0,
  sub_name: '',
  url: '',
  children: [] as IProduct[],
}

export interface IDataOpt {
  id: number
  label: string
}

export const useSearchProducts = () => {
  const [mainProducts, setMainProducts] = React.useState<IProduct[]>([initProduct])
  const [products, setProducts] = React.useState<IProduct[]>([])
  const [options, setOptions] = React.useState<IDataOpt[]>([])
  const [subOptions, setSubOptions] = React.useState<IDataOpt[]>([])

  const { configurations } = useSelector((store: IStore) => store)

  React.useEffect(() => {
    if (configurations.products) {
      const subProducts = [] as IDataOpt[]
      setProducts(configurations.products)
      const opt = configurations.products.map((it) => {
        if (it.children) {
          const subs = it.children.map((sub) => ({ id: sub.absnum, label: sub.name }))
          subProducts.push(...subs)
        }
        return { id: it.absnum, label: it.name }
      })
      setOptions(opt)
      setSubOptions(subProducts)
    }
  }, [configurations.products])

  const selectedMainProduct = (id?: number, idx?: number) => {
    if (products?.length > 0 && id) {
      const mainProduct = products.find((it) => it.absnum === id)
      if (!mainProduct) return null

      if (typeof idx === 'number' && mainProducts[idx]) {
        const prodcs = [...mainProducts]
        prodcs[idx] = mainProduct
        setMainProducts(prodcs)
      } else {
        setMainProducts([mainProduct])
      }
    }
  }

  const removeSubProduct = (id: number, index: number) => {
    if (mainProducts[index]) {
      const children = mainProducts[index].children?.filter((item) => item.absnum !== id) || []
      const products = [...mainProducts]
      if (children.length > 0) {
        products[index] = { ...products[index], children }
      } else {
        products[index] = initProduct
      }

      setMainProducts(products.filter((it, idx) => idx === 0 || !!it.absnum))
    }
  }

  const addProduct = () => {
    setMainProducts([...mainProducts, initProduct])
  }

  return {
    mainProducts,
    options,
    subOptions,
    setMainProducts,
    selectedMainProduct,
    removeSubProduct,
    addProduct,
  }
}
