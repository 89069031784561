import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { SearchWorkspace } from '../../components/SearchWorkspace'
import { useAppDispatch } from '../../store'
import { searchChats } from '../../store/searchChatsUnauthorized/actions'
import { STATUSES_CHAT_UNAUTHORIZED } from '../../utils/consts'
import { ROUTES } from '../../utils/routes'

export const SearchChatUnauthorized: React.FC = () => {
  const { searchChatsUnauthorized } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!searchChatsUnauthorized.data) {
      dispatch(searchChats({}))
    }
  }, [searchChatsUnauthorized.data])

  const handleChangePage = (page: number) => {
    if (!searchChatsUnauthorized.data) return null
    if (searchChatsUnauthorized.data.pages >= page) {
      dispatch(searchChats({ ...searchChatsUnauthorized.searchFields, page }))
    }
  }

  const handleGetDialog = (dialogId: number) => {
    navigate(ROUTES.changeSearch(dialogId))
  }

  return (
    <SearchWorkspace
      types={STATUSES_CHAT_UNAUTHORIZED}
      data={searchChatsUnauthorized.data}
      changePage={handleChangePage}
      getDialog={handleGetDialog}
    />
  )
}
