import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import { IStore } from '../../store/types'

interface Props {
  element?: JSX.Element
  children?: JSX.Element
}

export const RequireAuth: React.FC<Props> = ({ element, children }) => {
  const { users } = useSelector((store: IStore) => store)
  const location = useLocation()

  if (users.isLogin) {
    return <Navigate to='/login' state={{ from: location }} replace />
  }

  if (element) return element
  if (children) return children

  return null
}
