import React from 'react'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { IStore } from '../../store/types'
import { CHANNELS_ID, Roles } from '../../utils/consts'
import { useAppDispatch } from '../../store'
import {
  downlaodStat,
  getStatRaiting,
  getStatQuantity,
  getStatQuantityAndExpert,
  getStatCountSession,
} from '../../store/statWritten/actions'
import { IDtoSearch } from '../../store/statSales/types'
import { StatDataPages, ColumnChats } from '../../components/Stat/StatDataPages'
import { checkFio } from '../../utils/checkFio'
import { createStatUsing } from '../../store/users/actions'

const columns: ColumnChats[] = [
  { id: 'name', label: 'Система' },
  { id: 'count_created', label: 'Загальна к-ть запитань, шт', align: 'center' },
  { id: 'count_quantity', label: 'Загальна к-ть опрацьованих запитань, шт', align: 'center' },
  { id: 'count_transferred_online', label: 'Передано з Онлайн-чату, шт', align: 'center' },
  { id: 'count_transferred_tech_support', label: 'Передано з Техпідтримки, шт', align: 'center' },
  { id: 'count_demo', label: 'Запитання від ДД, шт', align: 'center' },
  {
    id: 'count_positive',
    label: 'Запитання із оцінкою від 4 до 5, шт',
    align: 'center',
  },
  {
    id: 'count_negative',
    label: 'Запитання із оцінкою від 1 до 3, шт',
    align: 'center',
  },
  {
    id: 'count_refusal',
    label: 'Відмова, шт',
    align: 'center',
  },
  {
    id: 'count_outstanding',
    label: 'Прострочений дедлайн, шт',
    align: 'center',
  },
]

const columns2: ColumnChats[] = [
  { id: 'name', label: 'Система' },
  { id: 'fio', label: 'Експерт', align: 'center' },
  {
    id: 'count_quantity',
    label: 'Загальна к-ть запитань, шт',
    align: 'center',
  },
  {
    id: 'count_positive',
    label: 'Запитання із оцінкою від 4 до 5, шт',
    align: 'center',
  },
  {
    id: 'count_negative',
    label: 'Запитання із оцінкою від 1 до 3, шт',
    align: 'center',
  },
  {
    id: 'count_refusal',
    label: 'Відмова, шт',
    align: 'center',
  },
  {
    id: 'count_outstanding',
    label: 'Прострочений дедлайн, шт',
    align: 'center',
  },
]

const columns3: ColumnChats[] = [
  { id: 'name', label: 'Система' },
  { id: 'fio', label: 'Експерт', align: 'center' },
  {
    id: 'rating',
    label: 'Оцінка (від 1 до 5)',
    align: 'center',
  },
  {
    id: 'message',
    label: 'Коментар до оцінки',
    align: 'center',
  },
  {
    id: 'link',
    label: 'Чат',
    align: 'center',
  },
  { id: 'client_fio', label: 'ПІБ клієнта', align: 'center' },
  {
    id: 'email',
    label: 'Мейл клієнта',
    align: 'center',
  },
]

const columns4: ColumnChats[] = [
  { id: 'fio', label: 'ПІБ' },
  { id: 'email', label: 'Ел-пошта', align: 'center' },
  {
    id: 'total',
    label: 'Кількість',
    align: 'center',
  },
]

export const StatWritten: React.FC = () => {
  const [siteIds, setSiteIds] = React.useState<{ value: string; label: string }[] | undefined>()
  const [experts, setExperts] = React.useState<{ value: string; label: string }[] | undefined>()
  const [page, setPage] = React.useState(1)
  const { users, statWritten, configurations, employees } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  if (users.user && users.user.role < Roles.CHIEF) {
    return null
  }

  if (users.user && !users.user.channels.includes(String(CHANNELS_ID.WRITTEN))) {
    return null
  }

  const restartData = React.useCallback(() => {
    if (page === 1) {
      dispatch(getStatQuantity({}))
    }

    if (page === 2) {
      dispatch(getStatQuantityAndExpert({}))
    }
    if (page === 3) {
      dispatch(getStatRaiting({}))
    }
    if (page === 4) {
      dispatch(getStatCountSession({}))
    }
    dispatch(createStatUsing({ type_id: CHANNELS_ID.WRITTEN, part_id: page }))
  }, [page])

  React.useEffect(restartData, [page])

  React.useEffect(() => {
    if (configurations.all && configurations.all.length > 1) {
      setSiteIds(
        configurations.all
          .filter((item) => !!item.approved_written)
          .map((item) => ({ value: String(item.absnum), label: item.name }))
      )
    }
  }, [configurations.all])

  React.useEffect(() => {
    if (employees.data) {
      setExperts(employees.data.employees.map((item) => ({ value: String(item.id), label: checkFio(item) })))
    }
  }, [employees.data])

  const dowmloadStat = (values: IDtoSearch, callback: (data?: Buffer) => void) => {
    dispatch(downlaodStat(values)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as { data: Buffer }
        callback(payload.data)
      }
      callback()
    })
  }

  const renderPage = () => {
    const shortName =
      configurations.all
        ?.filter((item) => !!item.short_name)
        .map((item) => item.short_name)
        .join('_') || ''
    if (page === 1) {
      return (
        <StatDataPages
          id={page}
          siteIds={siteIds}
          fileName={`ОП_Письмово_${shortName}`}
          inputFormat='dd/MM/yyyy'
          views={['year', 'month', 'day']}
          labelFrom='Від (включно)'
          labelTo='До (включно)'
          columns={columns}
          data={statWritten.statQuantity}
          downloadFile={dowmloadStat}
          actionThunk={getStatQuantity}
          handleClearFilters={restartData}
        />
      )
    }
    if (page === 2) {
      return (
        <StatDataPages
          id={page}
          siteIds={siteIds}
          experts={experts}
          labelExpert='Експерт'
          fileName={`ОП_Письмово_${shortName}`}
          inputFormat='dd/MM/yyyy'
          views={['year', 'month', 'day']}
          labelFrom='Від (включно)'
          labelTo='До (включно)'
          data={statWritten.statQuantityAndExpert}
          columns={columns2}
          downloadFile={dowmloadStat}
          actionThunk={getStatQuantityAndExpert}
          handleClearFilters={restartData}
        />
      )
    }
    if (page === 3) {
      return (
        <StatDataPages
          id={page}
          siteIds={siteIds}
          experts={experts}
          labelExpert='Експерт'
          fileName={`ОП_Письмово_${shortName}`}
          data={statWritten.statDataRating?.messages}
          dataPage={statWritten.statDataRating}
          columns={columns3}
          downloadFile={dowmloadStat}
          actionThunk={getStatRaiting}
          handleClearFilters={restartData}
        />
      )
    }
    if (page === 4) {
      return (
        <StatDataPages
          isEmail
          id={page}
          siteIds={siteIds}
          experts={experts}
          labelExpert='Експерт'
          fileName={`ОП_Письмово_${shortName}`}
          data={statWritten.session?.chats}
          dataPage={statWritten.session}
          total={statWritten.session?.total}
          columns={columns4}
          downloadFile={dowmloadStat}
          actionThunk={getStatCountSession}
          handleClearFilters={restartData}
          filterTypes={[
            { value: 1, label: 'Не користуються' },
            { value: 2, label: 'Користуються' },
          ]}
        />
      )
    }
  }

  return (
    <Paper sx={{ m: 2 }} style={{ height: 'calc(100vh - 96px)' }}>
      <Stack pt={2} pl={2} direction={'row'}>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 1}
          onClick={() => setPage(1)}
          style={{ height: '36px', marginRight: '10px' }}
        >
          Зведений
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 2}
          onClick={() => setPage(2)}
          style={{ height: '36px', marginRight: '10px' }}
        >
          Експерти
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 3}
          onClick={() => setPage(3)}
          style={{ height: '36px', marginRight: '10px' }}
        >
          Оцінки
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 4}
          onClick={() => setPage(4)}
          sx={{ height: '36px', marginRight: '10px' }}
        >
          Кількість сесій
        </Button>
      </Stack>
      {renderPage()}
    </Paper>
  )
}
