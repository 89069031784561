import React from 'react'
import { RequireAuth } from './RequireAuth'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { CHANNELS_ID, Roles } from '../../utils/consts'

interface Props {
  element: JSX.Element
}

export const Marketer: React.FC<Props> = ({ element }) => {
  const { users } = useSelector((store: IStore) => store)

  if (
    (users.user && ![Roles.ADMIN, Roles.CHIEF, Roles.MARKETER].includes(users.user.role)) ||
    (users.user && !users.user.channels.includes(String(CHANNELS_ID.NEWS)))
  ) {
    return null
  }

  return <RequireAuth>{element}</RequireAuth>
}
