import React from 'react'
import { getFormFioShort } from '../../../utils/changeFio'
import { ListItem, ListItemButton, Avatar, ListItemText } from '@mui/material'
import { StyledBadge } from '../../StyledBadge'

interface Props {
  status: number
  fio: string
  onClick: () => void
}

export const ChooseEmployee: React.FC<Props> = ({ status, fio, onClick }) => {
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={onClick}>
        <StyledBadge
          overlap='circular'
          status={status}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          variant='dot'
        >
          <Avatar alt='Remy Sharp'>{getFormFioShort(fio)}</Avatar>
        </StyledBadge>
        <ListItemText sx={{ marginLeft: '10px' }} primary={fio} />
      </ListItemButton>
    </ListItem>
  )
}
