export const spaces = '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'

const elementLink = [
  {
    tag: '<',
    el: '#[188]',
  },
  {
    tag: '>',
    el: '#[190]',
  },
  {
    tag: '/>',
    el: '#[191|190]',
  },
]

export const parseLink = (str?: string, revers?: boolean) => {
  if (!str) return ''
  let newStr2 = ''

  elementLink.forEach((item) => {
    if (revers) {
      if (newStr2) {
        return (newStr2 = newStr2.replaceAll(item.tag, item.el))
      }
      newStr2 = str.replaceAll(item.tag, item.el)
    } else {
      if (newStr2) {
        return (newStr2 = newStr2.replaceAll(item.el, item.tag))
      }
      newStr2 = str.replaceAll(item.el, item.tag)
    }
  })

  return newStr2
}
