import { createAsyncThunk } from '@reduxjs/toolkit'
import { ChatsOnlineActions } from '.'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IEmployeesState } from '../employees/types'
import { IChatsOnlineState, IChatOline, IMessageOnline } from './types'

export const getChats = createAsyncThunk<IChatsOnlineState['chats'], { status: number; page?: number }>(
  'chatsOnline/getChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-online?${query}` : '/admin-chat-online',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getChatsByPage = createAsyncThunk<IChatsOnlineState['chats'], { status: number; page?: number }>(
  'chatsOnline/getChatsByPage',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-online?${query}` : '/admin-chat-online',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getMessages = createAsyncThunk<IChatOline, { id: number; page: number }>(
  'chatsOnline/getMessages',
  async (payload, thunkAPI) => {
    try {
      const { id, page } = payload
      return callApi({
        method: 'get',
        path: page ? `/admin-chat-online/messages/${id}?page=${page}` : '/admin-chat-online',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getCurrentChat = createAsyncThunk<IChatsOnlineState['currentChat'], number>(
  'chatsOnline/getCurrentChat',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-chat-online/chat/${payload}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const closeChat = createAsyncThunk<IChatsOnlineState['currentChat'], number>(
  'chatsOnline/closeChat',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      const resp = (await callApi({
        method: 'get',
        path: `/admin-chat-online/close/${id}`,
      })) as IChatsOnlineState['currentChat']
      thunkAPI.dispatch(ChatsOnlineActions.updateChats(resp))
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const captureChat = createAsyncThunk<IChatsOnlineState['currentChat'], number>(
  'chatsOnline/captureChat',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-online/capture/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const passChatAutomate = createAsyncThunk<IChatsOnlineState['currentChat'], { chat_id: number }>(
  'chatsOnline/passChatAutomate',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-online/pass/automate`,
      })) as IChatsOnlineState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatR {
  chat_id: number
  user_id: number
}
export const passChat = createAsyncThunk<IChatsOnlineState['currentChat'], TPassChatR>(
  'chatsOnline/passChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-online/pass`,
      })) as IChatsOnlineState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatToTechExpertR {
  chat_id: number
  message: string
}
export const passChatToTechSupoort = createAsyncThunk<IChatsOnlineState['currentChat'], TPassChatToTechExpertR>(
  'chatsOnline/passChatToTechSupoort',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-online/pass/tech-support`,
      })) as IChatsOnlineState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatToExpertR {
  chat_id: number
  product_id: number
  message: string
}
export const passChatToWritten = createAsyncThunk<IChatsOnlineState['currentChat'], TPassChatToExpertR>(
  'chatsOnline/passChatToWritten',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-online/pass/written`,
      })) as IChatsOnlineState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TChangeStatusChatR {
  chat_id: number
  status: number
}
export const changeStatusChat = createAsyncThunk<IChatsOnlineState['currentChat'], TChangeStatusChatR>(
  'chatsOnline/changeStatusChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'patch',
        data,
        path: `/admin-chat-online/status`,
      })) as IChatsOnlineState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCreateMessageR {
  chat_id: number
  message: string
}
export const createMessage = createAsyncThunk<IMessageOnline, TCreateMessageR>(
  'chatsOnline/createMessage',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: `/admin-chat-online/message`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateMessage = createAsyncThunk<IMessageOnline, { id: number; data: { message: string } }>(
  'chatsOnline/updateMessage',
  async (payload, thunkAPI) => {
    try {
      const { data, id } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/admin-chat-online/message/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface IDraftMessage {
  draft_message?: string
  written_draft_message?: string
}
export const updateDraftMessage = createAsyncThunk<
  IChatsOnlineState['currentChat'],
  { id: number; data: IDraftMessage }
>('chatsOnline/updateDraftMessage', async (payload, thunkAPI) => {
  try {
    const { data, id } = payload
    return callApi({
      method: 'patch',
      data,
      path: `/admin-chat-online/draft-message/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const readMessage = createAsyncThunk<void, number>('chatsOnline/readMessage', async (payload, thunkAPI) => {
  try {
    const id = payload
    return callApi({
      method: 'get',
      path: `/admin-chat-online/read-message/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const getOnlineEmoloyees = createAsyncThunk<
  IEmployeesState['data'],
  { product_id: number; status: number; fio?: string }
>('chatsOnline/getOnlineEmoloyees', async (payload, thunkAPI) => {
  try {
    const query = buildQuery(payload)

    return callApi({
      method: 'get',
      path: query ? `/employees?${query}` : '/employees',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const uploadFile = createAsyncThunk<{ link: string }, FormData>(
  'chatsOnline/uploadFile',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        isFormData: true,
        path: `/admin-chat-online/upload`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateNotifications = createAsyncThunk<
  IChatsOnlineState['currentChat'],
  { chat_id: number; is_notifications: number }
>('chatsOnline/updateNotifications', async (payload, thunkAPI) => {
  try {
    const data = payload
    return callApi({
      method: 'post',
      data,
      path: '/admin-chat-online/notifications',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const sendForEvaluation = createAsyncThunk<IChatsOnlineState['currentChat'], number>(
  'chatsOnline/sendForEvaluation',
  async (payload, thunkAPI) => {
    try {
      const chatId = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-online/evaluation/${chatId}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const createDialog = createAsyncThunk<IChatsOnlineState['currentChat'], number>(
  'chatsOnline/createDialog',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-online/initiate/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
