import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { useAppDispatch } from '../../store'
import { createEmployee } from '../../store/employees/actions'
import { EmployeeForm } from '../../components/EmployeeForm'

export const CreateEmployee: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <EmployeeForm
      title='Добавити співробітника'
      handleSubmit={(values, callback) => {
        dispatch(createEmployee(values)).then((res) => {
          if (res.meta.requestStatus !== 'fulfilled') {
            const { error } = res as { error: { message: string } }
            callback(false, error?.message)
          } else {
            navigate(ROUTES.employees)
          }
        })
      }}
    />
  )
}
