import React from 'react'
import Box from '@mui/material/Box'
import BottomNavigation from '@mui/material/BottomNavigation'
import BottomNavigationAction from '@mui/material/BottomNavigationAction'
import ChatBubbleIcon from '@mui/icons-material/ChatBubble'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { InnerHeight, Roles, STATUSES_CHAT_TECH, TechChatStatus } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { EMainView, IStore } from '../../store/types'
import { setMainView } from '../../store/mainViewSlice'
import { AllChats } from '../AllChats'
import { Settings } from '../Settings'
import { Search } from '../Search'
import { ListGroupTemplates } from '../Templates/ListGroupTemplates'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { ChatsOnlineActions } from '../../store/chatsOnline'
import { searchChats } from '../../store/searchChatsTechSupport/actions'
import { ISearchChat } from '../../store/searchChatsTechSupport/types'
import { useAppDispatch } from '../../store'
import { TemplatesTechSupportActions } from '../../store/templatesTechSupport'
import { IGroupTemplate } from '../../store/templatesTechSupport/types'
import { SearchChatsTechSupportActions } from '../../store/searchChatsTechSupport'
import { IProduct } from '../../store/products/types'
import { useScrollChats } from '../../hooks/useScrollChats'
import { getChats, getChatsByPage } from '../../store/techSupport/actions'
import { ChatsTechSupportActions } from '../../store/techSupport'
import { ChatItemTechSupport } from '../ChatItem'

export const MainWorkspaceTechSupport: React.FC<{ children: any }> = ({ children }) => {
  const [statuses, setStatuses] = React.useState<{ value: string | number; label: string }[]>([])
  const [subProducts, setSubProducts] = React.useState<IProduct[]>([])
  const { techSupport, templatesTechSupport, mainView, users, configurations, products } = useSelector(
    (store: IStore) => store
  )
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { handleScroll } = useScrollChats({
    data: techSupport,
    getChats: getChatsByPage,
  })

  const checkActiveBtn = () => {
    if (location.pathname.includes(ROUTES.templatesTechSupport)) return dispatch(setMainView(EMainView.textSettings))
    if (location.pathname.includes(ROUTES.settingsTech)) return dispatch(setMainView(EMainView.settings))
    if (location.pathname.includes(ROUTES.searchTechSupport)) return dispatch(setMainView(EMainView.search))
    dispatch(setMainView(EMainView.chat))
  }

  React.useEffect(() => {
    const data = Object.entries(STATUSES_CHAT_TECH).map((item) => ({ value: Number(item[0]), label: item[1].title }))
    const initiated = data[TechChatStatus.INITIATED - 1]
    data.splice(TechChatStatus.INITIATED - 1, 1)
    data.splice(TechChatStatus.PROCESSED_OFFLINE_CHAT - 1, 1)

    setStatuses([...data, initiated])
  }, [])

  React.useEffect(() => {
    checkActiveBtn()
  }, [location.pathname])

  React.useEffect(() => {
    if (mainView === EMainView.chat && users.socket) {
      dispatch(getChats({ status: techSupport.statusChat }))
    }
  }, [techSupport.statusChat, mainView, users.socket])

  React.useEffect(() => {
    if (configurations.products) {
      const subProducts = [] as IProduct[]
      configurations.products.map((it) => {
        if (it.children) {
          subProducts.push(...it.children)
        }
      })
      setSubProducts(subProducts)
    }
  }, [configurations])

  const handleSearch = (values: ISearchChat, callback?: (success?: boolean) => void) => {
    dispatch(SearchChatsTechSupportActions.setSearchFields(values))
    dispatch(searchChats(values)).then(() => callback && callback())
  }

  const handleSetGroup = (group: IGroupTemplate) => {
    dispatch(TemplatesTechSupportActions.setCurrentGroup(group))
  }

  const handleChangeStatus = (status: number) => {
    dispatch(ChatsTechSupportActions.changeStatus(status))
    navigate(ROUTES.routeChange(''))
  }

  const renderWorkspaceLeft = () => {
    switch (mainView) {
      case EMainView.chat:
        return (
          <AllChats
            statuses={[{ value: 0, label: 'Усі' }, ...statuses]}
            total={techSupport.chats?.total || 0}
            status={techSupport.statusChat}
            handleChange={handleChangeStatus}
            handleScroll={handleScroll}
          >
            <>
              {techSupport.chats?.chats.map((item, idx) => (
                <ChatItemTechSupport key={`1_${idx}`} chat={item} />
              ))}
            </>
          </AllChats>
        )
      case EMainView.textSettings:
        if (users.user?.role === Roles.ADMIN || users.user?.role === Roles.CHIEF) {
          return <ListGroupTemplates groups={templatesTechSupport.groups} setCurrentGroup={handleSetGroup} />
        }
        return <Box />
      case EMainView.settings:
        return <Settings />
      case EMainView.search:
        return (
          <Search
            statuses={statuses}
            types={[
              { value: '1', label: 'Онлайн' },
              { value: '2', label: 'Офлайн' },
              { value: '4', label: 'Ініційований' },
            ]}
            products={subProducts}
            searchChats={handleSearch}
          />
        )
      default:
        return <Box />
    }
  }

  const handlerChange = (num: number) => {
    if (num === 0) {
      navigate(ROUTES.routeChange(''))
    }
    dispatch(setMainView(num))
    switch (num) {
      case EMainView.chat:
        return navigate(ROUTES.chatsTechSupport)
      case EMainView.textSettings:
        return navigate(ROUTES.templatesTechSupport)
      case EMainView.settings:
        return navigate(ROUTES.settingsTech)
      case EMainView.search:
        return navigate(ROUTES.searchTechSupport)
      default:
        return <Box />
    }
  }

  return (
    <React.Fragment>
      <Box display={'flex'}>
        <Box>
          <Box
            sx={{ width: '25vw', maxWidth: 500, minWidth: 300, height: InnerHeight, background: 'white' }}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            boxShadow={'4px 2px 5px rgba(0,0,0,0.1)'}
          >
            {renderWorkspaceLeft()}
            <BottomNavigation
              showLabels
              value={mainView}
              sx={{ borderTop: '1px solid #dcdcdc', background: '#f5f5f5' }}
              onChange={(e, num) => handlerChange(num)}
            >
              <BottomNavigationAction label='' icon={<ChatBubbleIcon />} />
              {users.user?.role === Roles.ADMIN || users.user?.role === Roles.CHIEF ? (
                <BottomNavigationAction label='' icon={<DescriptionOutlinedIcon />} />
              ) : null}
              <BottomNavigationAction label='' icon={<SettingsOutlinedIcon />} />
              <BottomNavigationAction label='' icon={<SearchOutlinedIcon />} />
            </BottomNavigation>
          </Box>
        </Box>
        <Box
          flex={1}
          display={'flex'}
          justifyContent={'center'}
          alignItems={techSupport.currentChat ? 'start' : 'center'}
          maxHeight={InnerHeight}
        >
          <Box display={'flex'} flexDirection={'column'} flex={1} height={InnerHeight} position={'relative'}>
            {children}
          </Box>
        </Box>
      </Box>
    </React.Fragment>
  )
}
