import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { getStatCountSession, getStatDynamics, getStatRaiting, getStatSystem, getStatSystemAndExpert } from './actions'

const initialState: IStore['statOnline'] = {
  statSystem: null,
  statDataRating: null,
  statSystemAndExpert: null,
  session: null,
  dynamics: null,
}

export const slice = createSlice({
  name: 'statOnline',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatSystem.fulfilled, (state, action) => {
      state.statSystem = action.payload
    })
    builder.addCase(getStatSystemAndExpert.fulfilled, (state, action) => {
      state.statSystemAndExpert = action.payload
    })
    builder.addCase(getStatRaiting.fulfilled, (state, action) => {
      state.statDataRating = action.payload
    })
    builder.addCase(getStatCountSession.fulfilled, (state, action) => {
      state.session = action.payload
    })
    builder.addCase(getStatDynamics.fulfilled, (state, action) => {
      state.dynamics = action.payload
    })
  },
})

export const StatOnlineActions = slice.actions

export default slice.reducer
