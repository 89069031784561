import React from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import { MailingForm } from '../../components/MailingForm'
import { getMailing, updateMailing } from '../../store/mailing/actions'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'

export const EditMailing: React.FC = () => {
  const { mailing } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  React.useEffect(() => {
    if (id) {
      dispatch(getMailing(Number(id)))
    }
  }, [])

  if (!mailing.current) {
    return (
      <Stack alignItems={'center'} justifyContent={'space-around'} height={'calc(100% - 69px)'}>
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <MailingForm
      title='Редагувати повідомлення'
      data={mailing.current}
      handleSubmit={(values, callback) => {
        if (mailing.current) {
          dispatch(updateMailing({ id: mailing.current.absnum as number, data: values })).then((res) => {
            if (res.meta.requestStatus !== 'fulfilled') {
              const { error } = res as { error: { message: string } }
              callback(error.message)
            } else {
              navigate(ROUTES.mailing)
            }
          })
        }
      }}
    />
  )
}
