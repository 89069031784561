import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ConfigForm } from '../../components/Config/ConfigForm'
import { useAppDispatch } from '../../store'
import { createConfiguration } from '../../store/configurations/actions'
import { ROUTES } from '../../utils/routes'

export const CreateConfig: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <ConfigForm
      title={'Створити конфігурацію'}
      handleSubmit={(values, callback) => {
        dispatch(createConfiguration(values)).then((res) => {
          if (res.meta.requestStatus !== 'fulfilled') {
            callback(false)
          } else {
            navigate(ROUTES.configurators)
          }
        })
      }}
    />
  )
}
