import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { WrittenStatus } from '../../utils/consts'
import { IActionHistory, IChatsWrittenState, IMessageWritten, ISearchMessageWritten } from './types'

export const getWittenMessages = createAsyncThunk<IChatsWrittenState['data'], ISearchMessageWritten>(
  'chatsWritten/getWittenMessages',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-written?${encodeURI(query)}` : '/admin-chat-written',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getWittenMessage = createAsyncThunk<IChatsWrittenState['message'], { id: number; partId: number }>(
  'chatsWritten/getWittenMessage',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-chat-written/message/${payload.id}-${payload.partId}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const deleteWittenMessage = createAsyncThunk<IChatsWrittenState['message'], { id: number; partId: number }>(
  'chatsWritten/deleteWittenMessage',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'delete',
        data: {},
        path: `/admin-chat-written/message/${payload.id}-${payload.partId}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const uploadFile = createAsyncThunk<{ link: string }, FormData>(
  'chatsWritten/uploadFile',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        isFormData: true,
        path: `/admin-chat-written/upload`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const deleteFile = createAsyncThunk<IChatsWrittenState['message'], { id: number; partId: number }>(
  'chatsWritten/deleteFile',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'delete',
        data: {},
        path: `/admin-chat-written/upload/${payload.id}-${payload.partId}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const changeExpert = createAsyncThunk<
  IMessageWritten,
  { message_id: number; part_id: number; expert_id: number }
>('chatsWritten/changeExpert', async (payload, thunkAPI) => {
  try {
    const data = payload

    return callApi({
      method: 'post',
      data,
      path: '/admin-chat-written/expert',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

interface ISaveAnswer {
  message_id: number
  part_id: number
  variant_id: number
  is_public: number
  body: string
  comment: string
}
export const saveAnswer = createAsyncThunk<IMessageWritten, ISaveAnswer>(
  'chatsWritten/saveAnswer',
  async (payload, thunkAPI) => {
    try {
      const data = payload

      return callApi({
        method: 'post',
        data,
        path: '/admin-chat-written/answer',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface IRefusalAnswer {
  message_id: number
  part_id: number
  refusal_id: number
  body: string
}
export const refusalAnswer = createAsyncThunk<IMessageWritten, IRefusalAnswer>(
  'chatsWritten/refusalAnswer',
  async (payload, thunkAPI) => {
    try {
      const data = payload

      return callApi({
        method: 'post',
        data,
        path: '/admin-chat-written/refusal',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface IClarifylAnswer {
  message_id: number
  part_id: number
  body: string
}
export const clarifyQuestion = createAsyncThunk<IMessageWritten, IClarifylAnswer>(
  'chatsWritten/clarifyQuestion',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/admin-chat-written/clarify',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface ITopicMessage {
  message_id: number
  part_id: number
  topic: string
}
export const topicMessage = createAsyncThunk<IMessageWritten, ITopicMessage>(
  'chatsWritten/topicMessage',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/admin-chat-written/topic',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface IEditQuestion {
  message_id: number
  part_id: number
  question: string
  is_added: number
}
export const editQuestion = createAsyncThunk<IMessageWritten, IEditQuestion>(
  'chatsWritten/editQuestion',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'patch',
        data,
        path: '/admin-chat-written/question',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface ICreateQuestion {
  chat_id: number
  question: string
}
export const createQuestion = createAsyncThunk<IMessageWritten, ICreateQuestion>(
  'chatsWritten/createQuestion',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/admin-chat-written/question',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface ISplitAnswer {
  message_id: number
  part_id: number
  data: {
    questions: { question: string }[]
  }
}
export const splitMessage = createAsyncThunk<IMessageWritten, ISplitAnswer>(
  'chatsWritten/splitMessage',
  async (payload, thunkAPI) => {
    try {
      const { data, part_id, message_id } = payload
      return callApi({
        method: 'post',
        data,
        path: `/admin-chat-written/split/${message_id}-${part_id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface IChangeStatus {
  message_id: number
  part_id: number
  status: WrittenStatus.REPID | WrittenStatus.NEW_CLARIFIED
}
export const changeStatus = createAsyncThunk<IMessageWritten, IChangeStatus>(
  'chatsWritten/changeStatus',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/admin-chat-written/status',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const publicAnswer = createAsyncThunk<IMessageWritten, { id: number; part_id: number }>(
  'chatsWritten/publicAnswer',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-chat-written/public/${payload.id}-${payload.part_id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getTemplate = createAsyncThunk<Buffer, { id: number; part_id: number }>(
  'chatsWritten/getTemplate',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-chat-written/template/${payload.id}-${payload.part_id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getMiniChat = createAsyncThunk<
  IChatsWrittenState['miniChat'],
  { message_id: number; part_id: number; page?: number }
>('chatsWritten/getMiniChat', async (payload, thunkAPI) => {
  try {
    return callApi({
      method: 'get',
      path: payload.page
        ? `/admin-chat-written/mini-chat/${payload.message_id}-${payload.part_id}?page=${payload.page}`
        : `/admin-chat-written/mini-chat/${payload.message_id}-${payload.part_id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const getMiniChatMessages = createAsyncThunk<
  IChatsWrittenState['miniChat'],
  { message_id: number; part_id: number; page: number }
>('chatsWritten/getMiniChatMessages', async (payload, thunkAPI) => {
  try {
    return callApi({
      method: 'get',
      path: `/admin-chat-written/mini-chat/${payload.message_id}-${payload.part_id}?page=${payload.page}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const createMessageMiniChat = createAsyncThunk<
  IMessageWritten,
  { chat_id: number; message_id: number; part_id: number; message: string }
>('chatsWritten/createMessageMiniChat', async (payload, thunkAPI) => {
  try {
    const data = payload

    return callApi({
      method: 'post',
      data,
      path: '/admin-chat-written/mini-chat',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const readMessagesMiniChat = createAsyncThunk<
  IChatsWrittenState['miniChat'],
  { message_id: number; part_id: number }
>('chatsWritten/readMessagesMiniChat', async (payload, thunkAPI) => {
  try {
    return callApi({
      method: 'get',
      path: `/admin-chat-written/read-mini-chat/${payload.message_id}-${payload.part_id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const getActionHistory = createAsyncThunk<IActionHistory[], { message_id: number; part_id: number }>(
  'chatsWritten/getActionHistory',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-chat-written/history/${payload.message_id}-${payload.part_id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatToTechExpertR {
  message_id: number
  part_id: number
  data: {
    message: string
  }
}
export const passChatToTechSupoort = createAsyncThunk<IMessageWritten, TPassChatToTechExpertR>(
  'chatsWritten/passChatToTechSupoort',
  async (payload, thunkAPI) => {
    try {
      const data = payload.data
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-written/pass/tech-support/${payload.message_id}-${payload.part_id}`,
      })) as IMessageWritten
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
