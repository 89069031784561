export const buildQuery = (data?: { [x: string]: any } | null) => {
  if (!data) return ''
  return Object.keys(data)
    .map((key) => {
      if (data[key]) {
        return `${key}=${data[key]}`
      }
      return null
    })
    .filter((it) => !!it)
    .join('&')
}
