import React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import { useOutsideClick } from '../hooks/useOutsideClick'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'

const StyleSelect = {
  minWidth: '300px',
  maxHeight: '300px',
  overflowX: 'hidden',
  position: 'absolute',
  zIndex: 5,
  boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
}

interface IOption {
  value: string
  label: string
}

type PropsType = {
  label?: string
  value: string[]
  options?: IOption[]
  style?: React.CSSProperties | undefined
  className?: string
  onChange: (value: string[]) => void
}

export const SelectCheckboxSearch: React.FC<PropsType> = React.memo(
  ({ style, className, label, value, options, onChange }) => {
    const [checkedAll, setCheckedAll] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [isSearch, setIsSearch] = React.useState(false)
    const [isFocus, setIsFocus] = React.useState(false)
    const [items, setItems] = React.useState<IOption[] | undefined>()

    const handleLeaveFocus = React.useCallback(() => {
      setIsFocus(false)
      setIsSearch(false)
      setSearch('')
    }, [])

    const handleSetOptions = React.useCallback(() => {
      if (options) {
        setItems(options)
      }
    }, [options])

    const { ref } = useOutsideClick(() => {
      handleLeaveFocus()
    })

    React.useEffect(handleSetOptions, [handleSetOptions])

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchText = event.target.value
      setSearch(searchText)

      if (options) {
        const items = options
          .filter((item) => item.label.toLowerCase().includes(searchText.toLowerCase()))
          .sort((a, b) => (a.label > b.label ? 1 : -1))
        setItems(items)
      }
    }

    const handleClick = () => {
      setIsFocus(true)
      handleSetOptions()
    }

    const handleClickSearch = () => {
      setIsSearch(!isSearch)
      setSearch('')
      handleSetOptions()
    }

    const handleSelect = (element: IOption) => () => {
      const data = value.includes(element.label)
        ? value.filter((item) => item !== element.label)
        : [...value, element.label]

      const isAll = data.find((item) => item === 'Всі')
      if ((isAll && !checkedAll && options) || (!checkedAll && options && data.length === options?.length)) {
        setCheckedAll(true)
        const newData = ['Всі']
        options.map((item) => newData.push(item.label))
        return onChange(newData)
      }

      if (!isAll && checkedAll) {
        setCheckedAll(false)
        return onChange([])
      }

      if (checkedAll && options && data.length - 1 < options?.length) {
        setCheckedAll(false)
        return onChange(data.filter((item) => item !== 'Всі'))
      }

      onChange(data)
    }

    return (
      <Box style={style} className={className} ref={ref} sx={{ position: 'relative', cursor: 'pointer' }}>
        <TextField
          fullWidth
          sx={{ '.MuiInputBase-root': { paddingRight: '7px' } }}
          size={'small'}
          type='text'
          autoComplete='off'
          label={label}
          value={value.join(', ')}
          onClick={handleClick}
          InputProps={{
            readOnly: true,
            endAdornment: isFocus ? <ArrowDropUpIcon color='action' /> : <ArrowDropDownIcon color='action' />,
          }}
        />
        {isFocus ? (
          <Paper sx={StyleSelect}>
            {isSearch ? (
              <TextField
                fullWidth
                variant='filled'
                size={'small'}
                type='text'
                autoComplete='off'
                label={'Пошук'}
                value={search}
                autoFocus
                onChange={handleSearch}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleClickSearch}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            ) : (
              <MenuItem value={'Всі'}>
                <Checkbox checked={value.includes('Всі')} onClick={handleSelect({ label: 'Всі', value: 'Всі' })} />
                <ListItemText
                  sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line', textAlign: 'left' }}
                  primary={'Всі'}
                />
                <IconButton onClick={handleClickSearch}>
                  <SearchIcon />
                </IconButton>
              </MenuItem>
            )}
            {items?.map((item, index) => (
              <MenuItem key={index} value={item.label} onClick={handleSelect(item)}>
                <Checkbox checked={value.includes(item.label)} />
                <ListItemText
                  sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line', textAlign: 'left' }}
                  primary={item.label}
                />
              </MenuItem>
            ))}
          </Paper>
        ) : null}
      </Box>
    )
  }
)
