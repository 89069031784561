import React from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import { getEmployee, updateEmployee } from '../../store/employees/actions'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { EmployeeForm } from '../../components/EmployeeForm'

export const EditEmployee: React.FC = () => {
  const { employees } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  React.useEffect(() => {
    if (id) {
      dispatch(getEmployee(Number(id)))
    }
  }, [])

  if (!employees.current) {
    return (
      <Stack alignItems={'center'} justifyContent={'space-around'} height={'calc(100% - 69px)'}>
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <EmployeeForm
      title='Редагувати дані співробітника'
      data={employees.current}
      handleSubmit={(values, callback) => {
        if (employees.current) {
          dispatch(updateEmployee({ id: employees.current.id, data: values })).then((res) => {
            if (res.meta.requestStatus !== 'fulfilled') {
              const { error } = res as { error: { message: string } }
              callback(false, error?.message)
            } else {
              navigate(ROUTES.employees)
            }
          })
        }
      }}
    />
  )
}
