import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GroupForm } from '../../../components/Templates/GroupForm'
import { useAppDispatch } from '../../../store'
import { createGroup } from '../../../store/templates/actions'
import { ROUTES } from '../../../utils/routes'

export const OnlineCreateGroup: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <GroupForm
      title={'Створити групу'}
      handleSubmit={(values, callback) => {
        dispatch(createGroup(values)).then((res) => {
          if (res.meta.requestStatus !== 'fulfilled') {
            callback(false)
          } else {
            navigate(ROUTES.templates)
          }
        })
      }}
    />
  )
}
