import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { SearchWorkspace } from '../../components/SearchWorkspace'
import { useAppDispatch } from '../../store'
import { searchChats } from '../../store/searchChatsNews/actions'
import { STATUSES_CHAT_SALES } from '../../utils/consts'
import { ROUTES } from '../../utils/routes'

export const SearchChatNews: React.FC = () => {
  const { searchChatsNews } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!searchChatsNews.data) {
      dispatch(searchChats({}))
    }
  }, [searchChatsNews.data])

  const handleChangePage = (page: number) => {
    if (!searchChatsNews.data) return null
    if (searchChatsNews.data.pages >= page) {
      dispatch(searchChats({ page }))
    }
  }

  const handleGetDialog = (dialogId: number) => {
    navigate(ROUTES.changeSearch(dialogId))
  }

  return (
    <SearchWorkspace
      types={STATUSES_CHAT_SALES}
      data={searchChatsNews.data}
      changePage={handleChangePage}
      getDialog={handleGetDialog}
    />
  )
}
