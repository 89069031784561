import React from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { passChat, passChatToOnline, passChatToWritten, uploadFile } from '../../store/techSupport/actions'
import List from '@mui/material/List'
import { searchEmployees } from '../../store/employees/actions'
import { CHANNELS_ID, StatusWork } from '../../utils/consts'
import { ConfirmModal } from '../modals/ConfirmModal'
import { ChooseEmployee } from './components/ChooseEmployee'
import { MessageSender } from './components/MessageSender'
import { parseLink } from '../../utils/parseLink'

export const RightTechSupport: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | boolean>(false)
  const [loading, setLoading] = React.useState(false)
  const [is_approved, setApproved] = React.useState<{ is_online: number; is_written: number }>({
    is_online: 0,
    is_written: 0,
  })
  const [passEmpl, setPassEmpl] = React.useState<null | { id: number; fio: string }>(null)

  const { techSupport, configurations, employees, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(searchEmployees({ channels: String(CHANNELS_ID.TECH_SUPPORT), status: StatusWork.ONLINE }))
  }, [employees.data])

  React.useEffect(() => {
    if (configurations.all && techSupport.currentChat && !techSupport.currentChat.is_demo) {
      const config = configurations.all.find((item) => item.absnum === techSupport.currentChat?.site_id)
      if (config) {
        setApproved({ is_online: config.approved_online, is_written: config.approved_written })
      }
    }
  }, [configurations.all, techSupport.currentChat])

  const handleSaveFile = (file: File, callback?: (succes: boolean, data?: any) => void) => {
    if (techSupport.currentChat) {
      const fd = new FormData()
      fd.append('file', file)
      fd.append('chat_id', String(techSupport.currentChat.absnum))
      fd.append('file_name', file.name)
      dispatch(uploadFile(fd)).then((res) => {
        const success = res.meta.requestStatus === 'fulfilled'
        if (callback) callback(success, res)
      })
    }
  }

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handlePassChat = (callback: (error: string) => void) => {
    if (!techSupport.currentChat || loading || !passEmpl) return null
    setLoading(true)
    dispatch(
      passChat({
        chat_id: techSupport.currentChat.absnum,
        user_id: passEmpl.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus !== 'fulfilled') {
        callback(res.payload as string)
      } else {
        dispatch(setRightSidebar(false))
      }
      setLoading(false)
    })
  }

  const handlePassChatToOnline = (value: string, callback: () => void) => {
    if (!techSupport.currentChat || !value) return null
    dispatch(
      passChatToOnline({
        chat_id: techSupport.currentChat.absnum,
        product_id: techSupport.currentChat.product_id,
        message: value,
      })
    ).finally(() => {
      setExpanded(false)
      dispatch(setRightSidebar(false))
      callback()
    })
  }

  const handlePassChatToWritten = (value: string, callback: () => void) => {
    if (!techSupport.currentChat || !value) return null
    dispatch(
      passChatToWritten({
        chat_id: techSupport.currentChat.absnum,
        product_id: techSupport.currentChat.product_id,
        message: value,
      })
    ).finally(() => {
      setExpanded(false)
      dispatch(setRightSidebar(false))
      callback()
    })
  }

  if (!techSupport.currentChat) return null

  const text = techSupport.currentChat.chat?.messages.filter((item) => !!item.client_id).pop()?.message || ''
  const message = parseLink(text)

  return (
    <Box sx={{ width: 350, paddingTop: '70px' }} role='presentation'>
      {techSupport.currentChat.product_id ? (
        <>
          {' '}
          {is_approved.is_online ? (
            <MessageSender
              title='Онлайн'
              initValue={message}
              expanded={expanded === '1'}
              onChange={handleChange('1')}
              onSend={handlePassChatToOnline}
              saveFile={handleSaveFile}
            />
          ) : null}
          {is_approved.is_written ? (
            <MessageSender
              title='Письмово'
              initValue={message}
              expanded={expanded === '2'}
              onChange={handleChange('2')}
              onSend={handlePassChatToWritten}
              saveFile={handleSaveFile}
            />
          ) : null}
        </>
      ) : null}
      <List>
        {employees.searchData?.employees?.map((item) => {
          if (item.id === users.user?.id) return null
          return (
            <ChooseEmployee
              key={item.id}
              status={item.status || 0}
              fio={item.fio}
              onClick={() => setPassEmpl({ id: item.id, fio: item.fio })}
            />
          )
        })}
      </List>
      <ConfirmModal
        title={'Передати чат до ' + passEmpl?.fio || ''}
        open={!!passEmpl}
        delHandler={handlePassChat}
        closeHandler={() => setPassEmpl(null)}
      />
    </Box>
  )
}
