import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { Roles } from '../../utils/consts'

interface Props {
  element?: JSX.Element
  children?: JSX.Element
}

export const AdminCheck: React.FC<Props> = ({ element, children }) => {
  const { users } = useSelector((store: IStore) => store)

  if (users.user?.role !== Roles.ADMIN) return null

  if (element) return element
  if (children) return children

  return null
}
