import { Badge } from '@mui/material'
import styled from 'styled-components'
import { UserStatus } from '../../utils/consts'

function getColor(status: number): string {
  if (UserStatus[status].color) {
    return UserStatus[status].color
  }
  return 'red'
}

export const StyledBadge = styled(Badge)<{ status: number }>(({ status }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: getColor(status),
    color: getColor(status),
    // boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))
