export const ROUTES = {
  onlineHelp: '/',
  chats: '/chats',
  chatsSales: '/chats-sales',
  chatsUnauthorized: '/chats-unauthorized',
  chatsTechSupport: '/chats-tech-support',
  chatsNews: '/chats-news',
  statTechSupport: '/stat-tech-support',
  statUnauthorized: '/stat-unauthorized',
  statSales: '/stat-sales',
  statNews: '/stat-news',
  statOnline: '/stat-online',
  statWritten: '/stat-written',
  templates: '/templates',
  templatesSales: '/templates-sales',
  templatesUnauthorized: '/templates-unauthorized',
  templatesTechSupport: '/templates-tech-support',
  templatesNews: '/templates-news',
  products: 'products',
  notification: 'notification',
  logs: 'logs',
  appeal: 'appeal',
  configurators: '/configurators',
  operators: 'operators',
  employees: '/employees',
  settings: '/settings',
  settingsTech: '/settings-tech-support',
  settingsSales: '/settings-sales',
  settingsUnauthorized: '/settings-unauthorized',
  settingsNews: '/settings-news',
  search: '/search',
  searchSales: '/search-sales',
  searchTechSupport: '/search-tech-support',
  searchNews: '/search-news',
  searchUnauthorized: '/search-unauthorized',
  letter: 'letter',
  login: '/login',
  mailing: '/mailing',
  logChats: '/log-chats',
  routeChange: function (type: string) {
    const path = window.location.pathname.split('/')[1]
    if (!path) return '/'
    return '/' + path + '/' + type
  },
  employeeChange: function (type: string | number) {
    return this.employees + '/' + type
  },
  mailingChange: function (type: string | number) {
    return this.mailing + '/' + type
  },
  configuratorChange: function (type: string) {
    return this.configurators + '/' + type
  },
  onlineHelpGroup: function (id: number | string) {
    const path = window.location.pathname.split('/')[1]
    return '/' + path + '/group/' + id
  },
  onlineHelpCreateGroup: function (id: number | string) {
    const path = window.location.pathname.split('/')[1]
    return '/' + path + '/group/' + id
  },
  onlineHelpEditGroup: function (id: number | string) {
    const path = window.location.pathname.split('/')[1]
    return '/' + path + '/group/edit/' + id
  },
  onlineHelpTemplate: function (id: number | string) {
    const path = window.location.pathname.split('/')[1]
    return '/' + path + '/template/' + id
  },
  changeSearch: function (id: number | string) {
    const path = window.location.pathname.split('/')[1]
    return '/' + path + '/' + id
  },
  changeAppeal: function (id: number | string) {
    const path = window.location.pathname.split('/')[1]
    return '/' + path + '/' + this.appeal + '/' + id
  },
}
