import React from 'react'
import Box from '@mui/material/Box'
import MenuItem from '@mui/material/MenuItem'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import TextField from '@mui/material/TextField'
import SearchIcon from '@mui/icons-material/Search'
import { useOutsideClick } from '../hooks/useOutsideClick'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import ListItemText from '@mui/material/ListItemText'

const StyleSelect = {
  minWidth: '300px',
  maxHeight: '300px',
  overflowX: 'hidden',
  position: 'absolute',
  zIndex: 5,
  boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
}

interface IOption {
  value: string
  label: string
}

type PropsType = {
  label?: string
  value?: string
  required?: boolean
  options?: IOption[]
  error?: boolean
  style?: React.CSSProperties | undefined
  className?: string
  onChange: (value: string) => void
}

export const SelectSearch: React.FC<PropsType> = React.memo(
  ({ style, className, label, required, error, value, options, onChange }) => {
    const [search, setSearch] = React.useState('')
    const [selected, setSelected] = React.useState('')
    const [items, setItems] = React.useState<IOption[] | undefined>()
    const [isFocus, setIsFocus] = React.useState(false)
    const [isSearch, setIsSearch] = React.useState(false)

    const handleLeaveFocus = React.useCallback(() => {
      setIsFocus(false)
      setIsSearch(false)
      setSearch('')
    }, [])

    const handleSetOptions = React.useCallback(() => {
      if (options) {
        setItems(options)
      }
    }, [options])

    const handleSetSelected = React.useCallback(() => {
      if (options && value) {
        const item = options.find((it) => it.value === value)
        if (item) {
          setSelected(item.label)
        }
      } else {
        setSelected('')
      }
    }, [options, value])

    const { ref } = useOutsideClick(() => {
      handleLeaveFocus()
      handleSetSelected()
    })

    React.useEffect(handleSetOptions, [handleSetOptions])

    React.useEffect(handleSetSelected, [options, value, handleSetSelected])

    const handleClick = () => {
      setIsFocus(true)
      handleSetOptions()
    }

    const handleClickSearch = () => {
      setIsSearch(!isSearch)
      setSearch('')
      handleSetOptions()
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const searchText = event.target.value
      setSearch(searchText)

      if (options) {
        const items = options
          .filter((item) => item.label.toLowerCase().includes(searchText.toLowerCase()))
          .sort((a, b) => (a.label > b.label ? 1 : -1))
        setItems(items)
      }
    }

    const handleSelect = (element: IOption) => () => {
      onChange(element.value)
      handleLeaveFocus()
    }

    return (
      <Box style={style} className={className} ref={ref} sx={{ position: 'relative', cursor: 'pointer' }}>
        <TextField
          fullWidth
          sx={{ '.MuiInputBase-root': { paddingRight: '7px' } }}
          size={'small'}
          type='text'
          autoComplete='off'
          required={required}
          error={error}
          label={label}
          value={selected}
          onClick={handleClick}
          InputProps={{
            readOnly: true,
            endAdornment: isFocus ? <ArrowDropUpIcon color='action' /> : <ArrowDropDownIcon color='action' />,
          }}
        />
        {isFocus ? (
          <Paper sx={StyleSelect}>
            {isSearch ? (
              <TextField
                fullWidth
                variant='filled'
                size={'small'}
                type='text'
                autoComplete='off'
                label={'Пошук'}
                value={search}
                autoFocus
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={handleClickSearch}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            ) : (
              <MenuItem sx={{ textAlign: 'left' }}>
                <ListItemText primary={'Усі'} onClick={handleSelect({ label: '', value: '' })} />
                <IconButton onClick={handleClickSearch}>
                  <SearchIcon />
                </IconButton>
              </MenuItem>
            )}
            {items?.map((item) => (
              <MenuItem
                sx={{ wordBreak: 'break-word', whiteSpace: 'pre-line', textAlign: 'left' }}
                key={item.value}
                value={item.value}
                onClick={handleSelect(item)}
              >
                {item.label}
              </MenuItem>
            ))}
          </Paper>
        ) : null}
      </Box>
    )
  }
)
