import React from 'react'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { Button, Stack } from '@mui/material'
import { parseLink } from '../../utils/parseLink'

interface Props {
  name: string
  time: string
  message: string
  hasCreateBtn?: boolean
  createQuestion?: () => void
}

export const MiniChatMessage: React.FC<Props> = ({ name, time, message, hasCreateBtn, createQuestion }) => {
  return (
    <Stack p={2} direction={'row'} spacing={2} width={'100%'} alignItems={'flex-start'}>
      <Stack flex={1}>
        <Typography>
          <Box component={'b'}>{name}</Box>{' '}
          <Box fontSize={12} component={'span'}>
            {time}
          </Box>
        </Typography>
        <Typography mt={1} fontSize={13} dangerouslySetInnerHTML={{ __html: parseLink(message) }} />
      </Stack>
      {hasCreateBtn && (
        <Button size={'small'} onClick={createQuestion}>
          Створити питання
        </Button>
      )}
    </Stack>
  )
}
