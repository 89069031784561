import React from 'react'
import Box from '@mui/material/Box'
import { Title } from '../Title'
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextareaAutosize } from '@mui/material'
import Typography from '@mui/material/Typography'
import styled from 'styled-components'
import { ERROR_MESSAGE } from '../../utils/consts'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { AlertMessage } from '../AlertMessage'
import { IGroupTemplate, ITemplate } from '../../store/templates/types'
import { IProduct } from '../../store/products/types'

interface Props {
  title: string
  groupId: number
  data?: ITemplate | null
  products?: IProduct[] | null
  createTemplate: (
    values: { group_id?: number; product_id: number; message: string },
    callback: (success: boolean) => void
  ) => void
}

export const TamplateForm: React.FC<Props> = ({ title, data, groupId, products, createTemplate }) => {
  const [productId, setProductId] = React.useState(data ? String(data.product_id) : '')
  const [message, setMessage] = React.useState(data?.message)
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')

  const navigate = useNavigate()

  const createHandler = () => {
    if (loading) return null
    if ((products && !productId) || !message) return setError('Заповніть поля')

    setLoading(true)
    setError('')
    createTemplate(
      {
        group_id: groupId,
        product_id: Number(productId),
        message,
      },
      (success) => {
        setLoading(false)
        if (success) {
          navigate(ROUTES.onlineHelpGroup(groupId))
        } else {
          setError(ERROR_MESSAGE)
        }
      }
    )
  }

  const cancelHandler = () => {
    navigate(ROUTES.onlineHelpGroup(groupId))
  }

  return (
    <Paper sx={{ p: 2, m: 2, height: 'calc(100vh - 102px)' }}>
      <Box py={2} maxWidth={'900px'}>
        <Title title={title} color={'default'} mb={2} />
        <Stack direction={'column'} alignItems={'flex-start'}>
          {products ? (
            <FormControl fullWidth>
              <InputLabel size={'small'} id='simple-select'>
                Продукт
              </InputLabel>
              <Select
                size={'small'}
                labelId='simple-select'
                id='simple-select'
                label='Продукт'
                value={productId}
                onChange={(e) => {
                  setProductId(e.target.value)
                }}
              >
                <MenuItem value={''}>Усі</MenuItem>
                {products.map((item) => (
                  <MenuItem key={item.absnum} value={item.absnum}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          <Box mt={2} width={'100%'}>
            <Typography mb={0.5} sx={{ fontSize: 14, color: '#494949' }}>
              Текст шаблону повідомлення
            </Typography>
            <TextAreaS
              aria-label='empty textarea'
              value={message}
              placeholder='Введіть текст шаблону повідомлення'
              onChange={(event) => setMessage(event.target.value)}
            />
          </Box>
        </Stack>
        <Box mt={2}>
          <Box mt={2} component={'form'} display={'flex'} autoComplete='off' justifyContent={'flex-start'}>
            <Button variant={'contained'} onClick={createHandler}>
              Створити
            </Button>

            <Button variant={'outlined'} sx={{ mx: 2 }} onClick={cancelHandler}>
              Відміна
            </Button>
          </Box>
        </Box>
        {error ? <AlertMessage type='error' message={error} /> : null}
      </Box>
    </Paper>
  )
}

const TextAreaS = styled(TextareaAutosize)`
  width: 100%;
  outline: 0;
  min-height: 90px;
  padding: 5px 10px;
  border-radius: 0;
  border: 1px solid #d3d3d3;
`
