import React from 'react'
import { FormikValues, useField } from 'formik'
import TextField from '@mui/material/TextField'
import { IField, onValidate } from './InputFormik'
import Button from '@mui/material/Button'
import { Box, CircularProgress } from '@mui/material'
import { callApi } from '../utils/callApi'
import { AlertMessage } from '../components/AlertMessage'

interface Props extends IField {
  apiPath: string
  checkFile?: (file: File) => Promise<boolean>
}

export const UploadFileFormik = React.memo(({ disabled, label, name, required, style, apiPath, checkFile }: Props) => {
  const [loading, setLoading] = React.useState(false)
  const [field, meta, { setValue }] = useField({
    name,
    validate: (value: FormikValues) => onValidate(name, value, { required }),
  })

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files ? event.target.files[0] : null
      if (file) {
        if (checkFile) {
          const result = await checkFile(file)
          if (!result) return null
        }

        const fd = new FormData()
        fd.append('file', file)
        fd.append('file_name', file.name)

        setLoading(true)

        callApi({
          method: 'post',
          data: fd,
          isFormData: true,
          path: apiPath,
        })
          .then((res) => {
            const { link } = res as { link: string }
            setValue(link)
          })
          .finally(() => setLoading(false))
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box display={'flex'} flexWrap={'wrap'}>
      <Box display={'flex'} position={'relative'} width={'100%'}>
        <TextField
          fullWidth
          style={style}
          disabled={disabled}
          label={label || ''}
          size='small'
          variant='outlined'
          error={meta.touched && !!meta.error}
          {...field}
        />
        <Box position={'absolute'} right={'5px'} bottom={'5px'}>
          <Button size='small' variant='contained' component='label'>
            {loading ? <CircularProgress color='inherit' size={22} /> : 'Завантажити'}
            <input hidden accept='image/*' multiple type='file' onChange={handleChange} />
          </Button>
        </Box>
      </Box>
      {meta.error ? <AlertMessage type='error' message={meta.error} /> : null}
    </Box>
  )
})
