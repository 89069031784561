import React from 'react'
import Paper from '@mui/material/Paper'
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { Title } from '../Title'
import { useSearchProducts } from '../../hooks/useSearchProducts'
import { Formik, Form } from 'formik'
import { InputFormik } from '../../ui/InputFormik'
import { IConfiguration, TCreateConfig } from '../../store/configurations/types'
import { AlertMessage } from '../AlertMessage'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { SelectFormik } from '../../ui/SelectFormik'
import { TypeBtn } from '../../utils/consts'

interface Props {
  title: string
  config?: IConfiguration | null
  handleSubmit: (values: TCreateConfig, callback: (success: boolean) => void) => void
}

export const ConfigForm: React.FC<Props> = ({ title, config, handleSubmit }) => {
  const [errors, setErrors] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const { products } = useSelector((store: IStore) => store)
  const { mainProducts, addProduct, removeSubProduct, selectedMainProduct, setMainProducts } = useSearchProducts()
  const navigate = useNavigate()

  React.useEffect(() => {
    if (config?.products) {
      setMainProducts(config?.products)
    }
  }, [config])

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: config?.name || '',
          url: config?.url || '',
          approved: config?.approved ? true : false,
          text_unauthorized: config?.text_unauthorized || '',
          btn_name_authorized: config?.btn_name_authorized || '',
          link_rules: config?.link_rules || '',
          tarriff_message: config?.tarriff_message || '',
          type_btn: config?.type_btn || 1,

          start_message: config?.start_message || '',
          first_message: config?.first_message || '',
          wait_message: config?.wait_message || '',
          goodbye_message: config?.goodbye_message || '',
          rating_message: config?.rating_message || '',
          offline_message: config?.offline_message || '',
          technical_delay_message: config?.technical_delay_message || '',
          holidays: config?.holidays || '',
          approved_online: config?.approved_online ? true : false,

          answer_tech: config?.answer_tech || '',
          answer_payment: config?.answer_payment || '',
          first_notification: config?.first_notification || '',
          second_notification: config?.second_notification || '',
          third_notification: config?.third_notification || '',
          first_notif_minute: config?.first_notif_minute || '',
          second_notif_minute: config?.second_notif_minute || '',
          third_notif_minute: config?.third_notif_minute || '',
          sales_start_message: config?.sales_start_message || '',
          sales_first_message: config?.sales_first_message || '',
          sales_wait_message: config?.sales_wait_message || '',
          sales_goodbye_message: config?.sales_goodbye_message || '',
          sales_rating_message: config?.sales_rating_message || '',
          sales_offline_message: config?.sales_offline_message || '',
          sales_technical_delay_message: config?.sales_technical_delay_message || '',
          approved_sales: config?.approved_sales ? true : false,

          unauthorized_first_notification: config?.unauthorized_first_notification || '',
          unauthorized_second_notification: config?.unauthorized_second_notification || '',
          unauthorized_third_notification: config?.unauthorized_third_notification || '',
          unauthorized_first_notif_minute: config?.unauthorized_first_notif_minute || '',
          unauthorized_second_notif_minute: config?.unauthorized_second_notif_minute || '',
          unauthorized_third_notif_minute: config?.unauthorized_third_notif_minute || '',
          unauthorized_start_message: config?.unauthorized_start_message || '',
          unauthorized_first_message: config?.unauthorized_first_message || '',
          unauthorized_wait_message: config?.unauthorized_wait_message || '',
          unauthorized_goodbye_message: config?.unauthorized_goodbye_message || '',
          unauthorized_rating_message: config?.unauthorized_rating_message || '',
          unauthorized_offline_message: config?.unauthorized_offline_message || '',
          unauthorized_technical_delay_message: config?.unauthorized_technical_delay_message || '',
          approved_unauthorized: config?.approved_unauthorized ? true : false,

          tech_start_message: config?.tech_start_message || '',
          tech_first_message: config?.tech_first_message || '',
          tech_wait_message: config?.tech_wait_message || '',
          tech_goodbye_message: config?.tech_goodbye_message || '',
          tech_rating_message: config?.tech_rating_message || '',
          tech_offline_message: config?.tech_offline_message || '',
          tech_technical_delay_message: config?.tech_technical_delay_message || '',
          tech_holidays: config?.tech_holidays || '',
          approved_tech: config?.approved_tech ? true : false,

          news_start_message: config?.news_start_message || '',
          news_first_message: config?.news_first_message || '',
          news_wait_message: config?.news_wait_message || '',
          news_goodbye_message: config?.news_goodbye_message || '',
          news_rating_message: config?.news_rating_message || '',
          news_offline_message: config?.news_offline_message || '',
          news_technical_delay_message: config?.news_technical_delay_message || '',
          approved_news: config?.approved_news ? true : false,

          start_text_written: config?.start_text_written || '',
          sub_start_text_written: config?.sub_start_text_written || '',
          btn_name_written: config?.btn_name_written || '',
          written_holidays: config?.written_holidays || '',
          approved_written: config?.approved_written ? true : false,
        }}
        onSubmit={(values) => {
          const products = mainProducts.reduce((prev, item) => {
            if (item.children) {
              prev.push(...item.children.map((it) => ({ product_id: it.absnum })))
            }
            return prev
          }, [] as { product_id: number }[])

          setLoading(true)
          handleSubmit(
            {
              products,
              site: {
                name: values.name,
                url: values.url,
                approved: values.approved ? 1 : 0,
              },
              configuration: {
                type_btn: values.type_btn,
                text_unauthorized: values.text_unauthorized,
                btn_name_authorized: values.btn_name_authorized,
                link_rules: values.link_rules,
                tarriff_message: values.tarriff_message,
              },
              config_online: {
                start_message: values.start_message,
                first_message: values.first_message,
                goodbye_message: values.goodbye_message,
                offline_message: values.offline_message,
                rating_message: values.rating_message,
                technical_delay_message: values.technical_delay_message,
                wait_message: values.wait_message,
                holidays: values.holidays,
                approved: values.approved_online ? 1 : 0,
              },
              config_sales: {
                first_notification: values.first_notification,
                second_notification: values.second_notification,
                third_notification: values.third_notification,
                first_notif_minute: values.first_notif_minute ? Number(values.first_notif_minute) : 0,
                second_notif_minute: values.second_notif_minute ? Number(values.second_notif_minute) : 0,
                third_notif_minute: values.third_notif_minute ? Number(values.third_notif_minute) : 0,
                answer_payment: values.third_notif_minute,
                answer_tech: values.answer_tech,
                start_message: values.sales_start_message,
                first_message: values.sales_first_message,
                goodbye_message: values.sales_goodbye_message,
                offline_message: values.sales_offline_message,
                rating_message: values.sales_rating_message,
                technical_delay_message: values.sales_technical_delay_message,
                wait_message: values.sales_wait_message,
                approved: values.approved_sales ? 1 : 0,
              },
              config_unauthorized: {
                first_notification: values.unauthorized_first_notification,
                second_notification: values.unauthorized_second_notification,
                third_notification: values.unauthorized_third_notification,
                first_notif_minute: values.unauthorized_first_notif_minute
                  ? Number(values.unauthorized_first_notif_minute)
                  : 0,
                second_notif_minute: values.unauthorized_second_notif_minute
                  ? Number(values.unauthorized_second_notif_minute)
                  : 0,
                third_notif_minute: values.unauthorized_third_notif_minute
                  ? Number(values.unauthorized_third_notif_minute)
                  : 0,
                start_message: values.unauthorized_start_message,
                first_message: values.unauthorized_first_message,
                goodbye_message: values.unauthorized_goodbye_message,
                offline_message: values.unauthorized_offline_message,
                rating_message: values.unauthorized_rating_message,
                technical_delay_message: values.unauthorized_technical_delay_message,
                wait_message: values.unauthorized_wait_message,
                approved: values.approved_unauthorized ? 1 : 0,
              },
              config_tech_support: {
                start_message: values.tech_start_message,
                first_message: values.tech_first_message,
                goodbye_message: values.tech_goodbye_message,
                offline_message: values.tech_offline_message,
                rating_message: values.tech_rating_message,
                technical_delay_message: values.tech_technical_delay_message,
                wait_message: values.tech_wait_message,
                holidays: values.tech_holidays,
                approved: values.approved_tech ? 1 : 0,
              },
              config_news: {
                start_message: values.news_start_message,
                first_message: values.news_first_message,
                goodbye_message: values.news_goodbye_message,
                offline_message: values.news_offline_message,
                rating_message: values.news_rating_message,
                technical_delay_message: values.news_technical_delay_message,
                wait_message: values.news_wait_message,
                approved: values.approved_news ? 1 : 0,
              },
              config_written: {
                btn_name_written: values.btn_name_written,
                start_text_written: values.start_text_written,
                sub_start_text_written: values.sub_start_text_written,
                holidays: values.written_holidays,
                approved: values.approved_written ? 1 : 0,
              },
            },
            (success) => {
              if (!success) {
                setErrors(true)
              }
              setLoading(false)
            }
          )
        }}
      >
        {(formik) => (
          <Form>
            <Paper sx={{ m: 2, p: 2, overflow: 'hidden', height: 'calc(100% - 100px)' }}>
              <Stack spacing={2} direction={'column'} maxWidth={'900px'}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} spacing={2}>
                  <Title title={title} color={'default'} mb={2} />
                  <Stack direction={'row'} spacing={2}>
                    <Button variant={'contained'} type='submit'>
                      {loading ? <CircularProgress size={15} /> : 'Зберегти'}
                    </Button>
                    <Button variant={'outlined'} color={'inherit'} onClick={() => navigate(ROUTES.configurators)}>
                      Назад
                    </Button>
                  </Stack>
                </Stack>
                {errors ? <AlertMessage type='error' message='Сталася помилка' /> : null}
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography component='h5' variant='h5' marginRight={'20px'}>
                    Сайт
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox checked={formik.values.approved} name='approved' onChange={formik.handleChange} />
                      }
                      label='Публікація'
                    />
                  </FormGroup>
                </Stack>
                <InputFormik label='Назва сайту' name='name' required />
                <InputFormik label='URL' name='url' required />
                <SelectFormik
                  label='Тип кнопки'
                  name='type_btn'
                  data={[
                    { value: TypeBtn.RECTANGULAR, label: 'Стандартна' },
                    { value: TypeBtn.CIRCLE, label: 'Для систем' },
                    { value: TypeBtn.CIRCLE_NEW_YEARS, label: 'Новорічна кругла' },
                    { value: TypeBtn.RECTANGULAR_NEW_YEARS, label: 'Новорічна прямокутна' },
                    { value: TypeBtn.RECTANGULAR_TECH_SUPPORT, label: 'Технічна підтримка' },
                  ]}
                />
                {mainProducts.map((item, index) => (
                  <React.Fragment key={`${item.absnum}_${index}`}>
                    <FormControl fullWidth variant='outlined'>
                      <InputLabel size={'small'} id={`select-outlined-${item.absnum}_${index}`}>
                        Продукт
                      </InputLabel>
                      <Select
                        label='Продукт'
                        size={'small'}
                        labelId={`select-outlined-label_${item.absnum}_${index}`}
                        id={`select-outlined-${item.absnum}_${index}`}
                        value={item.absnum || 0}
                        error={error}
                        onChange={(e) => {
                          setError(false)
                          selectedMainProduct(Number(e.target.value), index)
                        }}
                        variant={'outlined'}
                      >
                        <MenuItem value={0} />
                        {products.products?.map((item) => (
                          <MenuItem key={item.absnum} value={item.absnum}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Stack spacing={1} direction={'row'} flexWrap={'wrap'} key={`sub_${item.absnum}`}>
                      {item.children?.map((subProduct) => (
                        <Chip
                          key={`sub_${item.absnum}_${subProduct.absnum}`}
                          label={subProduct.name}
                          onDelete={() => removeSubProduct(subProduct.absnum, index)}
                          deleteIcon={<DeleteIcon />}
                          variant='outlined'
                        />
                      ))}
                    </Stack>
                  </React.Fragment>
                ))}
                <Stack direction={'row'}>
                  <Button variant={'outlined'} onClick={addProduct}>
                    Додати продукт
                  </Button>
                </Stack>
                <InputFormik label='Текст коли неавторизований' name='text_unauthorized' required />
                <InputFormik label='Назва кнопки коли неавторизований' name='btn_name_authorized' required />
                <InputFormik label='Правила роботи експертів' name='link_rules' />
                <InputFormik label='Повідомлення для ДД' name='tarriff_message' required />
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography component='h5' variant='h5' marginRight={'20px'}>
                    Для неавторизованих
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.approved_unauthorized}
                          name='approved_unauthorized'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Публікація'
                    />
                  </FormGroup>
                </Stack>
                <InputFormik label='Початкове повідомлення' name='unauthorized_start_message' />
                <InputFormik label='Перше повідомлення' name='unauthorized_first_message' />
                <InputFormik label='Повідомлення щоб зачекали' name='unauthorized_wait_message' />
                <InputFormik label='Повідомлення до побачення' name='unauthorized_goodbye_message' />
                <InputFormik label='Повідомлення після 18:00' name='unauthorized_offline_message' />
                <InputFormik
                  label='Повідомлення про технічні несправності'
                  name='unauthorized_technical_delay_message'
                />
                <InputFormik label='Назва повідомлення оцінки' name='unauthorized_rating_message' />
                <InputFormik label='Відповвідь на технічне питання' name='answer_tech' />
                <InputFormik label='Відповвідь на запит на документи по оплаті' name='answer_payment' />
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <InputFormik
                    style={{ width: '90%', marginRight: '15px' }}
                    label='Спливаюче вікно з текстом'
                    name='unauthorized_first_notification'
                  />
                  <InputFormik style={{ width: '100px' }} label='Хвилини' name='unauthorized_first_notif_minute' />
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <InputFormik
                    style={{ width: '90%', marginRight: '15px' }}
                    label='Спливаюче вікно з текстом'
                    name='unauthorized_second_notification'
                  />
                  <InputFormik style={{ width: '100px' }} label='Хвилини' name='unauthorized_second_notif_minute' />
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <InputFormik
                    style={{ width: '90%', marginRight: '15px' }}
                    label='Спливаюче вікно з текстом'
                    name='unauthorized_third_notification'
                  />
                  <InputFormik style={{ width: '100px' }} label='Хвилини' name='unauthorized_third_notif_minute' />
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography component='h5' variant='h5' marginRight={'20px'}>
                    Онлайн чат
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.approved_online}
                          name='approved_online'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Публікація'
                    />
                  </FormGroup>
                </Stack>
                <InputFormik label='Не робочі дні формат ДД.ММ через кому' name='holidays' />
                <InputFormik label='Початкове повідомлення' name='start_message' />
                <InputFormik label='Перше повідомлення' name='first_message' />
                <InputFormik label='Повідомлення щоб зачекали' name='wait_message' />
                <InputFormik label='Повідомлення до побачення' name='goodbye_message' />
                <InputFormik label='Повідомлення після 18:00' name='offline_message' />
                <InputFormik label='Повідомлення про технічні несправності' name='technical_delay_message' />
                <InputFormik label='Назва повідомлення оцінки' name='rating_message' />
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography component='h5' variant='h5' marginRight={'20px'}>
                    Письмовий чат
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.approved_written}
                          name='approved_written'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Публікація'
                    />
                  </FormGroup>
                </Stack>
                <InputFormik label='Не робочі дні формат ДД.ММ через кому' name='written_holidays' />
                <InputFormik label='Початковий текст' name='start_text_written' />
                <InputFormik label='Під текст початкового' name='sub_start_text_written' />
                <InputFormik label='Назва кнопки' name='btn_name_written' />
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography component='h5' variant='h5' marginRight={'20px'}>
                    Техпідтримка
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.approved_tech}
                          name='approved_tech'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Публікація'
                    />
                  </FormGroup>
                </Stack>
                <InputFormik label='Не робочі дні формат ДД.ММ через кому' name='tech_holidays' />
                <InputFormik label='Початкове повідомлення' name='tech_start_message' />
                <InputFormik label='Перше повідомлення' name='tech_first_message' />
                <InputFormik label='Повідомлення щоб зачекали' name='tech_wait_message' />
                <InputFormik label='Повідомлення до побачення' name='tech_goodbye_message' />
                <InputFormik label='Повідомлення після 18:00' name='tech_offline_message' />
                <InputFormik label='Повідомлення про технічні несправності' name='tech_technical_delay_message' />
                <InputFormik label='Назва повідомлення оцінки' name='tech_rating_message' />
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography component='h5' variant='h5' marginRight={'20px'}>
                    Продажі
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.approved_sales}
                          name='approved_sales'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Публікація'
                    />
                  </FormGroup>
                </Stack>
                <InputFormik label='Початкове повідомлення' name='sales_start_message' />
                <InputFormik label='Перше повідомлення' name='sales_first_message' />
                <InputFormik label='Повідомлення щоб зачекали' name='sales_wait_message' />
                <InputFormik label='Повідомлення до побачення' name='sales_goodbye_message' />
                <InputFormik label='Повідомлення після 18:00' name='sales_offline_message' />
                <InputFormik label='Повідомлення про технічні несправності' name='sales_technical_delay_message' />
                <InputFormik label='Назва повідомлення оцінки' name='sales_rating_message' />
                <InputFormik label='Відповвідь на технічне питання' name='answer_tech' />
                <InputFormik label='Відповвідь на запит на документи по оплаті' name='answer_payment' />
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <InputFormik
                    style={{ width: '90%', marginRight: '15px' }}
                    label='Спливаюче вікно з текстом'
                    name='first_notification'
                  />
                  <InputFormik style={{ width: '100px' }} label='Хвилини' name='first_notif_minute' />
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <InputFormik
                    style={{ width: '90%', marginRight: '15px' }}
                    label='Спливаюче вікно з текстом'
                    name='second_notification'
                  />
                  <InputFormik style={{ width: '100px' }} label='Хвилини' name='second_notif_minute' />
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <InputFormik
                    style={{ width: '90%', marginRight: '15px' }}
                    label='Спливаюче вікно з текстом'
                    name='third_notification'
                  />
                  <InputFormik style={{ width: '100px' }} label='Хвилини' name='third_notif_minute' />
                </Stack>
                <Stack flexDirection={'row'} alignItems={'center'}>
                  <Typography component='h5' variant='h5' marginRight={'20px'}>
                    Новини
                  </Typography>

                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formik.values.approved_news}
                          name='approved_news'
                          onChange={formik.handleChange}
                        />
                      }
                      label='Публікація'
                    />
                  </FormGroup>
                </Stack>
                <InputFormik label='Початкове повідомлення' name='news_start_message' />
                <InputFormik label='Перше повідомлення' name='news_first_message' />
                <InputFormik label='Повідомлення щоб зачекали' name='news_wait_message' />
                <InputFormik label='Повідомлення до побачення' name='news_goodbye_message' />
                <InputFormik label='Повідомлення після 18:00' name='news_offline_message' />
                <InputFormik label='Повідомлення про технічні несправності' name='news_technical_delay_message' />
                <InputFormik label='Назва повідомлення оцінки' name='news_rating_message' />
              </Stack>
            </Paper>
          </Form>
        )}
      </Formik>
    </>
  )
}
