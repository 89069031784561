import React from 'react'
import { CircularProgress, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { useAppDispatch } from '../../store'
import { updateConfiguration } from '../../store/configurations/actions'
import { ConfigForm } from '../../components/Config/ConfigForm'

export const EditConfig: React.FC = () => {
  const { configurations } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  if (!configurations.current) {
    return (
      <Stack alignItems={'center'} justifyContent={'space-around'} height={'calc(100% - 69px)'}>
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <ConfigForm
      title={`Редагувати конфігурацію №${configurations.current.absnum}`}
      config={configurations.current}
      handleSubmit={(values, callback) => {
        if (configurations.current) {
          dispatch(updateConfiguration({ id: configurations.current.absnum, data: values })).then((res) => {
            callback(res.meta.requestStatus === 'fulfilled')
          })
        }
      }}
    />
  )
}
