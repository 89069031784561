import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '../../utils/callApi'
import { TypeTemplates } from '../../utils/consts'
import { IGroupTemplate, ITemplate, ITemplatesState } from '../templates/types'

export const getSalesTemplates = createAsyncThunk<ITemplatesState['groups']>(
  'templatesSales/getSalesTemplates',
  async (_, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-office/templates?is_type=${TypeTemplates.SALES}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCreateCroupR {
  name: string
  is_type: TypeTemplates.SALES
}
export const createGroup = createAsyncThunk<IGroupTemplate, TCreateCroupR>(
  'templatesSales/createGroup',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/admin-office/group',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TUpdateCroupR {
  id: number
  data: {
    name: string
    is_type: TypeTemplates.SALES
  }
}
export const updateGroup = createAsyncThunk<IGroupTemplate, TUpdateCroupR>(
  'templatesSales/updateGroup',
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/admin-office/group/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCreateTemplateR {
  group_id: number
  product_id: number
  message: string
  is_type: TypeTemplates.SALES
}
export const createTemplate = createAsyncThunk<ITemplate, TCreateTemplateR>(
  'templatesSales/createTemplate',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/admin-office/template',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TUpdateTemplateR {
  id: number
  data: {
    product_id: number
    message: string
    is_type: TypeTemplates.SALES
  }
}
export const updateTemplate = createAsyncThunk<ITemplate, TUpdateTemplateR>(
  'templatesSales/updateTemplate',
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/admin-office/template/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const deleteTemplate = createAsyncThunk<ITemplate, number>(
  'templatesSales/deleteTemplate',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'delete',
        data: {},
        path: `/admin-office/template/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
