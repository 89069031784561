import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { useAppDispatch } from '../../store'
import { createMailing } from '../../store/mailing/actions'
import { MailingForm } from '../../components/MailingForm'

export const CreateMailing: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <MailingForm
      title='Створити нове повідомлення'
      handleSubmit={(values, callback) => {
        dispatch(createMailing(values)).then((res) => {
          if (res.meta.requestStatus !== 'fulfilled') {
            const { error } = res as { error: { message: string } }
            callback(error.message)
          } else {
            navigate(ROUTES.mailing)
          }
        })
      }}
    />
  )
}
