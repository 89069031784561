import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '../../utils/callApi'
import { IUsersState } from './types'
import { buildQuery } from '../../utils/buildQuery'
import { CHANNELS_ID, TypeUserLog } from '../../utils/consts'

interface TLoginR {
  email: string
  password: string
}
interface TLoginRes {
  tokens: { accessToken: string; refreshToken: string }
  user: IUsersState['user']
}
export const login = createAsyncThunk<TLoginRes, TLoginR>('users/login', async (payload, thunkAPI) => {
  try {
    const data = payload
    return callApi({
      method: 'post',
      data,
      path: '/admin-auth/login',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const logout = createAsyncThunk('users/logout', async (_, thunkAPI) => {
  try {
    return callApi({
      method: 'post',
      data: {},
      path: '/admin-auth/logout',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const getUser = createAsyncThunk<IUsersState['user']>('users/getUser', async (_, thunkAPI) => {
  try {
    return callApi({
      method: 'get',
      path: '/admin-office/user',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

interface TChangeUserStatusR {
  status?: number
  notif_online?: number
  notif_written?: number
  popup_chat?: number
}
export const changeUser = createAsyncThunk<IUsersState['user'], TChangeUserStatusR>(
  'users/changeUser',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'patch',
        data,
        path: '/admin-office/user',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface IUserLog {
  fio?: string
  site_id?: number
  is_type?: number
  date_from?: Date
  date_to?: Date
  page?: number
  status?: number
  channels?: string | number
}
export const getUserLogs = createAsyncThunk<IUsersState['userLogs'], IUserLog>(
  'users/getUserLogs',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: `/admin-office/logs${query ? `?${query}` : ''}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface ICreateUserLog {
  is_type: number
}
export const createUserLog = createAsyncThunk<string, ICreateUserLog>(
  'users/createUserLog',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'post',
        data: {},
        path: `/admin-office/logs${query ? `?${query}` : ''}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface IStatUsing {
  type_id: number
  part_id?: CHANNELS_ID
}
export const createStatUsing = createAsyncThunk<string, IStatUsing>(
  'users/createUserLog',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: `/admin-office/stat_using${query ? `?${query}` : ''}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
