import React from 'react'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Button from '@mui/material/Button'
import { useSearch } from '../../hooks/useSearch'
import Stack from '@mui/material/Stack'
import CircularProgress from '@mui/material/CircularProgress'
import { SIZE_PROG } from '../../utils/consts'
import { IDtoSearch } from '../../store/statSales/types'
import { AsyncThunk } from '@reduxjs/toolkit'
import moment from 'moment'
import { makeStyles } from '@mui/styles'
import { IAvgTimeChat } from '../../store/statOnline/types'
import DatePicker from '../../ui/DatePicker'
import { SelectCheckboxSearch } from '../../ui/SelectCheckboxSearch'

const Styles = makeStyles(() => ({
  searchField: {
    width: '223px !important',
  },
}))

export interface ColumnChats {
  id: string
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

type Keys = 'dynamic_count' | 'temp_count' | 'dynamic_time' | 'temp_time'

interface Props {
  id: number
  columns: ColumnChats[]
  columns2: ColumnChats[]
  fileName: string
  data?: IAvgTimeChat[] | null
  siteIds?: { value: string; label: string }[]
  actionThunk: AsyncThunk<any, any, any>
  downloadFile: (values: IDtoSearch, callback: (data?: Buffer) => void) => void
  handleClearFilters?: () => void
}

const formatDate = 'YYYY-MM-DD'

export const StatDynamics: React.FC<Props> = ({
  id,
  columns,
  columns2,
  data,
  siteIds,
  fileName,
  actionThunk,
  downloadFile,
  handleClearFilters,
}) => {
  const [siteId, setSiteId] = React.useState<string[]>([])
  const [date, setDate] = React.useState<Date | null>(new Date())
  const [loading, setLoading] = React.useState(false)

  const { loading: loadingSearch, handleSearch } = useSearch({ actionThunk })
  const classes = Styles()

  const clearFilters = () => {
    setDate(null)
    setSiteId([])
  }

  React.useEffect(() => {
    clearFilters()
  }, [id])

  const onSearch = () => {
    const obj = {
      site_id:
        siteIds
          ?.filter((it) => siteId.includes(it.label))
          .map((it) => it.value)
          .join(',') || '',
      date_from: date ? moment(date).format(formatDate) : '',
    }
    handleSearch('', '', obj)
  }

  const downloadXlsx = () => {
    if (loading) return null
    setLoading(true)

    const obj = {
      site_id:
        siteIds
          ?.filter((it) => siteId.includes(it.label))
          .map((it) => it.value)
          .join(',') || '',
      date_from: date ? moment(date).format(formatDate) : '',
    }

    downloadFile(obj, (data) => {
      if (data) {
        const names = [fileName]
        if (date) {
          names.push(`_від_${moment(date).format('DD/MM/YYYY')}`)
        }
        const buff = new Uint8Array(data).buffer
        const url = window.URL.createObjectURL(
          new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${names.join('')}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.parentNode && link.parentNode.removeChild(link)
      }
      setLoading(false)
    })
  }

  if (!data) {
    return (
      <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress size={SIZE_PROG} />
      </Box>
    )
  }

  return (
    <Box p={2}>
      <Stack spacing={2} direction={'row'}>
        <Button variant='contained' color='primary' disabled={loading} onClick={downloadXlsx}>
          {loading ? <CircularProgress size={SIZE_PROG} /> : 'Скачати звіт'}
        </Button>
        {siteIds ? (
          <SelectCheckboxSearch
            className={classes.searchField}
            label='Продукт'
            value={siteId}
            options={siteIds}
            onChange={setSiteId}
          />
        ) : null}
        <DatePicker
          className={classes.searchField}
          id={'date'}
          label='Базовий період'
          value={date}
          inputFormat='MMM yyyy'
          mask='___ ____'
          views={['month', 'year']}
          onChange={setDate}
        />
        <Button variant='contained' color='primary' onClick={onSearch}>
          {loadingSearch ? <CircularProgress color='inherit' size={25} /> : 'Ок'}
        </Button>
        <Button
          variant='contained'
          color='error'
          onClick={() => {
            clearFilters()
            handleClearFilters && handleClearFilters()
          }}
        >
          Очистити фільтр
        </Button>
      </Stack>
      <TableContainer sx={{ height: `calc(100vh - 220px)` }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow sx={{ border: '1px solid' }}>
              <TableCell />
              <TableCell sx={{ fontWeight: 'bold' }}>Базовий період</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{data ? data[0]?.date : ''}</TableCell>
              <TableCell />
              {columns.map((column) => (
                <React.Fragment key={column.id}>
                  <TableCell />
                  <TableCell sx={{ fontWeight: 'bold' }} align={column.align}>
                    {column.label.split(' ')[0]}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align={column.align}>
                    {column.label.split(' ')[1]}
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </React.Fragment>
              ))}
            </TableRow>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Система</TableCell>
              <TableCell sx={{ fontWeight: 'bold', borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>
                К-сть чатів
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Сер. трив. чатів, хв</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Заг. трив. чатів, хв</TableCell>
              {columns.map((row) =>
                columns2.map((column, index) => (
                  <TableCell
                    sx={{
                      fontWeight: 'bold',
                      borderLeft: index === 0 ? '1px solid rgba(224, 224, 224, 1)' : '',
                    }}
                    key={`${row.id}_${column.id}`}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((row) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.absnum}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell sx={{ borderLeft: '1px solid rgba(224, 224, 224, 1)' }}>{row.count}</TableCell>
                  <TableCell>{row.avg_time}</TableCell>
                  <TableCell>{row.total_time}</TableCell>
                  {columns.map((column) => {
                    const value = row.statistics[column.id]
                    return columns2.map((column2, index) => {
                      const value2 = value ? value[column2.id as Keys] : ''
                      return (
                        <TableCell
                          sx={{ borderLeft: index === 0 ? '1px solid rgba(224, 224, 224, 1)' : '' }}
                          key={`${row.absnum}_${index}_${column2.id}`}
                          align={column2.align}
                        >
                          {String(value2)}
                        </TableCell>
                      )
                    })
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
