import React from 'react'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Stack from '@mui/material/Stack'
import { IStore } from '../../store/types'
import { CHANNELS_ID, DialogType, Roles } from '../../utils/consts'
import { useAppDispatch } from '../../store'
import {
  downlaodStat,
  getStatCountSession,
  getStatDynamics,
  getStatRaiting,
  getStatSystem,
  getStatSystemAndExpert,
} from '../../store/statOnline/actions'
import { IDtoSearch } from '../../store/statSales/types'
import { StatDataPages, ColumnChats } from '../../components/Stat/StatDataPages'
import { StatDynamics } from '../../components/Stat'
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove'
import { checkFio } from '../../utils/checkFio'
import { createStatUsing } from '../../store/users/actions'

const columns: ColumnChats[] = [
  { id: 'name', label: 'Система' },
  { id: 'total_chats', label: 'Загальна к-ть чатів, шт', align: 'center' },
  { id: 'count_processed', label: 'Загальна к-ть опрацьованих чатів, шт', align: 'center' },
  { id: 'count_not_work_time', label: 'Чати у неробочий час', align: 'center' },
  { id: 'count_demo', label: 'Чатів від ДД, шт', align: 'center' },
  {
    id: 'total_time_chat',
    label: 'Час чатів, год',
    align: 'center',
  },
  {
    id: 'count_offline',
    label: 'Опрацьовані офлайни, шт',
    align: 'center',
  },
  {
    id: 'count_online',
    label: 'Опрацьовані онлайни, шт',
    align: 'center',
  },
  {
    id: 'count_initiated',
    label: 'Ініційовані, шт',
    align: 'center',
  },
  {
    id: 'avg_time_answer',
    label: 'Час відповіді, хв',
    align: 'center',
  },
  {
    id: 'over_fifteen_minutes',
    label: '% чатів із реакцією понад 15 хв',
    align: 'center',
  },
  {
    id: 'positive_count',
    label: 'Чати із оцінкою від 4 до 5, шт',
    align: 'center',
  },
  {
    id: 'negative_count',
    label: 'Чати із оцінкою від 1 до 3, шт',
    align: 'center',
  },
]

const columns2: ColumnChats[] = [
  { id: 'name', label: 'Система' },
  { id: 'fio', label: 'Консультант', align: 'center' },
  {
    id: 'count_offline',
    label: 'Опрацьовані офлайни, шт',
    align: 'center',
  },
  {
    id: 'count_online',
    label: 'Опрацьовані онлайни, шт',
    align: 'center',
  },
  {
    id: 'count_initiated',
    label: 'Ініційовані, шт',
    align: 'center',
  },
  {
    id: 'avg_time_answer',
    label: 'Час відповіді, хв',
    align: 'center',
  },
  {
    id: 'over_fifteen_minutes',
    label: '% чатів із реакцією понад 15 хв',
    align: 'center',
  },
  {
    id: 'positive_count',
    label: 'Чати із оцінкою від 4 до 5, шт',
    align: 'center',
  },
  {
    id: 'negative_count',
    label: 'Чати із оцінкою від 1 до 3, шт',
    align: 'center',
  },
]

const columns3: ColumnChats[] = [
  { id: 'name', label: 'Система' },
  { id: 'expert_fio', label: 'Консультант', align: 'center' },
  {
    id: 'rating',
    label: 'Оцінка (від 1 до 5)',
    align: 'center',
  },
  {
    id: 'message',
    label: 'Коментар до оцінки',
    align: 'center',
  },
  {
    id: 'link',
    label: 'Чат',
    align: 'center',
  },
  {
    id: 'is_dialog',
    label: 'Ініційований',
    align: 'center',
  },
  { id: 'client_fio', label: 'ПІБ клієнта', align: 'center' },
  {
    id: 'email',
    label: 'Мейл клієнта',
    align: 'center',
  },
]

const columns4: ColumnChats[] = [
  { id: 'fio', label: 'ПІБ' },
  { id: 'email', label: 'Ел-пошта', align: 'center' },
  {
    id: 'total',
    label: 'Кількість',
    align: 'center',
  },
]

const columns5: ColumnChats[] = [
  { id: 'count', label: 'К-сть чатів', align: 'center' },
  { id: 'dynamic_count', label: 'Абсолютна динаміка к-сть', align: 'center' },
  { id: 'temp_count', label: 'Темп зміни к-сть, %', align: 'center' },
  { id: 'dynamic_time', label: 'Абсолютна динаміка сер. трив. хв', align: 'center' },
  { id: 'temp_time', label: 'Темп зміни сер. трив., %', align: 'center' },
]

export const StatOnline: React.FC = () => {
  const [siteIds, setSiteIds] = React.useState<{ value: string; label: string }[] | undefined>()
  const [experts, setExperts] = React.useState<{ value: string; label: string }[] | undefined>()
  const [page, setPage] = React.useState(1)
  const { users, statOnline, configurations, employees } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  if (users.user && users.user.role < Roles.CHIEF) {
    return null
  }

  if (users.user && !users.user.channels.includes(String(CHANNELS_ID.ONLINE))) {
    return null
  }

  const restartData = React.useCallback(() => {
    if (page === 1) {
      dispatch(getStatSystem({}))
    }
    if (page === 2) {
      dispatch(getStatSystemAndExpert({}))
    }
    if (page === 3) {
      dispatch(getStatRaiting({}))
    }
    if (page === 4) {
      dispatch(getStatCountSession({}))
    }
    if (page === 5) {
      dispatch(getStatDynamics({}))
    }
    dispatch(createStatUsing({ type_id: CHANNELS_ID.ONLINE, part_id: page }))
  }, [page])

  React.useEffect(restartData, [page])

  React.useEffect(() => {
    if (configurations.all) {
      setSiteIds(
        configurations.all
          .filter((item) => !!item.approved_online)
          .map((item) => ({ value: String(item.absnum), label: item.name }))
      )
    }
  }, [configurations.all])

  React.useEffect(() => {
    if (employees.data) {
      setExperts(employees.data.employees.map((item) => ({ value: String(item.id), label: checkFio(item) })))
    }
  }, [employees.data])

  const dowmloadStat = (values: IDtoSearch, callback: (data?: Buffer) => void) => {
    dispatch(downlaodStat(values)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        const payload = res.payload as { data: Buffer }
        callback(payload.data)
      }
      callback()
    })
  }

  const renderPage = () => {
    const shortName =
      configurations.all
        ?.filter((item) => !!item.short_name)
        .map((item) => item.short_name)
        .join('_') || ''
    if (page === 1) {
      return (
        <StatDataPages
          id={page}
          siteIds={siteIds}
          fileName={`ОП_ Онлайн_${shortName}`}
          inputFormat='dd/MM/yyyy'
          views={['year', 'month', 'day']}
          labelFrom='Від (включно)'
          labelTo='До (включно)'
          columns={columns}
          data={statOnline.statSystem}
          downloadFile={dowmloadStat}
          actionThunk={getStatSystem}
          handleClearFilters={restartData}
        />
      )
    }
    if (page === 2) {
      return (
        <StatDataPages
          id={page}
          siteIds={siteIds}
          experts={experts}
          fileName={`ОП_ Онлайн_${shortName}`}
          inputFormat='dd/MM/yyyy'
          views={['year', 'month', 'day']}
          labelFrom='Від (включно)'
          labelTo='До (включно)'
          data={statOnline.statSystemAndExpert}
          columns={columns2}
          downloadFile={dowmloadStat}
          actionThunk={getStatSystemAndExpert}
          handleClearFilters={restartData}
        />
      )
    }
    if (page === 3) {
      return (
        <StatDataPages
          id={page}
          siteIds={siteIds}
          experts={experts}
          fileName={`ОП_ Онлайн_${shortName}`}
          data={statOnline.statDataRating?.chats.map((item) => {
            return {
              ...item,
              is_dialog: item.is_dialog === DialogType.INITIATED ? <AddIcon /> : <RemoveIcon />,
            }
          })}
          dataPage={statOnline.statDataRating}
          columns={columns3}
          downloadFile={dowmloadStat}
          actionThunk={getStatRaiting}
          handleClearFilters={restartData}
        />
      )
    }
    if (page === 4) {
      return (
        <StatDataPages
          isEmail
          id={page}
          siteIds={siteIds}
          experts={experts}
          fileName={`ОП_ Онлайн_${shortName}`}
          data={statOnline.session?.chats}
          dataPage={statOnline.session}
          total={statOnline.session?.total}
          columns={columns4}
          downloadFile={dowmloadStat}
          actionThunk={getStatCountSession}
          handleClearFilters={restartData}
          filterTypes={[
            { value: 1, label: 'Не користуються' },
            { value: 2, label: 'Користуються' },
          ]}
        />
      )
    }
    if (page === 5) {
      let periods = [] as string[]
      statOnline.dynamics?.map((row) => {
        const keys = Object.keys(row.statistics)
        if (periods.length < keys.length) {
          periods = keys
        }
      })

      const columns: ColumnChats[] = periods.map((item) => ({ id: item, label: item, align: 'left' }))

      return (
        <StatDynamics
          id={page}
          siteIds={siteIds}
          fileName={`ОП_ Онлайн_${shortName}`}
          data={statOnline.dynamics}
          columns={columns}
          columns2={columns5}
          downloadFile={dowmloadStat}
          actionThunk={getStatDynamics}
          handleClearFilters={restartData}
        />
      )
    }
  }

  return (
    <Paper sx={{ m: 2 }} style={{ height: 'calc(100vh - 96px)' }}>
      <Stack pt={2} pl={2} direction={'row'}>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 1}
          onClick={() => setPage(1)}
          sx={{ height: '36px', marginRight: '10px' }}
        >
          Зведений
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 2}
          onClick={() => setPage(2)}
          sx={{ height: '36px', marginRight: '10px' }}
        >
          Консультанти
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 3}
          onClick={() => setPage(3)}
          sx={{ height: '36px', marginRight: '10px' }}
        >
          Оцінки
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 4}
          onClick={() => setPage(4)}
          sx={{ height: '36px', marginRight: '10px' }}
        >
          Кількість сесій
        </Button>
        <Button
          variant='outlined'
          color='primary'
          disabled={page === 5}
          onClick={() => setPage(5)}
          sx={{ height: '36px', marginRight: '10px' }}
        >
          Тривалість чатів
        </Button>
      </Stack>
      {renderPage()}
    </Paper>
  )
}
