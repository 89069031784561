export const debounce = (
  callback: (data: string, query: string, options?: { [x: string]: string }) => void,
  delay: number
) => {
  let data = ''
  return (value: string, query: string, options?: { [x: string]: string }) => {
    data = value
    const timeout = setTimeout(() => {
      if (data === value) callback(data, query, options)
      clearTimeout(timeout)
    }, delay)
  }
}
