import React from 'react'

export const Logo: React.FC = () => {
  return (
    <svg
      style={{ width: '22px', height: '22px' }}
      version='1.2'
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 200 200'
      width='200'
      height='200'
    >
      <path style={{ fill: '#c4005a' }} className='a' d='m0 74h200v52h-200z' />
      <path style={{ fill: '#007ac0' }} className='b' d='m0 148h200v52h-200z' />
      <path style={{ fill: '#93b93a' }} className='c' d='m0 0h200v52h-200z' />
    </svg>
  )
}
