export const styles = {
  minHeight: '270px',
  maxHeight: '680px',
  overflow: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 10,
  p: 3,
}
