import axios from 'axios'
import { checkToken } from './checkToken'
import { API_URL, TOKEN } from './config'
import { ERROR_MESSAGE } from './consts'

export async function callApi<T>(obj: {
  method: 'get' | 'post' | 'put' | 'patch' | 'delete'
  path: string
  data?: unknown | null
  refreshToken?: string
  isFormData?: boolean
}): Promise<T> {
  const { method, path, data, refreshToken, isFormData } = obj
  const token = localStorage.getItem(TOKEN.access)

  const mustCheckToken = path !== '/login' && !refreshToken
  let userToken = {
    success: true,
    token: '',
  }

  if (mustCheckToken) {
    userToken = await checkToken()
  } else if (token) {
    userToken.token = token
  }

  try {
    const res = await axios.request({
      method,
      headers: {
        'Content-Type': isFormData ? 'multipart/form-data ' : 'application/json',
        Authorization: `Bearer ${userToken.token}`,
      },
      url: API_URL + path,
      data: data ? data : null,
    })
    return res.data
  } catch (e: any) {
    console.log('Error', e)
    console.log(e.response, 'Error Api Call')
    let error = e.response
    if (e.response && typeof e.response.data === 'string')
      error = e.response.data + `${e.response.status ? `; Status: ${e.response.status}` : ''} `
    else if (e.response && e.response.status === 500) error = ERROR_MESSAGE
    else if (e.response && e.response.data) error = e.response.data.message
    else if (!e.response) error = ERROR_MESSAGE
    throw error
  }
}
