import React from 'react'
import Box from '@mui/material/Box'
import styled from 'styled-components'
import { ChatMessage } from '../ChatMessage'
import { IMessageOnline } from '../../store/chatsOnline/types'

interface Props {
  dialog?: {
    dialog_id: number
    pages: { [x: string]: { page: number; messages: any[] } }
    chat?: { messages: any[]; pages: number; page: number }
    date_start?: Date | null
    adate: Date | null
  } | null
  getDialogMessages: (data: { id: number; page: number }, callback?: (success?: boolean) => void) => void
}

export const CurrentDialog: React.FC<Props> = ({ dialog, getDialogMessages }) => {
  const [firstMessageDate, setFirstMessageDate] = React.useState<Date | null>(null)
  const [loading, setLoading] = React.useState(false)
  const [isScroll, setIsScroll] = React.useState(true)
  const refMessagesWrapper = React.useRef<HTMLDivElement | null>(null)

  React.useEffect(() => {
    if (dialog?.chat) {
      const firstMessage = dialog?.chat.messages.find((it) => dialog?.date_start && it.adate >= dialog.date_start)
      if (firstMessage) {
        setFirstMessageDate(firstMessage.adate)
      }
    }
  }, [dialog])

  React.useEffect(() => {
    if (refMessagesWrapper.current && !isScroll) {
      refMessagesWrapper.current.scrollIntoView()
    }
  }, [refMessagesWrapper, isScroll])

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (loading) return null
    if (!dialog?.chat) return null

    const element = event.currentTarget
    let prevPage = dialog.chat.page - 1
    let nextPage = dialog.chat.page + 1

    if (dialog.pages[prevPage]) {
      prevPage = dialog.pages[prevPage].page - 1
    }

    if (dialog.pages[nextPage]) {
      nextPage = dialog.pages[nextPage].page + 1
    }

    const dif = element.scrollHeight * 0.02
    if (element.scrollTop < dif && nextPage <= dialog.chat.pages && !dialog.pages[nextPage]) {
      setLoading(true)
      getDialogMessages(
        {
          id: dialog.dialog_id,
          page: nextPage,
        },
        () => setLoading(false)
      )
    }
    const difdDown = element.scrollHeight * 0.8
    if (element.scrollTop > difdDown && 1 <= prevPage && !dialog.pages[prevPage]) {
      setLoading(true)
      getDialogMessages(
        {
          id: dialog.dialog_id,
          page: prevPage,
        },
        () => setLoading(false)
      )
    }
  }

  const renderMessages = React.useMemo(() => {
    const data = [] as IMessageOnline[]
    if (!dialog?.pages) return data
    Object.keys(dialog.pages)
      .sort((a, b) => (a > b ? -1 : 1))
      .map((key) => data.push(...(dialog?.pages[key].messages || [])))
    return data
  }, [dialog])

  if (!dialog) return null

  return (
    <WrapperS overflow={'auto'} flex={1} onScroll={handleScroll}>
      <Box flex={1} display='flex' flexDirection='column' overflow={'auto'}>
        {renderMessages.map((message) => {
          if (firstMessageDate === message.adate)
            return (
              <div
                key={message.absnum}
                ref={(e) => {
                  refMessagesWrapper.current = e
                  isScroll && setIsScroll(false)
                }}
              >
                <ChatMessage isSelect={dialog?.dialog_id === message.dialog_id} message={message} />
              </div>
            )
          return (
            <ChatMessage isSelect={dialog?.dialog_id === message.dialog_id} key={message.absnum} message={message} />
          )
        })}
      </Box>
    </WrapperS>
  )
}

const WrapperS = styled(Box)<{ active?: boolean }>``
