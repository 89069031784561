import React from 'react'
import { Title } from '../Title'
import { FormControlLabel, FormGroup, Paper, Switch } from '@mui/material'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { useAppDispatch } from '../../store'
import { changeUser } from '../../store/users/actions'

interface Props {
  isPopup?: boolean
}

export const Notifications: React.FC<Props> = ({ isPopup }) => {
  const [loading, setLoading] = React.useState('')
  const { users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  const handleChange = (value: boolean, type: 'notif_online' | 'notif_chat' | 'popup_chat') => {
    const obj: any = {}
    obj[type] = value ? 1 : 0
    setLoading(type)
    dispatch(changeUser(obj)).then(() => setLoading(''))
  }

  return (
    <Paper sx={{ m: 2, p: 2, height: 'calc(100vh - 20px)' }}>
      <Title title={'Оповіщення'} color={'default'} mb={2} />
      <FormGroup>
        <FormControlLabel
          disabled={!!loading}
          control={
            <Switch
              checked={!!users.user?.notif_online}
              onChange={(e) => handleChange(e.target.checked, 'notif_online')}
            />
          }
          label='Нове повідомлення'
        />
        <FormControlLabel
          disabled={!!loading}
          control={
            <Switch checked={!!users.user?.notif_chat} onChange={(e) => handleChange(e.target.checked, 'notif_chat')} />
          }
          label='Новий чат'
        />
        {isPopup ? (
          <FormControlLabel
            disabled={!!loading}
            control={
              <Switch
                checked={!!users.user?.popup_chat}
                onChange={(e) => handleChange(e.target.checked, 'popup_chat')}
              />
            }
            label='Cпливаюче вікно'
          />
        ) : null}
      </FormGroup>
    </Paper>
  )
}
