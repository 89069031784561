import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { getStat, getStatBlock2, searchStat, searchStatBlock2 } from './actions'

const initialState: IStore['statTechSupport'] = {
  data: null,
  dataRating: null,
  searchData: null,
  searchDataRating: null,
}

export const slice = createSlice({
  name: 'statTechSupport',
  initialState,
  reducers: {
    setData: (state, action) => ({ ...state, searchData: action.payload }),
    setDataRating: (state, action) => ({ ...state, searchDataRating: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(getStat.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(getStatBlock2.fulfilled, (state, action) => {
      state.dataRating = action.payload
    })
    builder.addCase(searchStat.fulfilled, (state, action) => {
      state.searchData = action.payload
    })
    builder.addCase(searchStatBlock2.fulfilled, (state, action) => {
      state.searchDataRating = action.payload
    })
  },
})

export const StatTechSupportActions = slice.actions

export default slice.reducer
