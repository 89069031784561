import React from 'react'
import { ChatHeaderInfo } from '../../components/ChatHeaderInfo'
import Box from '@mui/material/Box'
import { Chat } from '../../components/Chat'
import { ChatStatus, InnerHeight, RightSidebarType } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { ChatsOnlineActions } from '../../store/chatsOnline'
import { useAppDispatch } from '../../store'
import {
  closeChat,
  createDialog,
  createMessage,
  getCurrentChat,
  getMessages,
  readMessage,
  sendForEvaluation,
  updateDraftMessage,
  updateMessage,
  uploadFile,
} from '../../store/chatsOnline/actions'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery'
import { ROUTES } from '../../utils/routes'

export const ChatWorkspaceOnline: React.FC = () => {
  const [search, setSearch] = React.useState('')
  const [mainProduct, setMainProduct] = React.useState('')
  const { chatsOnline, templates, products, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const status = useQuery().get('status')
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!Number.isNaN(Number(id))) {
      dispatch(getCurrentChat(Number(id))).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          navigate(ROUTES.routeChange(''))
        }
      })
    } else {
      dispatch(ChatsOnlineActions.setCurrentChat(null))
    }
  }, [chatsOnline.clikedOnChat, id, status, users.socket])

  React.useEffect(() => {
    if (status !== null) {
      dispatch(ChatsOnlineActions.changeStatus(Number(status)))
    }
  }, [status])

  React.useEffect(() => {
    if (chatsOnline.currentChat?.product_id && products.products) {
      const allProducts = products.products.reduce((prev, item) => {
        if (item.children) {
          item.children.map((product) => {
            prev[product.absnum] = item.name
          })
        }
        return prev
      }, {} as { [x: string]: string })
      setMainProduct(allProducts[chatsOnline.currentChat.product_id])
    }
  }, [chatsOnline.currentChat?.product_id, products.products])

  const handleReadMessage = (id: number) => {
    dispatch(readMessage(id))
  }

  const handleChangePage = (page: number, callback?: () => void) => {
    if (chatsOnline.currentChat) {
      dispatch(getMessages({ id: chatsOnline.currentChat.absnum, page })).finally(() => {
        callback && callback()
      })
    }
  }

  const handleCloseChat = (id: number, callback?: () => void) => {
    dispatch(closeChat(id)).finally(() => callback && callback())
  }

  const handleSendForEvaluation = (id: number, callback?: () => void) => {
    dispatch(sendForEvaluation(id)).finally(() => callback && callback())
  }

  const handleOpenSidebar = () => {
    dispatch(setRightSidebar(RightSidebarType.ONLINE))
  }

  const handleCreateMessage = React.useCallback(
    (value: string, callback?: () => void) => {
      if (chatsOnline.currentChat) {
        dispatch(
          createMessage({
            chat_id: chatsOnline.currentChat.absnum,
            message: value,
          })
        ).then(callback)
      }
    },
    [chatsOnline.currentChat?.absnum]
  )

  const handleSaveFile = React.useCallback(
    (file: File, callback?: (succes: boolean, data?: any) => void) => {
      if (chatsOnline.currentChat) {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('chat_id', String(chatsOnline.currentChat.absnum))
        fd.append('file_name', file.name)
        dispatch(uploadFile(fd)).then((res) => {
          const success = res.meta.requestStatus === 'fulfilled'
          if (callback) callback(success, res)
        })
      }
    },
    [chatsOnline.currentChat?.absnum]
  )

  const handleEditMessage = React.useCallback(
    (id: number, value: string, callback?: () => void) => {
      dispatch(
        updateMessage({
          id,
          data: { message: value },
        })
      ).then(callback)
    },
    [chatsOnline.currentChat?.absnum]
  )

  const handleCreateDialog = (callback: () => void) => {
    if (!chatsOnline.currentChat) return null
    dispatch(createDialog(chatsOnline.currentChat.chat_id)).then((res) => {
      callback()
      if (res.meta.requestStatus === 'fulfilled') {
        navigate(`${ROUTES.chats}/${chatsOnline.currentChat?.chat_id}?status=${ChatStatus.INITIATED}`)
      }
    })
  }

  const handleAutoSave = React.useCallback(
    (value: string, callback: () => void) => {
      if (!chatsOnline.currentChat) return null
      dispatch(
        updateDraftMessage({
          id: chatsOnline.currentChat.chat_id,
          data: { draft_message: value },
        })
      ).finally(callback)
    },
    [chatsOnline.currentChat?.absnum]
  )

  if (!chatsOnline.currentChat || chatsOnline.loadingChat) return null

  return (
    <Box px={3} height={InnerHeight} overflow={'auto'} display={'flex'} flexDirection={'column'}>
      {chatsOnline.currentChat ? (
        <ChatHeaderInfo
          isBtn={
            ChatStatus.CLOSE_CHAT !== chatsOnline.currentChat.status &&
            chatsOnline.currentChat.expert_id === users.user?.id
          }
          mainProduct={mainProduct}
          currentChat={chatsOnline.currentChat}
          comeBack={() => navigate(ROUTES.routeChange(''))}
          closeChat={handleCloseChat}
          openSidebar={handleOpenSidebar}
          sendForEvaluation={handleSendForEvaluation}
          createDialog={
            [ChatStatus.PROCESSED_OFFLINE_CHAT, ChatStatus.CLOSE_CHAT].includes(chatsOnline.statusChat) &&
            [ChatStatus.PROCESSED_OFFLINE_CHAT, ChatStatus.CLOSE_CHAT].includes(chatsOnline.currentChat.status)
              ? handleCreateDialog
              : undefined
          }
          handleSearch={setSearch}
        />
      ) : null}
      {chatsOnline.currentChat.chat ? (
        <Chat
          searchText={search}
          chat={chatsOnline.currentChat.chat}
          currentChat={chatsOnline.currentChat}
          groups={templates.groups}
          isEdit={[ChatStatus.ACTIVE, ChatStatus.COLLAPSED, ChatStatus.RAW_OFFLINE_CHAT, ChatStatus.INITIATED].includes(
            chatsOnline.currentChat.status
          )}
          readMessage={handleReadMessage}
          changePage={handleChangePage}
          createMessage={handleCreateMessage}
          saveFile={handleSaveFile}
          handleEditMessage={handleEditMessage}
          handleAutoSave={
            ![ChatStatus.PROCESSED_OFFLINE_CHAT, ChatStatus.CLOSE_CHAT].includes(chatsOnline.statusChat) ||
            ![ChatStatus.PROCESSED_OFFLINE_CHAT, ChatStatus.CLOSE_CHAT].includes(chatsOnline.currentChat.status)
              ? handleAutoSave
              : undefined
          }
        />
      ) : null}
    </Box>
  )
}
