import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import {
  changeStatusUserOffline,
  createEmployee,
  deleteEmployee,
  getEmployee,
  getEmployees,
  searchEmployees,
  updateEmployee,
} from './actions'

const initialState: IStore['employees'] = {
  data: null,
  searchData: null,
  current: null,
}

const slice = createSlice({
  name: 'employees',
  initialState,
  reducers: {
    setSearchData: (state, action) => {
      state.searchData = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployees.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(searchEmployees.fulfilled, (state, action) => {
      state.searchData = action.payload
    })
    builder.addCase(getEmployee.fulfilled, (state, action) => {
      state.current = action.payload
    })
    builder.addCase(createEmployee.fulfilled, (state, action) => {
      if (state.data && action.payload) {
        state.data.employees = [action.payload, ...state.data.employees]
      } else if (action.payload) {
        state.data = { employees: [action.payload], page: 1, pages: 1 }
      }
    })
    builder.addCase(updateEmployee.fulfilled, (state, action) => {
      if (state.data && action.payload) {
        state.data.employees = state.data.employees.map((item) => {
          if (action.payload && item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
      }
    })
    builder.addCase(deleteEmployee.fulfilled, (state, action) => {
      if (state.data && action.payload) {
        state.data.employees = state.data.employees.filter((item) => item.id !== action.payload?.id)
      }
    })
    builder.addCase(changeStatusUserOffline.fulfilled, (state, action) => {
      if (state.searchData && action.payload) {
        state.searchData.employees = state.searchData.employees.map((item) => {
          if (action.payload && item.id === action.payload.id) {
            return action.payload
          }
          return item
        })
      }
    })
  },
})

export const EmployeesActions = slice.actions

export default slice.reducer
