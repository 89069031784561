import styled from 'styled-components'
import Card from '@mui/material/Card'

export const WrapperMesssageS = styled(Card)`
  position: relative;
  cursor: pointer;
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  &:hover {
    background: #f8f8f8;
  }
  p:not([class]) {
    text-indent: 1.5em;
  }
`

export const WrapperBtnS = styled.div`
  position: absolute;
  right: 0px;
`
