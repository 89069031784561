export const getFormFioShort = (fio?: string) => {
  let short = ''
  if (!fio) return short
  const values = fio.split(' ')
  if (values[1] && values[1][0]) short += values[1][0]
  if (values[0][0]) short += values[0][0]

  return short.toUpperCase()
}

export const changeFio = (fio?: string) => {
  const arr = []
  if (!fio) return ''
  const values = fio.split(' ')
  if (values[1]) arr.push(values[1])
  if (values[0]) arr.push(values[0])

  return arr.join(' ')
}
