import React from 'react'
import Box from '@mui/material/Box'
import { FormControl, MenuItem, Select, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'

interface Props {
  statuses: { value: string | number; label: string }[]
  status: number
  total: number
  handleChange: (value: number) => void
  handleScroll: (event: React.UIEvent<HTMLDivElement>) => void
  children?: JSX.Element
}

export const AllChats: React.FC<Props> = ({ statuses, status, total, children, handleChange, handleScroll }) => {
  const navigate = useNavigate()

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <FormControl fullWidth variant='standard' sx={{ pt: 2, minWidth: 120 }}>
          <Select
            size={'small'}
            labelId='select-standard-label'
            id='select-standard'
            value={status}
            onChange={(e) => {
              navigate(ROUTES.routeChange(''))
              handleChange(Number(e.target.value))
            }}
            variant={'standard'}
            sx={{ px: 1 }}
          >
            {statuses.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      <Box flex={1} overflow={'auto'} onScroll={handleScroll}>
        <Box bgcolor={'#e3e8e8'} py={1} px={2}>
          <Typography variant={'h4'} fontSize={17}>
            Знайдено чатів {total}
          </Typography>
        </Box>
        {children}
      </Box>
    </>
  )
}
