const ERROR_PASSW = 'Латиниця і цифри, без пробілів, мінімум 6 символи'

const collectionValidate = {
  password: {
    error: ERROR_PASSW,
    checkValue: (value: string) => {
      const str = value?.replace(/[0-9]/g, '')
      const num = value?.replace(/[^\d.]/g, '')
      if (/^[a-zA-Z]+$/.test(str) && num.length > 0 && value.length >= 6 && !value.includes(' ')) {
        return true
      }
      return false
    },
  },
}

export { collectionValidate }
