import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { getStat, getStatBlock2, getStatBlock4, searchStat, searchStatBlock2, searchStatBlock4 } from './actions'

const initialState: IStore['statUnauthorized'] = {
  data: null,
  searchData: null,
  dataRating: null,
  searchDataRating: null,
  statChats: null,
  searchStatChats: null,
}

export const slice = createSlice({
  name: 'statUnauthorized',
  initialState,
  reducers: {
    setData: (state, action) => ({ ...state, searchData: action.payload }),
    setDataRating: (state, action) => ({ ...state, searchDataRating: action.payload }),
    setStatChats: (state, action) => ({ ...state, searchStatChats: action.payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(getStat.fulfilled, (state, action) => {
      state.data = action.payload
    })
    builder.addCase(searchStat.fulfilled, (state, action) => {
      state.searchData = action.payload
    })
    builder.addCase(getStatBlock2.fulfilled, (state, action) => {
      state.dataRating = action.payload
    })
    builder.addCase(searchStatBlock2.fulfilled, (state, action) => {
      state.searchDataRating = action.payload
    })
    builder.addCase(getStatBlock4.fulfilled, (state, action) => {
      state.statChats = action.payload
    })
    builder.addCase(searchStatBlock4.fulfilled, (state, action) => {
      state.searchStatChats = action.payload
    })
  },
})

export const StatUnauthorizedActions = slice.actions

export default slice.reducer
