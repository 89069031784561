import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IStore } from '../types'
import {
  createConfiguration,
  deleteConfiguration,
  getConfigurations,
  getConfigProducts,
  updateConfiguration,
  getConfiguration,
} from './actions'
import { IConfigurationsState } from './types'

const initialState: IStore['configurations'] = {
  all: null,
  current: null,
  products: null,
}

const slice = createSlice({
  name: 'configurations',
  initialState,
  reducers: {
    setCurrent: (state, action: PayloadAction<IConfigurationsState['current']>) => {
      state.current = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getConfigProducts.fulfilled, (state, action) => {
      state.products = action.payload
    })
    builder.addCase(getConfigurations.fulfilled, (state, action) => {
      state.all = action.payload
    })
    builder.addCase(getConfiguration.fulfilled, (state, action) => {
      state.current = action.payload
    })
    builder.addCase(createConfiguration.fulfilled, (state, action) => {
      if (state.all && action.payload) {
        state.all = [action.payload, ...state.all]
      } else if (action.payload) {
        state.all = [action.payload]
      }
    })
    builder.addCase(updateConfiguration.fulfilled, (state, action) => {
      if (state.all && action.payload) {
        state.all = state.all.map((item) => {
          if (action.payload && item.absnum === action.payload.absnum) {
            return action.payload
          }
          return item
        })
      }
    })
    builder.addCase(deleteConfiguration.fulfilled, (state, action) => {
      if (state.all && action.payload) {
        state.all = state.all.filter((item) => item.absnum !== action.payload?.absnum)
      }
    })
  },
})

export const ConfigurationsActions = slice.actions

export default slice.reducer
