import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { IconButton, Stack } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { splitMessage } from '../../../store/chatsWritten/actions'
import { IMessageWritten } from '../../../store/chatsWritten/types'
import { AlertMessage } from '../../AlertMessage'
import { styles } from './Styles'
import { RejectedWithValueAction } from '../../../store/types'
import { EditorInline } from '../../Editors/EditorInline'
import { parseLink } from '../../../utils/parseLink'
import DeleteIcon from '@mui/icons-material/Delete'

interface Props {
  closeHandler: () => void
  message: IMessageWritten
  open: boolean
}

export const SplitModal: React.FC<Props> = ({ open, message, closeHandler }) => {
  const [messages, setMessages] = React.useState<string[]>([parseLink(message.question), ''])
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(false)
  const [errors, setErrors] = React.useState('')

  const dispatch = useAppDispatch()

  const handleSend = async () => {
    const questions = messages.filter((it) => !!it).map((it) => ({ question: it }))
    if (questions.length < 2) return setError(true)
    if (!message.absnum || loading) return null
    setError(false)
    setLoading(true)

    dispatch(
      splitMessage({
        message_id: message.absnum,
        part_id: message.part_id,
        data: {
          questions,
        },
      })
    ).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        const { error } = res as RejectedWithValueAction<any, any>
        if (error.message) {
          setErrors(error.message)
        } else {
          setErrors('Сталася помилка')
        }
        setLoading(false)
      } else {
        setLoading(false)
        closeHandler()
      }
    })
  }

  const handleChange = (value: string, index: number) => {
    setMessages((prev) => {
      return prev.map((item, idx) => {
        if (idx === index) {
          return value
        }
        return item
      })
    })
  }

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{ ...styles, width: 960 }}>
        <Stack direction={'column'}>
          <Stack direction={'row'} justifyContent={'space-between'}>
            <Typography variant='h6' component='h2'>
              Розбити питання
            </Typography>
            <Button variant={'outlined'} color={'inherit'} onClick={() => setMessages([...messages, ''])}>
              Додати частину
            </Button>
          </Stack>
          {messages.map((item, index) => (
            <Stack pt={2} key={index}>
              <Stack direction={'row'} justifyContent={'space-between'}>
                <Typography variant='h6' component='h2'>
                  Частина № {index + 1}
                </Typography>
                {index > 1 ? (
                  <IconButton
                    color={'error'}
                    onClick={() => {
                      const prev = [...messages]
                      prev.splice(index, 1)
                      setMessages(prev)
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                ) : null}
              </Stack>
              <EditorInline value={item} onChange={(value) => handleChange(value, index)} />
            </Stack>
          ))}
        </Stack>
        <Stack pt={4} direction={'row'} justifyContent={'space-around'}>
          <Button variant={'contained'} color={'error'} onClick={handleSend}>
            Розбити
          </Button>
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            Закрити
          </Button>
        </Stack>
        {error ? <AlertMessage type='error' message={'Заповніть поля'} /> : null}
        {errors ? <AlertMessage type='error' message={errors} /> : null}
      </Box>
    </Modal>
  )
}
