import React from 'react'
import Paper from '@mui/material/Paper'
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch } from '../../store'
import { getStat, getStatDetailsDownload } from '../../store/statMailing/actions'
import { ROUTES } from '../../utils/routes'

interface Props {
  title: string
  value?: number | string
}

const StatItem: React.FC<Props> = ({ title, value }) => {
  return (
    <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
      <Typography sx={{ width: '260px' }}>{title}</Typography>
      <TextField sx={{ width: '150px' }} disabled size={'small'} variant='outlined' value={value} />
    </Box>
  )
}

export const StatisticsView: React.FC = () => {
  const [loading, setLoading] = React.useState(false)
  const { statMailing } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  React.useEffect(() => {
    if (id) {
      dispatch(getStat(Number(id)))
    }
  }, [])

  const downloadStat = () => {
    if (id) {
      setLoading(true)
      dispatch(getStatDetailsDownload(id)).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          const { data } = res.payload as { data: Buffer }
          const buff = new Uint8Array(data).buffer
          const url = window.URL.createObjectURL(
            new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `Cтатистику_по_окремих_користувачах_${id}.xlsx`)
          document.body.appendChild(link)
          link.click()
          link.parentNode && link.parentNode.removeChild(link)
          setLoading(false)
        }
      })
    }
  }

  if (!statMailing.view) {
    return (
      <Stack alignItems={'center'} justifyContent={'space-around'} height={'calc(100% - 69px)'}>
        <CircularProgress />
      </Stack>
    )
  }

  return (
    <Paper sx={{ m: 2, overflow: 'hidden', minHeight: 'calc(100% - 100px)' }}>
      <Stack my={2} px={2} spacing={2} direction={'row'}>
        <Button variant={'outlined'} color={'inherit'} onClick={() => navigate(ROUTES.mailing)}>
          Повернутися
        </Button>
      </Stack>
      <Stack px={2} spacing={2} direction={'row'} alignItems={'flex-end'} flexWrap={'wrap'}>
        <Stack px={2} spacing={2}>
          <StatItem title='Покази' value={statMailing.view?.shows} />
          <StatItem title='Кліки «Закрити повідомлення»' value={statMailing.view?.click_close} />
          <StatItem title='Кліки на кнопку чату' value={statMailing.view?.click_chat} />
          <StatItem title='Кліки на змінювану кнопку (всього)' value={statMailing.view?.click_link_total} />
          <StatItem title='Кліки на змінювану кнопку (унікальні)' value={statMailing.view?.click_link} />
          <StatItem title='Кількість відкритих чатів ' value={statMailing.view?.open_chats} />
        </Stack>
        <Stack spacing={2}>
          <Box />
          <StatItem title='% закритів від показів' value={statMailing.view?.interest_close} />
          <StatItem title='% кліків на чат від показів' value={statMailing.view?.interest_chat} />
          <StatItem
            title='% всього кліків на змінювану кнопку від показів'
            value={statMailing.view?.interest_link_total}
          />
          <StatItem
            title='% унікальних кліків на змінювану кнопку від показів'
            value={statMailing.view?.interest_link}
          />
          <StatItem title='% відкритих чатів від показів' value={statMailing.view?.interest_open} />
        </Stack>
        <Button
          color={'success'}
          disabled={!statMailing.view?.open_chats}
          variant={'contained'}
          onClick={() => {
            navigate(ROUTES.mailingChange(`chats?mailing_id=${id}`))
          }}
        >
          Переглянути історію чатів
        </Button>
      </Stack>
      <Box p={2}>
        <Button disabled={loading} color={'success'} variant={'contained'} onClick={downloadStat}>
          Завантажити статистику по окремих користувачах
        </Button>
      </Box>
    </Paper>
  )
}
