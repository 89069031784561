import React from 'react'
import Paper from '@mui/material/Paper'
import { Button, CircularProgress, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { ROUTES } from '../../utils/routes'
import { Title } from '../Title'
import { AlertMessage } from '../AlertMessage'
import { InputFormik } from '../../ui/InputFormik'

interface Props {
  title: string
  label?: string
  data?: { name: string } | null
  handleSubmit: (values: { name: string }, callback: (success: boolean) => void) => void
}

export const GroupForm: React.FC<Props> = ({ title, label, data, handleSubmit }) => {
  const [errors, setErrors] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const navigate = useNavigate()

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          name: data?.name || '',
        }}
        onSubmit={(values) => {
          setLoading(true)
          handleSubmit(values, (success) => {
            if (!success) {
              setErrors(true)
            }
            setLoading(false)
          })
        }}
      >
        {(formik) => (
          <Form>
            <Paper sx={{ m: 2, p: 2, overflow: 'hidden', height: 'calc(100vh - 102px)' }}>
              <Stack spacing={2} direction={'column'} maxWidth={'900px'}>
                <Title title={title} color={'default'} mb={2} />
                <InputFormik label={label || 'Назва групи'} name='name' required />
                <Stack direction={'row'} spacing={2}>
                  <Button variant={'contained'} type='submit'>
                    {loading ? <CircularProgress size={15} /> : 'Зберегти'}
                  </Button>
                  <Button variant={'outlined'} color={'inherit'} onClick={() => navigate(ROUTES.routeChange(''))}>
                    Назад
                  </Button>
                </Stack>
                {errors ? <AlertMessage type='error' message='Сталася помилка' /> : null}
              </Stack>
            </Paper>
          </Form>
        )}
      </Formik>
    </>
  )
}
