import React from 'react'
import { MainWorkspaceTechSupport } from '../../components/MainWorkspace'
import { RequireAuth } from './RequireAuth'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { CHANNELS_ID } from '../../utils/consts'

interface Props {
  element: JSX.Element
}

export const TechSupport: React.FC<Props> = ({ element }) => {
  const { users } = useSelector((store: IStore) => store)

  if (
    (users.user && !users.user.channels.includes(String(CHANNELS_ID.TECH_SUPPORT))) ||
    (users.user && !users.user.approved_tech_support)
  ) {
    return null
  }

  return (
    <RequireAuth>
      <MainWorkspaceTechSupport>{element}</MainWorkspaceTechSupport>
    </RequireAuth>
  )
}
