import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IDtoSearch } from '../statSales/types'
import { IStatOnlineState } from './types'

export const getStatSystem = createAsyncThunk<IStatOnlineState['statSystem'], IDtoSearch>(
  'statOnline/getStatSystem',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-online/stat?${query}` : '/admin-chat-online/stat',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatSystemAndExpert = createAsyncThunk<IStatOnlineState['statSystemAndExpert'], IDtoSearch>(
  'statOnline/getStatSystemAndExpert',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-online/stat/block2?${query}` : '/admin-chat-online/stat/block2',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatRaiting = createAsyncThunk<IStatOnlineState['statDataRating'], IDtoSearch>(
  'statOnline/getStatRaiting',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-online/stat/block3?${query}` : '/admin-chat-online/stat/block3',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStat = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statOnline/downlaodStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-online/stat/download?${query}` : '/admin-chat-online/stat/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatCountSession = createAsyncThunk<IStatOnlineState['session'], IDtoSearch>(
  'statOnline/getStatCountSession',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-online/stat/session?${query}` : '/admin-chat-online/stat/session',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatDynamics = createAsyncThunk<IStatOnlineState['dynamics'], IDtoSearch>(
  'statOnline/getStatDynamics',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-online/stat/dynamics?${query}` : '/admin-chat-online/stat/dynamics',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
