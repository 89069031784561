import React from 'react'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import AppBar from '@mui/material/AppBar'
import { Avatar, Stack } from '@mui/material'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { SettingsHeader } from '../SettingsHeader'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { MENU } from '../../utils/menu'
import { getFormFioShort } from '../../utils/changeFio'
import { StyledBadge } from '../StyledBadge'
import { CHANNELS_ID } from '../../utils/consts'
import { useAppDispatch } from '../../store'
import { changeUser } from '../../store/users/actions'

const imgSrcTest = ''

export const Header: React.FC = () => {
  const users = useSelector((store: IStore) => store.users)
  const location = useLocation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  let isFilterVis = false

  const handleChangeStatus = (value: number) => {
    dispatch(
      changeUser({
        status: value,
      })
    ).finally(() => {
      navigate(ROUTES.routeChange(''))
    })
  }

  const renderTitle = () => {
    const path = location.pathname.substring(1)
    if (!path && users.user?.channels.includes(String(CHANNELS_ID.ONLINE))) {
      isFilterVis = true
      return MENU.onlineHelp.title
    }
    if (!path && users.user?.channels.includes(String(CHANNELS_ID.TECH_SUPPORT))) {
      isFilterVis = true
      return MENU.techSupport.title
    }
    if (!path && users.user?.channels.includes(String(CHANNELS_ID.SALES))) {
      isFilterVis = true
      return MENU.chatsSales.title
    }
    if (!path && users.user?.channels.includes(String(CHANNELS_ID.NEWS))) {
      return MENU.chatsNews.title
    }
    if (!path && users.user?.channels.includes(String(CHANNELS_ID.UNAUTHORIZED))) {
      return MENU.chatsUnauthorized.title
    }
    if (path === ROUTES.letter) {
      return MENU.letter.title
    }
    if (location.pathname === ROUTES.logChats) {
      return MENU.logChats.title
    }
    if (ROUTES.statTechSupport.includes(path)) {
      return MENU.techStat.title
    }
    if (ROUTES.statSales.includes(path)) {
      return MENU.salesStat.title
    }
    if (ROUTES.statNews.includes(path)) {
      return MENU.newsStat.title
    }
    if (ROUTES.statOnline.includes(path)) {
      return MENU.onlineStat.title
    }
    if (ROUTES.statUnauthorized.includes(path)) {
      return MENU.unauthorizedStat.title
    }
    if (ROUTES.statWritten.includes(path)) {
      return MENU.writtenStat.title
    }
    if (path.includes('tech-support')) {
      isFilterVis = true
      return MENU.techSupport.title
    }
    if (path.includes('sales')) {
      isFilterVis = true
      return MENU.chatsSales.title
    }
    if (path.includes('news')) {
      isFilterVis = true
      return MENU.chatsNews.title
    }
    if (path.includes('unauthorized')) {
      return MENU.chatsUnauthorized.title
    }
    if (ROUTES.employees.includes(path)) {
      return MENU.participants.title
    }
    if (ROUTES.configurators.includes(path)) {
      return MENU.config.title
    }
    isFilterVis = true
    return MENU.onlineHelp.title
  }

  const render = () => {
    if (!users.user) return <></>

    return (
      <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Stack direction={'row'} alignItems={'center'}>
          <Typography variant='h6' noWrap component='div'>
            {renderTitle()}
          </Typography>
          {isFilterVis && (
            <SettingsHeader statusUser={users.user.status || 0} handleChangeStatus={handleChangeStatus} />
          )}
        </Stack>

        <Stack direction='row' spacing={2} alignItems={'center'}>
          <Typography variant={'body1'} noWrap component='div'>
            Привіт, {users.user.fio?.split(' ')[1]}
          </Typography>
          <StyledBadge
            overlap='circular'
            status={users.user.status || 0}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            variant='dot'
          >
            <Avatar alt='Remy Sharp' src={imgSrcTest}>
              {getFormFioShort(users.user.fio)}
            </Avatar>
          </StyledBadge>
        </Stack>
      </Toolbar>
    )
  }

  return (
    <>
      <AppBar position='relative' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        {render()}
      </AppBar>
    </>
  )
}
