import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Box, CircularProgress } from '@mui/material'
import { IStore } from '../../store/types'
import { CurrentDialog } from '../../components/SearchWorkspace/CurrentDialog'
import { useAppDispatch } from '../../store'
import { getDialog, getDialogMessages } from '../../store/searchChatsSales/actions'
import { SearchChatsSalesActions } from '../../store/searchChatsSales'
import { InnerHeight, SIZE_PROG } from '../../utils/consts'
import { ChatHeaderInfo } from '../../components/ChatHeaderInfo'
import { ROUTES } from '../../utils/routes'

export const CurrentDialogSales: React.FC = () => {
  const { searchChatsSales, configurations } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()

  React.useEffect(() => {
    if (id) {
      dispatch(getDialog(Number(id)))
    }
  }, [])

  const handleGetDialogMessages = (data: { id: number; page: number }, callback?: () => void) => {
    dispatch(getDialogMessages(data)).then(callback)
  }

  const handleCloseDialog = () => {
    dispatch(SearchChatsSalesActions.setDialog(null))
    navigate(ROUTES.changeSearch(''))
  }

  if (!searchChatsSales.dialog) {
    return (
      <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress size={SIZE_PROG} />
      </Box>
    )
  }

  return (
    <Box px={3} height={InnerHeight} overflow={'auto'} display={'flex'} flexDirection={'column'}>
      {searchChatsSales.dialog ? (
        <ChatHeaderInfo
          siteUrl={configurations.all?.find((item) => item.absnum === searchChatsSales.dialog?.site_id)?.url}
          currentChat={searchChatsSales.dialog}
          comeBack={handleCloseDialog}
        />
      ) : null}
      <CurrentDialog dialog={searchChatsSales.dialog} getDialogMessages={handleGetDialogMessages} />
    </Box>
  )
}
