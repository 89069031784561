import React from 'react'
import { ChatHeaderInfo } from '../../components/ChatHeaderInfo'
import Box from '@mui/material/Box'
import { Chat } from '../../components/Chat'
import { SalesChatStatus, InnerHeight, RightSidebarType } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { useAppDispatch } from '../../store'
import {
  createMessage,
  getCurrentChat,
  getMessages,
  readMessage,
  updateDraftMessage,
  updateMessage,
  uploadFile,
} from '../../store/chatsSales/actions'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery'
import { ChatsSalesActions } from '../../store/chatsSales'
import { ROUTES } from '../../utils/routes'

export const ChatWorkspaceSales: React.FC = () => {
  const [search, setSearch] = React.useState('')
  const { chatsSales, templatesSales, users, configurations } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const status = useQuery().get('status')
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!Number.isNaN(Number(id))) {
      dispatch(getCurrentChat(Number(id))).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          navigate(ROUTES.routeChange(''))
        }
      })
    } else {
      dispatch(ChatsSalesActions.setCurrentChat(null))
    }
  }, [chatsSales.clikedOnChat, id, status, users.socket])

  React.useEffect(() => {
    if (status !== null) {
      dispatch(ChatsSalesActions.changeStatus(Number(status)))
    }
  }, [status])

  const handleReadMessage = (id: number) => {
    dispatch(readMessage(id))
  }

  const handleChangePage = (page: number, callback?: () => void) => {
    if (chatsSales.currentChat) {
      dispatch(getMessages({ id: chatsSales.currentChat.absnum, page })).finally(() => {
        callback && callback()
      })
    }
  }

  const handleOpenSidebar = () => {
    dispatch(setRightSidebar(RightSidebarType.SALES))
  }

  const handleCreateMessage = React.useCallback(
    (value: string, callback?: () => void) => {
      if (chatsSales.currentChat) {
        dispatch(
          createMessage({
            chat_id: chatsSales.currentChat.absnum,
            message: value,
          })
        ).then(callback)
      }
    },
    [chatsSales.currentChat?.absnum]
  )

  const handleEditMessage = React.useCallback(
    (id: number, value: string, callback?: () => void) => {
      dispatch(
        updateMessage({
          id,
          data: { message: value },
        })
      ).then(callback)
    },
    [chatsSales.currentChat?.absnum]
  )

  const handleSaveFile = React.useCallback(
    (file: File, callback?: (succes: boolean, data?: any) => void) => {
      if (chatsSales.currentChat) {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('chat_id', String(chatsSales.currentChat.absnum))
        fd.append('file_name', file.name)
        dispatch(uploadFile(fd)).then((res) => {
          const success = res.meta.requestStatus === 'fulfilled'
          if (callback) callback(success, res)
        })
      }
    },
    [chatsSales.currentChat?.absnum]
  )

  const handleAutoSave = React.useCallback(
    (value: string, callback: () => void) => {
      if (!chatsSales.currentChat) return null
      dispatch(
        updateDraftMessage({
          id: chatsSales.currentChat.chat_id,
          data: { message: value },
        })
      ).finally(callback)
    },
    [chatsSales.currentChat?.absnum]
  )

  if (!chatsSales.currentChat || chatsSales.loadingChat) return null

  return (
    <Box px={3} height={InnerHeight} overflow={'auto'} display={'flex'} flexDirection={'column'}>
      {chatsSales.currentChat ? (
        <ChatHeaderInfo
          isBtn={
            SalesChatStatus.CLOSE !== chatsSales.currentChat.status &&
            chatsSales.currentChat.expert_id === users.user?.id
          }
          currentChat={chatsSales.currentChat}
          openSidebar={handleOpenSidebar}
          siteUrl={configurations.all?.find((item) => item.absnum === chatsSales.currentChat?.site_id)?.url}
          country={chatsSales.currentChat.profile?.country || ''}
          handleSearch={setSearch}
        />
      ) : null}
      {chatsSales.currentChat.chat ? (
        <Chat
          searchText={search}
          chat={chatsSales.currentChat.chat}
          currentChat={chatsSales.currentChat}
          groups={templatesSales.groups}
          isEdit={[SalesChatStatus.MISSED, SalesChatStatus.OFFLINE, SalesChatStatus.ONLINE].includes(
            chatsSales.currentChat.status
          )}
          readMessage={handleReadMessage}
          changePage={handleChangePage}
          createMessage={handleCreateMessage}
          saveFile={handleSaveFile}
          handleEditMessage={handleEditMessage}
          handleAutoSave={
            ![SalesChatStatus.TRANSFERED, SalesChatStatus.CLOSE].includes(chatsSales.statusChat) ||
            ![SalesChatStatus.TRANSFERED, SalesChatStatus.CLOSE].includes(chatsSales.currentChat.status)
              ? handleAutoSave
              : undefined
          }
        />
      ) : null}
    </Box>
  )
}
