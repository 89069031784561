import React from 'react'
import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { Title } from '../Title'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { CHANNELS_ID, Roles, UserStatus, StatusWork } from '../../utils/consts'
import { useAppDispatch } from '../../store'
import { changeStatusUserOffline, searchEmployees, transferAllChats } from '../../store/employees/actions'
import { useSearch } from '../../hooks/useSearch'
import { EmployeesActions } from '../../store/employees'
import { useUsersChats } from '../../hooks/useUsersChats'

interface Column {
  id: 'fio' | 'role' | 'hours' | 'products' | 'to_offline' | 'transfer_chats'
  label: string
  minWidth?: number
  align?: 'right' | 'left' | 'center'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'fio', label: 'ПІБ', minWidth: 170 },
  { id: 'role', label: 'Посада', minWidth: 100 },
  { id: 'hours', label: 'Години', minWidth: 100 },
  {
    id: 'products',
    label: 'Продукти',
    minWidth: 200,
  },
  {
    id: 'transfer_chats',
    label: 'Передати всі чати',
    align: 'center',
  },
  {
    id: 'to_offline',
    label: 'Перевести в офлайн',
    align: 'center',
  },
]

interface Props {
  channelId: CHANNELS_ID
  positions: { [x: number]: { role: number[]; name: string } }
}

export const Operators: React.FC<Props> = ({ channelId, positions }) => {
  const [fio, setFio] = React.useState('')
  const [position, setPosition] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [loadingUserId, setLoadingUserId] = React.useState(0)

  const { usersChats, handleRemoveUserChats } = useUsersChats(channelId)

  const { loading, handleSearch } = useSearch({ actionThunk: searchEmployees })
  const { employees, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (employees.data) {
      dispatch(EmployeesActions.setSearchData(employees.data))
    }
  }, [employees.data])

  const changeStatus = (userId: number) => () => {
    setLoadingUserId(userId)
    dispatch(changeStatusUserOffline({ id: userId, data: { channelId } })).then(() => {
      setLoadingUserId(0)
    })
  }

  const transferChats = (userId: number) => () => {
    setLoadingUserId(userId)
    dispatch(transferAllChats(userId)).then((res) => {
      setLoadingUserId(0)

      if (res.meta.requestStatus === 'fulfilled') {
        handleRemoveUserChats(userId)
      }
    })
  }

  return (
    <Paper sx={{ m: 2, p: 2 }}>
      <Title title={'Оператори'} color={'default'} />
      <Stack my={2} spacing={2} direction={'row'}>
        <TextField
          size={'small'}
          id='outlined-basic-id'
          label='Пошук по ПІБ'
          variant='outlined'
          value={fio}
          onChange={(event) => {
            setFio(event.target.value)
            handleSearch(event.target.value, 'fio', { status, role: position, channels: String(channelId) })
          }}
        />
        <FormControl style={{ width: '223px' }}>
          <InputLabel size={'small'} id='simple-select'>
            Посада
          </InputLabel>
          <Select
            size={'small'}
            labelId='simple-select'
            id='simple-select'
            label='Посада'
            value={position}
            onChange={(event) => {
              setPosition(event.target.value)
              handleSearch(event.target.value, 'role', { fio, status, channels: String(channelId) })
            }}
          >
            <MenuItem value={0}>Всі</MenuItem>
            {Object.entries(positions).map((items, index) => {
              if (!users.user || !items[1].role.includes(users.user.role)) return null
              return (
                <MenuItem key={`role_${index}`} value={Number(items[0])}>
                  {items[1].name}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        <FormControl style={{ width: '223px' }}>
          <InputLabel id='simple-select-label3' size={'small'}>
            Виберiть статус
          </InputLabel>

          <Select
            size={'small'}
            labelId='simple-select-label3'
            id='simple-select3'
            value={status}
            label='Виберiть статус'
            onChange={(event) => {
              setStatus(event.target.value)
              handleSearch(event.target.value, 'status', { fio, role: position })
            }}
          >
            {Object.keys(UserStatus).map((item, index) => (
              <MenuItem key={`${index}_${item}`} value={Number(item)}>
                {UserStatus[item].title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {loading ? <CircularProgress size={25} /> : null}
      </Stack>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 235px)' }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (!users.user || ![Roles.ADMIN, Roles.CHIEF].includes(users.user.role)) return null
                if (column.id === 'transfer_chats' && channelId !== CHANNELS_ID.ONLINE) return null
                return (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {employees.searchData?.employees
              .filter((item) => !!item.approved)
              .map((row, index) => {
                if (!row.channels?.includes(String(channelId))) return null
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.id}>
                    {columns.map((column) => {
                      if (
                        column.id === 'to_offline' &&
                        users.user &&
                        [Roles.ADMIN, Roles.CHIEF].includes(users.user.role)
                      ) {
                        return (
                          <TableCell key={column.id} align={'center'}>
                            <Button
                              disabled={row.status !== StatusWork.ONLINE || loadingUserId === row.id}
                              onClick={changeStatus(row.id)}
                              color={'primary'}
                              variant={'outlined'}
                            >
                              Перевести
                            </Button>
                          </TableCell>
                        )
                      } else if (
                        channelId === CHANNELS_ID.ONLINE &&
                        column.id === 'transfer_chats' &&
                        users.user &&
                        [Roles.ADMIN, Roles.CHIEF].includes(users.user.role)
                      ) {
                        return (
                          <TableCell key={column.id} align={'center'}>
                            <Button
                              disabled={loadingUserId === row.id || !usersChats[row.id]}
                              onClick={transferChats(row.id)}
                              color={'primary'}
                              variant={'outlined'}
                            >
                              Передати{usersChats[row.id] ? ` (${usersChats[row.id]})` : ''}
                            </Button>
                          </TableCell>
                        )
                      } else if (column.id === 'to_offline') {
                        return null
                      } else if (column.id === 'transfer_chats') {
                        return null
                      }

                      const value = row[column.id]
                      if (column.id === 'role')
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {positions[value as number]?.name || ''}
                          </TableCell>
                        )
                      if (Array.isArray(value))
                        return (
                          <TableCell key={column.id} align={column.align}>
                            {value.length > 3 ? (
                              <>
                                {value.slice(0, 3).map((item) => (
                                  <div key={`${item.absnum}_${index}`}>{item.name}</div>
                                ))}{' '}
                                і т. д.
                              </>
                            ) : (
                              value.map((item) => <div key={`${item.absnum}_${index}`}>{item.name}</div>)
                            )}
                          </TableCell>
                        )
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  )
}
