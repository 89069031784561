import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '../store/types'
import { CHANNELS_ID, ChatStatus, Roles } from '../utils/consts'
import { useAppDispatch } from '../store'
import { ChatsOnlineActions } from '../store/chatsOnline'
import { getChats } from '../store/chatsOnline/actions'

export const useUsersChats = (channelId: CHANNELS_ID) => {
  const [usersChats, setUsersChats] = React.useState<Record<string, number>>({})

  const users = useSelector((store: IStore) => store.users)
  const chatsOnline = useSelector((store: IStore) => store.chatsOnline)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (channelId === CHANNELS_ID.ONLINE && [Roles.ADMIN, Roles.CHIEF].includes(users.user?.role || 0)) {
      dispatch(getChats({ status: ChatStatus.ACTIVE }))
      dispatch(ChatsOnlineActions.changeStatus(ChatStatus.ACTIVE))
    }
  }, [channelId, users.user])

  React.useEffect(() => {
    if (
      channelId === CHANNELS_ID.ONLINE &&
      chatsOnline.chats &&
      [Roles.ADMIN, Roles.CHIEF].includes(users.user?.role || 0)
    ) {
      const data = chatsOnline.chats.chats.reduce((acum, item) => {
        if (acum[item.expert_id]) {
          acum[item.expert_id]++
        } else {
          acum[item.expert_id] = 1
        }

        return acum
      }, {} as Record<string, number>)

      setUsersChats(data)
    }
  }, [channelId, chatsOnline.chats, users.user])

  const handleRemoveUserChats = React.useCallback((userId: number) => {
    setUsersChats((prev) => {
      const data = { ...prev }

      delete data[userId]

      return data
    })
  }, [])

  return {
    usersChats,
    handleRemoveUserChats,
  }
}
