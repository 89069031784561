import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Stack } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { clarifyQuestion } from '../../../store/chatsWritten/actions'
import { IMessageWritten } from '../../../store/chatsWritten/types'
import { EditorDefault } from '../../Editors/EditorDefault'
import { AlertMessage } from '../../AlertMessage'
import { clarifyTemplate } from '../../../utils/config'
import { styles } from './Styles'
import { RejectedWithValueAction } from '../../../store/types'
import { parseLink } from '../../../utils/parseLink'

interface Props {
  closeHandler: () => void
  message: IMessageWritten
  open: boolean
}

export const ClarifyModal: React.FC<Props> = ({ open, message, closeHandler }) => {
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState('')

  const editorRef = React.useRef<any | null>(null)

  const dispatch = useAppDispatch()

  const handleSaveAndswer = async () => {
    if (!message.absnum || !editorRef.current || loading) return null
    const body = editorRef.current.getContent()
    setLoading(true)

    dispatch(
      clarifyQuestion({
        message_id: message.absnum,
        part_id: message.part_id,
        body,
      })
    ).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        const { error } = res as RejectedWithValueAction<any, any>
        if (error.message) {
          setErrors(error.message)
        } else {
          setErrors('Сталася помилка')
        }
        setLoading(false)
      } else {
        setLoading(false)
        closeHandler()
      }
    })
  }

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableEnforceFocus
    >
      <Box sx={{ ...styles, width: 960 }}>
        <Stack pt={4} direction={'column'}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Уточнення питання
          </Typography>
          <EditorDefault
            editorRef={editorRef}
            initialValue={clarifyTemplate({
              messageId: message.part_id ? `${message.absnum}/${message.part_id}` : String(message.absnum),
              question: parseLink(message.question),
              siteAddress: message.site_url,
              targetAddress: `${message.site_url}?show_written=1&id=${message.absnum}-${message.part_id}`,
              siteName: message.site_name,
              clientName: message.client_fio?.split(' ')[1] || '',
              fioExpert: message.expert_fio,
            })}
          />
        </Stack>
        <Stack pt={4} direction={'row'} justifyContent={'space-around'}>
          <Button variant={'contained'} color={'error'} onClick={handleSaveAndswer}>
            Відправити
          </Button>
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            Закрити
          </Button>
        </Stack>
        {errors ? <AlertMessage type='error' message={errors} /> : null}
      </Box>
    </Modal>
  )
}
