import React from 'react'
import Drawer from '@mui/material/Drawer'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { RightSidebarType } from '../../utils/consts'
import { RightOnline } from './RightOnline'
import { RightTechSupport } from './RightTechSupport'
import { RightSales } from './RightSales'
import { RightNews } from './RightNews'
import { RightWritten } from './RightWritten'
import { RightUnauthorized } from './RightUnauthorized'

export const RightSidebar: React.FC = () => {
  const { rightSidebar } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  const renderBody = () => {
    if (rightSidebar === RightSidebarType.ONLINE) {
      return <RightOnline />
    }
    if (rightSidebar === RightSidebarType.WRITTEN) {
      return <RightWritten />
    }
    if (rightSidebar === RightSidebarType.TECH_SUPPORT) {
      return <RightTechSupport />
    }
    if (rightSidebar === RightSidebarType.SALES) {
      return <RightSales />
    }
    if (rightSidebar === RightSidebarType.NEWS) {
      return <RightNews />
    }
    if (rightSidebar === RightSidebarType.UNAUTHORIZED) {
      return <RightUnauthorized />
    }

    return null
  }

  return (
    <Drawer anchor={'right'} open={!!rightSidebar} onClose={() => dispatch(setRightSidebar(RightSidebarType.DEFAULT))}>
      {renderBody()}
    </Drawer>
  )
}
