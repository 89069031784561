import { IAppealState } from './appeal/types'
import { IChatsNewsState } from './chatsNews/types'
import { IChatsOnlineState } from './chatsOnline/types'
import { IChatsSalesState } from './chatsSales/types'
import { IChatsUnauthorizedState } from './chatsUnauthorized/types'
import { IChatsWrittenState } from './chatsWritten/types'
import { IConfigurationsState } from './configurations/types'
import { IEmployeesState } from './employees/types'
import { store } from './index'
import { ILogChatsState } from './logChats/types'
import { IMailingState } from './mailing/types'
import { IProductsState } from './products/types'
import { ISearchChatsNewsState } from './searchChatsNews/types'
import { ISearchChatsOnlineState } from './searchChatsOnline/types'
import { ISearchChatsSalesState } from './searchChatsSales/types'
import { ISearchChatsUnauthorizedState } from './searchChatsUnauthorized/types'
import { IStatMailingState } from './statMailing/types'
import { IStatNewsState } from './statNews/types'
import { IStatOnlineState } from './statOnline/types'
import { IStatSalesState } from './statSales/types'
import { IStatTechSupportState } from './statTechSupport/types'
import { IStatUnauthorizedState } from './statUnauthorized/types'
import { IStatWrittenState } from './statWritten/types'
import { ITechSupportState } from './techSupport/types'
import { ITemplatesState } from './templates/types'
import { ITemplatesNewsState } from './templatesNews/types'
import { ITemplatesSalesState } from './templatesSales/types'
import { ITemplatesUnauthorizedState } from './templatesUnauthorized/types'
import { IUsersState } from './users/types'

export enum EChatType {
  online,
  letter,
}

export enum EMainView {
  chat = 0,
  textSettings,
  settings,
  search,
}

export interface IStore {
  appeal: IAppealState
  users: IUsersState
  mainView: EMainView
  rightSidebar: number
  chatsOnline: IChatsOnlineState
  techSupport: ITechSupportState
  chatsSales: IChatsSalesState
  chatsNews: IChatsNewsState
  chatsUnauthorized: IChatsUnauthorizedState
  statTechSupport: IStatTechSupportState
  statSales: IStatSalesState
  statNews: IStatNewsState
  statUnauthorized: IStatUnauthorizedState
  statOnline: IStatOnlineState
  statWritten: IStatWrittenState
  statMailing: IStatMailingState
  searchChatsNews: ISearchChatsNewsState
  searchChatsOnline: ISearchChatsOnlineState
  searchChatsSales: ISearchChatsSalesState
  searchChatsTechSupport: ISearchChatsOnlineState
  searchChatsUnauthorized: ISearchChatsUnauthorizedState
  chatsWritten: IChatsWrittenState
  products: IProductsState
  templates: ITemplatesState
  templatesNews: ITemplatesNewsState
  templatesSales: ITemplatesSalesState
  templatesTechSupport: ITemplatesState
  templatesUnauthorized: ITemplatesUnauthorizedState
  configurations: IConfigurationsState
  employees: IEmployeesState
  mailing: IMailingState
  logChats: ILogChatsState
}

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export interface RejectedWithValueAction<ThunkArg, RejectedValue> {
  type: string
  payload: RejectedValue
  error: { message: string }
  meta: {
    requestId: string
    arg: ThunkArg
    aborted: boolean
  }
}
