import { createSlice } from '@reduxjs/toolkit'
import { RightSidebarType } from '../utils/consts'
import { EMainView, IStore } from './types'

const initialState: IStore['rightSidebar'] = RightSidebarType.DEFAULT

export const rightSidebarSlice = createSlice({
  name: 'rightSidebar',
  initialState,
  reducers: {
    setRightSidebar: (state, action) => action.payload,
  },
})

export const { setRightSidebar } = rightSidebarSlice.actions

export default rightSidebarSlice.reducer
