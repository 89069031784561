import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '../../utils/callApi'
import { IGroupTemplate, ITemplate, ITemplatesState } from './types'

export const getTemplates = createAsyncThunk<ITemplatesState['groups']>(
  'templates/getTemplates',
  async (_, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: '/admin-office/templates',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCreateCroupR {
  name: string
}
export const createGroup = createAsyncThunk<IGroupTemplate, TCreateCroupR>(
  'templates/createGroup',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/admin-office/group',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TUpdateCroupR {
  id: number
  data: {
    name: string
  }
}
export const updateGroup = createAsyncThunk<IGroupTemplate, TUpdateCroupR>(
  'templates/updateGroup',
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/admin-office/group/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCreateTemplateR {
  group_id: number
  product_id: number
  message: string
}
export const createTemplate = createAsyncThunk<ITemplate, TCreateTemplateR>(
  'templates/createTemplate',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/admin-office/template',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TUpdateTemplateR {
  id: number
  data: {
    product_id: number
    message: string
  }
}
export const updateTemplate = createAsyncThunk<ITemplate, TUpdateTemplateR>(
  'templates/updateTemplate',
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/admin-office/template/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const deleteTemplate = createAsyncThunk<ITemplate, number>(
  'templates/deleteTemplate',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'delete',
        data: {},
        path: `/admin-office/template/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
