import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { ISearchChat } from '../searchChatsNews/types'
import { IChatOline } from '../techSupport/types'
import { IStatMailingState } from './types'

export const getStat = createAsyncThunk<IStatMailingState['view'], number>(
  'statMailing/getStat',
  async (payload, thunkAPI) => {
    try {
      const id = payload

      return callApi({
        method: 'get',
        path: `/mailing/statistics/view/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatViewDownload = createAsyncThunk<Buffer, ISearchChat>(
  'statMailing/getStatViewDownload',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/mailing/statistics/download/view?${query}` : '/mailing/statistics/download/view',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getDownloadStatatistics = createAsyncThunk<Buffer, ISearchChat>(
  'statMailing/downloadStatatistics',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/mailing/statistics/download/statistics?${query}` : '/mailing/statistics/download/statistics',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const searchChats = createAsyncThunk<IStatMailingState['data'], ISearchChat>(
  'statMailing/searchChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news/chats?${query}` : '/admin-chat-news/chats',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatDetailsDownload = createAsyncThunk<any, number | string>(
  'statMailing/getStatDetailsDownload',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/mailing/statistics/download/details/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getDialog = createAsyncThunk<IStatMailingState['dialog'], number>(
  'statMailing/getDialog',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-news/dialog/chat/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getDialogMessages = createAsyncThunk<IChatOline, { id: number; page: number }>(
  'statMailing/getDialogMessages',
  async (payload, thunkAPI) => {
    try {
      const { id, page } = payload
      return callApi({
        method: 'get',
        path: page ? `/admin-chat-news/dialog/messages/${id}?page=${page}` : 'admin-chat-news/dialog/messages',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
