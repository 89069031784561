import React from 'react'
import { useNavigate } from 'react-router-dom'
import { GroupForm } from '../../../components/Templates/GroupForm'
import { useAppDispatch } from '../../../store'
import { createGroup } from '../../../store/templatesNews/actions'
import { TypeTemplates } from '../../../utils/consts'
import { ROUTES } from '../../../utils/routes'

export const NewsCreateGroup: React.FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  return (
    <GroupForm
      title={'Створити групу'}
      handleSubmit={(values, callback) => {
        dispatch(createGroup({ ...values, is_type: TypeTemplates.NEWS })).then((res) => {
          if (res.meta.requestStatus !== 'fulfilled') {
            callback(false)
          } else {
            navigate(ROUTES.templatesNews)
          }
        })
      }}
    />
  )
}
