import React from 'react'
import { Avatar, Box, Button, Rating, Typography } from '@mui/material'
import styled from 'styled-components'
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import { IMessageOnline } from '../../store/chatsOnline/types'
import { changeFio, getFormFioShort } from '../../utils/changeFio'
import { getDateFormat } from '../../utils/getDateFormat'
import { parseLink } from '../../utils/parseLink'
import { NameTransfer, TypeMessage } from '../../utils/consts'
import { IMessageUnauthorized } from '../../store/chatsUnauthorized/types'
import { addParagraph } from '../../utils/addParagraph'
import { changeMessge } from '../../utils/changeMessage'

interface PropsMessage {
  message: IMessageOnline | IMessageUnauthorized
}

const MessageTransfer: React.FC<PropsMessage> = ({ message }) => {
  return (
    <div
      style={{
        width: '100%',
        textAlign: 'center',
        color: 'green',
        padding: '10px',
      }}
    >
      {NameTransfer[message.type_id]} чат. Консультант {message.expert_fio}
    </div>
  )
}

interface Props {
  message: IMessageOnline | IMessageUnauthorized
  isSelect?: boolean
  searchText?: string
  handleClick?: (event: React.MouseEvent<HTMLElement>) => void
}

export const ChatMessage: React.FC<Props> = ({ isSelect, message, searchText, handleClick }) => {
  const isMy = !!message.expert_id || (message.type_id > 0 && message.type_id !== TypeMessage.RATING)
  const styleObj = {
    bgcolor: isMy ? '#277091' : '#f2f4f8',
    textColor: isMy ? 'white' : '#5d5d5d',
    nameColor: isMy ? '#5d5d5d' : '#5d5d5d',
    avatarBg: isMy && message.photo ? 'initial' : 'orange',
    avatarTextColor: isMy ? 'white' : 'white',
  }

  const { bgcolor, textColor, avatarBg, avatarTextColor, nameColor } = styleObj

  const renderIcons = () => {
    if (message.is_read) {
      return <DoneAllOutlinedIcon color={'primary'} fontSize={'small'} sx={{ fontSize: 14 }} />
    }
    return <DoneOutlinedIcon fontSize={'small'} sx={{ fontSize: 14 }} />
  }

  const renderMesssage = () => {
    if (message.type_id === TypeMessage.RATING)
      return <Rating name='read-only' value={message.rating} readOnly size={'small'} />
    if (message.type_id === TypeMessage.MAILING)
      return (
        <Box px={1} py={1} bgcolor={bgcolor}>
          <Typography
            pb={1}
            fontSize={13}
            color={textColor}
            style={{ wordBreak: 'break-word' }}
            dangerouslySetInnerHTML={{ __html: changeMessge(addParagraph(message.body), searchText) }}
          />
          {message.img ? (
            <img
              style={{ maxHeight: '450px', maxWidth: '290px', width: '100%', display: 'block', marginBottom: '10px' }}
              src={message.img}
              alt='Зображення'
            />
          ) : null}
          <Button
            sx={{ mt: 2, display: 'inline' }}
            fullWidth
            variant='contained'
            href={message.link}
            style={{ backgroundColor: message.btn_color, whiteSpace: 'nowrap' }}
          >
            {message.btn_name}
          </Button>
        </Box>
      )
    return (
      <Typography
        bgcolor={bgcolor}
        px={1}
        py={1}
        fontSize={13}
        color={textColor}
        style={{ wordBreak: 'break-word' }}
        dangerouslySetInnerHTML={{
          __html:
            message.type_id === TypeMessage.AUTO
              ? `<h4>Автоповідомлення</h4> ${changeMessge(parseLink(message.message), searchText)}`
              : changeMessge(parseLink(message.message), searchText),
        }}
      />
    )
  }

  return (
    <MainWrapperS
      display={'flex'}
      justifyContent={isMy ? 'end' : ' start'}
      sx={{ background: isSelect ? '#fff3e0' : '' }}
      onContextMenu={handleClick}
    >
      {message.type_id === TypeMessage.TRANSFERED || message.type_id === TypeMessage.RECIVED ? (
        <MessageTransfer message={message} />
      ) : (
        <WrapperS sx={{ px: 1, py: 1, mb: 2, borderRadius: 1 }} display='flex' maxWidth={'90%'}>
          <Avatar
            src={message.photo || message.mailing_photo}
            sx={{ bgcolor: avatarBg, mr: 1, mt: 1, color: avatarTextColor }}
          >
            {isMy ? getFormFioShort(message.expert_fio) : getFormFioShort(message.client_fio)}
          </Avatar>
          <Box>
            <Box display={'flex'} alignItems={'flex-start'} flexDirection={'column'}>
              <Typography component='h6' fontWeight={500} variant={'h6'} color={nameColor} fontSize={13}>
                {isMy ? message.sender || changeFio(message.expert_fio) : changeFio(message.client_fio)}
              </Typography>
            </Box>

            <WrapperMessageS isMy={isMy}>{renderMesssage()}</WrapperMessageS>

            <Box mt={1} width={'100%'} display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
              {isMy && renderIcons()}
              <Typography ml={0.5} component={'small'} color={nameColor} fontSize={10}>
                {getDateFormat(message.adate)} {message.is_edit ? 'Відредаговано' : ''}
              </Typography>
            </Box>
          </Box>
        </WrapperS>
      )}
    </MainWrapperS>
  )
}

const MainWrapperS = styled(Box)`
  width: 100%;
`

const WrapperS = styled(Box)``

const WrapperMessageS = styled.span<{ isMy?: boolean }>`
  a {
    color: ${({ isMy }) => (isMy ? '#fff;' : '')};
  }
  h4 {
    margin: 0 0 10px 0;
  }
  p:not([class]) {
    text-indent: 1.5em;
  }
`
