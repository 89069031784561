import React from 'react'
import { AsyncThunk } from '@reduxjs/toolkit'
import { useAppDispatch } from '../store'
import { ITechSupportState } from '../store/techSupport/types'
import { IChatsUnauthorizedState } from '../store/chatsUnauthorized/types'

interface Props {
  getChats: AsyncThunk<any, { status: number; page?: number }, any>
  data: ITechSupportState | IChatsUnauthorizedState
}

export const useScrollChats = ({ data, getChats }: Props) => {
  const [loading, setLoading] = React.useState(false)
  const dispatch = useAppDispatch()

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (loading) return null
    if (!data.chats) return null

    const element = event.currentTarget
    const difdDown = element.scrollHeight * 0.8
    if (element.scrollTop > difdDown && data.chats.page < data.chats.pages) {
      setLoading(true)
      dispatch(getChats({ status: data.statusChat, page: data.chats.page + 1 })).then(() => {
        setLoading(false)
      })
    }
  }

  return { handleScroll }
}
