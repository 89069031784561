import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ChatStatus } from '../../utils/consts'
import { IStore } from '../types'
import {
  captureChat,
  getChats,
  getChatsByPage,
  getCurrentChat,
  getMessages,
  getOnlineEmoloyees,
  updateMessage as actionUpdateMessage,
  closeChat,
  changeStatusChat,
  createMessage,
  updateDraftMessage,
} from './actions'
import { IChat, IChatOline, IChatsOnlineState, IMessageOnline } from './types'

const initialState: IStore['chatsOnline'] = {
  chats: null,
  currentChat: null,
  epmloyees: null,
  statusChat: ChatStatus.ACTIVE,
  loadingChat: 0,
  clikedOnChat: 0,
}

const filterChats = (chats: IChat[], chat: IChat) => {
  return chats?.filter((item) => {
    if (item.absnum !== chat.absnum) return true
    return false
  })
}

const sortChats = (chat1: IChat, chat2: IChat) => {
  if (!chat1.adate_message) return -1
  if (!chat2.adate_message) return 1
  if (new Date(chat1.adate_message).getTime() < new Date(chat2.adate_message).getTime()) return 1
  return -1
}

const countTotal = (currentLength: number, prevLength: number, total: number) => {
  const diff = currentLength - prevLength
  const result = total + diff
  return result > 0 ? result : 0
}

const updateChats = (state: IChatsOnlineState, action: PayloadAction<IChatsOnlineState['currentChat']>) => {
  if (state.chats && action.payload) {
    const chats = filterChats(state.chats.chats, action.payload)
    chats.push(action.payload)
    let filtered = chats
    if (state.statusChat > 0) {
      filtered = chats.filter((it) => it.status === state.statusChat)
    }
    state.chats = {
      ...state.chats,
      total: countTotal(filtered.length, state.chats.chats.length, state.chats.total),
      chats: filtered.sort(sortChats),
    }
    const currentChat = filtered.find((item) => item.absnum === action.payload?.absnum)
    if (state.currentChat?.absnum === action.payload?.absnum && currentChat) {
      state.currentChat = action.payload
    } else if (state.currentChat?.absnum === action.payload?.absnum && !currentChat) {
      state.currentChat = null
    }
  }
}

const addMessage = (state: IChatsOnlineState, action: PayloadAction<IMessageOnline>) => {
  if (state.currentChat?.chat && action.payload.chat_id === state.currentChat.absnum) {
    const messsages = state.currentChat.chat.messages.filter((it) => it.absnum !== action.payload.absnum)
    messsages.push(action.payload)
    state.currentChat.chat.messages = messsages
  }
}

const updateMessage = (state: IChatsOnlineState, action: PayloadAction<IMessageOnline>) => {
  if (state.currentChat?.chat) {
    const messsages = state.currentChat.chat.messages.map((message) => {
      if (message.absnum === action.payload.absnum) {
        return action.payload
      }
      return message
    })
    state.currentChat.chat.messages = messsages
  }
}

export const slice = createSlice({
  name: 'chatsOnline',
  initialState,
  reducers: {
    updateChats,
    updateMessage,
    addMessage,
    changeStatus: (state, action: PayloadAction<number>) => {
      state.statusChat = action.payload
    },
    setClickChat: (state) => {
      state.clikedOnChat++
    },
    updateChatsForStatusAll: (state: IChatsOnlineState, action: PayloadAction<IChatsOnlineState['currentChat']>) => {
      if (state.chats && action.payload && state.statusChat === 0) {
        const chats = filterChats(state.chats.chats, action.payload)
        chats.push(action.payload)

        state.chats = {
          ...state.chats,
          total: countTotal(chats.length, state.chats.chats.length, state.chats.total),
          chats: chats.sort(sortChats),
        }
      }
    },
    addChat: (state, action: PayloadAction<IChatsOnlineState['currentChat']>) => {
      if (state.chats && action.payload) {
        const chats = state.chats.chats.filter((it) => it.absnum !== action.payload?.absnum)
        chats.push(action.payload)
        state.chats = {
          ...state.chats,
          total: countTotal(chats.length, state.chats.chats.length, state.chats.total),
          chats: chats.sort(sortChats),
        }
      } else if (action.payload) {
        state.chats = { chats: [action.payload], total: 1, page: 1, pages: 1 }
      }
    },
    removeChat: (state, action: PayloadAction<IChatsOnlineState['currentChat']>) => {
      if (state.chats) {
        const chats = state.chats.chats.filter((it) => it.absnum !== action.payload?.absnum)
        state.chats = {
          ...state.chats,
          total: countTotal(chats.length, state.chats.chats.length, state.chats.total),
          chats: chats.sort(sortChats),
        }
      }
      if (state.currentChat?.absnum === action.payload?.absnum) {
        state.currentChat = null
      }
    },
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload
    },
    setMessages: (state, action: PayloadAction<IChatOline>) => {
      if (state.currentChat?.chat) {
        const messsages = state.currentChat.chat.messages
        state.currentChat.chat = { ...action.payload, messages: [...action.payload.messages, ...messsages] }
      }
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(closeChat.fulfilled, updateChats)
    builder.addCase(changeStatusChat.fulfilled, updateChats)
    builder.addCase(createMessage.fulfilled, addMessage)
    builder.addCase(actionUpdateMessage.fulfilled, updateMessage)
    builder.addCase(getChats.fulfilled, (state, action) => {
      state.chats = action.payload
    })
    builder.addCase(getChatsByPage.fulfilled, (state, action) => {
      if (state.chats && action.payload) {
        state.chats = {
          ...action.payload,
          chats: [...state.chats.chats, ...action.payload.chats],
        }
      } else {
        state.chats = action.payload
      }
    })
    builder.addCase(getCurrentChat.pending, (state, action) => {
      state.loadingChat = action.meta.arg
    })
    builder.addCase(captureChat.pending, (state, action) => {
      state.currentChat = null
      state.loadingChat = action.meta.arg
    })
    builder.addCase(getCurrentChat.fulfilled, (state, action) => {
      state.currentChat = action.payload
      state.loadingChat = 0
    })
    builder.addCase(captureChat.fulfilled, (state, action) => {
      state.loadingChat = 0
    })
    builder.addCase(getCurrentChat.rejected, (state, action) => {
      state.loadingChat = 0
    })
    builder.addCase(captureChat.rejected, (state, action) => {
      state.loadingChat = 0
    })
    builder.addCase(getOnlineEmoloyees.fulfilled, (state, action) => {
      state.epmloyees = action.payload?.employees || []
    })
    builder.addCase(getMessages.fulfilled, (state, action) => {
      if (state.currentChat?.chat && action.payload) {
        state.currentChat.chat = {
          ...action.payload,
          messages: [...action.payload.messages, ...state.currentChat.chat.messages],
        }
      }
    })
    builder.addCase(updateDraftMessage.fulfilled, (state, action) => {
      if (state.currentChat && state.currentChat?.absnum === action.payload?.absnum) {
        state.currentChat = {
          ...state.currentChat,
          draft_message: action.payload.draft_message,
          written_draft_message: action.payload.written_draft_message,
        }
      }
    })
  },
})

export const ChatsOnlineActions = slice.actions

export default slice.reducer
