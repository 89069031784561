import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { UnauthorizedChatStatus } from '../../utils/consts'
import { IStore } from '../types'
import { captureChat, getChats, getChatsByPage, getCurrentChat, getMessages } from './actions'
import {
  IChatUnauthorized,
  IChatsUnauthorizedState,
  IMessagesUnauthorizedResponse,
  IMessageUnauthorized,
} from './types'

const initialState: IStore['chatsUnauthorized'] = {
  chats: null,
  currentChat: null,
  statusChat: UnauthorizedChatStatus.NEWS,
  badgeContent: 0,
  loadingChat: 0,
  clikedOnChat: 0,
}

const filterChats = (chats: IChatUnauthorized[], chat: IChatUnauthorized) => {
  return chats?.filter((item) => {
    if (item.absnum !== chat.absnum) return true
    return false
  })
}

const sortChats = (chat1: IChatUnauthorized, chat2: IChatUnauthorized) => {
  if (!chat1.adate_message) return -1
  if (!chat2.adate_message) return 1
  if (new Date(chat1.adate_message).getTime() < new Date(chat2.adate_message).getTime()) return 1
  return -1
}

const countTotal = (currentLength: number, prevLength: number, total: number) => {
  const diff = currentLength - prevLength
  const result = total + diff
  return result > 0 ? result : 0
}

export const slice = createSlice({
  name: 'chatsUnauthorized',
  initialState,
  reducers: {
    changeStatus: (state, action: PayloadAction<number>) => {
      state.statusChat = action.payload
    },
    setClickChat: (state) => {
      state.clikedOnChat++
    },
    updateChats: (state, action: PayloadAction<IChatsUnauthorizedState['currentChat']>) => {
      if (state.chats && action.payload) {
        const chats = filterChats(state.chats.chats, action.payload)
        chats.push(action.payload)
        let filtered = chats
        if (state.statusChat > 0) {
          filtered = chats.filter((it) => it.status === state.statusChat)
        }
        state.chats = {
          ...state.chats,
          total: countTotal(filtered.length, state.chats.chats.length, state.chats.total),
          chats: filtered.sort(sortChats),
        }
        state.badgeContent = action.payload.count_chats || 0
        const currentChat = filtered.find((item) => item.absnum === action.payload?.absnum)
        if (state.currentChat?.absnum === action.payload?.absnum && currentChat) {
          state.currentChat = action.payload
        } else if (state.currentChat?.absnum === action.payload?.absnum && !currentChat) {
          state.currentChat = null
        }
      }
    },
    addChat: (state, action: PayloadAction<IChatsUnauthorizedState['currentChat']>) => {
      if (state.chats && action.payload) {
        const chats = state.chats.chats.filter((it) => it.absnum !== action.payload?.absnum)
        chats.push(action.payload)
        state.chats = {
          ...state.chats,
          total: countTotal(chats.length, state.chats.chats.length, state.chats.total),
          chats: chats.sort(sortChats),
        }
        state.badgeContent = action.payload.count_chats || 0
      } else if (action.payload) {
        state.chats = { chats: [action.payload], total: 1, page: 1, pages: 1 }
      }
    },
    removeChat: (state, action: PayloadAction<IChatsUnauthorizedState['currentChat']>) => {
      if (state.chats) {
        const chats = state.chats.chats.filter((it) => it.absnum !== action.payload?.absnum)
        state.chats = {
          ...state.chats,
          total: countTotal(chats.length, state.chats.chats.length, state.chats.total),
          chats: chats.sort(sortChats),
        }
      }
      if (state.currentChat?.absnum === action.payload?.absnum) {
        state.currentChat = null
      }
    },
    setCurrentChat: (state, action) => {
      state.currentChat = action.payload
    },
    addMessage: (state, action: PayloadAction<IMessageUnauthorized>) => {
      if (state.currentChat?.chat && action.payload.absnum === state.currentChat.absnum) {
        const messsages = state.currentChat.chat.messages.filter((it) => it.absnum !== action.payload.absnum)
        messsages.push(action.payload)
        state.currentChat.chat.messages = messsages
      }
    },
    updateMessage: (state, action: PayloadAction<IMessageUnauthorized>) => {
      if (state.currentChat?.chat) {
        const messsages = state.currentChat.chat.messages.map((message) => {
          if (message.absnum === action.payload.absnum) {
            return action.payload
          }
          return message
        })
        state.currentChat.chat.messages = messsages
      }
    },
    setMessages: (state, action: PayloadAction<IMessagesUnauthorizedResponse>) => {
      if (state.currentChat?.chat) {
        const messsages = state.currentChat.chat.messages
        state.currentChat.chat = { ...action.payload, messages: [...action.payload.messages, ...messsages] }
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getChats.fulfilled, (state, action) => {
      state.chats = action.payload
      state.badgeContent = action.payload?.count_chats || 0
    })
    builder.addCase(getChatsByPage.fulfilled, (state, action) => {
      if (state.chats && action.payload) {
        state.chats = {
          ...action.payload,
          chats: [...state.chats.chats, ...action.payload.chats],
        }
      } else {
        state.chats = action.payload
      }
    })
    builder.addCase(getCurrentChat.pending, (state, action) => {
      state.loadingChat = action.meta.arg
    })
    builder.addCase(getCurrentChat.rejected, (state, action) => {
      state.loadingChat = 0
    })
    builder.addCase(getCurrentChat.fulfilled, (state, action) => {
      state.currentChat = action.payload
      state.loadingChat = 0
    })
    builder.addCase(captureChat.fulfilled, (state, action) => {
      state.currentChat = null
    })
    builder.addCase(getMessages.fulfilled, (state, action) => {
      if (state.currentChat?.chat && action.payload) {
        state.currentChat.chat = {
          ...action.payload,
          messages: [...action.payload.messages, ...state.currentChat.chat.messages],
        }
      }
    })
  },
})

export const ChatsUnauthorizedActions = slice.actions

export default slice.reducer
