import React from 'react'
import { Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { deleteAppeal } from '../../store/appeal/actions'
import { useAppDispatch } from '../../store'
import { ConfirmModal } from '../../components/modals/ConfirmModal'

interface Column {
  id: 'absnum' | 'name'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'absnum', label: 'ID', minWidth: 10 },
  { id: 'name', label: 'Тип' },
]

export const ListAppeal: React.FC = () => {
  const [deleteId, setDeleteId] = React.useState(0)
  const [loading, setLoading] = React.useState(false)

  const { appeal } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const editHandler = (id: number) => {
    navigate(ROUTES.changeAppeal(id))
  }

  const deleteHandler = () => {
    if (!deleteId || loading) return null
    setLoading(true)
    dispatch(deleteAppeal(deleteId)).then(() => setLoading(false))
    setDeleteId(0)
  }

  return (
    <>
      <Paper sx={{ m: 2, overflow: 'hidden', height: '100vh' }}>
        <Stack my={2} px={2} spacing={2} direction={'row'}>
          <Button onClick={() => navigate(ROUTES.appeal)} color={'primary'} variant={'outlined'}>
            Створити
          </Button>
        </Stack>
        <TableContainer sx={{ maxHeight: 620 }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {appeal.list?.map((row) => (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.absnum}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value}
                      </TableCell>
                    )
                  })}
                  <TableCell width={200} align={'right'}>
                    <Button onClick={() => editHandler(row.absnum)} color={'primary'} variant={'outlined'}>
                      Редагувати
                    </Button>
                  </TableCell>
                  <TableCell width={100} align={'right'}>
                    <Button
                      disabled={loading}
                      onClick={() => setDeleteId(row.absnum)}
                      color={'error'}
                      variant={'outlined'}
                    >
                      Видалити
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ConfirmModal
        title={`Видалити тип № ${deleteId}`}
        open={!!deleteId}
        delHandler={deleteHandler}
        closeHandler={() => setDeleteId(0)}
      />
    </>
  )
}
