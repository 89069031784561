import React from 'react'
import { useSelector } from 'react-redux'
import { IStore } from '../../../store/types'
import { useAppDispatch } from '../../../store'
import { deleteTemplate } from '../../../store/templatesNews/actions'
import { ListTamplates } from '../../../components/Templates/ListTamplates'

export const NewsListTamplates: React.FC = () => {
  const { templatesNews } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  const handleDelete = (id: number, calback?: () => void) => {
    dispatch(deleteTemplate(id)).then(calback)
  }

  return <ListTamplates groups={templatesNews.groups} deleteTemplate={handleDelete} />
}
