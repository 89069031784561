import React from 'react'
import Box from '@mui/material/Box'
import { Title } from '../Title'
import { Button, Pagination, Rating, Stack, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import moment from 'moment'
import { ISearchChatsOnlineState } from '../../store/searchChatsOnline/types'
import { ISearchChatsUnauthorizedState } from '../../store/searchChatsUnauthorized/types'

interface Column {
  id: 'client_fio' | 'status' | 'adate_message' | 'rating'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'client_fio', label: 'Чат', minWidth: 170 },
  { id: 'status', label: 'Статус', minWidth: 100, align: 'center' },
  {
    id: 'adate_message',
    label: 'Останнє повідомлення',
    minWidth: 200,
    align: 'center',
  },
  {
    id: 'rating',
    label: 'Оцінка',
    minWidth: 200,
    align: 'center',
  },
]

interface Props {
  types: { [x: string]: { title: string; color: string } }
  data: ISearchChatsOnlineState['data'] | ISearchChatsUnauthorizedState['data']
  changePage: (page: number) => void
  getDialog: (dialogId: number) => void
  comeBack?: () => void
}

export const SearchWorkspace: React.FC<Props> = ({ data, types, changePage, getDialog, comeBack }) => {
  return (
    <Paper sx={{ m: 2 }}>
      <Box p={2}>
        {comeBack ? (
          <Stack mb={2} spacing={2} direction={'row'}>
            <Button variant={'outlined'} color={'inherit'} onClick={comeBack}>
              Повернутися
            </Button>
          </Stack>
        ) : null}
        <Title color={'default'} title={`Пошук по чатам. Знайдено ${data?.total || 0}`} mb={2} />
        <TableContainer sx={{ height: `calc(100vh - ${comeBack ? '276px' : '220px'})` }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.chats.map((row, index) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={`${index}_${row.absnum}_${row.dialog_id}`}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      if (column.id === 'status')
                        return (
                          <TableCell key={column.id} sx={{ maxWidth: 150 }} align={'center'}>
                            <Typography fontSize={12} color={types[row.status || 0]?.color}>
                              {types[row.status || 0]?.title}
                            </Typography>
                          </TableCell>
                        )
                      if (column.id === 'adate_message')
                        return (
                          <TableCell key={column.id} sx={{ maxWidth: 150 }} align={'center'}>
                            {row.adate_message ? moment(row.adate_message).format('DD.MM.YYYY HH:mm') : ''}
                          </TableCell>
                        )
                      if (column.id === 'rating')
                        return (
                          <TableCell key={column.id} sx={{ maxWidth: 150 }} align={'center'}>
                            <Rating name='read-only' value={row.rating} readOnly size={'small'} />
                          </TableCell>
                        )
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {String(value)}
                        </TableCell>
                      )
                    })}
                    <TableCell width={200} align={'right'}>
                      <Button
                        onClick={() => {
                          getDialog(row.dialog_id || 0)
                        }}
                        color={'primary'}
                        variant={'outlined'}
                      >
                        Перейти
                      </Button>
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination
          style={{ display: 'flex', justifyContent: 'flex-end', paddingTop: '10px' }}
          count={data ? data.pages : 1}
          page={data ? data.page : 1}
          onChange={(_, page) => changePage(page)}
        />
      </Box>
    </Paper>
  )
}
