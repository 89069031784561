import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Button, FormControl, IconButton, InputLabel, Link, MenuItem, Select, TextField } from '@mui/material'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import { ChatStatus, UnauthorizedChatStatus } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'

interface Props {
  mainProduct?: string
  isBtn?: boolean
  currentChat: {
    status: number
    absnum: number
    is_rating?: number
    product_name?: string
    profile?: { fio?: string; phone?: string; email?: string; company?: string }
    currentStatus?: number
  }
  siteUrl?: string
  country?: string
  product_name?: string
  comeBack?: () => void
  choiceAppeal?: (value: number) => void
  closeChat?: (value: number, callback: (success?: boolean) => void) => void
  sendForEvaluation?: (value: number, callback: (success?: boolean) => void) => void
  createDialog?: (callback: () => void) => void
  openSidebar?: () => void
  handleSearch?: (value: string) => void
}

export const ChatHeaderInfo: React.FC<Props> = ({
  mainProduct,
  isBtn,
  country,
  currentChat,
  siteUrl,
  choiceAppeal,
  comeBack,
  closeChat,
  openSidebar,
  sendForEvaluation,
  createDialog,
  handleSearch,
}) => {
  const [loadingDialog, setLoadingDialog] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [appealId, setAppealId] = React.useState('')
  const [search, setSearch] = React.useState('')
  const [isSearch, setIsSearch] = React.useState(false)
  const { appeal } = useSelector((store: IStore) => store)
  const navigate = useNavigate()

  const handleCreateDialog = () => {
    if (createDialog) {
      setLoadingDialog(true)
      createDialog(() => setLoadingDialog(false))
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const searchText = event.target.value
    if (searchText.length > 70) return null
    if (searchText.length === 0 && handleSearch) handleSearch('')
    setSearch(searchText)
  }

  const handleClickSearch = () => {
    if (handleSearch) {
      handleSearch(search)
    }
  }

  const handleClickCloseSearch = () => {
    setSearch('')
    if (handleSearch) {
      handleSearch('')
    }
  }

  const handleChangeShowSearch = () => {
    setIsSearch(!isSearch)
  }

  const renderSearchField = () => {
    if (!handleSearch) return null

    if (isSearch) {
      return (
        <Box display={'flex'} alignItems={'center'} gap={2}>
          <TextField
            variant='filled'
            size={'small'}
            type='text'
            autoComplete='off'
            label={'Пошук'}
            value={search}
            maxRows={70}
            autoFocus
            onChange={handleChange}
            onKeyDown={(e) => (e.code.toLowerCase() === 'enter' ? handleClickSearch() : null)}
            InputProps={{
              endAdornment: (
                <Box display={'flex'}>
                  <IconButton onClick={handleClickSearch}>
                    <SearchIcon />
                  </IconButton>
                  <IconButton onClick={handleClickCloseSearch}>
                    <CloseIcon />
                  </IconButton>
                </Box>
              ),
            }}
          />
          <IconButton color='primary' onClick={handleChangeShowSearch}>
            <SearchIcon />
          </IconButton>
        </Box>
      )
    }

    return (
      <IconButton color='primary' onClick={handleChangeShowSearch}>
        <SearchIcon />
      </IconButton>
    )
  }

  const renderCloseBtn = () => {
    if (currentChat.status !== ChatStatus.CLOSE_CHAT && closeChat && isBtn) {
      return (
        <Button
          color={'error'}
          variant={'contained'}
          disabled={loading}
          onClick={() => {
            if (currentChat) {
              setLoading(true)
              closeChat(currentChat.absnum, () => {
                setLoading(false)
                navigate(ROUTES.routeChange(''))
              })
            }
          }}
        >
          Закрити чат
        </Button>
      )
    }
  }

  const renderRatingBtn = () => {
    if (currentChat.status !== ChatStatus.CLOSE_CHAT && sendForEvaluation && isBtn) {
      return (
        <Button
          variant={'contained'}
          disabled={loading || !!currentChat.is_rating}
          onClick={() => {
            if (currentChat && !currentChat.is_rating) {
              setLoading(true)
              sendForEvaluation(currentChat.absnum, () => setLoading(false))
            }
          }}
        >
          {currentChat.is_rating ? 'Оцінюється' : 'На оцінку'}
        </Button>
      )
    }
  }

  const renderTransferBtn = () => {
    if (currentChat.status < ChatStatus.PROCESSED_OFFLINE_CHAT && openSidebar && isBtn) {
      return (
        <Button color={'info'} variant={'contained'} onClick={openSidebar}>
          Передати чат
        </Button>
      )
    }
  }

  const renderAppealSelect = () => {
    if (currentChat.status === UnauthorizedChatStatus.IN_WORK && choiceAppeal) {
      return (
        <FormControl variant='outlined' sx={{ width: '200px' }}>
          <InputLabel size={'small'} id='select-appeal'>
            Тип звернення
          </InputLabel>
          <Select
            size={'small'}
            labelId='select-appeal'
            id='select-appeal'
            value={appealId}
            onChange={(e) => {
              setAppealId(e.target.value)
              choiceAppeal(Number(e.target.value))
            }}
            label='Тип звернення'
            variant={'outlined'}
          >
            {appeal.list?.map((item) => (
              <MenuItem key={item.absnum} value={item.absnum}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )
    }
  }

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text)
  }

  return (
    <Box borderBottom={'1px solid #cccccc'} py={1}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
        <Button
          onClick={() => setIsOpen(!isOpen)}
          color={'primary'}
          variant={'text'}
          sx={{ ml: -1, fontWeight: '600', fontSize: 18 }}
        >
          {currentChat.profile?.fio}
        </Button>
        <Box display={'flex'} gap={4}>
          {renderSearchField()}
          {createDialog ? (
            <Button
              disabled={
                currentChat.currentStatus
                  ? ![ChatStatus.PROCESSED_OFFLINE_CHAT, ChatStatus.CLOSE_CHAT].includes(currentChat.currentStatus)
                  : loadingDialog
              }
              color={'success'}
              variant={'contained'}
              onClick={handleCreateDialog}
            >
              Ініціювати чат
            </Button>
          ) : null}
          {comeBack ? (
            <Button color={'info'} variant={'contained'} disabled={loading} onClick={comeBack}>
              Повернутися
            </Button>
          ) : null}
          {renderAppealSelect()}
          {renderCloseBtn()}
          {renderRatingBtn()}
          {renderTransferBtn()}
        </Box>
      </Box>
      {isOpen && (
        <>
          {mainProduct ? (
            <Box mt={1} display={'flex'} alignItems={'center'}>
              <Typography minWidth={100} color={'gray'} pr={2}>
                Продукт
              </Typography>
              <Typography>{mainProduct}</Typography>
            </Box>
          ) : null}
          <Box mt={1} display={'flex'} alignItems={'center'}>
            <Typography minWidth={100} color={'gray'} pr={2}>
              Пошта
            </Typography>
            <Typography>
              <Link href={currentChat.profile?.email}>{currentChat.profile?.email}</Link>
            </Typography>
            <Button
              onClick={() => handleCopy(currentChat.profile?.email || '')}
              startIcon={<ContentCopyOutlinedIcon />}
            />
            {currentChat.profile?.phone ? (
              <>
                {' '}
                <Typography color={'gray'} pl={2}>
                  Телефон
                  <Link ml={1} href={`tel:${currentChat.profile.phone}`}>
                    {currentChat.profile.phone}
                  </Link>
                </Typography>
                <Button
                  onClick={() => handleCopy(currentChat.profile?.phone || '')}
                  startIcon={<ContentCopyOutlinedIcon />}
                />
              </>
            ) : null}
          </Box>
          {currentChat.profile?.company ? (
            <Box mt={1} display={'flex'} alignItems={'center'}>
              <Typography minWidth={100} color={'gray'} pr={2}>
                Компанія
              </Typography>
              <Typography>{currentChat.profile.company}</Typography>
            </Box>
          ) : null}
          {currentChat.product_name ? (
            <Box mt={1} display={'flex'} alignItems={'center'}>
              <Typography minWidth={100} color={'gray'} pr={2}>
                Поточна передплата
              </Typography>
              <Typography>{currentChat.product_name}</Typography>
            </Box>
          ) : null}
          {siteUrl ? (
            <Box mt={1} display={'flex'} alignItems={'center'}>
              <Typography minWidth={100} color={'gray'} pr={2}>
                Сайт
              </Typography>

              <Link href={siteUrl} target='_blank'>
                {siteUrl}
              </Link>
              <Button onClick={() => handleCopy(siteUrl)} startIcon={<ContentCopyOutlinedIcon />} />
            </Box>
          ) : null}
          {country ? (
            <Box mt={1} display={'flex'} alignItems={'center'}>
              <Typography minWidth={100} color={'gray'} pr={2}>
                ІД
              </Typography>

              <Typography>{country}</Typography>
            </Box>
          ) : null}
        </>
      )}
    </Box>
  )
}
