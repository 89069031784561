import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Stack, TextareaAutosize } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { topicMessage } from '../../../store/chatsWritten/actions'
import { IMessageWritten } from '../../../store/chatsWritten/types'
import { styles } from './Styles'
import { AlertMessage } from '../../AlertMessage'
import { RejectedWithValueAction } from '../../../store/types'

interface Props {
  closeHandler: () => void
  open: boolean
  message: IMessageWritten
}

export const TopicMessageModal: React.FC<Props> = ({ open, message, closeHandler }) => {
  const [topic, setTopic] = React.useState(message.topic || '')
  const [loading, setLoading] = React.useState(false)
  const [errors, setErrors] = React.useState('')

  const dispatch = useAppDispatch()

  const handleSend = () => {
    if (!topic || !message || loading) return null
    setLoading(true)
    dispatch(
      topicMessage({
        message_id: message.absnum,
        part_id: message.part_id,
        topic,
      })
    ).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        const { error } = res as RejectedWithValueAction<any, any>
        if (error.message) {
          setErrors(error.message)
        } else {
          setErrors('Сталася помилка')
        }
        setLoading(false)
      } else {
        setLoading(false)
        closeHandler()
      }
    })
  }

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{ ...styles, width: 500 }}>
        <Stack direction={'column'}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Тема
          </Typography>
          <TextareaAutosize
            style={{ height: '100px' }}
            value={topic}
            aria-label='empty textarea'
            onChange={(e) => setTopic(e.target.value)}
          />
        </Stack>
        <Stack pt={4} direction={'row'} justifyContent={'space-around'}>
          <Button variant={'contained'} color={'error'} onClick={handleSend}>
            Підтвердити
          </Button>
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            Скасувати
          </Button>
        </Stack>
        {errors ? <AlertMessage type='error' message={errors} /> : null}
      </Box>
    </Modal>
  )
}
