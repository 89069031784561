import React from 'react'
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  debounce,
  useTheme,
} from '@mui/material'
import styled from 'styled-components'
import SendIcon from '@mui/icons-material/Send'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import AttachmentIcon from '@mui/icons-material/Attachment'
import Typography from '@mui/material/Typography'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import { ACCEPT, SIZE_PROG } from '../../utils/consts'
import CircularProgress from '@mui/material/CircularProgress'
import { IChat, IMessageOnline } from '../../store/techSupport/types'
import { IGroupTemplate } from '../../store/templates/types'
import { IChatUnauthorized, IMessageUnauthorized } from '../../store/chatsUnauthorized/types'
import { parseLink, spaces } from '../../utils/parseLink'
import { EditorDefault } from '../Editors/EditorDefault'
import { Editor } from '@tinymce/tinymce-react'
import { AlertMessage } from '../AlertMessage'
import WarningIcon from '@mui/icons-material/Warning'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

interface Props {
  editMessage?: null | IMessageOnline | IMessageUnauthorized
  draft_message?: string
  chatId?: number
  groups?: IGroupTemplate[] | null
  saveFile?: (
    value: File,
    callback: (success: boolean, data?: { payload?: { link: string }; error?: { message: string } }) => void
  ) => void
  createMessage?: (value: string, callback: (success?: boolean | { error: { message: string } }) => void) => void
  handleEditMessage?: (
    id: number,
    value: string,
    callback: (success?: boolean | { error: { message: string } }) => void
  ) => void
  handleAutoSave?: (value: string, callback: (success?: boolean) => void) => void
}

export const InputMassage: React.FC<Props> = React.memo(
  ({ editMessage, groups, draft_message, chatId, saveFile, createMessage, handleEditMessage, handleAutoSave }) => {
    const [draftMessage, setDraftMessage] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [groupId, setGroupId] = React.useState('')
    const [sv, setSv] = React.useState('')
    const [file, setFile] = React.useState<File | null>(null)
    const [fileName, setFileName] = React.useState('')
    const [error, setError] = React.useState('')
    const [prevValue, setPrevValue] = React.useState(draft_message)
    const [statusDraft, setStatusDraft] = React.useState(0)
    const theme = useTheme()
    const editorRef = React.useRef<Editor['editor'] | null>(null)

    const debounceSave = React.useCallback(
      debounce((data) => {
        if (handleAutoSave && !loading) {
          setStatusDraft(2)
          handleAutoSave(data, () => {
            setStatusDraft(0)
          })
        }
      }, 2000),
      [handleAutoSave, loading]
    )

    React.useEffect(() => {
      setGroupId('')
      setSv('')
      setPrevValue(draft_message || '')
      setDraftMessage(draft_message || '')
    }, [chatId])

    React.useEffect(() => {
      editorRef.current?.setContent(parseLink(editMessage?.message).replace(spaces, '').replaceAll(spaces, '\n'))
    }, [editMessage])

    React.useEffect(() => {
      let timer: string | number | NodeJS.Timeout | null | undefined = null
      if (handleAutoSave) {
        timer = setInterval(() => {
          const value = editorRef.current?.getContent() || ''
          if (value !== prevValue) {
            setPrevValue(value)
            setStatusDraft(1)
            debounceSave(value)
          }
        }, 1000)
      }

      return () => {
        if (timer) {
          clearInterval(timer)
        }
      }
    }, [debounceSave, handleAutoSave, prevValue])

    const cancelDraftMessage = () => {
      setStatusDraft(0)
      setPrevValue('')
    }

    const handleSend = async () => {
      try {
        let link = ''
        if (!chatId || loading) return null
        setLoading(true)
        setError('')
        debounceSave?.clear()

        if (file && saveFile) {
          const resp = (await new Promise((resolve, reject) =>
            saveFile(file, (success, data) => {
              if (!success || !data?.payload) return reject(data?.error?.message || 'error upload file')
              resolve(data.payload.link)
            })
          )) as string
          link = `\n<a href="${resp}">${fileName || resp}</a>`
          setFileName('')
          setFile(null)
        }

        const html = editorRef.current?.getContent({ format: 'html' }) || ''
        const message = parseLink(html + link, true)

        if (message && editMessage && handleEditMessage) {
          await new Promise((resolve, reject) =>
            handleEditMessage(editMessage.absnum as number, message, (res) => {
              if (typeof res === 'object' && res.error) {
                reject(res.error.message)
              }
              resolve(1)
            })
          )
        } else if (message && createMessage) {
          await new Promise((resolve, reject) =>
            createMessage(message, (res) => {
              if (typeof res === 'object' && res.error) {
                reject(res.error.message)
              } else if (handleAutoSave) {
                setStatusDraft(2)
                handleAutoSave('', () => resolve(1))
              } else {
                resolve(1)
              }
            })
          )
        }

        editorRef.current?.setContent('')
        setLoading(false)
        cancelDraftMessage()
      } catch (err) {
        console.log(err)
        setLoading(false)
        if (typeof err === 'string') {
          setError(err)
          const timeId = setTimeout(() => {
            setError('')
            clearTimeout(timeId)
          }, 1500)
        }
      }
    }

    const handleDeleteFile = () => {
      setFileName('')
      setFile(null)
    }

    const handleChangeGroup = (event: SelectChangeEvent<string>) => {
      setGroupId(event.target.value)
      setSv('')
    }

    const handleChangeTemplate = (e: SelectChangeEvent<string>) => {
      setSv(e.target.value)
      const template = groups
        ?.find((group) => group.absnum === Number(groupId))
        ?.templates.find((template) => template.absnum === Number(e.target.value))
      if (template && editorRef.current) {
        editorRef.current.insertContent(template.message)
      }
    }

    const onSelectFile = () => {
      const input = document.createElement('input')
      input.accept = ACCEPT
      input.type = 'file'
      input.onchange = function () {
        const files = (this as any).files as null | File[]
        const file = files ? files[0] : null
        setFile(file)
        setFileName(file?.name || '')
      }
      input.click()
    }

    const renderStatusDraft = () => {
      if (statusDraft === 1) return <WarningIcon color='warning' />
      if (statusDraft === 2) return <CircularProgress color='primary' size={20} />
      return <CheckCircleOutlineIcon color='success' />
    }

    return (
      <Stack bgcolor={'white'} p={1} position={'relative'}>
        {handleAutoSave && (
          <Box zIndex={10} sx={{ position: 'absolute', top: '95px', right: 'calc(0px + 115px)' }}>
            {renderStatusDraft()}
          </Box>
        )}
        <Stack spacing={2} direction={'row'} py={1}>
          <FormControl onSubmit={() => console.log('submit')} fullWidth sx={{ maxWidth: 300 }}>
            <InputLabel size={'small'} id='lb1'>
              Вибір Групи
            </InputLabel>
            <Select
              size={'small'}
              labelId='lb1'
              id='select1'
              label='Вибір Групи'
              value={groupId}
              onChange={handleChangeGroup}
            >
              {groups?.map((group) => (
                <MenuItem key={group.absnum} value={group.absnum}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ maxWidth: 300 }}>
            <InputLabel size={'small'} id='lb3'>
              Шаблон відповіді
            </InputLabel>
            <Select
              size={'small'}
              labelId='lb2'
              id='select2'
              value={sv}
              label='Шаблон відповіді'
              onChange={handleChangeTemplate}
            >
              {groups
                ?.find((group) => group.absnum === Number(groupId))
                ?.templates.map((template) => (
                  <MenuItem key={template.absnum} value={template.absnum}>
                    {template.message}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Stack>

        <InputWrapperS display={'flex'} alignItems={'center'} mt={1}>
          <TextAreaS>
            <EditorDefault editorRef={editorRef} initialValue={draftMessage} />
          </TextAreaS>
          <IconButton component='label' sx={{ ml: 1 }} onClick={onSelectFile}>
            <AttachFileIcon color={'action'} />
          </IconButton>
          <IconButton disabled={loading} onClick={handleSend}>
            {loading ? <CircularProgress size={SIZE_PROG} /> : <SendIcon color={'primary'} />}
          </IconButton>
        </InputWrapperS>
        {fileName && (
          <Stack
            color={theme.palette.primary.main}
            py={1}
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            justifyContent={'flex-end'}
          >
            <AttachmentIcon />
            <Typography>{fileName.split(/(\\|\/)/g).pop()}</Typography>
            <IconButton color={'default'} onClick={handleDeleteFile}>
              <DeleteOutlineIcon />
            </IconButton>
          </Stack>
        )}
        {error ? <AlertMessage type='error' message={error} /> : null}
      </Stack>
    )
  }
)

const InputWrapperS = styled(Box)`
  height: auto;
  box-sizing: border-box;
  position: relative;
  margin-top: 15px;
`

const TextAreaS = styled(Box)`
  width: 100%;
  min-height: 120px;
`
