import React from 'react'
import { MainWorkspaceSales } from '../MainWorkspace'
import { RequireAuth } from './RequireAuth'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { CHANNELS_ID } from '../../utils/consts'

interface Props {
  element: JSX.Element
}

export const Sales: React.FC<Props> = ({ element }) => {
  const { users } = useSelector((store: IStore) => store)

  if (
    (users.user && !users.user.channels.includes(String(CHANNELS_ID.SALES))) ||
    (users.user && !users.user.approved_sales)
  ) {
    return null
  }

  return (
    <RequireAuth>
      <MainWorkspaceSales>{element}</MainWorkspaceSales>
    </RequireAuth>
  )
}
