import React from 'react'
import TextField, { TextFieldProps } from '@mui/material/TextField'
import ukLocale from 'date-fns/locale/uk'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { CalendarOrClockPickerView } from '@mui/x-date-pickers/internals/models'

interface PropsType {
  id?: string
  label?: string
  value?: Date | null
  error?: boolean
  required?: boolean
  style?: React.CSSProperties | undefined
  className?: string
  views?: CalendarOrClockPickerView[]
  mask?: string
  inputFormat?: string
  onChange: (value: Date | null) => void
}

export const BasicDatePicker: React.FC<PropsType> = ({
  id,
  value,
  required,
  className,
  onChange,
  label,
  style,
  error,
  inputFormat = 'dd/MM/yyyy HH:mm',
  mask = '__/__/____ __:__',
  views = ['year', 'month', 'day', 'hours', 'minutes'],
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
      <DateTimePicker
        mask={mask}
        inputFormat={inputFormat}
        label={label}
        views={views}
        value={value}
        className={className}
        onChange={onChange}
        renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
          <TextField
            id={id}
            name={id}
            style={style}
            fullWidth
            required={required}
            error={error}
            size='small'
            {...params}
          />
        )}
      />
    </LocalizationProvider>
  )
}
