import React from 'react'
import {
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  Box,
  Button,
  IconButton,
  useTheme,
  Stack,
  debounce,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { EditorInline } from '../../Editors/EditorInline'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import { parseLink } from '../../../utils/parseLink'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { ACCEPT } from '../../../utils/consts'
import { AlertMessage } from '../../AlertMessage'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../utils/routes'
import WarningIcon from '@mui/icons-material/Warning'
import CircularProgress from '@mui/material/CircularProgress'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

interface Props {
  title: string
  expanded: boolean
  initValue?: string
  onChange: (event: React.SyntheticEvent, isExpanded: boolean) => void
  onSend: (value: string, callback: () => void) => void
  saveFile: (
    value: File,
    callback: (success: boolean, data?: { payload?: { link: string }; error?: { message: string } }) => void
  ) => void
  handleAutoSave?: (value: string, callback: (success?: boolean) => void) => void
}

export const MessageSender: React.FC<Props> = ({
  initValue = '',
  title,
  expanded,
  onChange,
  onSend,
  saveFile,
  handleAutoSave,
}) => {
  const [value, setValue] = React.useState(initValue)
  const [file, setFile] = React.useState<File | null>(null)
  const [fileName, setFileName] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState('')
  const [statusDraft, setStatusDraft] = React.useState(0)

  const navigate = useNavigate()

  const theme = useTheme()

  const debounceSave = React.useCallback(
    debounce((data) => {
      if (handleAutoSave && !loading) {
        setStatusDraft(2)
        handleAutoSave(data, () => {
          setStatusDraft(0)
        })
      }
    }, 2000),
    [handleAutoSave, loading]
  )

  const handleChangeValue = (inputValue: string) => {
    setStatusDraft(1)
    setValue(inputValue)
    debounceSave(inputValue)
  }

  const handleClick = async () => {
    try {
      let link = ''
      if (loading || (!value && !file)) return null

      if (file) {
        const resp = (await new Promise((resolve, reject) =>
          saveFile(file, (success, data) => {
            if (!success || !data?.payload) return reject(data?.error?.message || 'error upload file')
            resolve(data.payload.link)
          })
        )) as string
        link = `\n<a href="${resp}">${fileName || resp}</a>`
        setFileName('')
        setFile(null)
      }

      const message = parseLink(value + link, true)

      setLoading(true)
      setError('')

      onSend(message, () => {
        setLoading(false)
        navigate(ROUTES.routeChange(''))
      })
    } catch (err) {
      console.log(err)
      setLoading(false)
      if (typeof err === 'string') {
        setError(err)
        const timeId = setTimeout(() => {
          setError('')
          clearTimeout(timeId)
        }, 1500)
      }
    }
  }

  const onSelectFile = () => {
    const input = document.createElement('input')
    input.accept = ACCEPT
    input.type = 'file'
    input.onchange = function () {
      const files = (this as any).files as null | File[]
      const file = files ? files[0] : null
      setFile(file)
      setFileName(file?.name || '')
    }
    input.click()
  }

  const handleDeleteFile = () => {
    setFileName('')
    setFile(null)
  }

  const renderStatusDraft = () => {
    if (statusDraft === 1) return <WarningIcon color='warning' />
    if (statusDraft === 2) return <CircularProgress color='primary' size={20} />
    return <CheckCircleOutlineIcon color='success' />
  }

  return (
    <Accordion expanded={expanded} onChange={onChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel1a-content-${title}`}
        id={`panel1a-header-${title}`}
      >
        <Typography variant={'h6'}>{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {handleAutoSave && (
          <Box zIndex={10} sx={{ position: 'absolute', right: '17px', top: '81px' }}>
            {renderStatusDraft()}
          </Box>
        )}
        <EditorInline value={value} onChange={handleChangeValue} />
        {fileName ? (
          <Stack
            color={theme.palette.primary.main}
            direction={'row'}
            spacing={2}
            alignItems={'center'}
            justifyContent={'flex-start'}
          >
            <AttachmentIcon />
            <Typography>{fileName.split(/(\\|\/)/g).pop()}</Typography>
            <IconButton color={'default'} onClick={handleDeleteFile}>
              <DeleteOutlineIcon />
            </IconButton>
          </Stack>
        ) : (
          <IconButton component='label' onClick={onSelectFile}>
            <AttachFileIcon color={'action'} />
          </IconButton>
        )}
        <Box mt={2} component={'form'} display={'flex'} autoComplete='off' justifyContent={'flex-start'}>
          <Button disabled={loading || !value} variant={'contained'} onClick={handleClick}>
            Передати
          </Button>
        </Box>
        {error ? <AlertMessage type='error' message={error} /> : null}
      </AccordionDetails>
    </Accordion>
  )
}
