import { createSlice } from '@reduxjs/toolkit'
import { TOKEN } from '../../utils/config'
import { IStore } from '../types'
import { changeUser, getUserLogs, getUser, login, logout } from './actions'

const initialState: IStore['users'] = {
  user: null,
  isAuth: false,
  isLogin: false,
  socket: '',
  userLogs: null,
}

export const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setStatus: (state, action) => {
      if (state.user) {
        state.user.status = action.payload
      }
    },
    setSocket: (state, action) => {
      state.socket = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.fulfilled, (state, action) => {
      const { tokens, user } = action.payload
      state.user = user
      state.isAuth = !!user
      state.isLogin = false
      localStorage.setItem(TOKEN.access, tokens.accessToken)
      localStorage.setItem(TOKEN.refresh, tokens.refreshToken)
    })
    builder.addCase(logout.fulfilled, (state, action) => {
      state.user = null
      state.isAuth = false
      state.isLogin = true
      localStorage.removeItem(TOKEN.access)
      localStorage.removeItem(TOKEN.refresh)
    })
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.user = action.payload
      state.isAuth = !!action.payload
    })
    builder.addCase(getUser.rejected, (state, action) => {
      state.user = null
      state.isAuth = false
      state.isLogin = true
    })
    builder.addCase(changeUser.fulfilled, (state, action) => {
      state.user = action.payload
      state.isAuth = !!action.payload
    })
    builder.addCase(getUserLogs.fulfilled, (state, action) => {
      state.userLogs = action.payload
    })
  },
})

export const UserActions = slice.actions

export default slice.reducer
