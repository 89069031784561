import React from 'react'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'
import Drawer from '@mui/material/Drawer'
import { Badge, styled } from '@mui/material'
const transition = '.2s'
import { useNavigate } from 'react-router-dom'
import { Logo } from '../icons/logo'
import { MENU } from '../../utils/menu'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { logout } from '../../store/users/actions'
import { useAppDispatch } from '../../store'
import { setMainView } from '../../store/mainViewSlice'
import { ROUTES } from '../../utils/routes'
import { CHANNELS_ID } from '../../utils/consts'
import { socket } from '../../utils/socket'

type TKey = keyof typeof MENU
type MenuItem = (typeof MENU)['chatsNews']

interface Props {
  menuItm: MenuItem
  badgeContent?: number
  handleClick: () => void
}

const Item: React.FC<Props> = ({ menuItm, badgeContent, handleClick }) => {
  return (
    <ListItem disablePadding onClick={handleClick}>
      <ListItemButton>
        <WrapperIconS>
          <Badge color='primary' badgeContent={badgeContent}>
            {menuItm.icon}
          </Badge>
        </WrapperIconS>
        <ListItemText sx={{ whiteSpace: 'nowrap', color: 'white' }} primary={menuItm.title} />
      </ListItemButton>
    </ListItem>
  )
}

export const Sidebar: React.FC = () => {
  const users = useSelector((store: IStore) => store.users)
  const chatsNews = useSelector((store: IStore) => store.chatsNews)
  const chatsSales = useSelector((store: IStore) => store.chatsSales)
  const chatsUnauthorized = useSelector((store: IStore) => store.chatsUnauthorized)

  const dispatch = useAppDispatch()
  const [drawerWidth, setDrawerWidth] = React.useState(50)
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
    socket.close()
  }

  const handleClick = (idx: number, link: string) => () => {
    if (idx === 1) {
      navigate(ROUTES.routeChange(''))
      dispatch(setMainView(0))
    }
    users.user && link ? navigate(link) : null
  }

  return (
    <Drawer
      variant='permanent'
      onMouseEnter={() => setDrawerWidth(250)}
      onMouseLeave={() => setDrawerWidth(50)}
      sx={{
        width: drawerWidth,
        overflow: 'hidden',
        transition,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, transition, boxSizing: 'border-box' },
      }}
    >
      {/*<Toolbar />*/}
      <Box
        sx={{
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          flex: 1,
          background: 'rgb(50, 51, 50)',
        }}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <WrapperIconS>
                <Logo />
              </WrapperIconS>
              <ListItemText
                sx={{ whiteSpace: 'nowrap', color: 'white', fontSize: '24px' }}
                primary={'Експертус Консультанти'}
              />
            </ListItemButton>
          </ListItem>
          {Object.keys(MENU).map((key, idx) => {
            const menuItm = MENU[key as TKey]
            if (!users.user) return null
            if (!MENU[key as TKey].roles.includes(users.user.role || 0)) return null
            if (
              [ROUTES.onlineHelp, ROUTES.statOnline].includes(MENU[key as TKey].link) &&
              !users.user.channels.includes(String(CHANNELS_ID.ONLINE))
            )
              return null

            if (
              [ROUTES.letter, ROUTES.statWritten].includes(MENU[key as TKey].link) &&
              !users.user.channels.includes(String(CHANNELS_ID.WRITTEN))
            )
              return null

            if (
              [ROUTES.chatsSales, ROUTES.statSales].includes(MENU[key as TKey].link) &&
              !users.user.channels.includes(String(CHANNELS_ID.SALES))
            )
              return null

            if (
              [ROUTES.chatsTechSupport, ROUTES.statTechSupport].includes(MENU[key as TKey].link) &&
              !users.user.channels.includes(String(CHANNELS_ID.TECH_SUPPORT))
            )
              return null

            if (
              [ROUTES.chatsNews, ROUTES.statNews, ROUTES.mailing].includes(MENU[key as TKey].link) &&
              !users.user.channels.includes(String(CHANNELS_ID.NEWS))
            )
              return null

            if (
              [ROUTES.chatsUnauthorized, ROUTES.statUnauthorized].includes(MENU[key as TKey].link) &&
              !users.user.channels.includes(String(CHANNELS_ID.UNAUTHORIZED))
            )
              return null

            if (MENU[key as TKey].link === ROUTES.onlineHelp) {
              return <Item key={menuItm.title} menuItm={menuItm} handleClick={handleClick(idx, menuItm.link)} />
            }

            if (MENU[key as TKey].link === ROUTES.chatsTechSupport) {
              return <Item key={menuItm.title} menuItm={menuItm} handleClick={handleClick(idx, menuItm.link)} />
            }

            if (MENU[key as TKey].link === ROUTES.chatsSales) {
              return (
                <Item
                  key={menuItm.title}
                  menuItm={menuItm}
                  badgeContent={chatsSales.badgeContent}
                  handleClick={handleClick(idx, menuItm.link)}
                />
              )
            }

            if (MENU[key as TKey].link === ROUTES.chatsNews) {
              return (
                <Item
                  key={menuItm.title}
                  menuItm={menuItm}
                  badgeContent={chatsNews.badgeContent}
                  handleClick={handleClick(idx, menuItm.link)}
                />
              )
            }

            if (MENU[key as TKey].link === ROUTES.chatsUnauthorized) {
              return (
                <Item
                  key={menuItm.title}
                  menuItm={menuItm}
                  badgeContent={chatsUnauthorized.badgeContent}
                  handleClick={handleClick(idx, menuItm.link)}
                />
              )
            }

            return <Item key={menuItm.title} menuItm={menuItm} handleClick={handleClick(idx, menuItm.link)} />
          })}
        </List>

        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogout}>
              <WrapperIconS>
                <ExitToAppIcon sx={{ color: 'white' }} />
              </WrapperIconS>
              <ListItemText primary={'Вихід'} sx={{ color: 'white' }} />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
  )
}

const WrapperIconS = styled(ListItemIcon)`
  min-width: 40px;
`
