import React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { getActionHistory } from '../../../store/chatsWritten/actions'
import { IActionHistory } from '../../../store/chatsWritten/types'
import { styles } from './Styles'
import CircularProgress from '@mui/material/CircularProgress'
import { SIZE_PROG } from '../../../utils/consts'
import moment from 'moment'

interface Column {
  id: 'adate' | 'note' | 'fio'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'adate', label: 'Час і дата' },
  { id: 'note', label: 'Дія' },
  { id: 'fio', label: 'Експерт' },
]

interface Props {
  closeHandler: () => void
  open: boolean
  messageId: number
  partId: number
}

export const HistoryModal: React.FC<Props> = ({ open, messageId, partId, closeHandler }) => {
  const [list, setList] = React.useState<IActionHistory[]>([])
  const [loading, setLoading] = React.useState(false)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (messageId) {
      setLoading(true)
      dispatch(
        getActionHistory({
          message_id: messageId,
          part_id: partId,
        })
      ).then((res) => {
        if (res.meta.requestStatus === 'fulfilled') {
          setList(res.payload as IActionHistory[])
        }
        setLoading(false)
      })
    }
  }, [])

  const renderBody = () => {
    if (loading)
      return (
        <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
          <CircularProgress size={SIZE_PROG} />
        </Box>
      )

    return (
      <TableContainer sx={{ maxHeight: 600 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ fontWeight: 'bold' }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((row) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.absnum}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    if (column.id === 'adate')
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value ? moment(value).format('DD.MM.YYYY HH:mm') : null}
                        </TableCell>
                      )
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value as string}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    )
  }

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      disableEnforceFocus
    >
      <Box sx={{ ...styles, width: 960, p: 1 }}>{renderBody()}</Box>
    </Modal>
  )
}
