import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { GroupForm } from '../../../components/Templates/GroupForm'
import { useAppDispatch } from '../../../store'
import { updateGroup } from '../../../store/templatesUnauthorized/actions'
import { IGroupTemplate } from '../../../store/templates/types'
import { IStore } from '../../../store/types'
import { ROUTES } from '../../../utils/routes'
import { TypeTemplates } from '../../../utils/consts'

export const UnauthorizedEditGroup: React.FC = () => {
  const [group, setGroup] = React.useState<IGroupTemplate | null>(null)

  const { templatesUnauthorized } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id } = useParams()

  React.useEffect(() => {
    if (templatesUnauthorized.groups) {
      const group = templatesUnauthorized.groups?.find((group) => group.absnum === Number(id))
      if (group) {
        setGroup(group)
      }
    }
  }, [templatesUnauthorized.groups, id])

  return (
    <GroupForm
      title={'Редагувати групу'}
      data={group}
      handleSubmit={(values, callback) => {
        if (!group) return null
        dispatch(updateGroup({ id: group.absnum, data: { ...values, is_type: TypeTemplates.UNAUTHORIZED } })).then(
          (res) => {
            if (res.meta.requestStatus !== 'fulfilled') {
              callback(false)
            } else {
              navigate(ROUTES.templatesUnauthorized)
            }
          }
        )
      }}
    />
  )
}
