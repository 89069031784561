export const SocketEvents = {
  JOIN_ADMIN: 'JOIN_ADMIN',

  CHECK_CONNECT: 'CHECK_CONNECT',

  UPDATE_CHAT: 'UPDATE_CHAT',
  ADD_CHAT: 'ADD_CHAT',
  CAPTURE_CHAT: 'CAPTURE_CHAT',
  REMOVE_CHAT: 'REMOVE_CHAT',
  UPDATE_CHAT_CONSULTANT: 'UPDATE_CHAT_CONSULTANT',

  UPDATE_TECH_SUPPORT_CHAT: 'UPDATE_TECH_SUPPORT_CHAT',
  ADD_TECH_SUPPORT_CHAT: 'ADD_TECH_SUPPORT_CHAT',
  REMOVE_TECH_SUPPORT_CHAT: 'REMOVE_TECH_SUPPORT_CHAT',
  CAPTURE_TECH_SUPPORT_CHAT: 'CAPTURE_TECH_SUPPORT_CHAT',

  UPDATE_SALES_CHAT: 'UPDATE_SALES_CHAT',
  ADD_SALES_CHAT: 'ADD_SALES_CHAT',
  REMOVE_SALES_CHAT: 'REMOVE_SALES_CHAT',
  CAPTURE_SALES_CHAT: 'CAPTURE_SALES_CHAT',

  UPDATE_NEWS_CHAT: 'UPDATE_NEWS_CHAT',
  ADD_NEWS_CHAT: 'ADD_NEWS_CHAT',
  REMOVE_NEWS_CHAT: 'REMOVE_NEWS_CHAT',
  CAPTURE_NEWS_CHAT: 'CAPTURE_NEWS_CHAT',

  UPDATE_UNAUTHORIZED_CHAT: 'UPDATE_UNAUTHORIZED_CHAT',
  ADD_UNAUTHORIZED_CHAT: 'ADD_UNAUTHORIZED_CHAT',
  REMOVE_UNAUTHORIZED_CHAT: 'REMOVE_UNAUTHORIZED_CHAT',
  CAPTURE_UNAUTHORIZED_CHAT: 'CAPTURE_UNAUTHORIZED_CHAT',

  CREATE_UNAUTHORIZED_MESSAGE: 'CREATE_UNAUTHORIZED_MESSAGE',
  UPDATE_UNAUTHORIZED_MESSAGE: 'UPDATE_UNAUTHORIZED_MESSAGE',

  UPDATE_MAILING: 'UPDATE_MAILING',

  CREATE_MESSAGE_MINI_CHAT: 'CREATE_MESSAGE_MINI_CHAT',

  CREATE_ONLINE_MESSAGE: 'CREATE_ONLINE_MESSAGE',
  UPDATE_ONLINE_MESSAGE: 'UPDATE_ONLINE_MESSAGE',
  NOTIFICATIONS_ONLINE: 'NOTIFICATIONS_ONLINE',

  CREATE_TECH_SUPPORT_MESSAGE: 'CREATE_TECH_SUPPORT_MESSAGE',
  UPDATE_TECH_SUPPORT_MESSAGE: 'UPDATE_TECH_SUPPORT_MESSAGE',
  NOTIFICATIONS_TECH_SUPPORT: 'NOTIFICATIONS_TECH_SUPPORT',

  CREATE_SALES_MESSAGE: 'CREATE_SALES_MESSAGE',
  UPDATE_SALES_MESSAGE: 'UPDATE_SALES_MESSAGE',

  CREATE_NEWS_MESSAGE: 'CREATE_NEWS_MESSAGE',
  UPDATE_NEWS_MESSAGE: 'UPDATE_NEWS_MESSAGE',

  CREATE_WRITTEN_MESSAGE: 'CREATE_WRITTEN_MESSAGE',
  UPDATE_WRITTEN_MESSAGE: 'UPDATE_WRITTEN_MESSAGE',
  DELETE_WRITTEN_MESSAGE: 'DELETE_WRITTEN_MESSAGE',

  USER_CHANGE_STATUS: 'USER_CHANGE_STATUS',
}
