import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IEmployeesState, TCreateEmployeeR, TGetEmployeesR, TUpdateEmployeeR } from './types'

export const getEmployees = createAsyncThunk<IEmployeesState['data'], TGetEmployeesR>(
  'employees/getEmployees',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/employees?${query}` : '/employees',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const searchEmployees = createAsyncThunk<IEmployeesState['data'], TGetEmployeesR>(
  'employees/searchEmployees',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/employees?${query}` : '/employees',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getEmployee = createAsyncThunk<IEmployeesState['current'], number>(
  'employees/getEmployee',
  async (payload, thunkAPI) => {
    try {
      const id = payload

      return callApi({
        method: 'get',
        path: `/employees/employee/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const createEmployee = createAsyncThunk<IEmployeesState['current'], TCreateEmployeeR>(
  'employees/createEmployee',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: '/employees/employee',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateEmployee = createAsyncThunk<IEmployeesState['current'], TUpdateEmployeeR>(
  'employees/updateEmployee',
  async (payload, thunkAPI) => {
    try {
      const { id, data } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/employees/employee/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const changePasswordEmployee = createAsyncThunk<void, { userId: number; data: { password: string } }>(
  'employees/changePasswordEmployee',
  async (payload, thunkAPI) => {
    try {
      const { userId, data } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/employees/password/${userId}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const deleteEmployee = createAsyncThunk<IEmployeesState['current'], number>(
  'employees/deleteEmployee',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'delete',
        data: {},
        path: `/employees/employee/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const changeStatusUserOffline = createAsyncThunk<
  IEmployeesState['current'],
  { id: number; data: { channelId: number } }
>('employees/changeStatusUserOffline', async (payload, thunkAPI) => {
  try {
    const { id, data } = payload
    return callApi({
      method: 'post',
      data,
      path: `/employees/status/offline/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const transferAllChats = createAsyncThunk<string, number>(
  'employees/transferAllChats',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/employees/transfer-chats/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const uploadFile = createAsyncThunk<{ link: string }, FormData>(
  'employees/uploadFile',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        isFormData: true,
        path: `/employees/upload`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
