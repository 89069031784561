export const URI = process.env.REACT_APP_URL?.trim() || 'https://helper-api.expertus.com.ua'
export const API_URL = URI + '/api'

export const TOKEN = {
  access: 'JPROOEmnRTe534nFEmf',
  refresh: 'EKMPROFEFNKETtkome',
}

interface IClarifyTemplate {
  clientName?: string
  siteName?: string
  siteAddress?: string
  targetAddress?: string
  question?: string
  messageId?: string
  fioExpert?: string
}
export const clarifyTemplate = (data: IClarifyTemplate) => `<div>
<div>
<p>Вітаємо, ${data.clientName}!&nbsp; <br>Ми отримали вашу робочу ситуацію за номером ${data.messageId}:&nbsp;</p>
</div>
<div>
<p>&laquo;${data.question}&raquo;</p>
</div>
<div>
<p>Щоб підготувати вашу робочу ситуацію до публікування у базі даних системи, маємо уточнити деталі. Будь ласка, перейдіть за посиланням та залиште коментар для експерта:&nbsp;</p>
</div>
<div>
<p><a href="${data.targetAddress}" target="_blank" rel="noreferrer noopener">Доповнити</a> робочу ситуацію &nbsp;</p>
</div>
<div>
<p>Дякуємо! Продуктивної роботи!&nbsp;</p>
</div>
<div>
<p>Із повагою<br>експерт системи&nbsp;&laquo;${data.siteName}&raquo;,&nbsp;${data.fioExpert} &nbsp;<br>0 800 21 38 38 &nbsp;<br><a href="${data.targetAddress}" target="_blank" rel="noreferrer noopener">${data.siteAddress}</a> </p>
</div>
</div>
`

export const valiarntsRefusal = [
  {
    absnum: 1,
    name: 'Запитання не належить до нашої тематики',
    body: (data: IClarifyTemplate) => `<p>Добрий день, ${data.clientName}!</p>
    <p>На жаль, ми не можемо опублікувати вашу робочу ситуацію у базі даних системи, оскільки вона виходить за межі тематики бази даних системи.</p> 
    <p>З повагою експерт Системи «${data.siteName}» ${data.fioExpert}</p> 
    <p>тел.: 0 800 21 38 38</p>
    <p><a href="${data.siteAddress}" target="_blank" rel="noopener noreferrer">${data.siteAddress}</a></p>
    `,
  },
  {
    absnum: 2,
    name: 'Скасоване відповідно до правил роботи експертної підтримки',
    body: (data: IClarifyTemplate) => `<p>Добрий день, ${data.clientName}!</p>
    <p>Дякуємо, що користуєтеся системою ${data.siteName} та Онлайн-помічником. Проте згідно з правилами роботи експертної підтримки, на жаль, ми вимушені відмовити у публікуванні вашої робочої ситуації у базі даних системи, оскільки:</p>
    <br> 
    <p>З повагою експерт Системи «${data.siteName}» ${data.fioExpert}</p> 
    <p>тел.: 0 800 21 38 38</p>
    <p><a href="${data.siteAddress}" target="_blank" rel="noopener noreferrer">${data.siteAddress}</a></p>`,
  },
]
