import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { ISearchChatsUnauthorizedState } from './types'
import { IChatUnauthorized, IMessagesUnauthorizedResponse } from '../chatsUnauthorized/types'

export const searchChats = createAsyncThunk<
  ISearchChatsUnauthorizedState['data'],
  ISearchChatsUnauthorizedState['searchFields']
>('searchChatsUnauthorized/searchChats', async (payload, thunkAPI) => {
  try {
    const query = buildQuery(payload)

    return callApi({
      method: 'get',
      path: query ? `/admin-chat-unauthorized/search?${query}` : '/admin-chat-unauthorized/search',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const getDialog = createAsyncThunk<ISearchChatsUnauthorizedState['dialog'], number>(
  'chatsOnline/getDialog',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-unauthorized/dialog/chat/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getDialogMessages = createAsyncThunk<IMessagesUnauthorizedResponse, { id: number; page: number }>(
  'searchChatsUnauthorized/getDialogMessages',
  async (payload, thunkAPI) => {
    try {
      const { id, page } = payload
      return callApi({
        method: 'get',
        path: page
          ? `/admin-chat-unauthorized/dialog/messages/${id}?page=${page}`
          : 'admin-chat-unauthorized/dialog/messages',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
