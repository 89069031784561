import React from 'react'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import TextField from '@mui/material/TextField'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Title } from '../Title'
import {
  Button,
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { BasicDatePicker } from '../../ui/BasicDatePicker'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { ISearchChat } from '../../store/searchChatsOnline/types'
import { ROUTES } from '../../utils/routes'
import { useNavigate } from 'react-router-dom'
import { SelectSearch } from '../../ui/SelectSearch'
import { checkFio } from '../../utils/checkFio'
import moment from 'moment'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const names = [
  { value: '1', label: 'Дуже Погано' },
  { value: '2', label: 'Погано' },
  { value: '3', label: 'Нормально' },
  { value: '4', label: 'Добре' },
  { value: '5', label: 'Відмінно' },
]

interface Props {
  products: { absnum: number; name: string }[] | null
  types?: { value: string; label: string }[]
  statuses: { value: string | number; label: string }[]
  searchChats: (values: ISearchChat, callback?: (success?: boolean) => void) => void
}

export const Search: React.FC<Props> = ({ products, types, statuses, searchChats }) => {
  const [client_fio, setClient_fio] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [ratings, setRatings] = React.useState<string[]>([])
  const [date_start, setDate_start] = React.useState<Date | null | undefined>(null)
  const [date_end, setDate_end] = React.useState<Date | null | undefined>(null)
  const [expert_id, setExpert_id] = React.useState('')
  const [product_id, setProduct_id] = React.useState('')
  const [status, setStatus] = React.useState('')
  const [type, setType] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const { employees } = useSelector((store: IStore) => store)
  const navigate = useNavigate()

  const handleChange2 = (event: SelectChangeEvent<typeof ratings>) => {
    const {
      target: { value },
    } = event
    setRatings(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const hanldeApprove = () => {
    const str = ratings
      .map((label) => names.find((it) => it.label === label)?.value)
      .filter((it) => !!it)
      .join(',')

    setLoading(true)
    searchChats(
      {
        client_fio,
        email,
        ratings: str ? str : '',
        date_start: date_start ? moment(date_start).format('YYYY-MM-DD HH:mm') : '',
        date_end: date_end ? moment(date_end).format('YYYY-MM-DD HH:mm') : '',
        expert_id,
        product_id,
        status,
        is_dialog: type,
      },
      () => setLoading(false)
    )
    navigate(ROUTES.changeSearch(''))
  }

  return (
    <Box px={2}>
      <Title title={'Пошук по чатам'} />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Stack spacing={3}>
          <BasicDatePicker id={'date_start'} label='Час початку' value={date_start} onChange={setDate_start} />
          <BasicDatePicker id={'date_end'} label='Час кінця' value={date_end} onChange={setDate_end} />
          <FormControl sx={{ mt: 1 }} fullWidth>
            <InputLabel size={'small'} id='demo-multiple-checkbox-label'>
              Оцінка
            </InputLabel>
            <Select
              size={'small'}
              labelId='demo-multiple-checkbox-label'
              id='demo-multiple-checkbox'
              multiple
              value={ratings}
              onChange={handleChange2}
              input={<OutlinedInput sx={{ color: '#000' }} label='Оцінка' />}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}
            >
              {names.map((item) => (
                <MenuItem key={item.value} value={item.label}>
                  <Checkbox checked={!!ratings.find((it) => it === item.label)} />
                  <ListItemText primary={item.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            size={'small'}
            id='outlined-basic-id-1'
            label='ПІБ'
            variant='outlined'
            value={client_fio}
            onChange={(event) => {
              setClient_fio(event.target.value)
            }}
          />
          <TextField
            size={'small'}
            id='outlined-basic-id-1'
            label='Email'
            variant='outlined'
            value={email}
            onChange={(event) => {
              setEmail(event.target.value)
            }}
          />
          <SelectSearch
            label='Консультант'
            value={expert_id}
            options={employees.data?.employees.map((item) => ({ value: String(item.id), label: checkFio(item) })) || []}
            onChange={setExpert_id}
          />
          {products ? (
            <FormControl fullWidth>
              <InputLabel size={'small'} id='simple-select'>
                Продукт
              </InputLabel>
              <Select
                size={'small'}
                labelId='simple-select'
                id='simple-select'
                label='Продукт'
                value={product_id}
                onChange={(e) => {
                  setProduct_id(e.target.value)
                }}
              >
                <MenuItem value={''}>Усі</MenuItem>
                {products.map((item) => (
                  <MenuItem key={item.absnum} value={item.absnum}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : null}
          {types && (
            <FormControl fullWidth>
              <InputLabel size={'small'} id='simple-select'>
                Тип чату
              </InputLabel>
              <Select
                size={'small'}
                labelId='simple-select'
                id='simple-select'
                label='Тип чату'
                value={type}
                onChange={(e) => {
                  setType(e.target.value)
                }}
              >
                <MenuItem value={''}>Усі</MenuItem>
                {types.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
          <FormControl fullWidth>
            <InputLabel size={'small'} id='simple-select'>
              Статус чату
            </InputLabel>
            <Select
              size={'small'}
              labelId='simple-select'
              id='simple-select'
              label='Статус чату'
              value={status}
              onChange={(e) => {
                setStatus(e.target.value)
              }}
            >
              <MenuItem value={''}>Усі</MenuItem>
              {statuses.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box>
            <Button disabled={loading} variant={'contained'} onClick={hanldeApprove}>
              Застосувати
            </Button>
          </Box>
        </Stack>
      </LocalizationProvider>
    </Box>
  )
}
