import { AsyncThunk } from '@reduxjs/toolkit'
import React from 'react'
import { useAppDispatch } from '../store'
import { TIMEOUT_REQ } from '../utils/consts'
import { debounce } from '../utils/debounce'

interface PropsType {
  actionThunk: AsyncThunk<any, any, any>
}

export const useSearch = ({ actionThunk }: PropsType) => {
  const [loading, setLoading] = React.useState(false)

  const dispatch = useAppDispatch()

  const requestSearch = (value: string, query: string, options?: { [x: string]: string }) => {
    setLoading(true)
    const obj: { [x: string]: string } = {}
    if (options) {
      Object.keys(options).map((key) => (obj[key] = options[key]))
    }
    obj[query] = value
    dispatch(actionThunk(obj)).finally(() => setLoading(false))
  }

  // eslint-disable-next-line
  const handleSearch = React.useCallback(
    debounce((data, query, options?: { [x: string]: string }) => {
      requestSearch(data, query, options)
    }, TIMEOUT_REQ),
    [actionThunk]
  )

  return {
    loading,
    handleSearch,
  }
}
