import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IDtoSearch, IStatSalesState } from './types'

export const getStat = createAsyncThunk<IStatSalesState['data'], IDtoSearch>(
  'statSales/getStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-sales/stat?${query}` : '/admin-chat-sales/stat',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const searchStat = createAsyncThunk<IStatSalesState['data'], IDtoSearch>(
  'statSales/searchStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-sales/stat?${query}` : '/admin-chat-sales/stat',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStat = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statSales/downlaodStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-sales/stat/download?${query}` : '/admin-chat-sales/stat/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatBlock2 = createAsyncThunk<IStatSalesState['dataRating'], IDtoSearch>(
  'statSales/getStatBlock2',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-sales/stat/block2?${query}` : '/admin-chat-sales/stat/block2',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const searchStatBlock2 = createAsyncThunk<IStatSalesState['dataRating'], IDtoSearch>(
  'statSales/searchStatBlock2',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-sales/stat/block2?${query}` : '/admin-chat-sales/stat/block2',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStatBlock2 = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statSales/downlaodStatBlock2',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-sales/stat/block2/download?${query}` : '/admin-chat-sales/stat/block2/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStatBlock3 = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statSales/downlaodStatBlock3',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-sales/stat/block3/download?${query}` : '/admin-chat-sales/stat/block3/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
