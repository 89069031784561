import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { GroupForm } from '../../components/Templates/GroupForm'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import { ROUTES } from '../../utils/routes'
import { updateAppeal } from '../../store/appeal/actions'
import { IAppeal } from '../../store/appeal/types'

export const EditAppeal: React.FC = () => {
  const [data, setData] = React.useState<IAppeal | null>(null)

  const { appeal } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id } = useParams()

  React.useEffect(() => {
    if (appeal.list) {
      const item = appeal.list.find((item) => item.absnum === Number(id))
      if (item) {
        setData(item)
      }
    }
  }, [appeal.list, id])

  return (
    <GroupForm
      title={'Редагувати тип звернення'}
      label={'Назва типу'}
      data={data}
      handleSubmit={(values, callback) => {
        if (!data) return null
        dispatch(updateAppeal({ id: data.absnum, data: values })).then((res) => {
          if (res.meta.requestStatus !== 'fulfilled') {
            callback(false)
          } else {
            navigate(ROUTES.settingsUnauthorized)
          }
        })
      }}
    />
  )
}
