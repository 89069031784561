import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '../../utils/callApi'
import { ILogChatsState } from './types'
import { buildQuery } from '../../utils/buildQuery'
import { CHANNELS_ID, DialogType, LogChatTypes } from '../../utils/consts'

interface ILogChat {
  channel_id?: CHANNELS_ID
  date_from?: string
  date_to?: string
  dialog_id?: number
  user_id?: number
  log_chat_type_id?: LogChatTypes
  chat_type_id?: DialogType
  status?: number
  page?: number
}
export const getLogChats = createAsyncThunk<ILogChatsState['data'], ILogChat>(
  'logChats/getLogChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: `/log-chat${query ? `?${query}` : ''}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downloadLogChats = createAsyncThunk<Buffer, { year: number; month: string }>(
  'logChats/downloadLogChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: `/log-chat/download${query ? `?${query}` : ''}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
