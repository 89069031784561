import { createAsyncThunk } from '@reduxjs/toolkit'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IDtoSearch } from '../statSales/types'
import { IStatNewsState } from './types'

export const getStat = createAsyncThunk<IStatNewsState['data'], IDtoSearch>(
  'statNews/getStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news/stat?${query}` : '/admin-chat-news/stat',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const searchStat = createAsyncThunk<IStatNewsState['data'], IDtoSearch>(
  'statNews/searchStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news/stat?${query}` : '/admin-chat-news/stat',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStat = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statNews/downlaodStat',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news/stat/download?${query}` : '/admin-chat-news/stat/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getStatBlock2 = createAsyncThunk<IStatNewsState['dataRating'], IDtoSearch>(
  'statNews/getStatBlock2',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news/stat/block2?${query}` : '/admin-chat-news/stat/block2',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const searchStatBlock2 = createAsyncThunk<IStatNewsState['dataRating'], IDtoSearch>(
  'statNews/searchStatBlock2',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news/stat/block2?${query}` : '/admin-chat-news/stat/block2',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStatBlock2 = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statNews/downlaodStatBlock2',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news/stat/block2/download?${query}` : '/admin-chat-news/stat/block2/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const downlaodStatBlock3 = createAsyncThunk<{ data: Buffer }, IDtoSearch>(
  'statNews/downlaodStatBlock3',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-news/stat/block3/download?${query}` : '/admin-chat-news/stat/block3/download',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
