import { createSlice } from '@reduxjs/toolkit'
import { EMainView, IStore } from './types'

const initialState: IStore['mainView'] = EMainView.chat

export const mainViewSlice = createSlice({
  name: 'mainView',
  initialState,
  reducers: {
    setMainView: (state, action) => action.payload,
  },
})

export const { setMainView } = mainViewSlice.actions

export default mainViewSlice.reducer
