import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Stack } from '@mui/material'

const style = {
  maxHeight: '600px',
  overflow: 'auto',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

interface Props {
  closeHandler: () => void
  open: boolean
  title: string
}

export const WarningModal: React.FC<Props> = ({ open, closeHandler, title }) => {
  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          {title}
        </Typography>
        <Stack pt={4} spacing={2} direction={'row'}>
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            Закрити
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
