import { createAsyncThunk } from '@reduxjs/toolkit'
import { callApi } from '../../utils/callApi'
import { IProductsState } from './types'

export const getProducts = createAsyncThunk<IProductsState['products']>('products/getProducts', async (_, thunkAPI) => {
  try {
    return callApi({
      method: 'get',
      path: '/admin-office/products',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})
