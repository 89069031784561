import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import usersReducer from './users'
import productsReducer from './products'
import chatsOnlineReducer from './chatsOnline'
import chatsSalesReducer from './chatsSales'
import searchChatsOnlineReducer from './searchChatsOnline'
import searchChatsSalesReducer from './searchChatsSales'
import chatsWrittenReducer from './chatsWritten'
import templatesReducer from './templates'
import configurationsReducer from './configurations'
import employeesReducer from './employees'
import mainViewReducer from './mainViewSlice'
import rightSidebarReducer from './rightSidebarSlice'
import techSupportReducer from './techSupport'
import statTechSupportReducer from './statTechSupport'
import statSalesReducer from './statSales'
import searchChatsTechSupport from './searchChatsTechSupport'
import templatesSalesReducer from './templatesSales'
import templatesTechSupportReducer from './templatesTechSupport'
import chatsNewsReducer from './chatsNews'
import searchChatsNewsReducer from './searchChatsNews'
import templatesNewsReducer from './templatesNews'
import mailingReducer from './mailing'
import statMailingReducer from './statMailing'
import statNewsReducer from './statNews'
import statOnlineReducer from './statOnline'
import statWrittenReducer from './statWritten'
import statUnauthorizedReducer from './statUnauthorized'
import searchChatsUnauthorizedReducer from './searchChatsUnauthorized'
import chatsUnauthorizedReducer from './chatsUnauthorized'
import templatesUnauthorizedReducer from './templatesUnauthorized'
import appealReducer from './appeal'
import logChatsReducer from './logChats'

const rootReducer = combineReducers({
  appeal: appealReducer,
  users: usersReducer,
  chatsOnline: chatsOnlineReducer,
  chatsSales: chatsSalesReducer,
  chatsUnauthorized: chatsUnauthorizedReducer,
  techSupport: techSupportReducer,
  statTechSupport: statTechSupportReducer,
  statSales: statSalesReducer,
  statUnauthorized: statUnauthorizedReducer,
  statNews: statNewsReducer,
  statOnline: statOnlineReducer,
  statMailing: statMailingReducer,
  statWritten: statWrittenReducer,
  chatsNews: chatsNewsReducer,
  searchChatsNews: searchChatsNewsReducer,
  searchChatsOnline: searchChatsOnlineReducer,
  searchChatsSales: searchChatsSalesReducer,
  searchChatsUnauthorized: searchChatsUnauthorizedReducer,
  searchChatsTechSupport: searchChatsTechSupport,
  chatsWritten: chatsWrittenReducer,
  mainView: mainViewReducer,
  products: productsReducer,
  templates: templatesReducer,
  templatesNews: templatesNewsReducer,
  templatesSales: templatesSalesReducer,
  templatesUnauthorized: templatesUnauthorizedReducer,
  templatesTechSupport: templatesTechSupportReducer,
  configurations: configurationsReducer,
  employees: employeesReducer,
  rightSidebar: rightSidebarReducer,
  mailing: mailingReducer,
  logChats: logChatsReducer,
})

export const store = configureStore({
  reducer: rootReducer,
})

export const useAppDispatch = () => useDispatch<typeof store.dispatch>()
