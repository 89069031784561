import React from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../../store'
import { createTemplate } from '../../../store/templatesSales/actions'
import { IStore } from '../../../store/types'
import { TamplateForm } from '../../../components/Templates/TamplateForm'
import { TypeTemplates } from '../../../utils/consts'

export const SalesCreateTamplate: React.FC = () => {
  const { templatesSales } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  const handleCreate = (
    values: { group_id?: number; product_id: number; message: string },
    callback: (success: boolean) => void
  ) => {
    if (!values.group_id) return callback(false)
    dispatch(
      createTemplate({
        group_id: values.group_id,
        message: values.message,
        product_id: values.product_id,
        is_type: TypeTemplates.SALES,
      })
    ).then((res) => {
      if (res.meta.requestStatus !== 'fulfilled') {
        callback(false)
      } else {
        callback(true)
      }
    })
  }

  if (!templatesSales.currentGroup) return null

  return (
    <TamplateForm
      title={'Створити шаблон'}
      groupId={templatesSales.currentGroup.absnum}
      createTemplate={handleCreate}
    />
  )
}
