import React from 'react'
import { RequireAuth } from './RequireAuth'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { Roles } from '../../utils/consts'

interface Props {
  element: JSX.Element
}

export const Chief: React.FC<Props> = ({ element }) => {
  const { users } = useSelector((store: IStore) => store)

  if (users.user && users.user.role < Roles.CHIEF) {
    return null
  }

  return <RequireAuth>{element}</RequireAuth>
}
