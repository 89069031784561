import React from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { SearchWorkspace } from '../../components/SearchWorkspace'
import { useAppDispatch } from '../../store'
import { STATUSES_CHAT_TECH } from '../../utils/consts'
import { searchChats } from '../../store/statMailing/actions'
import { ROUTES } from '../../utils/routes'

export const SearchChatMailing: React.FC = () => {
  const { statMailing } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [params] = useSearchParams()

  React.useEffect(() => {
    dispatch(searchChats({ mailing_id: params.get('mailing_id') }))
  }, [params])

  const handleChangePage = (page: number) => {
    if (!statMailing.data) return null
    if (statMailing.data.pages >= page) {
      dispatch(searchChats({ page }))
    }
  }

  const handleGetDialog = (dialogId: number) => {
    navigate(ROUTES.mailingChange(`chats/${dialogId}`))
  }

  return (
    <SearchWorkspace
      types={STATUSES_CHAT_TECH}
      data={statMailing.data}
      changePage={handleChangePage}
      getDialog={handleGetDialog}
      comeBack={() => navigate(-1)}
    />
  )
}
