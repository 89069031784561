import { createTheme } from '@mui/material/styles'

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#fff',
    },
  },
})

export const customTheme = createTheme({
  palette: {
    primary: {
      // main: '#1A82C5FF',
      main: '#1A82C5FF',
    },
  },
  typography: {
    button: {
      textTransform: 'inherit',
    },
  },
})
