import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { GroupForm } from '../../../components/Templates/GroupForm'
import { useAppDispatch } from '../../../store'
import { updateGroup } from '../../../store/templates/actions'
import { IGroupTemplate } from '../../../store/templates/types'
import { IStore } from '../../../store/types'
import { ROUTES } from '../../../utils/routes'

export const OnlineEditGroup: React.FC = () => {
  const [group, setGroup] = React.useState<IGroupTemplate | null>(null)

  const { templates } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const { id } = useParams()

  React.useEffect(() => {
    if (templates.groups) {
      const group = templates.groups?.find((group) => group.absnum === Number(id))
      if (group) {
        setGroup(group)
      }
    }
  }, [templates.groups, id])

  return (
    <GroupForm
      title={'Редагувати групу'}
      data={group}
      handleSubmit={(values, callback) => {
        if (!group) return null
        dispatch(updateGroup({ id: group.absnum, data: values })).then((res) => {
          if (res.meta.requestStatus !== 'fulfilled') {
            callback(false)
          } else {
            navigate(ROUTES.templates)
          }
        })
      }}
    />
  )
}
