import { Box, Stack, Button, IconButton, ThemeProvider, Tooltip } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { darkTheme } from '../../utils/darkTheme'
import { ROUTES } from '../../utils/routes'
import { SectionTitleWrapper } from '../SectionTitleWrapper'
import { Title } from '../Title'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import EditIcon from '@mui/icons-material/Edit'
import { IGroupTemplate } from '../../store/templates/types'

const justifyContent = 'flex-start'

interface Props {
  groups: IGroupTemplate[] | null
  setCurrentGroup: (group: IGroupTemplate) => void
}

export const ListGroupTemplates: React.FC<Props> = ({ groups, setCurrentGroup }) => {
  const [val, setVal] = React.useState(0)
  const navigate = useNavigate()

  return (
    <Box>
      <SectionTitleWrapper>
        <>
          <Title title={'Налаштування шаблонів'} />
          <ThemeProvider theme={darkTheme}>
            <Tooltip title='Створити групу'>
              <IconButton onClick={() => navigate(ROUTES.onlineHelpCreateGroup('create'))}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </ThemeProvider>
        </>
      </SectionTitleWrapper>
      <Stack direction={'column'} alignItems={'flex-start'}>
        {groups?.map((group) => (
          <Stack
            width={'100%'}
            direction={'row'}
            style={{ background: group.absnum === val ? 'rgba(25, 118, 210, 0.08)' : '' }}
            key={`group_${group.absnum}`}
          >
            <Button
              onClick={() => {
                setVal(group.absnum)
                navigate(ROUTES.onlineHelpGroup(group.absnum))
                setCurrentGroup(group)
              }}
              size={'large'}
              fullWidth
              sx={{ justifyContent, textAlign: 'left' }}
            >
              {group.name}
            </Button>
            <IconButton
              color='primary'
              onClick={() => {
                setVal(group.absnum)
                navigate(ROUTES.onlineHelpEditGroup(group.absnum))
              }}
            >
              <EditIcon />
            </IconButton>
          </Stack>
        ))}
      </Stack>
    </Box>
  )
}
