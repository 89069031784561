import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { getStatRaiting, getStatQuantity, getStatQuantityAndExpert, getStatCountSession } from './actions'

const initialState: IStore['statWritten'] = {
  statDataRating: null,
  statQuantity: null,
  statQuantityAndExpert: null,
  session: null,
}

export const slice = createSlice({
  name: 'statWritten',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatQuantity.fulfilled, (state, action) => {
      state.statQuantity = action.payload
    })
    builder.addCase(getStatQuantityAndExpert.fulfilled, (state, action) => {
      state.statQuantityAndExpert = action.payload
    })
    builder.addCase(getStatRaiting.fulfilled, (state, action) => {
      state.statDataRating = action.payload
    })
    builder.addCase(getStatCountSession.fulfilled, (state, action) => {
      state.session = action.payload
    })
  },
})

export const StatWrittenActions = slice.actions

export default slice.reducer
