import React from 'react'
import { ChatHeaderInfo } from '../../components/ChatHeaderInfo'
import Box from '@mui/material/Box'
import { Chat } from '../../components/Chat'
import { TechChatStatus, InnerHeight, RightSidebarType, SalesChatStatus } from '../../utils/consts'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { useAppDispatch } from '../../store'
import {
  createMessage,
  getCurrentChat,
  getMessages,
  readMessage,
  updateDraftMessage,
  updateMessage,
  uploadFile,
} from '../../store/chatsNews/actions'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from '../../hooks/useQuery'
import { ChatsNewsActions } from '../../store/chatsNews'
import { ROUTES } from '../../utils/routes'

export const ChatWorkspaceNews: React.FC = () => {
  const [search, setSearch] = React.useState('')
  const { chatsNews, templatesNews, users, configurations } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const status = useQuery().get('status')
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!Number.isNaN(Number(id))) {
      dispatch(getCurrentChat(Number(id))).then((res) => {
        if (res.meta.requestStatus === 'rejected') {
          navigate(ROUTES.routeChange(''))
        }
      })
    } else {
      dispatch(ChatsNewsActions.setCurrentChat(null))
    }
  }, [chatsNews.clikedOnChat, id, status, users.socket])

  React.useEffect(() => {
    if (status !== null) {
      dispatch(ChatsNewsActions.changeStatus(Number(status)))
    }
  }, [status])

  const handleReadMessage = (id: number) => {
    dispatch(readMessage(id))
  }

  const handleChangePage = (page: number, callback?: () => void) => {
    if (chatsNews.currentChat) {
      dispatch(getMessages({ id: chatsNews.currentChat.absnum, page })).then(callback)
    }
  }

  const handleOpenSidebar = () => {
    dispatch(setRightSidebar(RightSidebarType.NEWS))
  }

  const handleCreateMessage = React.useCallback(
    (value: string, callback?: () => void) => {
      if (chatsNews.currentChat) {
        dispatch(
          createMessage({
            chat_id: chatsNews.currentChat.absnum,
            message: value,
          })
        ).then(callback)
      }
    },
    [chatsNews.currentChat?.absnum]
  )

  const handleEditMessage = React.useCallback(
    (id: number, value: string, callback?: () => void) => {
      dispatch(
        updateMessage({
          id,
          data: { message: value },
        })
      ).then(callback)
    },
    [chatsNews.currentChat?.absnum]
  )

  const handleSaveFile = React.useCallback(
    (file: File, callback?: (succes: boolean, data?: any) => void) => {
      if (chatsNews.currentChat) {
        const fd = new FormData()
        fd.append('file', file)
        fd.append('chat_id', String(chatsNews.currentChat.absnum))
        fd.append('file_name', file.name)
        dispatch(uploadFile(fd)).then((res) => {
          const success = res.meta.requestStatus === 'fulfilled'
          if (callback) callback(success, res)
        })
      }
    },
    [chatsNews.currentChat?.absnum]
  )

  const handleAutoSave = React.useCallback(
    (value: string, callback: () => void) => {
      if (!chatsNews.currentChat) return null
      dispatch(
        updateDraftMessage({
          id: chatsNews.currentChat.chat_id,
          data: { message: value },
        })
      ).finally(callback)
    },
    [chatsNews.currentChat?.absnum]
  )

  if (!chatsNews.currentChat || chatsNews.loadingChat) return null

  return (
    <Box px={3} height={InnerHeight} overflow={'auto'} display={'flex'} flexDirection={'column'}>
      {chatsNews.currentChat ? (
        <ChatHeaderInfo
          isBtn={
            TechChatStatus.CLOSE_CHAT !== chatsNews.currentChat.status &&
            TechChatStatus.TRANSFERED !== chatsNews.currentChat.status &&
            chatsNews.currentChat.expert_id === users.user?.id
          }
          currentChat={chatsNews.currentChat}
          siteUrl={configurations.all?.find((item) => item.absnum === chatsNews.currentChat?.site_id)?.url}
          openSidebar={handleOpenSidebar}
          country={chatsNews.currentChat.profile?.country || ''}
          handleSearch={setSearch}
        />
      ) : null}
      {chatsNews.currentChat.chat ? (
        <Chat
          searchText={search}
          chat={chatsNews.currentChat.chat}
          groups={templatesNews.groups}
          currentChat={chatsNews.currentChat}
          isEdit={[SalesChatStatus.MISSED, SalesChatStatus.OFFLINE, SalesChatStatus.ONLINE].includes(
            chatsNews.currentChat.status
          )}
          readMessage={handleReadMessage}
          changePage={handleChangePage}
          createMessage={handleCreateMessage}
          saveFile={handleSaveFile}
          handleEditMessage={handleEditMessage}
          handleAutoSave={
            ![SalesChatStatus.TRANSFERED, SalesChatStatus.CLOSE].includes(chatsNews.statusChat) ||
            ![SalesChatStatus.TRANSFERED, SalesChatStatus.CLOSE].includes(chatsNews.currentChat.status)
              ? handleAutoSave
              : undefined
          }
        />
      ) : null}
    </Box>
  )
}
