import * as React from 'react'
import Button from '@mui/material/Button'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ukLocale from 'date-fns/locale/uk'

interface Props {
  label?: string
  value?: Date | null
  disabled?: boolean
  onChange: (value: Date | null) => void
}

export const ButtonDatePicker: React.FC<Props> = ({ label, disabled, value, onChange }) => {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      {' '}
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ukLocale}>
        <DatePicker
          views={['year', 'month']}
          openTo='year'
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          onChange={() => null}
          onMonthChange={onChange}
          value={value}
          renderInput={() => (
            <Button disabled={disabled} variant='outlined' onClick={() => setOpen?.((prev) => !prev)}>
              {label}
            </Button>
          )}
        />
      </LocalizationProvider>
    </>
  )
}
