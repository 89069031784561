import React from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { Button, List, Stack, Typography } from '@mui/material'
import {
  passChat,
  passChatAutomate,
  passChatToTechSupoort,
  passChatToWritten,
  updateDraftMessage,
  uploadFile,
} from '../../store/chatsOnline/actions'
import { MessageSender } from './components/MessageSender'
import { parseLink } from '../../utils/parseLink'
import { ChooseEmployee } from './components/ChooseEmployee'
import { ConfirmModal } from '../modals/ConfirmModal'
import { searchEmployees } from '../../store/employees/actions'
import { CHANNELS_ID, StatusWork } from '../../utils/consts'

export const RightOnline: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | boolean>(false)
  const [loading, setLoading] = React.useState(false)
  const [is_approved, setApproved] = React.useState<{ is_tech_support: number; is_written: number }>({
    is_tech_support: 0,
    is_written: 0,
  })
  const [passEmpl, setPassEmpl] = React.useState<null | { id: number; fio: string }>(null)

  const { chatsOnline, configurations, employees, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (chatsOnline.currentChat) {
      dispatch(
        searchEmployees({
          channels: String(CHANNELS_ID.ONLINE),
          status: StatusWork.ONLINE,
          product_id: chatsOnline.currentChat.product_id,
        })
      )
    }
  }, [employees.data, chatsOnline.currentChat])

  React.useEffect(() => {
    if (configurations.all && chatsOnline.currentChat) {
      const config = configurations.all.find((item) => item.absnum === chatsOnline.currentChat?.site_id)
      if (config) {
        setApproved({
          is_tech_support: config.approved_tech,
          is_written: config.approved_written,
        })
      }
    }
  }, [configurations.all, chatsOnline.currentChat])

  const handleSaveFile = (file: File, callback?: (succes: boolean, data?: any) => void) => {
    if (chatsOnline.currentChat) {
      const fd = new FormData()
      fd.append('file', file)
      fd.append('chat_id', String(chatsOnline.currentChat.absnum))
      fd.append('file_name', file.name)
      dispatch(uploadFile(fd)).then((res) => {
        const success = res.meta.requestStatus === 'fulfilled'
        if (callback) callback(success, res)
      })
    }
  }

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handlePassChatAutomate = () => {
    if (!chatsOnline.currentChat) return null
    setLoading(true)
    dispatch(
      passChatAutomate({
        chat_id: chatsOnline.currentChat.absnum,
      })
    ).finally(() => {
      setLoading(false)
      dispatch(setRightSidebar(false))
    })
  }

  const handlePassChat = (callback: (error: string) => void) => {
    if (!chatsOnline.currentChat || loading || !passEmpl) return null
    setLoading(true)
    dispatch(
      passChat({
        chat_id: chatsOnline.currentChat.absnum,
        user_id: passEmpl.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus !== 'fulfilled') {
        callback(res.payload as string)
      } else {
        dispatch(setRightSidebar(false))
      }
      setLoading(false)
    })
  }

  const handlePassChatToTechSupport = (value: string, callback: () => void) => {
    if (!chatsOnline.currentChat || !value) return null
    dispatch(
      passChatToTechSupoort({
        chat_id: chatsOnline.currentChat.absnum,
        message: value,
      })
    ).finally(() => {
      setExpanded(false)
      dispatch(setRightSidebar(false))
      callback()
    })
  }

  const handlePassChatToWritten = (value: string, callback: () => void) => {
    if (!chatsOnline.currentChat || !value) return null
    dispatch(
      passChatToWritten({
        chat_id: chatsOnline.currentChat.absnum,
        product_id: chatsOnline.currentChat.product_id,
        message: value,
      })
    ).finally(() => {
      setExpanded(false)
      dispatch(setRightSidebar(false))
      callback()
    })
  }

  const handleAutoSave = React.useCallback(
    (value: string, callback: () => void) => {
      if (!chatsOnline.currentChat) return null
      dispatch(
        updateDraftMessage({
          id: chatsOnline.currentChat.chat_id,
          data: { written_draft_message: value },
        })
      ).finally(callback)
    },
    [chatsOnline.currentChat?.absnum]
  )

  if (!chatsOnline.currentChat) return null

  const text = chatsOnline.currentChat.chat?.messages.filter((item) => !!item.client_id).pop()?.message || ''
  const message = parseLink(text)
  const initValueWritten = parseLink(chatsOnline.currentChat.written_draft_message || text)

  return (
    <Box sx={{ width: 350, paddingTop: '70px' }} role='presentation'>
      <Stack px={'15px'} py={'10px'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
        <Typography variant={'h6'}>Консультантам</Typography>
        <Button disabled={loading} onClick={handlePassChatAutomate}>
          Передати
        </Button>
      </Stack>
      {is_approved.is_tech_support ? (
        <MessageSender
          title='Техпідтримка'
          initValue={message}
          expanded={expanded === '1'}
          onChange={handleChange('1')}
          onSend={handlePassChatToTechSupport}
          saveFile={handleSaveFile}
        />
      ) : null}
      {is_approved.is_written ? (
        <MessageSender
          title='Письмово'
          initValue={initValueWritten}
          expanded={expanded === '2'}
          onChange={handleChange('2')}
          onSend={handlePassChatToWritten}
          saveFile={handleSaveFile}
          handleAutoSave={handleAutoSave}
        />
      ) : null}
      <List>
        {employees.searchData?.employees?.map((item) => {
          if (item.id === users.user?.id) return null
          return (
            <ChooseEmployee
              key={item.id}
              status={item.status || 0}
              fio={item.fio}
              onClick={() => setPassEmpl({ id: item.id, fio: item.fio })}
            />
          )
        })}
      </List>
      <ConfirmModal
        title={'Передати чат до ' + passEmpl?.fio || ''}
        open={!!passEmpl}
        delHandler={handlePassChat}
        closeHandler={() => setPassEmpl(null)}
      />
    </Box>
  )
}
