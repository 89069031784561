import { createSlice } from '@reduxjs/toolkit'
import { IStore } from '../types'
import { createAppeal, deleteAppeal, getAppeal, updateAppeal } from './actions'

const initialState: IStore['appeal'] = {
  list: null,
  appeal: null,
}

const slice = createSlice({
  name: 'appeal',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAppeal.fulfilled, (state, action) => {
      state.list = action.payload
    })
    builder.addCase(createAppeal.fulfilled, (state, action) => {
      if (state.list) {
        state.list = [...state.list, action.payload]
      } else {
        state.list = [action.payload]
      }
    })
    builder.addCase(updateAppeal.fulfilled, (state, action) => {
      if (state.list) {
        state.list = state.list.map((it) => (it.absnum === action.payload.absnum ? action.payload : it))
      }
    })
    builder.addCase(deleteAppeal.fulfilled, (state, action) => {
      if (state.list) {
        state.list = state.list.filter((it) => it.absnum !== action.payload.absnum)
      }
    })
  },
})

export const AppealActions = slice.actions

export default slice.reducer
