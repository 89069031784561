import React from 'react'
import { MainWorkspaceOnline } from '../../components/MainWorkspace'
import { RequireAuth } from './RequireAuth'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { Navigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { CHANNELS_ID, Roles } from '../../utils/consts'

interface Props {
  element: JSX.Element
}

export const OnlineHelper: React.FC<Props> = ({ element }) => {
  const { users } = useSelector((store: IStore) => store)

  const renderPage = (channels = '') => {
    if (channels.includes(String(CHANNELS_ID.TECH_SUPPORT))) {
      return <Navigate to={ROUTES.chatsTechSupport} replace />
    }
    if (channels.includes(String(CHANNELS_ID.SALES))) {
      return <Navigate to={ROUTES.chatsSales} replace />
    }

    if (channels.includes(String(CHANNELS_ID.NEWS))) {
      return <Navigate to={ROUTES.chatsNews} replace />
    }
    if (channels.includes(String(CHANNELS_ID.WRITTEN))) {
      return <Navigate to={ROUTES.letter} replace />
    }

    return null
  }

  if (users.user && users.user.role === Roles.MARKETER) {
    return <Navigate to={ROUTES.mailing} replace />
  }

  if (
    (users.user && !users.user.channels.includes(String(CHANNELS_ID.ONLINE))) ||
    (users.user && !users.user.approved_online)
  ) {
    return renderPage(users.user.channels)
  }

  return (
    <RequireAuth>
      <MainWorkspaceOnline>{element}</MainWorkspaceOnline>
    </RequireAuth>
  )
}
