import React from 'react'
import { FormikValues, useField } from 'formik'
import TextField from '@mui/material/TextField'
import TextareaAutosize from '@mui/material/TextareaAutosize'

export type ValidateType = {
  required?: boolean
  min?: number
  max?: number
}

export const onValidate = (name: string, value: FormikValues, validate: ValidateType) => {
  if (!value && validate.required) {
    return `Заповніть поле`
  }
}

export interface IField {
  label?: string
  required?: boolean
  disabled?: boolean
  name: string
  children?: JSX.Element
  style?: React.CSSProperties | undefined
  maxLength?: number
}

export const InputFormik: React.FC<IField> = ({ maxLength, disabled, label, name, required, style }) => {
  const [field, meta] = useField({
    name,
    validate: (value: FormikValues) => onValidate(name, value, { required, max: maxLength }),
  })
  return (
    <TextField
      style={style}
      disabled={disabled}
      label={label || ''}
      size='small'
      variant='outlined'
      error={meta.touched && !!meta.error}
      inputProps={{ maxLength: maxLength }}
      {...field}
    />
  )
}

export const TextareaFormik: React.FC<IField> = ({ disabled, maxLength, name, label, required, style }) => {
  const [field] = useField({
    name,
    validate: (value: FormikValues) => onValidate(name, value, { required }),
  })
  return (
    <TextareaAutosize maxLength={maxLength} style={style} disabled={disabled} placeholder={label || ''} {...field} />
  )
}
