import React from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { passChat } from '../../store/chatsSales/actions'
import List from '@mui/material/List'
import { searchEmployees } from '../../store/employees/actions'
import { CHANNELS_ID, Roles, StatusWork } from '../../utils/consts'
import { ConfirmModal } from '../modals/ConfirmModal'
import { ChooseEmployee } from './components/ChooseEmployee'

export const RightSales: React.FC = () => {
  const [loading, setLoading] = React.useState(false)
  const [passEmpl, setPassEmpl] = React.useState<null | { id: number; fio: string }>(null)

  const { chatsSales, employees, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(
      searchEmployees({
        channels: String(CHANNELS_ID.SALES),
        roles: [Roles.CONSULTANT, Roles.TRAINEE].join(','),
        status: StatusWork.ONLINE,
      })
    )
  }, [employees.data])

  const handlePassChat = (callback: (error: string) => void) => {
    if (!chatsSales.currentChat || !passEmpl || loading) return null
    setLoading(true)
    dispatch(
      passChat({
        chat_id: chatsSales.currentChat.absnum,
        user_id: passEmpl.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus !== 'fulfilled') {
        callback(res.payload as string)
      } else {
        dispatch(setRightSidebar(false))
      }
      setLoading(false)
    })
  }

  if (!chatsSales.currentChat) return null

  const filteredUsers = employees.searchData?.employees.filter((item) => item.id !== users.user?.id) || []

  return (
    <Box sx={{ width: 350, paddingTop: '70px' }} role='presentation'>
      {filteredUsers.length > 0 ? (
        <List>
          {filteredUsers.map((item) => (
            <ChooseEmployee
              key={item.id}
              status={item.status || 0}
              fio={item.fio}
              onClick={() => setPassEmpl({ id: item.id, fio: item.fio })}
            />
          ))}
        </List>
      ) : (
        <Box p={2} display={'flex'} justifyContent={'center'}>
          Немає консультантів
        </Box>
      )}
      <ConfirmModal
        title={'Передати чат до ' + passEmpl?.fio || ''}
        open={!!passEmpl}
        delHandler={handlePassChat}
        closeHandler={() => setPassEmpl(null)}
      />
    </Box>
  )
}
