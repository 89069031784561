import moment from 'moment'
import 'moment/locale/uk'

export const getDateFormat = (adate?: Date) => {
  if (!adate) return ''
  if (new Date(adate).getFullYear() === new Date().getFullYear()) {
    return moment(adate).format('DD MMM HH:mm')
  }

  return moment(adate).format('DD MMM YYYY HH:mm')
}
