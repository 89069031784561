import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Stack } from '@mui/material'
import { AlertMessage } from '../AlertMessage'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

interface Props {
  closeHandler: () => void
  delHandler: (callback: (error: string) => void) => void
  open: boolean
  title: string
  btnNameConfirm?: string
  btnNameCancel?: string
  disabled?: boolean
}

export const ConfirmModal: React.FC<Props> = ({
  open,
  closeHandler,
  title,
  delHandler,
  btnNameConfirm = 'Oк',
  btnNameCancel = 'Відміна',
  disabled,
}) => {
  const [errors, setErrors] = React.useState('')

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={style}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          {title}
        </Typography>
        {errors ? <AlertMessage type='error' message={errors} /> : null}
        <Stack pt={4} spacing={2} direction={'row'}>
          <Button
            disabled={disabled}
            variant={'contained'}
            color={'error'}
            onClick={() => delHandler((error) => setErrors(error))}
          >
            {btnNameConfirm}
          </Button>
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            {btnNameCancel}
          </Button>
        </Stack>
      </Box>
    </Modal>
  )
}
