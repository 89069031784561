import React from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { Button, Stack } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { deleteConfiguration, getConfiguration } from '../../store/configurations/actions'
import { useAppDispatch } from '../../store'
import { Roles } from '../../utils/consts'
import { ConfirmModal } from '../../components/modals/ConfirmModal'

interface Column {
  id: 'absnum' | 'name' | 'url'
  label: string
  minWidth?: number
  align?: 'right'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'absnum', label: 'ID Віджета', minWidth: 170 },
  { id: 'name', label: 'Назва сайту', minWidth: 100 },
  {
    id: 'url',
    label: 'URL',
    minWidth: 200,
  },
]

export const ListConfiguration: React.FC = () => {
  const navigate = useNavigate()
  const [deleteId, setDeleteId] = React.useState(0)
  const { configurations, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  const handleDelete = () => {
    dispatch(deleteConfiguration(deleteId))
    setDeleteId(0)
  }

  return (
    <>
      <Paper sx={{ m: 2, overflow: 'hidden', height: 'calc(100% - 95px)' }}>
        {users.user?.role === Roles.ADMIN ? (
          <Stack my={2} px={2} spacing={2} direction={'row'}>
            <Button onClick={() => navigate(ROUTES.configuratorChange('new'))} color={'primary'} variant={'outlined'}>
              Створити
            </Button>
          </Stack>
        ) : null}
        <TableContainer sx={{ maxHeight: 'calc(100vh - 180px)' }}>
          <Table stickyHeader aria-label='sticky table'>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
                <TableCell />
                {users.user?.role === Roles.ADMIN ? <TableCell /> : null}
              </TableRow>
            </TableHead>
            <TableBody>
              {configurations.all?.map((row) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.absnum}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      )
                    })}
                    <TableCell width={200} align={'right'}>
                      <Button
                        onClick={() => {
                          dispatch(getConfiguration(row.absnum))
                          navigate(ROUTES.configuratorChange(String(row.site_id)))
                        }}
                        color={'primary'}
                        variant={'outlined'}
                      >
                        Редагувати
                      </Button>
                    </TableCell>
                    {users.user?.role === Roles.ADMIN ? (
                      <TableCell width={100} align={'right'}>
                        <Button onClick={() => setDeleteId(row.absnum)} color={'error'} variant={'outlined'}>
                          Видалити
                        </Button>
                      </TableCell>
                    ) : null}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ConfirmModal
        title={`Видалити конфігурацію ${deleteId}`}
        open={!!deleteId}
        delHandler={handleDelete}
        closeHandler={() => setDeleteId(0)}
      />
    </>
  )
}
