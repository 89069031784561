import React from 'react'
import Box from '@mui/material/Box'
import { Title } from '../Title'
import { Button, Stack } from '@mui/material'
import { SectionTitleWrapper } from '../SectionTitleWrapper'
import { useNavigate, useLocation } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'

const routes = [
  {
    title: 'Оператори',
    route: '',
  },
  {
    title: 'Оповіщення',
    route: ROUTES.notification,
  },
  {
    title: 'Логи',
    route: ROUTES.logs,
  },
]

export const Settings: React.FC<{ titleFirstPage?: string }> = React.memo(({ titleFirstPage }) => {
  const navigate = useNavigate()
  const location = useLocation()

  const renderButton = ({ route, title }: (typeof routes)[0]) => {
    return (
      <Button
        color={location.pathname === ROUTES.routeChange(route) ? 'primary' : 'inherit'}
        onClick={() => navigate(ROUTES.routeChange(route))}
        fullWidth
        size={'large'}
        sx={{ textTransform: 'initial', justifyContent: 'flex-start' }}
      >
        {titleFirstPage ? title.replace('Оператори', titleFirstPage) : title}
      </Button>
    )
  }

  return (
    <Box>
      <SectionTitleWrapper>
        <Title title={'Налаштування'} />
      </SectionTitleWrapper>

      <Stack spacing={0} direction={'column'} alignItems={'flex-start'}>
        {routes.map((r) => (
          <React.Fragment key={r.title}>{renderButton(r)}</React.Fragment>
        ))}
      </Stack>
    </Box>
  )
})
