import { createAsyncThunk } from '@reduxjs/toolkit'
import { ChatsTechSupportActions } from '.'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { ITechSupportState, IChatOline } from './types'

export const getChats = createAsyncThunk<ITechSupportState['chats'], { status: number; page?: number }>(
  'techSupport/getChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-tech-support?${query}` : '/admin-tech-support',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getChatsByPage = createAsyncThunk<ITechSupportState['chats'], { status: number; page?: number }>(
  'techSupport/getChatsByPage',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-tech-support?${query}` : '/admin-tech-support',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getMessages = createAsyncThunk<IChatOline, { id: number; page: number }>(
  'techSupport/getMessages',
  async (payload, thunkAPI) => {
    try {
      const { id, page } = payload
      return callApi({
        method: 'get',
        path: page ? `/admin-tech-support/messages/${id}?page=${page}` : '/admin-tech-support',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getCurrentChat = createAsyncThunk<ITechSupportState['currentChat'], number>(
  'techSupport/getCurrentChat',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-tech-support/chat/${payload}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const closeChat = createAsyncThunk<ITechSupportState['currentChat'], number>(
  'techSupport/closeChat',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      const resp = (await callApi({
        method: 'get',
        path: `/admin-tech-support/close/${id}`,
      })) as ITechSupportState['currentChat']
      thunkAPI.dispatch(ChatsTechSupportActions.updateChats(resp))
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const captureChat = createAsyncThunk<ITechSupportState['currentChat'], number>(
  'techSupport/captureChat',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-tech-support/capture/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatR {
  chat_id: number
  user_id: number
}
export const passChat = createAsyncThunk<ITechSupportState['currentChat'], TPassChatR>(
  'techSupport/passChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-tech-support/pass`,
      })) as ITechSupportState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatToExpertR {
  chat_id: number
  product_id: number
  message: string
}
export const passChatToOnline = createAsyncThunk<ITechSupportState['currentChat'], TPassChatToExpertR>(
  'techSupport/passChatToOnline',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-tech-support/pass/online`,
      })) as ITechSupportState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const passChatToWritten = createAsyncThunk<ITechSupportState['currentChat'], TPassChatToExpertR>(
  'techSupport/passChatToWritten',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-tech-support/pass/written`,
      })) as ITechSupportState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TChangeStatusChatR {
  chat_id: number
  status: number
}
export const changeStatusChat = createAsyncThunk<ITechSupportState['currentChat'], TChangeStatusChatR>(
  'techSupport/changeStatusChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'patch',
        data,
        path: `/admin-tech-support/status`,
      })) as ITechSupportState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCreateMessageR {
  chat_id: number
  message: string
}
export const createMessage = createAsyncThunk<void, TCreateMessageR>(
  'techSupport/createMessage',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: `/admin-tech-support/message`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateMessage = createAsyncThunk<void, { id: number; data: { message: string } }>(
  'techSupport/updateMessage',
  async (payload, thunkAPI) => {
    try {
      const { data, id } = payload
      return callApi({
        method: 'patch',
        data,
        path: `/admin-tech-support/message/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateDraftMessage = createAsyncThunk<
  ITechSupportState['currentChat'],
  { id: number; data: { message: string } }
>('techSupport/updateDraftMessage', async (payload, thunkAPI) => {
  try {
    const { data, id } = payload
    return callApi({
      method: 'patch',
      data,
      path: `/admin-tech-support/draft-message/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const readMessage = createAsyncThunk<void, number>('techSupport/readMessage', async (payload, thunkAPI) => {
  try {
    const id = payload
    return callApi({
      method: 'get',
      path: `/admin-tech-support/read-message/${id}`,
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const uploadFile = createAsyncThunk<{ link: string }, FormData>(
  'techSupport/uploadFile',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        isFormData: true,
        path: `/admin-tech-support/upload`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateNotifications = createAsyncThunk<
  ITechSupportState['currentChat'],
  { chat_id: number; is_notifications: number }
>('techSupport/updateNotifications', async (payload, thunkAPI) => {
  try {
    const data = payload
    return callApi({
      method: 'post',
      data,
      path: '/admin-tech-support/notifications',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})

export const createDialog = createAsyncThunk<ITechSupportState['currentChat'], number>(
  'techSupport/createDialog',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-tech-support/initiate/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)
