import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { Avatar, Button, CardContent, IconButton, Rating } from '@mui/material'
import { CardActionArea } from '@mui/material'
import { useSelector } from 'react-redux'
import { changeFio, getFormFioShort } from '../../utils/changeFio'
import { getDateFormat } from '../../utils/getDateFormat'
import DoneAllOutlinedIcon from '@mui/icons-material/DoneAllOutlined'
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined'
import { IStore } from '../../store/types'
import { UnauthorizedChatStatus, SIZE_PROG, TypeMessage, STATUSES_TRANSFER_TECH } from '../../utils/consts'
import { BadgeCount } from '../Dadge'
import { captureChat, updateNotifications } from '../../store/chatsUnauthorized/actions'
import { useAppDispatch } from '../../store'
import { parseLink } from '../../utils/parseLink'
import NotificationsIcon from '@mui/icons-material/Notifications'
import CircularProgress from '@mui/material/CircularProgress'
import { IChatUnauthorized } from '../../store/chatsUnauthorized/types'
import { red, yellow } from '@mui/material/colors'
import { WrapperBtnS, WrapperMesssageS } from './styles'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../utils/routes'
import { ChatsUnauthorizedActions } from '../../store/chatsUnauthorized'

const getTime = (minute: number) => {
  return new Date(new Date().getTime() - 1000 * 60 * minute).getTime()
}

interface Props {
  chat: IChatUnauthorized
}
export const ChatItemUnauthorized: React.FC<Props> = ({ chat }) => {
  const [loadingNotif, setLoadingNotif] = React.useState(0)
  const [loadingCapture, setLoadingCapture] = React.useState(0)

  const { chatsUnauthorized, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const renderIcons = (isRead?: boolean) => {
    if (isRead) {
      return <DoneAllOutlinedIcon color={'primary'} fontSize={'small'} sx={{ fontSize: 14 }} />
    }
    return <DoneOutlinedIcon fontSize={'small'} sx={{ fontSize: 14 }} />
  }

  const handleClickArea = (chat: IChatUnauthorized) => {
    navigate(`${ROUTES.chatsUnauthorized}/${chat.absnum}?status=${chatsUnauthorized.statusChat}`)
    dispatch(ChatsUnauthorizedActions.setClickChat())
  }

  const handleClickNotifications = (chat: IChatUnauthorized) => {
    setLoadingNotif(chat.absnum)
    dispatch(
      updateNotifications({
        unauthorized_id: chat.absnum,
        is_notifications: chat.is_notifications ? 0 : 1,
      })
    ).then(() => setLoadingNotif(0))
  }

  const handleHandleCapture = (chat: IChatUnauthorized) => {
    setLoadingCapture(chat.absnum)
    dispatch(captureChat(chat.absnum)).then(() => setLoadingCapture(0))
  }

  const renderBtn = (itm: IChatUnauthorized) => {
    const typeColor = itm.is_notifications ? 'primary' : 'disabled'
    const isMy = users.user?.id === itm.expert_id
    const disabledNotif = loadingNotif === itm.absnum
    const disabledCapture = loadingCapture === itm.absnum
    const isCaptureChat = !itm.expert_id && itm.status === UnauthorizedChatStatus.NEWS

    if (chatsUnauthorized.currentChat?.absnum === itm.absnum && itm.status !== UnauthorizedChatStatus.CLOSE) {
      return (
        <WrapperBtnS>
          {isCaptureChat ? (
            <Button
              disabled={disabledCapture}
              variant='contained'
              size='small'
              onClick={() => handleHandleCapture(itm)}
            >
              {disabledCapture ? <CircularProgress size={SIZE_PROG} /> : 'Взяти в роботу'}
            </Button>
          ) : null}
          {isMy ? (
            <IconButton disabled={disabledNotif} aria-label='add' onClick={() => handleClickNotifications(itm)}>
              {disabledNotif ? <CircularProgress size={SIZE_PROG} /> : <NotificationsIcon color={typeColor} />}
            </IconButton>
          ) : null}
        </WrapperBtnS>
      )
    }

    if (isMy) {
      return (
        <WrapperBtnS>
          <IconButton aria-label='add' onClick={() => handleClickNotifications(itm)}>
            {disabledNotif ? <CircularProgress size={SIZE_PROG} /> : <NotificationsIcon color={typeColor} />}
          </IconButton>
        </WrapperBtnS>
      )
    }
    return null
  }

  const checkColor = (itm: IChatUnauthorized) => {
    if (itm.absnum === chatsUnauthorized.currentChat?.absnum) return 'rgb(229 229 228)'
    if ([UnauthorizedChatStatus.CLOSE].includes(itm.status)) return ''
    if (!itm.expert_id && getTime(5) > new Date(itm.date_start as Date).getTime()) return red[100]
    if (!itm.expert_id && getTime(3) > new Date(itm.date_start as Date).getTime()) return yellow[100]
    return ''
  }

  const renderMesssage = () => {
    if (chat.type_id === TypeMessage.RATING)
      return <Rating name='read-only' value={chat.rating} readOnly size={'small'} />
    return (
      <Typography
        variant={'body1'}
        fontSize={14}
        style={{ pointerEvents: 'none', cursor: 'default', wordBreak: 'break-word' }}
        dangerouslySetInnerHTML={{
          __html: parseLink(chat.message),
        }}
      />
    )
  }

  return (
    <WrapperMesssageS key={chat.absnum} style={{ background: checkColor(chat) }}>
      <Box display={'flex'} alignItems={'flex-start'} width={'100%'}>
        <CardActionArea
          disabled={chatsUnauthorized.currentChat?.absnum === chat.absnum}
          onClick={() => handleClickArea(chat)}
        >
          <CardContent
            style={{ paddingTop: '25px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Box display={'flex'} alignItems={'center'} width={'100%'}>
              <Avatar>{getFormFioShort(chat.client_fio)}</Avatar>
              <Box pl={2} width={'100%'} overflow={'hidden'}>
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                  <Typography variant={'h6'} fontSize={14} fontWeight={600}>
                    {changeFio(chat.client_fio)}
                  </Typography>

                  <Box pr={2} display={'flex'} alignItems={'center'}>
                    {renderIcons(!!chat.is_read)}
                    <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                      <Typography ml={1} variant={'h6'} fontSize={12} color={'#8f8f8f'}>
                        {getDateFormat(chat.adate_message)}
                      </Typography>
                      {chat.count_not_read && chat.is_transfered !== 1 ? (
                        <BadgeCount count={chat.count_not_read} />
                      ) : null}
                    </Box>
                  </Box>
                </Box>
                {renderMesssage()}
              </Box>
            </Box>
          </CardContent>
        </CardActionArea>
        {renderBtn(chat)}
        {chatsUnauthorized.loadingChat === chat.absnum ? (
          <CircularProgress size={SIZE_PROG} style={{ position: 'absolute', right: '50%', top: '50%' }} />
        ) : null}
      </Box>
      <Box px={2}>
        <Typography variant={'body1'} color={'#1A82C5FF'}>
          {chat.is_transfered ? 'Чат переданий' : ''}
        </Typography>
      </Box>
    </WrapperMesssageS>
  )
}
