import React from 'react'
import { useSelector } from 'react-redux'
import { ChatsNewsActions } from '../store/chatsNews'
import { ChatsOnlineActions } from '../store/chatsOnline'
import { IChat, IMessageOnline } from '../store/chatsOnline/types'
import { ChatsSalesActions } from '../store/chatsSales'
import { ChatsWrittenActions } from '../store/chatsWritten'
import { IMessageWritten, IMiniChat } from '../store/chatsWritten/types'
import { MailingActions } from '../store/mailing'
import { ChatsTechSupportActions } from '../store/techSupport'
import { IStore } from '../store/types'
import { UserActions } from '../store/users'
import { IUser } from '../store/users/types'
import { checkToken } from '../utils/checkToken'
import { TOKEN, URI } from '../utils/config'
import { Roles, StatusWork, TypeUserLog, TypeMessage } from '../utils/consts'
import { socket } from '../utils/socket'
import { SocketEvents } from '../utils/socketEvents'
import { ChatsUnauthorizedActions } from '../store/chatsUnauthorized'
import { IChatUnauthorized } from '../store/chatsUnauthorized/types'
import { createUserLog } from '../store/users/actions'
import { useAppDispatch } from '../store'

let audio1: HTMLAudioElement | null = new Audio(`${URI}/sound/short_bell.wav`)
let audio2: HTMLAudioElement | null = new Audio(`${URI}/sound/long_bell.wav`)

export const useSocket = () => {
  const users = useSelector((store: IStore) => store.users)
  const dispatch = useAppDispatch()

  const ref = React.useRef<IUser | null>(null)
  const refInterval = React.useRef<NodeJS.Timer | null>(null)

  React.useEffect(() => {
    if (users.user) {
      ref.current = users.user
    }
  }, [users.user])

  React.useEffect(() => {
    try {
      if (users.isAuth && users.socket) {
        checkToken().then((result) => {
          if (result?.token) {
            console.log({ emit: SocketEvents.JOIN_ADMIN })
            socket.emit(SocketEvents.JOIN_ADMIN, {
              token: result.token,
            })
            getInitialData()
          }
        })
      }
    } catch (err) {
      console.log(err)
    }
  }, [users.isAuth, users.socket])

  const handleNotification = (message: string) => {
    if (Notification.permission === 'granted') {
      new Notification(message)
    }
  }

  const handleSoundOnline = () => {
    if (audio1) {
      audio1?.play()
      audio1 = null
    } else {
      let audio: HTMLAudioElement | null = new Audio(`${URI}/sound/short_bell.wav`)
      audio.play()
      audio = null
    }
  }

  const handleSoundAddChat = () => {
    if (audio2) {
      audio2?.play()
      audio2 = null
    } else {
      let audio: HTMLAudioElement | null = new Audio(`${URI}/sound/long_bell.wav`)
      audio.play()
      audio = null
    }
  }

  const getInitialData = React.useCallback(() => {
    if (!refInterval.current) {
      refInterval.current = setInterval(() => {
        const token = localStorage.getItem(TOKEN.access)

        socket.emit(SocketEvents.CHECK_CONNECT, {
          token,
          status: ref.current?.status,
        })
      }, 1000 * 60)
    }

    socket.on(SocketEvents.JOIN_ADMIN, (data) => {
      console.log({ JOIN_ADMIN: data.success, status: data.status })
      dispatch(UserActions.setStatus(data.status))
    })

    socket.on(SocketEvents.USER_CHANGE_STATUS, ({ status }) => {
      const num = Number(status)
      if (!Number.isNaN(num)) {
        dispatch(UserActions.setStatus(num))
      }
    })

    socket.on(SocketEvents.NOTIFICATIONS_ONLINE, (data: number) => {
      if (!ref.current || ref.current.notif_online) {
        handleSoundOnline()
        if (!ref.current || ref.current.popup_chat) {
          handleNotification('Канал "Онлайн". Нове повідомлення')
        }
      }
    })

    socket.on(SocketEvents.NOTIFICATIONS_TECH_SUPPORT, (data: number) => {
      if (!ref.current || ref.current.notif_online) {
        handleSoundOnline()
      }
    })

    socket.on(SocketEvents.CREATE_ONLINE_MESSAGE, (data: IMessageOnline) => {
      dispatch(ChatsOnlineActions.addMessage(data))
    })

    socket.on(SocketEvents.CREATE_SALES_MESSAGE, (data: IMessageOnline) => {
      dispatch(ChatsSalesActions.addMessage(data))
      if (data.type_id === TypeMessage.DEFAULT || data.type_id === TypeMessage.RATING) {
        if (
          data.expert_id === 0 &&
          data.is_notifications &&
          data.own_expert_id === ref.current?.id &&
          ref.current?.notif_online
        ) {
          handleSoundOnline()
        }
      }
    })

    socket.on(SocketEvents.CREATE_TECH_SUPPORT_MESSAGE, (data: IMessageOnline) => {
      dispatch(ChatsTechSupportActions.addMessage(data))
    })

    socket.on(SocketEvents.CREATE_NEWS_MESSAGE, (data: IMessageOnline) => {
      dispatch(ChatsNewsActions.addMessage(data))
      if (data.type_id === TypeMessage.DEFAULT || data.type_id === TypeMessage.RATING) {
        if (
          data.expert_id === 0 &&
          data.is_notifications &&
          data.own_expert_id === ref.current?.id &&
          ref.current?.notif_online
        ) {
          handleSoundOnline()
        }
      }
    })

    socket.on(SocketEvents.UPDATE_ONLINE_MESSAGE, (data: IMessageOnline) => {
      dispatch(ChatsOnlineActions.updateMessage(data))
    })

    socket.on(SocketEvents.UPDATE_SALES_MESSAGE, (data: IMessageOnline) => {
      dispatch(ChatsSalesActions.updateMessage(data))
    })

    socket.on(SocketEvents.UPDATE_TECH_SUPPORT_MESSAGE, (data: IMessageOnline) => {
      dispatch(ChatsTechSupportActions.updateMessage(data))
    })

    socket.on(SocketEvents.UPDATE_NEWS_MESSAGE, (data: IMessageOnline) => {
      dispatch(ChatsNewsActions.updateMessage(data))
    })

    socket.on(SocketEvents.CREATE_WRITTEN_MESSAGE, (data: IMessageWritten) => {
      dispatch(ChatsWrittenActions.addMessage(data))
    })

    socket.on(SocketEvents.UPDATE_WRITTEN_MESSAGE, (data: IMessageWritten) => {
      dispatch(ChatsWrittenActions.updateMessage(data))
    })

    socket.on(SocketEvents.DELETE_WRITTEN_MESSAGE, (data: IMessageWritten) => {
      dispatch(ChatsWrittenActions.deleteMessage(data))
    })

    socket.on(SocketEvents.CREATE_MESSAGE_MINI_CHAT, (data: IMiniChat) => {
      dispatch(ChatsWrittenActions.addeMessageMiniChat(data))
    })

    socket.on(SocketEvents.UPDATE_CHAT, (data: IChat) => {
      dispatch(ChatsOnlineActions.updateChats(data))
    })

    socket.on(SocketEvents.UPDATE_CHAT_CONSULTANT, (data: IChat) => {
      dispatch(ChatsOnlineActions.updateChatsForStatusAll(data))
    })

    socket.on(SocketEvents.ADD_CHAT, (data: IChat) => {
      dispatch(ChatsOnlineActions.addChat(data))
    })

    socket.on(SocketEvents.REMOVE_CHAT, (data: IChat) => {
      dispatch(ChatsOnlineActions.removeChat(data))
    })

    socket.on(SocketEvents.UPDATE_TECH_SUPPORT_CHAT, (data: IChat) => {
      dispatch(ChatsTechSupportActions.updateChats(data))
    })

    socket.on(SocketEvents.CAPTURE_TECH_SUPPORT_CHAT, (data: IChat) => {
      if (data.expert_id === ref.current?.id || (ref.current && ref.current.role >= Roles.CHIEF)) {
        dispatch(ChatsTechSupportActions.updateChats(data))
      } else {
        dispatch(ChatsTechSupportActions.removeChat(data))
      }
    })

    socket.on(SocketEvents.ADD_TECH_SUPPORT_CHAT, (data: IChat) => {
      dispatch(ChatsTechSupportActions.addChat(data))
    })

    socket.on(SocketEvents.REMOVE_TECH_SUPPORT_CHAT, (data: IChat) => {
      dispatch(ChatsTechSupportActions.removeChat(data))
    })

    socket.on(SocketEvents.UPDATE_SALES_CHAT, (data: IChat) => {
      dispatch(ChatsSalesActions.updateChats(data))
    })

    socket.on(SocketEvents.CAPTURE_SALES_CHAT, (data: IChat) => {
      if (data.expert_id === ref.current?.id || (ref.current && ref.current.role >= Roles.CHIEF)) {
        dispatch(ChatsSalesActions.updateChats(data))
      } else {
        dispatch(ChatsSalesActions.removeChat(data))
      }
    })

    socket.on(SocketEvents.ADD_SALES_CHAT, (data: IChat) => {
      dispatch(ChatsSalesActions.addChat(data))
      if (ref.current?.notif_chat) {
        handleSoundAddChat()
      }
      if (ref.current?.popup_chat) {
        handleNotification('Канал "Продажі". Новий чат')
      }
    })

    socket.on(SocketEvents.REMOVE_SALES_CHAT, (data: IChat) => {
      dispatch(ChatsSalesActions.removeChat(data))
    })

    socket.on(SocketEvents.UPDATE_UNAUTHORIZED_CHAT, (data: IChatUnauthorized) => {
      dispatch(ChatsUnauthorizedActions.updateChats(data))
    })

    socket.on(SocketEvents.CAPTURE_UNAUTHORIZED_CHAT, (data: IChatUnauthorized) => {
      if (data.expert_id === ref.current?.id || (ref.current && ref.current.role >= Roles.CHIEF)) {
        dispatch(ChatsUnauthorizedActions.updateChats(data))
      } else {
        dispatch(ChatsUnauthorizedActions.removeChat(data))
      }
    })

    socket.on(SocketEvents.ADD_UNAUTHORIZED_CHAT, (data: IChatUnauthorized) => {
      dispatch(ChatsUnauthorizedActions.addChat(data))
      if (ref.current?.notif_chat) {
        handleSoundAddChat()
      }
      if (ref.current?.popup_chat) {
        handleNotification('Канал "Неавторизовані". Новий чат')
      }
    })

    socket.on(SocketEvents.REMOVE_UNAUTHORIZED_CHAT, (data: IChatUnauthorized) => {
      dispatch(ChatsUnauthorizedActions.removeChat(data))
    })

    socket.on(SocketEvents.UPDATE_NEWS_CHAT, (data: IChat) => {
      dispatch(ChatsNewsActions.updateChats(data))
    })

    socket.on(SocketEvents.CAPTURE_NEWS_CHAT, (data: IChat) => {
      if (data.expert_id === ref.current?.id || (ref.current && ref.current.role >= Roles.CHIEF)) {
        dispatch(ChatsNewsActions.updateChats(data))
      } else {
        dispatch(ChatsNewsActions.removeChat(data))
      }
    })

    socket.on(SocketEvents.ADD_NEWS_CHAT, (data: IChat) => {
      dispatch(ChatsNewsActions.addChat(data))
      if (ref.current?.notif_chat) {
        handleSoundAddChat()
      }
      if (ref.current?.popup_chat) {
        handleNotification('Канал "Новини". Новий чат')
      }
    })

    socket.on(SocketEvents.REMOVE_NEWS_CHAT, (data: IChat) => {
      dispatch(ChatsNewsActions.removeChat(data))
    })

    socket.on(SocketEvents.UPDATE_MAILING, (data: IChat) => {
      dispatch(MailingActions.updateMailing(data))
    })
  }, [])

  React.useEffect(() => {
    dispatch(createUserLog({ is_type: TypeUserLog.ENTRANCE }))

    socket.on('connect', () => {
      console.log(socket.id, 'connect')
      localStorage.setItem('SOCKET_ID_NAME', socket.id)
      dispatch(UserActions.setSocket(socket.id))
      dispatch(createUserLog({ is_type: TypeUserLog.CONNECTION }))
    })

    socket.on('disconnect', () => {
      console.log(socket, 'disconnect')
      dispatch(UserActions.setStatus(StatusWork.OFFLINE))
      dispatch(UserActions.setSocket(''))
    })

    window.onbeforeunload = () => {
      dispatch(createUserLog({ is_type: TypeUserLog.EXIT }))
    }
  }, [])
}
