import React from 'react'
import Box from '@mui/material/Box'
import { useSelector } from 'react-redux'
import { useAppDispatch } from '../../store'
import { IStore } from '../../store/types'
import { setRightSidebar } from '../../store/rightSidebarSlice'
import { passChat, passChatToTechSupoort, uploadFile } from '../../store/chatsNews/actions'
import List from '@mui/material/List'
import { searchEmployees } from '../../store/employees/actions'
import { CHANNELS_ID, StatusWork } from '../../utils/consts'
import { ConfirmModal } from '../modals/ConfirmModal'
import { ChooseEmployee } from './components/ChooseEmployee'
import { MessageSender } from './components/MessageSender'
import { parseLink } from '../../utils/parseLink'

export const RightNews: React.FC = () => {
  const [expanded, setExpanded] = React.useState<string | boolean>(false)
  const [loading, setLoading] = React.useState(false)
  const [is_approved, setApproved] = React.useState<{ is_tech_support: number }>({
    is_tech_support: 0,
  })
  const [passEmpl, setPassEmpl] = React.useState<null | { id: number; fio: string }>(null)

  const { chatsNews, configurations, employees, users } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(searchEmployees({ channels: String(CHANNELS_ID.NEWS), status: StatusWork.ONLINE }))
  }, [employees.data])

  React.useEffect(() => {
    if (configurations.all && chatsNews.currentChat) {
      const config = configurations.all.find((item) => item.absnum === chatsNews.currentChat?.site_id)
      if (config) {
        setApproved({
          is_tech_support: config.approved_tech,
        })
      }
    }
  }, [configurations.all, chatsNews.currentChat])

  const handleSaveFile = (file: File, callback?: (succes: boolean, data?: any) => void) => {
    if (chatsNews.currentChat) {
      const fd = new FormData()
      fd.append('file', file)
      fd.append('chat_id', String(chatsNews.currentChat.absnum))
      fd.append('file_name', file.name)
      dispatch(uploadFile(fd)).then((res) => {
        const success = res.meta.requestStatus === 'fulfilled'
        if (callback) callback(success, res)
      })
    }
  }

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handlePassChat = (callback: (error: string) => void) => {
    if (!chatsNews.currentChat || loading || !passEmpl) return null
    setLoading(true)
    dispatch(
      passChat({
        chat_id: chatsNews.currentChat.absnum,
        user_id: passEmpl.id,
      })
    ).then((res) => {
      if (res.meta.requestStatus !== 'fulfilled') {
        callback(res.payload as string)
      } else {
        dispatch(setRightSidebar(false))
      }
      setLoading(false)
    })
  }

  const handlePassChatToTechSupport = (value: string, callback: () => void) => {
    if (!chatsNews.currentChat || !value) return null
    dispatch(
      passChatToTechSupoort({
        chat_id: chatsNews.currentChat.absnum,
        message: value,
      })
    ).finally(() => {
      setExpanded(false)
      dispatch(setRightSidebar(false))
      callback()
    })
  }

  if (!chatsNews.currentChat) return null

  const message = chatsNews.currentChat.chat?.messages.filter((item) => !!item.client_id).pop()?.message || ''

  return (
    <Box sx={{ width: 350, paddingTop: '70px' }} role='presentation'>
      {is_approved.is_tech_support ? (
        <MessageSender
          title='Техпідтримка'
          initValue={parseLink(message)}
          expanded={expanded === '3'}
          onChange={handleChange('3')}
          onSend={handlePassChatToTechSupport}
          saveFile={handleSaveFile}
        />
      ) : null}
      <List>
        {employees.searchData?.employees?.map((item) => {
          if (item.id === users.user?.id) return null
          return (
            <ChooseEmployee
              key={item.id}
              status={item.status || 0}
              fio={item.fio}
              onClick={() => setPassEmpl({ id: item.id, fio: item.fio })}
            />
          )
        })}
      </List>
      <ConfirmModal
        title={'Передати чат до ' + passEmpl?.fio || ''}
        open={!!passEmpl}
        delHandler={handlePassChat}
        closeHandler={() => setPassEmpl(null)}
      />
    </Box>
  )
}
