import React from 'react'
import Box from '@mui/material/Box'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Button from '@mui/material/Button'
import { useSearch } from '../../hooks/useSearch'
import CircularProgress from '@mui/material/CircularProgress'
import { SIZE_PROG } from '../../utils/consts'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import { useSearchProducts } from '../../hooks/useSearchProducts'
import { IStatTechSupportState } from '../../store/statTechSupport/types'
import { AsyncThunk } from '@reduxjs/toolkit'
import { BasicDatePicker } from '../../ui/BasicDatePicker'
import moment from 'moment'
import { IDtoSearch } from '../../store/statSales/types'
import { makeStyles } from '@mui/styles'
import { IEmployee } from '../../store/employees/types'
import { TextField } from '@mui/material'
import { IStatUnauthorizedState } from '../../store/statUnauthorized/types'
import { SelectSearch } from '../../ui/SelectSearch'

const Styles = makeStyles(() => ({
  searchField: {
    width: '223px !important',
    marginRight: '10px !important',
    marginBottom: '10px !important',
  },
  button: {
    height: '37px',
    marginRight: '10px !important',
  },
}))

export interface ColumnRating {
  id:
    | 'date_key'
    | 'created_count'
    | 'distributed_count'
    | 'title'
    | 'rating_count'
    | 'rating_interest'
    | 'positive_count'
    | 'positive_interest'
    | 'negative_count'
    | 'negative_interest'
    | 'without_count'
    | 'without_interest'
  label: string
  minWidth?: number
  align?: 'right' | 'center' | 'left'
  format?: (value: number) => string
}

interface Props {
  id?: number
  columns: ColumnRating[]
  fileName: string
  dataRating: IStatTechSupportState['dataRating'] | IStatUnauthorizedState['statChats']
  actionThunk: AsyncThunk<any, any, any>
  types?: { value: string; label: string }[]
  employees?: IEmployee[]
  isFio?: boolean
  isDate?: boolean
  isAuthor?: boolean
  labelFrom?: string
  labelTo?: string
  downloadFile: (values: IDtoSearch, callback: (data?: Buffer) => void) => void
  handleClearFilters: () => void
}

export const StatChatsRating: React.FC<Props> = ({
  id = 0,
  columns,
  fileName,
  dataRating,
  actionThunk,
  types,
  employees,
  isFio,
  isAuthor,
  isDate,
  labelFrom = 'Від',
  labelTo = 'До',
  downloadFile,
  handleClearFilters,
}) => {
  const [fio, setFio] = React.useState('')
  const [expertId, setExpertId] = React.useState('')
  const [productId, setProductId] = React.useState('')
  const [from, setFrom] = React.useState<Date | null>(null)
  const [to, setTo] = React.useState<Date | null>(null)
  const [status, setStatus] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  const { loading: loadingSearch, handleSearch } = useSearch({ actionThunk })
  const { subOptions } = useSearchProducts()
  const classes = Styles()

  const obj = {
    date_from: from ? moment(from).format('YYYY-MM-DD HH:mm') : '',
    date_to: to ? moment(to).format('YYYY-MM-DD HH:mm') : '',
    product_id: productId,
    expert_id: expertId,
    status,
    fio,
  }

  const clearFilters = () => {
    setFio('')
    setProductId('')
    setStatus('')
    setExpertId('')
    setFrom(null)
    setTo(null)
    handleClearFilters()
  }

  React.useEffect(() => {
    clearFilters()
  }, [id])

  const downloadXlsx = () => {
    if (loading) return null
    setLoading(true)
    downloadFile(obj, (data) => {
      if (data) {
        const names = [fileName]
        if (isAuthor && employees && expertId) {
          const expert = employees.find((item) => item.id === Number(expertId))
          if (expert) {
            names.push('_' + expert.fio.split(' ').join('_'))
          }
        }
        if (isDate && from) {
          names.push(`_від_${moment(from).format('DD/MM/YYYY-HH:mm')}`)
        }
        if (isDate && to) {
          names.push(`_до_${moment(to).format('DD/MM/YYYY-HH:mm')}`)
        }
        const buff = new Uint8Array(data).buffer
        const url = window.URL.createObjectURL(
          new Blob([buff], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
        )
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `${names.join('')}.xlsx`)
        document.body.appendChild(link)
        link.click()
        link.parentNode && link.parentNode.removeChild(link)
      }
      setLoading(false)
    })
  }

  if (!dataRating) {
    return (
      <Box display={'flex'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
        <CircularProgress size={SIZE_PROG} />
      </Box>
    )
  }

  return (
    <Box p={2}>
      <Box display={'flex'} flexWrap={'wrap'}>
        <Button
          className={classes.button}
          variant='contained'
          color='primary'
          disabled={loading}
          onClick={downloadXlsx}
        >
          {loading ? <CircularProgress size={SIZE_PROG} /> : 'Скачати xlsx'}
        </Button>
        <SelectSearch
          className={classes.searchField}
          label='Продукт'
          value={productId}
          options={subOptions.map((item) => ({ value: String(item.id), label: item.label })) || []}
          onChange={(value) => {
            setProductId(value)
            handleSearch(value, 'product_id', obj)
          }}
        />
        {isFio ? (
          <TextField
            className={classes.searchField}
            size={'small'}
            id='outlined-basic-id'
            label='Пошук по ПІБ'
            variant='outlined'
            value={fio}
            onChange={(event) => {
              setFio(event.target.value)
              handleSearch(event.target.value, 'fio', obj)
            }}
          />
        ) : null}
        {employees ? (
          <FormControl>
            <InputLabel size={'small'} id={`simple-select-2-${id}`}>
              Оператори
            </InputLabel>
            <Select
              className={classes.searchField}
              size={'small'}
              labelId={`simple-select-2-${id}`}
              id={`simple-select-2-${id}`}
              label='Оператори'
              value={expertId}
              onChange={(event) => {
                setExpertId(event.target.value)
                handleSearch(event.target.value, 'expert_id', obj)
              }}
            >
              <MenuItem value={0}>Всі</MenuItem>
              {employees.map((item) => {
                return (
                  <MenuItem key={item.id} value={item.id}>
                    {item.fio}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
        ) : null}
        <BasicDatePicker
          className={classes.searchField}
          id={`from-${id}`}
          label={labelFrom}
          value={from}
          onChange={(date) => {
            setFrom(date)
            handleSearch(date ? moment(date).format('YYYY-MM-DD HH:mm') : '', 'date_from', obj)
          }}
        />
        <BasicDatePicker
          className={classes.searchField}
          id={`to-${id}`}
          label={labelTo}
          value={to}
          onChange={(date) => {
            setTo(date)
            handleSearch(date ? moment(date).format('YYYY-MM-DD HH:mm') : '', 'date_to', obj)
          }}
        />
        {types ? (
          <FormControl>
            <InputLabel size={'small'} id={`simple-select-3-${id}`}>
              Тип чату
            </InputLabel>
            <Select
              className={classes.searchField}
              size={'small'}
              labelId={`simple-select-3-${id}`}
              id={`simple-select-3-${id}`}
              label='Тип чату'
              value={status}
              onChange={(event) => {
                setStatus(event.target.value)
                handleSearch(event.target.value, 'status', obj)
              }}
            >
              <MenuItem value={''}>Усі</MenuItem>
              {types.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null}
        <Button className={classes.button} variant='contained' color='primary' onClick={clearFilters}>
          {loadingSearch ? <CircularProgress size={25} /> : 'Очистити фільтр'}
        </Button>
      </Box>
      <TableContainer sx={{ height: 'calc(100vh - 220px)' }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  sx={{ fontWeight: 'bold' }}
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRating?.map((row: any, index) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={index}>
                  {columns.map((column) => {
                    if (!Object.hasOwn(row, column.id)) return null
                    const value = row[column.id]
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {String(value)}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
