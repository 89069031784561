import React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { FormControl, InputLabel, MenuItem, Select, Stack } from '@mui/material'
import { useAppDispatch } from '../../../store'
import { refusalAnswer } from '../../../store/chatsWritten/actions'
import { IMessageWritten } from '../../../store/chatsWritten/types'
import { EditorDefault } from '../../Editors/EditorDefault'
import { parseLink } from '../../../utils/parseLink'
import { AlertMessage } from '../../AlertMessage'
import { valiarntsRefusal } from '../../../utils/config'
import { changeFio } from '../../../utils/changeFio'
import { styles } from './Styles'
import { RejectedWithValueAction } from '../../../store/types'

interface Props {
  closeHandler: () => void
  message: IMessageWritten
  open: boolean
}

export const RefusalModal: React.FC<Props> = ({ open, message, closeHandler }) => {
  const [loading, setLoading] = React.useState(false)
  const [refusalId, setRefusalId] = React.useState(String(message.refusal_id) || '')
  const [error, setError] = React.useState(false)
  const [errors, setErrors] = React.useState('')

  const editorRef = React.useRef<any | null>(null)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (editorRef.current && message) {
      editorRef.current.setContent(
        valiarntsRefusal
          .find((it) => it.absnum === Number(refusalId))
          ?.body({
            messageId: message.part_id ? `${message.absnum}/${message.part_id}` : String(message.absnum),
            siteAddress: message.site_url,
            siteName: message.site_name,
            clientName: message.client_fio?.split(' ')[1] || '',
            fioExpert: message.expert_fio,
          })
      )
    }
  }, [refusalId, message])

  const handleSaveAndswer = async () => {
    if (!refusalId) return setError(true)
    if (!message.absnum || loading || !refusalId) return null
    const body = editorRef.current.getContent()

    dispatch(
      refusalAnswer({
        message_id: message.absnum,
        part_id: message.part_id,
        body,
        refusal_id: Number(refusalId),
      })
    ).then((res) => {
      if (res.meta.requestStatus === 'rejected') {
        const { error } = res as RejectedWithValueAction<any, any>
        if (error.message) {
          setErrors(error.message)
        } else {
          setErrors('Сталася помилка')
        }
        setLoading(false)
      } else {
        setLoading(false)
        closeHandler()
      }
    })
  }

  return (
    <Modal
      open={open}
      onClose={closeHandler}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Box sx={{ ...styles, width: 960 }}>
        <Typography id='modal-modal-title' variant='h6' component='h2'>
          Відмовити
        </Typography>
        <Stack pt={2}>
          <FormControl fullWidth>
            <InputLabel id='demo-simple-select-label' size='small'>
              Вкажіть причину відмови
            </InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              size='small'
              error={error}
              value={refusalId}
              label='Вкажіть причину відмови'
              onChange={(event) => setRefusalId(event.target.value)}
            >
              <MenuItem value={0} />
              {valiarntsRefusal.map((item) => (
                <MenuItem key={item.absnum} value={item.absnum}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
        <Stack pt={4} direction={'column'}>
          <Typography id='modal-modal-title' variant='h6' component='h2'>
            Відповідь
          </Typography>
          <EditorDefault editorRef={editorRef} />
        </Stack>
        <Stack pt={4} direction={'row'} justifyContent={'space-around'}>
          <Button variant={'contained'} color={'error'} onClick={handleSaveAndswer}>
            Відправити
          </Button>
          <Button variant={'outlined'} color={'inherit'} onClick={closeHandler}>
            Закрити
          </Button>
        </Stack>
        {errors ? <AlertMessage type='error' message={errors} /> : null}
      </Box>
    </Modal>
  )
}
