import React from 'react'
import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material'
import { Title } from '../Title'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { UserLogName, Roles, UserStatus, CHANNELS_ID } from '../../utils/consts'
import { useSearch } from '../../hooks/useSearch'
import moment from 'moment'
import { BasicDatePicker } from '../../ui/BasicDatePicker'
import { SelectSearch } from '../../ui/SelectSearch'
import { useAppDispatch } from '../../store'
import { getUserLogs } from '../../store/users/actions'
import DeleteIcon from '@mui/icons-material/Delete'

const formatDate = 'YYYY-MM-DD HH:mm:ss'

interface Column {
  id: 'fio' | 'expert_id' | 'status' | 'created_at' | 'products' | 'is_type'
  label: string
  minWidth?: number
  align?: 'right' | 'left' | 'center'
  format?: (value: number) => string
}

const columns: readonly Column[] = [
  { id: 'expert_id', label: 'UserID' },
  { id: 'fio', label: 'ПІБ' },
  { id: 'created_at', label: 'Дата' },
  {
    id: 'products',
    label: 'Продукти',
    minWidth: 200,
  },
  {
    id: 'status',
    label: 'Статус',
  },
  {
    id: 'is_type',
    label: 'Тип',
  },
]

interface Props {
  channels: CHANNELS_ID
}

export const UserLogs: React.FC<Props> = ({ channels }) => {
  const [fio, setFio] = React.useState('')
  const [siteId, setSiteId] = React.useState('')
  const [isType, setIsType] = React.useState('')
  const [from, setFrom] = React.useState<Date | null>(null)
  const [to, setTo] = React.useState<Date | null>(null)
  const [page, setPage] = React.useState(1)

  const { loading, handleSearch } = useSearch({ actionThunk: getUserLogs })
  const { users, configurations } = useSelector((store: IStore) => store)
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    dispatch(getUserLogs({ page, channels }))
  }, [])

  const obj = {
    fio,
    site_id: siteId,
    is_type: isType,
    date_from: from ? moment(from).format(formatDate) : '',
    date_to: to ? moment(to).format(formatDate) : '',
    page: String(1),
    channels: String(channels),
  }

  const handleChangeFilter = (value: string, key: string) => {
    setPage(1)
    handleSearch(value, key, { ...obj, [key]: value })
  }

  const handleClearFilter = () => {
    setPage(1)
    setFio('')
    setSiteId('')
    setIsType('')
    setFrom(null)
    setTo(null)
    handleSearch('1', 'page')
  }

  const handleChangePage = (event: unknown, page: number) => {
    if (!users.userLogs) return null
    if (users.userLogs.pages >= page) {
      setPage(page)
      handleSearch(String(page), 'page', { ...obj, page: String(page) })
    }
  }

  return (
    <Paper sx={{ m: 2, p: 2 }}>
      <Title title={'Логи'} color={'default'} />
      <Stack my={2} spacing={2} direction={'row'}>
        <TextField
          size={'small'}
          id='outlined-basic-id'
          label='Пошук по ПІБ'
          variant='outlined'
          value={fio}
          onChange={(event) => {
            setFio(event.target.value)
            handleChangeFilter(event.target.value, 'fio')
          }}
        />

        <BasicDatePicker
          style={{ width: '223px' }}
          id={'from'}
          label='Від'
          value={from}
          onChange={(date) => {
            setFrom(date)
            handleChangeFilter(date ? moment(date).format(formatDate) : '', 'date_from')
          }}
        />
        <BasicDatePicker
          style={{ width: '223px' }}
          id={'to'}
          label='До'
          value={to}
          onChange={(date) => {
            setTo(date)
            handleChangeFilter(date ? moment(date).format(formatDate) : '', 'date_to')
          }}
        />

        <SelectSearch
          style={{ width: '223px' }}
          label='Продукт'
          value={siteId}
          options={configurations.all?.map((item) => ({ value: String(item.absnum), label: item.name })) || []}
          onChange={(value) => {
            setSiteId(value)
            handleChangeFilter(value, 'site_id')
          }}
        />

        <FormControl style={{ width: '223px' }}>
          <InputLabel id='simple-select-label3' size={'small'}>
            Тип
          </InputLabel>
          <Select
            size={'small'}
            labelId='simple-select-label3'
            id='simple-select3'
            value={isType}
            label='Тип'
            onChange={(event) => {
              setIsType(event.target.value)
              handleChangeFilter(event.target.value, 'is_type')
            }}
          >
            <MenuItem value={''}>Усі</MenuItem>
            {Object.keys(UserLogName).map((item, index) => (
              <MenuItem key={`${index}_${item}`} value={Number(item)}>
                {UserLogName[item].title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <IconButton color={'error'} onClick={handleClearFilter}>
          {loading ? <CircularProgress size={24} /> : <DeleteIcon />}
        </IconButton>
      </Stack>
      <TableContainer sx={{ maxHeight: 'calc(100vh - 287px)' }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns.map((column) => {
                if (!users.user || ![Roles.ADMIN, Roles.CHIEF].includes(users.user.role)) return null
                return (
                  <TableCell
                    sx={{ fontWeight: 'bold' }}
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.userLogs?.logs?.map((row, index) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.absnum}>
                  {columns.map((column) => {
                    const value = row[column.id]
                    if (Array.isArray(value))
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value.length > 3 ? (
                            <>
                              {value.slice(0, 3).map((item) => (
                                <div key={`${item.absnum}_${index}`}>{item.name}</div>
                              ))}{' '}
                              і т. д.
                            </>
                          ) : (
                            value.map((item) => <div key={`${item.absnum}_${index}`}>{item.name}</div>)
                          )}
                        </TableCell>
                      )
                    if (column.id === 'is_type')
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ color: UserLogName[value as string].color }}
                        >
                          {UserLogName[value as string].title}
                        </TableCell>
                      )
                    if (column.id === 'status')
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ color: UserStatus[value as string].color2 }}
                        >
                          {UserStatus[value as string].title}
                        </TableCell>
                      )
                    if (column.id === 'created_at')
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {moment(value).format(formatDate)}
                        </TableCell>
                      )
                    return (
                      <TableCell key={column.id} align={column.align}>
                        {value as string}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Pagination
        sx={{ display: 'flex', justifyContent: 'flex-end', pt: 2 }}
        count={users.userLogs?.pages}
        page={page}
        onChange={handleChangePage}
      />
    </Paper>
  )
}
