import { createAsyncThunk } from '@reduxjs/toolkit'
import { ChatsUnauthorizedActions } from '.'
import { buildQuery } from '../../utils/buildQuery'
import { callApi } from '../../utils/callApi'
import { IChatOline } from '../chatsOnline/types'
import { IChatsUnauthorizedState } from './types'

export const getChats = createAsyncThunk<IChatsUnauthorizedState['chats'], { status: number; page?: number }>(
  'chatsUnauthorized/getChats',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-unauthorized?${query}` : '/admin-chat-unauthorized',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getChatsByPage = createAsyncThunk<IChatsUnauthorizedState['chats'], { status: number; page?: number }>(
  'chatsUnauthorized/getChatsByPage',
  async (payload, thunkAPI) => {
    try {
      const query = buildQuery(payload)

      return callApi({
        method: 'get',
        path: query ? `/admin-chat-unauthorized?${query}` : '/admin-chat-unauthorized',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getMessages = createAsyncThunk<IChatOline, { id: number; page: number }>(
  'chatsUnauthorized/getMessages',
  async (payload, thunkAPI) => {
    try {
      const { id, page } = payload
      return callApi({
        method: 'get',
        path: page ? `/admin-chat-unauthorized/messages/${id}?page=${page}` : '/admin-chat-unauthorized',
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const getCurrentChat = createAsyncThunk<IChatsUnauthorizedState['currentChat'], number>(
  'chatsUnauthorized/getCurrentChat',
  async (payload, thunkAPI) => {
    try {
      return callApi({
        method: 'get',
        path: `/admin-chat-unauthorized/chat/${payload}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCloseChatR {
  unauthorized_id: number
  appeal_id: number
}
export const closeChat = createAsyncThunk<IChatsUnauthorizedState['currentChat'], TCloseChatR>(
  'chatsUnauthorized/closeChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-unauthorized/close`,
      })) as IChatsUnauthorizedState['currentChat']
      thunkAPI.dispatch(ChatsUnauthorizedActions.updateChats(resp))
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const captureChat = createAsyncThunk<IChatsUnauthorizedState['currentChat'], number>(
  'chatsUnauthorized/captureChat',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-unauthorized/capture/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TPassChatR {
  unauthorized_id: number
  user_id: number
}
export const passChat = createAsyncThunk<IChatsUnauthorizedState['currentChat'], TPassChatR>(
  'chatsUnauthorized/passChat',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      const resp = (await callApi({
        method: 'post',
        data,
        path: `/admin-chat-unauthorized/pass`,
      })) as IChatsUnauthorizedState['currentChat']
      return resp
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

interface TCreateMessageR {
  unauthorized_id: number
  message: string
}
export const createMessage = createAsyncThunk<void, TCreateMessageR>(
  'chatsUnauthorized/createMessage',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        path: `/admin-chat-unauthorized/message`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const readMessage = createAsyncThunk<void, number>(
  'chatsUnauthorized/readMessage',
  async (payload, thunkAPI) => {
    try {
      const id = payload
      return callApi({
        method: 'get',
        path: `/admin-chat-unauthorized/read-message/${id}`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const uploadFile = createAsyncThunk<{ link: string }, FormData>(
  'chatsUnauthorized/uploadFile',
  async (payload, thunkAPI) => {
    try {
      const data = payload
      return callApi({
        method: 'post',
        data,
        isFormData: true,
        path: `/admin-chat-unauthorized/upload`,
      })
    } catch (e) {
      return thunkAPI.rejectWithValue(e)
    }
  }
)

export const updateNotifications = createAsyncThunk<
  IChatsUnauthorizedState['currentChat'],
  { unauthorized_id: number; is_notifications: number }
>('chatsUnauthorized/updateNotifications', async (payload, thunkAPI) => {
  try {
    const data = payload
    return callApi({
      method: 'post',
      data,
      path: '/admin-chat-unauthorized/notifications',
    })
  } catch (e) {
    return thunkAPI.rejectWithValue(e)
  }
})
