import React from 'react'
import { Box, Alert, AlertTitle } from '@mui/material'

interface Props {
  type: 'success' | 'error' | 'warning'
  message: string
  style?: React.CSSProperties
}
export const AlertMessage: React.FC<Props> = ({ type, message, style }) => {
  return (
    <Box mt={2} width={'100%'} style={style}>
      <Alert severity={type}>
        <AlertTitle>{message}</AlertTitle>
      </Alert>
    </Box>
  )
}
