import jwt_decode from 'jwt-decode'
import { TOKEN } from './config'
import { callApi } from './callApi'

const cash: {
  [key: string]: {
    exp?: number
    fio?: string
    id?: number
    type?: string
    username?: string
    rights?: { [x: number]: number }
    iat?: number
  }
} = {}

export interface ReturnType {
  success: boolean
  token: string
  exp?: number
  fio?: string
  id?: number
  type?: string
  username?: string
  rights?: { [x: number]: number }
  iat?: number
}

const returnFailObj = {
  success: false,
  token: '',
}

const returnFail = (): ReturnType => {
  localStorage.clear()
  return returnFailObj
}

const refresh = async (): Promise<ReturnType> => {
  const refreshToken = localStorage.getItem(TOKEN.refresh)
  if (!refreshToken) return returnFail()

  const decodeToken: {
    exp?: number
    iat?: number
  } = jwt_decode(refreshToken || '')

  if (!decodeToken) {
    console.log('Token can not be decoded')
    return returnFail()
  }

  if (!decodeToken.exp) {
    console.log('Token expired')
    return returnFail()
  }
  const isExpired = Date.now() >= decodeToken.exp * 1000

  if (isExpired) {
    return returnFail()
  }

  try {
    const data = (await callApi({
      path: '/admin-auth/refresh-tokens',
      method: 'post',
      refreshToken,
      data: {
        refreshToken,
      },
    })) as { accessToken: string; refreshToken: string }

    if (!data.accessToken || !data.refreshToken) {
      return returnFail()
    }
    const decodeAccessToken: {
      exp?: number
      iat?: number
    } = jwt_decode(data.accessToken || '')

    if (!decodeAccessToken) {
      return returnFail()
    }

    if (!decodeAccessToken.exp) {
      return returnFail()
    }

    cash[data.accessToken] = decodeAccessToken

    localStorage.setItem(TOKEN.access, data.accessToken)
    localStorage.setItem(TOKEN.refresh, data.refreshToken)

    return {
      success: true,
      token: data.accessToken,
      ...decodeAccessToken,
    }
  } catch (e) {
    return returnFail()
  }
}

export async function checkToken(): Promise<ReturnType> {
  const accessToken = localStorage.getItem(TOKEN.access)
  if (!accessToken) return returnFail()
  const token = accessToken
  let decodeToken: {
    at_id?: string
    authorized?: boolean
    exp?: number
    iat?: number
    usr_id?: string
  } = {}

  if (cash[token]) {
    decodeToken = cash[token]
  } else {
    decodeToken = jwt_decode(token || '')
  }

  if (!decodeToken) {
    return returnFail()
  }

  if (!decodeToken.exp) {
    return returnFail()
  }

  const isExpired = Date.now() >= decodeToken.exp * 1000 - 1000 * 60 * 60 * 6
  if (isExpired) {
    delete cash[token]
    const result = await refresh()
    return result
    // return returnFail();
  }

  if (!cash[token]) {
    cash[token] = decodeToken
  }

  return {
    success: true,
    token: accessToken,
    ...decodeToken,
  }
}
