import React from 'react'
import { Box } from '@mui/material'
import styled from 'styled-components'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import { InputMassage } from '../InputMassege'
import { IChat, IChatOline, IMessageOnline } from '../../store/chatsOnline/types'
import { useSelector } from 'react-redux'
import { IStore } from '../../store/types'
import { ChatMessage } from '../ChatMessage'
import { IGroupTemplate } from '../../store/templates/types'
import {
  IChatUnauthorized,
  IMessageUnauthorized,
  IMessagesUnauthorizedResponse,
} from '../../store/chatsUnauthorized/types'
import { changeFio, getFormFioShort } from '../../utils/changeFio'
import { getDateFormat } from '../../utils/getDateFormat'
import { parseLink } from '../../utils/parseLink'

interface Props {
  chat: IChatOline | IMessagesUnauthorizedResponse
  currentChat: IChat | IChatUnauthorized
  groups: IGroupTemplate[] | null
  isEdit?: boolean
  searchText?: string
  readMessage: (value: number, callback?: (success?: boolean) => void) => void
  changePage: (value: number, callback?: (success?: boolean) => void) => void
  saveFile: (
    value: File,
    callback: (success: boolean, data?: { payload?: { link: string }; error?: { message: string } }) => void
  ) => void
  createMessage: (value: string, callback: (success?: boolean) => void) => void
  handleEditMessage?: (id: number, value: string, callback: (success?: boolean) => void) => void
  handleAutoSave?: (value: string, callback: (success?: boolean) => void) => void | null
}

export const Chat: React.FC<Props> = ({
  chat,
  currentChat,
  groups,
  isEdit,
  searchText,
  readMessage,
  changePage,
  createMessage,
  handleEditMessage,
  saveFile,
  handleAutoSave,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | {
    target: HTMLElement
    message: IMessageOnline | IMessageUnauthorized
    vertical: number
    horizontal: number
  }>(null)
  const [editMessage, setEditMessage] = React.useState<null | IMessageOnline | IMessageUnauthorized>(null)
  const [loading, setLoading] = React.useState(false)
  const [isScroll, setIsScroll] = React.useState(true)

  const refMessagesWrapper = React.useRef<HTMLDivElement | null>(null)

  const { users } = useSelector((store: IStore) => store)
  const open = Boolean(anchorEl)

  React.useEffect(() => {
    if (refMessagesWrapper.current && isScroll) {
      refMessagesWrapper.current.scrollIntoView()
    }
  }, [refMessagesWrapper, currentChat?.chat, isScroll])

  const handleClick = (message: IMessageOnline | IMessageUnauthorized) => (event: React.MouseEvent<HTMLElement>) => {
    if (message.expert_id && isEdit) {
      event.preventDefault()
      setAnchorEl({
        target: event.currentTarget,
        message,
        vertical: event.clientY / 16,
        horizontal: event.clientX / 1.4,
      })
    }
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleEvent = () => {
    if (!chat || !users.user) return null
    if (users.user.id !== currentChat?.expert_id) return null
    const messages: any[] = []
    chat.messages.forEach((it) => {
      if (it.expert_id === 0 && it.is_read === 0) {
        messages.push(it)
      }
    })
    if (messages.length === 0) return null
    const message = messages[messages.length - 1]
    readMessage(message.absnum)
  }

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const element = event.currentTarget
    if (Math.abs(element.scrollHeight - element.scrollTop - element.clientHeight) < 300) {
      !isScroll && setIsScroll(true)
    } else {
      isScroll && setIsScroll(false)
    }
    const dif = element.scrollHeight * 0.02
    if (element.scrollTop < dif && !loading && currentChat && chat.page < chat.pages) {
      setLoading(true)
      changePage(chat.page + 1, () => setLoading(false))
    }
  }

  const onEditMessage = React.useCallback(
    (id: number, value: string, callback: (success?: boolean | { error: { message: string } }) => void) => {
      setEditMessage(null)
      if (handleEditMessage) {
        handleEditMessage(id, value, callback)
      } else {
        callback()
      }
    },
    [handleEditMessage]
  )

  return (
    <>
      <WrapperS overflow={'auto'} flex={1} onMouseEnter={handleEvent} onScroll={handleScroll}>
        <Box flex={1} display='flex' flexDirection='column' overflow={'auto'}>
          {chat.messages.map((message) => (
            <ChatMessage
              key={message.absnum}
              searchText={searchText}
              message={message}
              handleClick={handleClick(message)}
            />
          ))}
          <div ref={refMessagesWrapper} />
        </Box>
      </WrapperS>
      {editMessage ? (
        <Card
          sx={{
            mb: 1,
          }}
        >
          <CardHeader
            avatar={
              <Avatar sx={{ bgcolor: 'orange' }} aria-label='recipe'>
                {getFormFioShort(editMessage.expert_fio)}
              </Avatar>
            }
            action={
              <IconButton aria-label='settings' onClick={() => setEditMessage(null)}>
                <CloseIcon />
              </IconButton>
            }
            title={changeFio(editMessage.expert_fio)}
            subheader={getDateFormat(editMessage.adate)}
          />
          <CardContent>
            <Typography
              variant='body2'
              style={{ wordBreak: 'break-word' }}
              dangerouslySetInnerHTML={{
                __html: parseLink(editMessage.message),
              }}
            />
          </CardContent>
        </Card>
      ) : null}
      <InputMassage
        editMessage={editMessage}
        chatId={currentChat.absnum}
        draft_message={currentChat.draft_message}
        groups={groups}
        saveFile={saveFile}
        createMessage={createMessage}
        handleAutoSave={users.user?.id === currentChat?.expert_id ? handleAutoSave : undefined}
        handleEditMessage={onEditMessage}
      />
      <Menu
        id='demo-positioned-menu'
        aria-labelledby='demo-positioned-button'
        anchorEl={anchorEl?.target}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: anchorEl?.vertical || 'top',
          horizontal: anchorEl?.horizontal || 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={() => {
            anchorEl && setEditMessage(anchorEl.message)
            handleClose()
          }}
        >
          <ListItemIcon>
            <EditIcon fontSize='small' />
          </ListItemIcon>
          Редагувати
        </MenuItem>
      </Menu>
    </>
  )
}

const WrapperS = styled(Box)<{ active?: boolean }>``
