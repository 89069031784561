import React from 'react'
import Typography from '@mui/material/Typography'

interface Props {
  title: string
  mb?: number
  color?: string
}

export const Title: React.FC<Props> = ({ title, mb = 0, color = 'white' }) => {
  return (
    <Typography variant='h5' mb={mb} color={color}>
      {title}
    </Typography>
  )
}
